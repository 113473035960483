
.module-20 {
	.search-results-list {
		margin: 20px 0;
		padding: 0;
		border-top: 1px solid $tint-crest-white-darker;
		li {
			margin: 0;
			padding: 0;
			border-bottom: 1px solid $tint-crest-white-darker;
			&:before {
				display: none;
			}
			&.website .label {
				background: $sky-blue;
			}
			&.people .label {
				background: $crest-yellow;
				color: $crest-black;
			}
			&.course .label {
				background: $crest-red;
			}
		}
		a {
			display: block;
			text-decoration: none !important;
			//padding: 10px 0;
		}
		h3 {
			margin: 10px 0 5px;
			font-family: $font-family-serif;
			font-weight: 400;
			em {
				font-style: normal;
				font-weight: 400;
			}
		}
		.link {
			color: $quad-green;
			margin: 0 0 5px;
		}
		.summary {
			color: $crest-black;
			margin-top: 0;
		}
		.label {
			font-size: 12px;
			line-height: 12px;
			border-radius: 0;
			font-family: $font-family-sans-serif;
			font-weight: 400;
			text-transform: uppercase;
			padding: .4em .7em .3em;
			position: relative;
			top: -2px;
			left: 10px;
			margin-right: 20px;
			@media (min-width: $screen-md-min) {
				margin-right: 20px;
			}
		}
	}
}


.module-20 {
	.search-results-list {
		&.people {
			.label {
				margin-right: 20px;
			}
		}
	}
}


.module-20b {
	.course-search-results-list {
		margin: 20px 0;
		padding: 0;
		border-top: 1px solid $tint-crest-white-darker;
		li {
			margin: 0;
			padding: 0;
			border-bottom: 1px solid $tint-crest-white-darker;
			&:before {
				display: none;
			}
			&.website .label {
				background: $sky-blue;
			}
			&.people .label {
				background: $crest-yellow;
				color: $crest-black;
			}
			&.course .label {
				background: $crest-red;
			}
		}
		a {
			display: block;
			padding: 10px 0;
		}
		h3 {
			margin: 10px 0;
		}
		.link {
			color: $quad-green;
			margin: 0 0 5px;
		}
		.summary {
			color: $crest-black;
		}
		.label {
			font-size: 12px;
			line-height: 12px;
			border-radius: 0;
			font-family: $font-family-sans-serif;
			font-weight: 400;
			text-transform: uppercase;
			padding: .4em .7em .3em;
			position: relative;
			top: -2px;
			left: 10px;
		}
	}

}

.search-wrapper {
	.summary {
		color: $crest-black;
	}
}
