.module-45 {
    .wrapper {
        iframe {
            max-width: 1400px;
        }
    }
    
    &--apple {
        iframe {
            width: 100%;
            max-width: 660px;
            overflow: hidden;
            border-radius: 10px;
        }
    }
    
    &--soundcloud {
        iframe {
            
        }
    }

    &--spotify {
        iframe {
            border-radius: 12px;
        }
    }

}

body.home {
    .module-45 {
        .wrapper {
            max-width: 1400px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
