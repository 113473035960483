.cookie-optin-message {
    position: relative;
    min-height: 300px;
}

.cookie-optin-message__container {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    padding: 15px;

    text-align: center;

    background-color: $tint-crest-white;

    h2 {
        font-size: 32px;
        font-weight: 700;
        line-height: 135%;

        margin-bottom: 20px;
    }

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 160%;

        margin-bottom: 20px;

        color: $crest-black;
    }

    .btn {
        font-family: "Playfair Display";
        font-size: 24px;
        font-weight: 700;
        font-style: normal;
        line-height: 32px;

        display: inline-flex;
        align-items: center;

        padding: 18px 30px;
        /* identical to box height */

        text-align: center;

        color: white;
        background: $crest-blue;

        svg {
            margin-left: 15px;
        }
    }

    @media all and (min-width: 768px) {
        padding: 30px;
    }

    @media all and (min-width: 1200px) {
        padding: 60px;
    }
}
