.content-wrap__split {
    @media (min-width: 1200px) {
        display: flex;
        justify-content: space-between;
    }

    .module-3c__button {
        text-align: center;

        flex-basis: 100%;
    }

    .module-3c__title {
        @media all and (min-width: 1200px) {
            font-size: 18px;
        }
    }

    .module-3c__item--featured {
        img {
            @media all and (min-width: 992px) {
                max-width: none;
            }
        }

        .module-3c__title {
            @media all and (min-width: 1200px) {
                font-size: 24px;
            }
        }

        .module-3c__text {
            @media all and (min-width: 992px) {
                display: none;
            }
        }

        .module-3c__content {
            @media all and (min-width: 992px) {
                width: 600px;
                height: auto;

                @media all and (max-width: 1439px) {
                    padding: 15px;
                }
            }
        }

        .module-3c__feature {
            @media all and (min-width: 992px) {
                top: -60px;
                left: 0;
            }
        }
    }

    .content-wrap__item {
        @include spacer-p-t;
        .module-3c {
            padding-top: 0;
        }
        @media (min-width: 1200px) {
            width: calc(60% - 10px);
        }

        @media (min-width: 1440px) {
            width: calc(50% - 10px);
        }

        &:nth-child(2) {
            @media all and (min-width: 1200px) {
                width: calc(40% - 10px);
            }

            @media all and (min-width: 1440px) {
                width: calc(50% - 10px);
            }
        }

        .module-3c__item {
            flex-basis: calc(50% - 10px);
        }

        .module-3c__item--featured {
            flex-basis: 100%;
        }
    }
}

.module-3c__button {
    width: 100%;

    text-align: center;
}

.module-3c {
    @include spacer-p-t;
    .container {
        padding: 0;

        @media all and (min-width: 992px) {
            display: flex;

            max-width: 1400px;

            flex-wrap: wrap;
        }
    }

    .btn {
        margin: 30px auto;
    }
}

.module-3c__item {
    position: relative;

    display: block;

    margin-bottom: 30px;

    color: black;

    flex-basis: calc(33.333% - 10px);

    &:hover {
        color: $crest-black;
    }

    @media all and (min-width: 992px) {
        margin: 0 5px;
    }

    img {
        width: 100%;
    }

    &:not(.module-3c__item--featured) {
        .module-3c__text {
            @media all and (min-width: 992px) {
                display: none;
            }
        }
    }
}

.module-3c__item--featured {
    flex-basis: 100%;

    @media all and (min-width: 992px) {
        margin-bottom: 10px;
    }

    img {
        @media all and (min-width: 992px) {
            max-width: 500px;
        }

        @media (min-width: 1200px) {
            max-width: 600px;
        }

        @media (min-width: 1440px) {
            max-width: 800px;
        }
    }

    .module-3c__content {
        @media all and (min-width: 992px) {
            position: absolute;
            z-index: 1;
            right: 0;
            bottom: 0;

            display: flex;
            flex-direction: column;
            justify-content: center;

            width: calc(100% - 500px);
            height: 100%;
            padding: 30px;

            background-color: #FFFFFF;
        }

        @media (min-width: 1200px) {
            width: calc(100% - 600px);
        }

        @media (min-width: 1440px) {
            width: calc(100% - 800px);
        }
    }

    .module-3c__feature {
        @media all and (min-width: 992px) {
            top: 0;
            left: -160px;

            width: 160px;
            height: 60px;
        }

        @media (min-width: 1440px) {
            left: -180px;

            width: 180px;
            height: 72px;
        }

        span {
            @media all and (min-width: 992px) {
                font-size: 24px;
                line-height: 1;
            }

            @media (min-width: 1440px) {
                font-size: 32px;
            }
        }
    }

    .module-3c__title {
        @media all and (min-width: 992px) {
            font-size: 28px;
            line-height: 1.35;

            margin-bottom: 15px;
        }

        @media (min-width: 1200px) {
            font-size: 32px;

            margin-bottom: 20px;
        }

        @media (min-width: 1440px) {
            font-size: 34px;
        }
    }
}

.module-3c__content {
    position: relative;

    // padding: 0 15px 15px 15px;
    padding-bottom: 15px;

    @media all and (min-width: 992px) {
        padding-top: 30px;
        padding-bottom: 30px;
        padding-right: 0;
        padding-left: 0;
    }
}

.module-3c__feature {
    font-size: 14px;

    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: max-content;
    height: 56px;
    margin-top: calc(-56px/2);
    margin-bottom: 10px;
    // margin-left: -15px;
    padding: 6px 15px;

    text-align: center;

    color: white;
    background-color: $crest-red;

    align-self: flex-start;

    @media all and (min-width: 992px) {
        position: absolute;
        top: -64px;

        height: 64px;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0;
    }

    span {
        @include serif-r;

        font-size: 18px;

        display: block;

        @media all and (min-width: 992px) {
            font-size: 22px;
        }
    }
}

.module-3c__date {
    font-size: 12px;

    display: inline-block;

    margin-bottom: 5px;

    @media all and (min-width: 992px) {
        font-size: 14px;
    }
}

.module-3c__title {
    @include serif-b;

    font-size: 22px;
    font-weight: 600;
    line-height: 30px;

    margin-top: 0;
    margin-bottom: 10px;

    text-align: left;
    letter-spacing: 0;

    @media all and (min-width: 992px) {
        font-size: 20px;
        line-height: 1.3;

        margin-bottom: 10px;
    }

    @media (min-width: 1200px) {
        font-size: 24px;

        margin-bottom: 20px;
    }
}

.module-3c__text {
    font-size: 16px;
    line-height: 1.3;

    @media all and (min-width: 992px) and (max-width: 1200px) {
        font-size: 14px;
    }
}

.module-3c__link {
    @include serif-b;

    font-size: 16px;
    font-weight: 600;
    line-height: 150%;

    color: $crest-black;

    &:after {
        font-family: FontAwesome;

        padding-left: 5px;

        content: "\f101";
    }

    &.linktype--internal:after {
        content: "\f178";
    }

    &.linktype--external:after {
        content: "\f08e";
    }

    &.linktype--modal:after {
        content: "\f2d0";
    }

    &.linktype--video:after {
        content: "\f01d";
    }

    &.linktype--document:after {
        content: "\f016";
    }
}

.content-wrap__feed-wrap {
    overflow-x: hidden;

    height: auto;
}

.twitter-timeline {
    overflow-x: hidden;

    iframe {
        height: 100%;
    }
}

.module-wrap {
    .content-wrap__wrapper {
        .module-3c__title {
            font-family: $font-family-serif;
        }

        .module-3c {
            @media all and (max-width: 1439px) {
                .module-3c__content {
                    @media all and (min-width: 992px) {
                        padding-top: 10px;
                    }
                }

                .module-3c__item {
                    @media all and (min-width: 992px) {
                        margin: 0 2px;
                    }
                }

                .module-3c__title {
                    margin-top: 0;

                    @media all and (min-width: 992px) {
                        font-size: 16px;

                        margin-top: 10px;
                    }
                }

                .module-3c__text {
                    p {
                        @media all and (min-width: 992px) {
                            font-size: 14px;
                        }
                    }
                }

                .module-3c__link {
                    @media all and (min-width: 992px) {
                        font-size: 14px;
                    }
                }

                .module-3c__feature {
                    @media all and (min-width: 992px) {
                        font-size: 12px;

                        top: -52px;

                        width: 120px;
                        height: 52px;
                    }

                    span {
                        @media all and (min-width: 992px) {
                            font-size: 18px;
                        }
                    }
                }

                .module-3c__item--featured {
                    @media all and (min-width: 992px) {
                        margin-bottom: 4px;
                    }

                    .module-3c__feature {
                        @media all and (min-width: 992px) {
                            top: -52px;
                            // left: -120px;
                            left: 0;

                            width: 120px;
                            height: 52px;
                        }
                    }

                    .module-3c__content {
                        @media all and (min-width: 992px) {
                            width: calc(100% - 300px);
                            max-width: none;
                            height: auto;
                            padding: 15px;
                        }
                    }

                    .module-3c__title {
                        @media all and (min-width: 992px) {
                            font-size: 18px;

                            margin-top: 0;
                            margin-bottom: 5px;
                        }
                    }

                    img {
                        @media all and (min-width: 992px) {
                            width: 100%;
                            max-width: none;
                        }
                    }
                }
            }
        }


        &.col-lg-9 {
            // @media all and (max-width: 1599px) {
            .content-wrap__split {
                flex-direction: column;

                .content-wrap__item {
                    width: 100%;
                }
            }

            // }
            .module-3c {
                @media all and (max-width: 1199px) {
                    .module-3c__item--featured {
                        margin-bottom: 30px;

                        .module-3c__content {
                            position: relative;

                            width: 100%;
                        }
                    }

                    .module-3c__item {
                        margin-bottom: 30px;

                        flex-basis: 100%;
                    }
                }

                .module-3c__content {
                    @media all and (min-width: 992px) {
                        padding-top: 10px;
                    }
                }

                .module-3c__item {
                    @media all and (min-width: 992px) {
                        margin: 0 2px;
                    }
                }

                .module-3c__title {
                    margin-top: 0;

                    @media all and (min-width: 992px) {
                        font-size: 16px;

                        margin-top: 10px;
                    }
                }

                .module-3c__text {
                    p {
                        @media all and (min-width: 992px) {
                            font-size: 14px;
                        }
                    }
                }

                .module-3c__link {
                    @media all and (min-width: 992px) {
                        font-size: 14px;
                    }
                }

                .module-3c__feature {
                    @media all and (min-width: 992px) {
                        font-size: 12px;

                        top: -52px;

                        width: 120px;
                        height: 52px;
                    }

                    span {
                        @media all and (min-width: 992px) {
                            font-size: 18px;
                        }
                    }
                }

                .module-3c__item--featured {
                    @media all and (min-width: 992px) {
                        margin-bottom: 4px;
                    }

                    .module-3c__feature {
                        @media all and (min-width: 992px) {
                            top: -52px;
                            // left: -120px;
                            left: 0;

                            width: 120px;
                            height: 52px;
                        }
                    }

                    .module-3c__content {
                        @media all and (min-width: 992px) {
                            width: calc(100% - 300px);
                            max-width: none;
                            height: auto;
                            padding: 15px;
                        }
                    }

                    .module-3c__title {
                        @media all and (min-width: 992px) {
                            font-size: 18px;

                            margin-top: 0;
                            margin-bottom: 5px;
                        }
                    }

                    img {
                        @media all and (min-width: 992px) {
                            width: 100%;
                            max-width: none;
                        }
                    }
                }
            }
        }
    }
}
