.module-24 {
	margin: 50px auto;
	padding: 0 15px;
	overflow: hidden;
	@media (max-width: 992px) {
		padding: 0 15px;
	}

	p > a {
		//color: $crest-red;
		//font-style: italic;
		text-decoration: underline;
	}
	ul {
      padding-left: 20px !important;
      margin: 20px auto 20px 20px !important;
      li {
        position: relative;
        margin: 5px 0 5px 20px !important;
      }
      li:before {
        content: "" !important;
        display: inline-block;
        width: 8px;
        height: 8px;
        position: relative;
        top: -2px;
        left: -15px;
        background-color: $crest-red;
        font-size: 8px;
      }
    }
	blockquote {
		font-style: italic;
		font-size: 18px;
		font-family: 'Playfair Display', serif;
		color: rgba($crest-black,0.8);
		border: 0;
		position: relative;
		padding-left: 55px;
		&:before {
			content:"\f10d";
			font-family: FontAwesome;
			color: $crest-yellow;
			font-size: 40px;
			font-style: normal;
			position: absolute;
			top: -4px;
			left: 0;
		}
		&:after {
			content: "\f10d";
			font-family: FontAwesome;
			color: $crest-yellow;
			font-size: 40px;
			font-style: normal;
			position: absolute;
			right: 0px;
			bottom: 0;
			transform: rotate(180deg);
		}
		p {
			line-height: 1.7;
		}
	}

	//float every second image to the left or right
	img.img-index {
		width: 100%;
		max-width: 100%;
		height: auto;
		@media (min-width: 768px) {
			max-width: 50% !important;
			height: auto !important;
			float: left;
			margin: 0 30px 30px 0;
			&.img-index-1,
			&.img-index-3,
			&.img-index-5,
			&.img-index-7,
			&.img-index-9,
			&.img-index-11
			&.img-index-13,
			&.img-index-15,
			&.img-index-17,
			&.img-index-19,
			&.img-index-21 {
				float: right;
				margin: 0 0 30px 30px;
			}
		}
	}

	//responsive videos
	@import "responsive-iframes";

}

.content-wrap__inner {
	.module-24 {
		max-width: 100%;
		margin-top: 0 !important;
		margin-bottom: 0 !important;
		+ .module-24 {
			margin-top: 0;
			margin-bottom: 0;
		}
	}
}

.cookiePolicy {
	.module-24 {
		max-width: 100%;
		margin-top: 0 !important;
		margin-bottom: 0 !important;
		background: transparent;
		padding: 0;
	}
}

// body.home {
body.home {
	.module-wrap {
		.module-24 {
			max-width: 1400px;
			margin: 0 auto;
			padding-top: 30px;
			padding-bottom: 30px;
			@media (min-width: $screen-sm-min) {
				padding-top: 45px;
				padding-bottom: 45px;
			}
			@media (min-width: $screen-md-min) {
				padding-top: 60px;
				padding-bottom: 60px;
			}
			> .inner {
				margin-left: auto;
				margin-right: auto;
				@media (min-width: $screen-sm-min) {
					width: 83.33333%;
				}
				@media (min-width: $screen-md-min) {
					width: 66.66667%;
				}
				> p:last-child {
					margin-bottom: 0;
				}
			}
			h2 {
				@include serif-r;
				font-weight: 400;
				font-size: $font-size-h3;
				text-align: center;
				margin-top: 0;
				margin-bottom: 0;
				padding-bottom: 30px;
				@media (min-width: $screen-md) {
					font-size: $font-size-h2;
					//padding-bottom: 40px;
				}
				@media (min-width: $screen-lg) {
					font-size: $font-size-md;
					//padding-bottom: 70px;
				}
				@media (min-width: 1600px) {
					font-size: 50px;
				}
			}
		}
	}
}
