// Used by Events content type

.module-41 {
    margin-top: 30px;

    background-color: #FFFFFF;
    .fc .fc-toolbar { 
        @media (max-width: 640px) {
            flex-wrap: wrap;
        }
    }
    .fc-toolbar-chunk {
        @media (max-width: 640px) {
            width: 100%;
            margin-bottom: 10px;
        }
    }
    .home & {
        padding: 0 10%;

        @media (max-width: $screen-sm-max) {
            padding: 0 15px;
        }

        .module-title {
            text-align: left;
        }
        .module-sub-text {
            margin-left: 0;

            text-align: left;
        }
    }
    .panel-group {
        margin-bottom: 49px;
        padding: 0!important;
        .panel {
            border: 0;
            box-shadow: none;
        }
        .panel-heading {
            color: white;
            border-radius: 0;
            background: $crest-blue;
            @media (min-width:1024px) {
                display: none;
            }
            @media (min-width: 1024px) and (max-width: 1400px) {
                .wide & {
                    display: block;
                }
            }

            .sidenav & {
                display: block;
            }
        }
        .panel-title {
            font-family: "Roboto"!important;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;

            margin: 0;
            > a {
                display: flex;

                align-items: center;
                justify-content: space-between;
                .fa {
                    font-size: 22px;

                    margin-right: 0;
                    margin-left: auto;
                }
                &:not(.collapsed) {
                    .fa:before {
                        content: "\f106";
                    }
                }
            }
        }
        .panel-body {
            @media (max-width:1023px) {
                padding: 0 15px 20px;

                background: rgba(0, 146, 255, 0.05);
            }
            .sidenav & {
                background: rgba(0, 146, 255, 0.05);
            }
            @media (min-width:1024px) {
                padding: 0;

                border: 0!important;
            }
        }
    }
    .panel-collapse {
        @media(min-width: 1024px) {
            // display: block!important;
            height: auto!important;
        }
        @media (min-width: 1024px) and (max-width: 1400px) {
            .wide & {
            }
        }
    }

    @media all and (min-width: 1200px) {
        margin-top: 70px;
    }

    form {
        background: transparent;
    }


    .dataTables_wrapper {
        margin-bottom: 20px;
        @media all and (min-width: 992px) {
            margin-bottom: 40px;
        }
    }
    .dataTables_wrapper .dataTables_paginate .paginate_button.current {
        color: $crest-blue!important;
        border: 0;
        background: #F2F2F2;
        box-shadow: none;

        appearance: none;
    }
    .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
        color: white !important;
        border: 0;
        background: $crest-blue;
        box-shadow: none;

        appearance: none;
    }



    .dataTables_wrapper .dataTables_paginate {
        padding-bottom: 60px;
        .paginate_button {
            font-size: 18px;
            font-weight: 800;
            line-height: 21px;

            display: inline-flex;

            min-width: 48px;
            height: 48px;

            color: #BCBCBC!important;

            justify-content: center;
            // border: 1px solid #EFEFEF;
            align-items: center;
            appearance: none;
            &.previous,
            &.next {
                font-size: 16px;
                font-weight: 400;
                line-height: 150%;

                color: $crest-black!important;
            }
        }
    }
    .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
        color: white !important;
        background: $crest-blue;
    }
    .dataTables_wrapper select {
        @media (max-width:1023px) {
            display: inline-flex;

            min-width: calc(25% - 8.5px);
            max-width: calc(25% - 8.5px);
            margin-top: 20px;
            margin-right: 10px;
            margin-left: 0;
            // &#datatablesFilter-4 {
            //     margin-right: 0;
            // }
        }
        @media (min-width: 992px) and (max-width: 1200px) {
            .sidenav & {
                min-width: calc(50% - 5px);
                max-width: calc(50% - 5px);
                margin-right: 10px;
                &#datatablesFilter-2 {
                    margin-right: 0;
                }
                &#datatablesFilter-3 {
                    margin-right: 10px;
                }
                &#datatablesFilter-4 {
                    margin-right: 0;
                }
            }
        }
        @media (max-width:767px) {
            min-width: calc(50% - 5px);
            max-width: calc(50% - 5px);
            &:nth-of-type(even) {
                margin-right: 0;
            }
        }

        + label + select {
            margin-left: 0;

            // @media all and (min-width: 992px) {
            //     margin-left: 15px;
            // }

            // @media all and (max-width: 991px) {
            //     margin-top: 10px;
            // }
        }
    }

    .dtr-data {
        display: flex;
        flex-direction: column;
    }

    .dataTables_wrapper .dataTables_filter {
        margin-top: 0;
        margin-right: 0!important;
        margin-bottom: 0;
        @media (min-width: 992px) and (max-width: 1200px) {
            .sidenav & {
                min-width: 100%;
                max-width: 100%;
                margin: 0 auto 0 0;
            }
        }
        @media (min-width: 1200px) {
            .sidenav & {
                min-width: 50%;
                max-width: 50%;
                margin: 0 auto 20px 0;
                input[type="search"] {
                    min-width: 100%;
                }
            }
        }
        @media (min-width: 1024px) and (max-width: 1400px) {
            .wide & {
                min-width: 50%;
                max-width: 50%;
                margin: 0 auto 0 0;
                input[type="search"] {
                    min-width: 100%;
                }
            }
        }
        @media (min-width: 1024px) and (max-width: 1460px) {
            flex: 1;
        }
        // @media (min-width: 641px) and (max-width: 1023px) {
        //     min-width: calc(100% - 160px);
        // }
    }

    .dataTables_wrapper .dataTables_filter input {
        margin-left: 0;
    }

    .btn--clearfilters {
        margin-top: 0;
        margin-right: 5px;
        margin-left: 5px;
        padding: 10px 15px !important;

        text-decoration: underline;
        // color: $crest-blue;

        border-radius: 0 !important;

        @extend .btn--blue;
        // background-color: #EFEFEF;

        &:active {
            color: $crest-white;
            background-color: $crest-blue;
        }
        @media (min-width: 641px) and (max-width: 1023px) {
            min-width: 150px;
            margin-top: 20px;
        }
        @media (min-width: 1024px) {
            .sidenav & {
                min-width: calc(20% - 5px);
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
        @media (max-width: 640px) {
            width: 100%;
            margin-top: 20px;
            margin-left: 0;
        }
        @media (min-width: 1024px) and (max-width: 1460px) {
            // min-width: calc(20% - 5px);
            margin-right: 0;
            // margin-bottom: 20px;
        }
        @media (min-width: 1024px) and (max-width: 1220px) {
            padding-right: 5px!important;
            padding-left: 5px!important;
        }
        @media (min-width:992px) and (max-width: 1199px) {
            .sidenav & {
                width: 100%;
                margin-bottom: 20px;
            }
        }
    }

    .dataTables_wrapper .dataTables_length {
        margin-right: auto;
        padding-top: 0;
        padding-bottom: 0;
        .sidenav & {
            @media (min-width:1024px) {
                margin-top: 20px;
            }
            @media (min-width:1200px) {
                margin-top: 0;
                margin-bottom: auto;
            }
        }
        label {
            font-weight: 400;

            display: flex;

            align-items: center;
        }
        select {
            min-width: 80px;
            min-height: 44px;
        }
        @media (max-width: 1023px) {
            margin-top: 20px;
            margin-left: auto;

            text-align: left !important;
            select {
                margin-top: 0;
                margin-left: 5px;
            }
        }
        @media (min-width: 641px) and (max-width: 767px) {
            // width: calc(50% - 100px);
            margin-left: 10px;
            margin-left: auto;
        }
        @media (max-width: 640px) {
            display: inline-flex;
            float: left;

            width: calc(100% - 100px);
            margin-right: auto;
            margin-left: 0;
        }

        // @media all and (max-width: 767px) {
        //     padding-top: 10px;
        //     padding-bottom: 10px;

        //     text-align: left !important;
        // }
    }

    .form-control {
        font-size: 16px;
        font-weight: 400;
        line-height: 135%;

        min-height: 44px;

        color: $crest-black;
        box-shadow: unset;
        @media all and (max-width: 991px) {
            width: calc(100% - 10px);
        }

        @media all and (min-width: 992px) {
            // min-width: 110px;
            max-width: 280px;
        }
        @media (min-width: 1024px) and (max-width: 1400px) {
            .wide & {
                // width: 25%;
                // max-width: calc(25% - 5px)!important;
                // margin-bottom: 20px;
                &#datatablesFilter-1 {
                    margin-left: 0;
                }
                &#datatablesFilter-4 {
                    margin-right: 0;
                }
            }
        }
        @media (min-width: 1024px) {
            // margin-top: 20px;
            .wide & {
                margin-top: 0;
            }
            .sidenav & {
                margin-bottom: 20px;
            }
            &#datatablesFilter-1 {
                max-width: 135px;
                .sidenav & {
                    max-width: calc(20% - 5px);
                    margin-left: 0;
                }
            }
            &#datatablesFilter-2 {
                max-width: 135px;
                .sidenav & {
                    max-width: calc(20% - 5px);
                }
            }
            &#datatablesFilter-3 {
                max-width: 135px;
                .sidenav & {
                    max-width: calc(20% - 5px);
                }
            }
            &#datatablesFilter-4 {
                max-width: 135px;
                .sidenav & {
                    max-width: calc(20% - 5px);
                }
            }
        }
        @media (min-width: 1024px) and (max-width: 1460px) {
            // min-width: calc(20% - 5px);
            // margin-bottom: 20px;
            margin-left: 0;
        }
    }

    #datatablesFilter-2 {
        @media all and (min-width: 992px) {
            max-width: 150px;
        }
    }

    .dataTables_wrapper {
        form {
            // justify-content: center;
            justify-content: space-between;

            @media (max-width:1023px) {
                flex-wrap: wrap;
            }
            .sidenav & {
                flex-wrap: wrap;
                @media(min-width: 1024px) {
                    justify-content: flex-end;
                }
            }
            .wide & {
                @media (min-width: 1024px) and (max-width: 1400px) {
                    background: rgba(0, 146, 255, 0.05);

                    flex-wrap: wrap;
                }
            }
        }
    }

    select {
        margin-right: 2px;
        margin-left: 2px;
        padding: 8px 15px;

        color: $crest-black;
        border: 1px solid #CFCFCF !important;
        @media all and (min-width: 1200px) {
            margin-right: 15px;
            margin-left: 15px;
        }
        @media (min-width: 1024px) {
            margin-right: 5px;
            margin-left: 5px;
        }
        .ucc-theme--plrg & {
            border: 1px solid #BBBCBC !important;
            border-radius: 10px !important;
        }
    }

    input[type="search"] {
        // border-radius: 10px;
        // @media all and (min-width: 992px) {
        //     max-width: 300px;
        // }
        min-height: 44px;

        border: 1px solid #CFCFCF;
        &::placeholder {
            color: $crest-black;
        }
    }

    .dataTables_length select,
    select.form-control {
        position: relative;

        padding-right: 30px;

        background-image: url(/en/media/2017siteassets/images/arrow-down.svg);
        background-repeat: no-repeat;
        background-position: calc(100% - 10px) 50%;
        background-size: 15px 8px;

        appearance: none;
    }

    input[type="search"] {
        position: relative;

        background-image: url(/en/media/2017siteassets/images/search.svg);
        background-repeat: no-repeat;
        background-position: calc(100% - 10px) calc(50% + 1px);
        background-size: 16px 16px;


        @media (min-width: 1200px) {
            // min-width: 300px;
            min-width: 260px;
        }
        @media (min-width: 1024px) and (max-width: 1460px) {
            min-width: 100%;
        }
    }

    .btn__wrap {
        display: flex;

        margin-top: 10px;
        margin-bottom: 10px;

        align-items: center;
        .sidenav & {
            @media (min-width:1024px) {
                margin-top: 20px;
            }
            @media (min-width:1200px) {
                margin-top: 0;
                margin-bottom: auto;
            }
        }
        @media (min-width:1024px) {
            margin-top: 0;
            margin-bottom: 0;
        }
        @media (max-width:1023px) {
            margin-top: 20px;
            margin-bottom: 0;
            margin-left: auto;
        }
        @media (max-width: 640px) {
            display: inline-flex;

            // max-width: 100px;
            margin-right: 0;
            margin-left: auto;
        }
        @media all and (min-width: 1200px) {
            margin-left: 10px;
        }
        .btn {
            font-size: 22px;

            width: 44px;
            height: 44px;

            border-radius: 0;
        }
        .btn--grid {
            background-color: $crest-blue;

            .fa {
                color: white;
            }
        }

        &.list-active {
            .btn--grid {
                background-color: #EFEFEF;
                @media (max-width: 1023px) {
                    background-color: white;
                }

                .fa {
                    color: $crest-blue;
                }
            }

            .btn--list {
                background-color: $crest-blue;

                .fa {
                    color: white;
                }
            }
        }
        &.card-active {
            .btn--list {
                @media (max-width: 1023px) {
                    background-color: white;
                }
            }
        }
        &.calendar-active {
            .btn--list,
            .btn--grid {
                background-color: #EFEFEF;
                @media (max-width: 1023px) {
                    background-color: white;
                }

                .fa {
                    color: $crest-blue;
                }
            }

            .btn--calendar {
                background-color: $crest-blue;

                .fa {
                    color: white;
                }
            }
        }
        
    }

    .btn {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;

        padding: 12px 15px;

        transition: none;

        border-radius: 10px;

        &:before {
            transition: none;
        }

        + .btn {
            @media all and (max-width : 991px) {
                // margin-top: 10px;
            }

            @media all and (min-width : 992px) {
                margin-left: 10px;
            }
        }
    }


    td {
        .btn + .btn {
            @media all and (max-width : 991px) {
                margin-top: 10px;
            }
        }
    }

    .dataTables_wrapper {
        // display: flex;
        // flex-direction: column;
        .form-inline {
            flex-wrap: wrap;
            @media (min-width: 1461px) {
                // flex-wrap: nowrap;
            }
        }

        .btn--cardview {
            margin-left: 5px;
        }

        .dataTables_filter {
            min-height: 44px;
            margin-right: 5px;
            margin-left: 5px;
            @media all and (min-width: 1200px) {
                margin-right: 15px;
                margin-left: 15px;
            }
            @media (min-width:1024px) {
                margin-left: auto;
                padding: 0;
            }
            @media (max-width: 1023px) {
                width: 50%;
                margin-top: 20px;
                margin-right: 0;
                margin-left: 0;
                padding-top: 0;
                padding-bottom: 0;
            }
            @media (min-width: 641px) and (max-width: 767px) {
                float: left;
            }
            @media (max-width:640px) {
                width: 100%;
            }

            label {
                width: 100%;



                input {
                    width: 100%;
                }
            }
        }
    }

    table.dataTable {
        .btn {
            border-radius: 0;
        }
        &.calendar {
            display: none;
            & + .dataTables_info,
            & ~ .dataTables_paginate{
                display: none;
            }
        }
        &.cards {
            tbody {
                justify-content: start;
            }

            .dataTable__category {
                img {
                    margin-bottom: 10px;
                }
            }

            td,
            th {
                padding: 0 15px;

                border: 0 !important;
            }

            tr {
                position: relative;

                display: flex;
                flex-direction: column;

                padding-top: 0;
                padding-bottom: 20px;

                border: solid 1px $crest-black;
                background: white;
                @media (max-width:767px) {
                    width: 100%;
                    max-width: 462px;
                    margin-right: auto;
                    margin-left: auto;
                }
            }

            tbody {
                tr {
                    @media all and (min-width: 768px) {
                        width: calc(100%/2 - 5px);
                        margin-right: 10px;
                        margin-bottom: 10px;
                        &:nth-child(2n + 2) {
                            margin-right: 0;
                        }
                    }
                    @media all and (min-width: 992px) {
                        width: calc(100%/3 - 10px);
                        margin-right: 10px;
                        margin-bottom: 10px;
                        &:nth-child(2n + 2) {
                            margin-right: 10px;
                        }
                        &:nth-child(3n + 3) {
                            margin-right: 0;
                        }
                    }
                    .sidenav & {
                        @media all and (min-width: 992px) {
                            width: 100%;
                            max-width: 460px;
                            margin-right: auto;
                            margin-left: auto;
                        }
                        @media all and (min-width: 1200px) {
                            width: calc(100%/2 - 10px);
                            margin-right: 10px;
                            margin-bottom: 10px;
                            &:nth-child(2n + 2) {
                                margin-right: 0;
                            }
                        }
                        @media all and (min-width: 1600px) {
                            width: calc(100%/3 - 10px);
                            margin-right: 10px;
                            margin-bottom: 10px;
                            &:nth-child(2n + 2) {
                                margin-right: 10px;
                            }
                            &:nth-child(3n + 3) {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            .dataTable {
                &__image {
                    order: 0;
                }
                &__title {
                    font-size: 22px;
                    font-weight: 700;
                    line-height: 150%;

                    margin-bottom: 15px;

                    color: $crest-blue;

                    order: 1;
                    h2 {
                        font-size: inherit;
                        font-weight: inherit;
                        line-height: inherit;

                        margin-bottom: 0;
                    }
                }
                &__startsTime {
                    order: 2;
                }
                &__startsDate {
                    order: 3;
                }
                &__topic {
                    order: 4;
                }
                &__location {
                    order: 5;
                }
                &__description {
                    order: 6;
                }
                &__link {
                    order: 7;
                }
                &__startsTime,
                &__startsDate,
                &__topic,
                &__location {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 150%;

                    margin-bottom: 15px;

                    color: $crest-blue;
                }
                &__startsDate,
                &__location {
                    font-weight: 400;
                }
                &__description {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 150%;

                    margin-bottom: 25px;

                    color: $crest-black;
                }
                &__info {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 150%;

                    display: inline-block;

                    min-width: 120px;
                    padding: 0;

                    color: $crest-blue;
                    background: none;
                }
            }


            .dataTable__abstract {
                margin-bottom: 20px;
            }
        }



        td {
            vertical-align: top;
            // &:last-child {
            //     @media all and (min-width: 992px) {
            //         min-width: 336px;

            //     }
            // }
        }

        td {
            min-width: 100px;

            h2 {
                font-weight: 400;
            }

            p,
            li {
                line-height: 1.3333333;
            }

            p {
                margin-bottom: 5px;
            }
        }
        &:not(.cards) {
            @media (min-width:1024px) {
                border: solid 1px $crest-blue;
            }
            .dataTable__info {
                display: none;
            }
        }
        th,
        td {
            box-sizing: border-box;
        }
        thead {
            th:last-child:after {
                content: none;
            }
            th {
                font-size: 18px;
                font-weight: 700;
                line-height: 150%;

                padding: 20px 20px 20px 15px;

                color: $crest-blue;
                border-top: solid 1px $crest-blue!important;
                border-right: none!important;
                border-bottom: solid 1px $crest-blue!important;
                border-left: none!important;
                background: #F1F1F1;
                @media (min-width:1024px) {
                    color: white;
                    background: $crest-blue;
                }
            }
        }
        &:not(.cards) tbody {
            tr {
                td {
                    color: $crest-blue;
                    border-top: solid 1px $crest-blue!important;
                    border-right: none!important;
                    border-bottom: none!important;
                    border-left: none!important;
                }
                &.child td {
                    border-top: 1px solid #CFCFCF!important;
                }
                &.even,
                &.even + .child {
                    td {
                        background: rgba(0, 146, 255, 0.05);
                    }
                }
                &.child {
                    .dtr-details {
                        padding-left: 20px;
                        li {
                            display: flex;

                            width: 100%;

                            border-bottom: none;
                            span.dtr-title {
                                min-width: 114px;
                            }
                        }
                    }
                }
            }
        }
        td.dataTable__startsTime {
            font-weight: bold;
        }
        td.dataTable__title {
            font-weight: bold;
        }
        td.dataTable__description {
            color: $crest-black;
        }
        td.dataTable__topic span,
        .item__topic {
            font-weight: 700;
            line-height: 24px;

            display: inline-block;

            padding: 4px 15px;

            color: $crest-black;
            border-radius: 100px;
            background: $crest-yellow;
        }
        td.dataTable__location {
            font-weight: bold;

            color: $crest-black;
        }
        td.dataTable__image {
            position: relative;

            overflow: hidden;

            width: 100%;
            margin-bottom: 15px;
            padding: 0;

            background: #F1F1F2;
            &:before {
                display: block;

                padding-bottom: 43%;

                content: "";
            }
            img {
                position: absolute;
                top: 50%;
                right: -50%;
                left: -50%;

                max-width: 100%;
                height: auto;
                margin: auto;

                transform: translateY(-50%);
            }
        }
    }

    table.dataTable.no-footer {
        // border-bottom: 0;
        margin-bottom: 30px;
        &.cards {
            border-bottom: 0;
        }
    }

    table.dataTable tbody th,
    table.dataTable tbody td {
        @media all and (min-width: 1200px) {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
}

.module-41 {
    .fullcalendar {
        &.card-active,
        &.list-active {
            display: none;
        }
    }
    .dataTable__actions {
        // display: flex;
        // align-items: center;

        .btn {
            color: black;
            background-color: #FFFFFF;
        }
    }

    .dataTable__category {
        img,
        svg {
            float: left;

            max-width: 40px;
            margin-right: 10px;
        }

        p {
            float: right;

            width: calc(100% - 50px);
        }
    }

    .dataTable__title {
        h2 {
            font-size: 16px;

            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .dataTable__link {
        .linktype--external .fa-long-arrow-right:before {
            content: "\f08e";
        }

        .linktype--modal .fa-long-arrow-right:before {
            content: "\f2d0";
        }

        .linktype--video .fa-long-arrow-right:before {
            content: "\f01d";
        }

        .linktype--document .fa-long-arrow-right:before {
            content: "\f016";
        }
    }
}
.buttons-actions {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    span {
        font-weight: bold;
    }
}
.not-filters {
    display: flex;
    align-items: center;
    margin-top: 10px;
    @media all and (max-width: 640px) {
        flex-wrap: wrap;
    }
}
.filters {
    display: flex;
    margin-top: 10px;
    align-items: center;
    @media all and (max-width: 1023px) {
        flex-wrap: wrap;
    }
}