//all styles to go within body.ucc-theme--fossil-heritage

//Font
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,600;0,700;0,800;1,400&family=Roboto&display=swap");

//Colors
$nptswi-main: #19646A;
$nptswi-secondary: #00C690;
$nptswi-accent: #02CF52;
$nptswi-navy: #032E3E;
$nptswi-yellow: #FFE061;


body.ucc-theme--nptswi {
    font-family: "Montserrat",
    sans-serif;

    color: $nptswi-navy;

    .table {
        th {
            padding-top: 10px;
            padding-bottom: 10px;

            color: white;
            background-color: $nptswi-main;
        }
    }

    .bg-crest-black,
    .bg-crest-red,
    .bg-crest-blue,
    .bg-crest-yellow,
    .bg-sky-blue,
    .bg-quad-green,
    .bg-earth-red,
    .bg-ogham-stone,
    .bg-dawn-mauve,
    .bg-limestone,
    .bg-brand-twitter,
    .bg-brand-facebook,
    .bg-brand-instagram,
    .bg-brand-youtube,
    .bg-brand-linkedin {
        background-color: $nptswi-main;
    }

    /////////////////////////////////////
    // global nptswi styles
    /////////////////////////////////////


    .people-by-overall-category {
        .module18 {
            .az-people {
                .az-group {
                    figure {
                        figcaption {
                            span {
                                i {
                                    color: $nptswi-main;
                                }
                            }
                        }
                    }

                    .btn.btn-block {
                        font-family: "Montserrat", sans-serif;
                        font-weight: 700;

                        max-width: inherit;
                        margin-bottom: 0;

                        color: #FFFFFF;
                        background-color: $nptswi-navy;

                        &:hover {
                            transform: inherit;

                            color: #FFFFFF;
                            background-color: $nptswi-main;
                        }
                    }
                }
            }
        }
    }


    .collapse#collapseExample {
        li a:hover,
        li span {
            background-color: $nptswi-main !important;
        }
    }

    .content-wrap__inner {
        .breadcrumb {
            font-family: "Roboto", sans-serif;
        }

        & > .page-title {
            font-family: "Montserrat", sans-serif;
            font-weight: 800;
        }
    }

    .search-wrapper {
        border: none !important;
        background-color: $nptswi-navy;

        .mega-close {
            width: 40px;
            height: 40px;

            background-color: rgba(0, 0, 0, 0);

            &:before {
                font-size: 20px;

                top: -5px;

                width: 40px;
                height: 40px;
            }
        }

        form {
            input {
                border: none;
            }

            .input-group {
                .input-group-addon {
                    border: none !important;
                    background-color: $nptswi-main !important;

                    span {
                        color: #FFFFFF;
                    }
                }
            }
        }

        & + .colorbox {
            .bg-crest-red {
                background-color: $nptswi-main;
            }

            .bg-crest-yellow {
                background-color: $nptswi-secondary;
            }

            .bg-crest-blue {
                background-color: $nptswi-navy;
            }
        }
    }

    .remodal-arrow,
    .remodal-close {
        color: #FFFFFF;
        background-color: $nptswi-main;

        &:hover {
            color: #FFFFFF;
            background-color: $nptswi-navy;
        }
    }

    .hyper-list-bullet--horizontal.build-expore-list {
        .collapse.in {
            padding: 15px !important;

            li {
                margin-bottom: 0 !important;
                padding-bottom: 0 !important;
            }
        }
    }

    &.has-header-carousel .page-wrap {
        @media all and (max-width: 768px) {
            margin-top: 205px !important;
        }

        @media all and (max-width: 600px) {
            margin-top: 205px !important;
        }
    }

    #header {
        color: #FFFFFF;
        background-color: $nptswi-navy;

        @media all and (max-width: 768px) {
            height: 85px;

            .top-nav {
                bottom: -28px !important;
            }

            .navbar-carousel {
                bottom: -97px !important;
            }
        }

        @media all and (max-width: 600px) {
            height: 75px;

            .top-nav {
                bottom: -18px !important;
            }

            .navbar-carousel {
                bottom: -87px !important;
            }
        }

        .ucc-class {
            border-left: 2px solid #FFFFFF;

            h1 {
                font-family: "Montserrat", sans-serif;
                font-size: 24px;

                width: 100%;
                max-width: 350px;

                text-transform: uppercase;

                color: #FFFFFF;

                @media all and (max-width: 768px) {
                    max-width: 440px;

                    text-transform: inherit;
                }

                @media all and (max-width: 600px) {
                    font-size: 17px;

                    max-width: auto;
                }
            }
        }

        .top-nav {
            background-color: $nptswi-navy;

            @media all and (max-width: 1200px) {
                position: relative;
                bottom: -72px;

                .bg-crest-white,
                .bg-tint-crest-white,
                .bg-crest-black,
                .bg-crest-red,
                .bg-crest-blue,
                .bg-crest-yellow,
                .bg-sky-blue,
                .bg-quad-green,
                .bg-earth-red,
                .bg-ogham-stone,
                .bg-dawn-mauve,
                .bg-limestone,
                .bg-brand-twitter,
                .bg-brand-facebook,
                .bg-brand-instagram,
                .bg-brand-youtube,
                .bg-brand-linkedin,
                .header-custom-button-1 {
                    overflow: hidden;
                }
            }

            .search.satellite-search-toggle {
                i,
                span {
                    color: #FFFFFF;
                }

                @media all and (max-width: 1200px) {
                    padding-left: 0;

                    i {
                        font-size: 18px;
                    }

                    span {
                        font-size: 12px;
                    }
                }
            }

            .ucc-navigation-toggle {
                background-color: inherit;

                svg {
                    path {
                        fill: #FFFFFF;
                    }
                }
            }

            #dropdown-language {
                background-color: $nptswi-secondary;
            }

            .bg-crest-white,
            .bg-tint-crest-white,
            .bg-crest-black,
            .bg-crest-red,
            .bg-crest-blue,
            .bg-crest-yellow,
            .bg-sky-blue,
            .bg-quad-green,
            .bg-earth-red,
            .bg-ogham-stone,
            .bg-dawn-mauve,
            .bg-limestone,
            .bg-brand-twitter,
            .bg-brand-facebook,
            .bg-brand-instagram,
            .bg-brand-youtube,
            .bg-brand-linkedin,
            .header-custom-button-1 {
                padding: 0;

                background-color: inherit;

                a {
                    display: block;

                    width: 140px;
                    padding: 5px;

                    color: $crest-white;

                    @media all and (max-width: 1200px) {
                        background-color: inherit;

                        i {
                            font-size: 18px;
                        }

                        span {
                            font-size: 12px;
                        }
                    }

                    i {
                        vertical-align: -4px;

                        color: inherit;
                    }

                    span {
                        color: inherit;
                    }
                }
            }
        }

        .navbar-carousel {
            @media all and (max-width: 1200px) {
                bottom: -141px;

                background-color: #032E3E;

                .slick-prev,
                .slick-next {
                    height: 40px;
                }
            }

            @media (max-width: 991px) {
                width: 260px;
            }

            @media (max-width: 768px) {
                width: 100%;
            }

            .slick-slide {
                img {
                    padding: 10px;

                    background-color: #FFFFFF;

                    @media all and (max-width: 1200px) {
                        padding: 3px;
                    }
                }
            }

            .slick-arrow:before,
            .slick-arrow:after {
                color: #FFFFFF;
            }
        }

        nav.ucc-nav {
            background-color: $nptswi-navy;

            @media all and (max-width: 1199px) {
                top: 230px;
            }

            @media all and (max-width: 991px) {
                top: 188px;
            }

            @media all and (max-width: 768px) {
                top: 145px;
            }

            @media all and (max-width: 600px) {
                top: 135px;
            }

            .ucc-nav__dropdown__menu {
                background-color: $nptswi-navy;
            }

            .ucc-nav__back {
                svg {
                    path {
                        fill: $nptswi-accent;
                    }
                }
            }

            a {
                color: #FFFFFF;

               
            }

            @media all and (max-width: 1200px) {
                border-top: 3px solid $nptswi-accent;
            }
        }
    }

    .module-wrap {
        .content-wrap__wrapper {
        }

        .hyper-list-btn {
            a {
                &:hover {
                    color: #FFFFFF;
                    background-color: $nptswi-main;
                }
            }
        }

        .left-side-nav {
            @media all and (max-width: 1200px) {
                button {
                    background-color: $nptswi-main;
                }
            }
        }

        .hyper-list-wrap {
            &.hyper-list-bullet {
                a {
                    &:before {
                        background-color: $nptswi-main !important;
                    }
                }
            }
        }
    }

    #back-to-top {
        background-color: $nptswi-main;
    }

    //////////////////////////////////////
    //header
    //////////////////////////////////////

    .explore-toggle {
        display: none;
    }

    .ucc--header .top-nav {
        right: 0;
    }


    //////////////////////////////////////
    //ucc satellite site nav
    //////////////////////////////////////



    /////////////////////////////////////
    // MODULE 2
    /////////////////////////////////////

    .module-2 {
        .inner {
            &.bg-crest-white,
            &.bg-tint-crest-white,
            &.bg-crest-black,
            &.bg-crest-red,
            &.bg-crest-blue,
            &.bg-crest-yellow,
            &.bg-sky-blue,
            &.bg-quad-green,
            &.bg-earth-red,
            &.bg-ogham-stone,
            &.bg-dawn-mauve,
            &.bg-limestone,
            &.bg-brand-twitter,
            &.bg-brand-facebook,
            &.bg-brand-instagram,
            &.bg-brand-youtube,
            &.bg-brand-linkedin {
                background-color: $nptswi-navy;
                box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);

                img {
                }

                & + h2 {
                    font-family: "Montserrat", sans-serif;

                    color: $nptswi-navy;

                    i {
                        color: $nptswi-main;
                    }
                }

                &:after {
                    background-color: $nptswi-secondary;
                }
            }
        }
    }

    .hyper-list-container {
        .hyper-list-wrap {
            background-color: $nptswi-navy !important;

            h2 {
                font-weight: 900 !important;

                color: #FFFFFF !important;
            }

            ol {
                li {
                    a {
                        color: #FFFFFF !important;
                    }
                }
            }
        }
    }

    /////////////////////////////////////
    // MODULE 3b
    /////////////////////////////////////

    &.inner {
        .module-3b {
            .inner {
                height: auto;
                padding-bottom: 100px;

                a {
                    &:nth-child(1) {
                        position: relative;

                        width: 100%;
                    }

                    &:nth-child(2) {
                        position: relative;
                        top: inherit;
                        right: inherit;
                        left: inherit;

                        width: 55%;
                        margin-top: -12%;

                        border-top-width: 6px;
                        border-right-width: 6px;
                    }

                    &:nth-child(3) {
                        position: relative;
                        top: inherit;
                        right: inherit;
                        left: inherit;

                        width: 45%;

                        border-left-width: 0;
                    }

                    &:nth-child(4) {
                        position: relative;
                        top: inherit;
                        right: inherit;
                        left: inherit;

                        width: 55%;
                        margin-top: -2%;

                        border-top-width: 0;
                        border-right-width: 6px;
                    }

                    &:nth-child(5) {
                        position: relative;
                        top: inherit;
                        right: inherit;
                        left: inherit;

                        width: 45%;

                        border-left-width: 0;
                    }
                }
            }
        }
    }


    .module-3b {
        .module-title {
            font-family: "Montserrat", sans-serif;
            font-weight: 800;
        }

        .inner {
            a:hover {
                .inner__content {
                    background-color: rgba(25, 100, 106, 0.8);
                }
            }

            .inner__content {
                h3 {
                    font-family: "Montserrat", sans-serif;
                    font-weight: 800;
                }

                .date {
                    color: #FFFFFF;
                    background-color: $nptswi-main;
                }
            }
        }

        .btn {
            font-family: "Montserrat", sans-serif;
            font-weight: 700;

            color: $nptswi-navy;
            background-color: $nptswi-accent;

            i {
                color: $nptswi-navy;
            }
        }
    }

    .module-3c {
        .btn {
            color: $nptswi-navy;
            background-color: $nptswi-accent;

            i,
            .fa {
                color: $nptswi-navy;
            }
        }
    }


    /////////////////////////////////////
    // MODULE 4
    /////////////////////////////////////


    &.inner {
        .module-4 {
            .wrapper {
                .inner {
                    width: 50%;
                    padding-bottom: 50%;
                }
            }
        }
    }

    .module-4 {
        .module-title {
            font-family: "Montserrat", sans-serif;
            font-weight: 800;
        }

        .inner__wrap {
            .inner__icon {
                p {
                    color: #FFFFFF !important;
                }
            }

            .inner__content {
                .inner__info {
                    background-color: $nptswi-secondary !important;

                    p,
                    .link-name {
                        color: $nptswi-navy;
                    }
                }
            }
        }

        .inner__img {
            background-color: $nptswi-main;
        }
    }



    /////////////////////////////////////
    // MODULE 23
    /////////////////////////////////////

    .module-23 {
        .caption,
        .module-title {
            font-family: "Montserrat", sans-serif;
            font-weight: 800;
        }

        .square {
            border-radius: 200px;
            background-color: $nptswi-accent !important;
        }

        .slick-dots {
            li {
                button {
                    &:before {
                        color: $nptswi-main;
                    }
                }

                &.slick-active {
                    button {
                        &:before {
                            color: $nptswi-navy;
                        }
                    }
                }
            }
        }

        .item-wrap {
            &:nth-child(3n+2),
            &:nth-child(3n+3) {
                & > div:nth-child(3n+2) {
                    .square {
                        width: 22.3%;
                        padding-bottom: 22.3%;

                        background-color: $nptswi-main !important;
                    }
                }
            }
        }
    }

    /////////////////////////////////////
    // MODULE 43
    /////////////////////////////////////

    .module-43 {
        .dataTables_wrapper .dataTables_paginate .paginate_button.current,
        .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
            color: white !important;
            background: $nptswi-main;
        }

        .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
            color: white !important;
            background: $nptswi-navy;
        }

        .dataTables_length select,
        select.form-control {
            position: relative;

            padding-right: 32px;

            background-image: url(/en/media/academic/obstetricsandgynaecology/chevron.svg);
            background-repeat: no-repeat;
            background-position: calc(100% - 10px) 50%;
            background-size: 15px 8px;

            appearance: none;
        }

        input[type="search"] {
            position: relative;

            background-image: url(/en/media/academic/obstetricsandgynaecology/search.svg);
            background-repeat: no-repeat;
            background-position: calc(100% - 10px) calc(50% + 2px);
            background-size: 16px 16px;
        }

        .btn__wrap {
            display: flex;
            align-items: center;

            margin-top: 10px;
            margin-bottom: 10px;

            @media all and (max-width: 1199px) {
                justify-content: flex-end;

                width: 100%;
            }

            .btn--grid {
                background-color: $nptswi-main;

                .fa {
                    color: white;
                }
            }

            &.list-active {
                .btn--grid {
                    background-color: #EFEFEF;

                    .fa {
                        color: $nptswi-main;
                    }
                }

                .btn--list {
                    background-color: $nptswi-main;

                    .fa {
                        color: white;
                    }
                }
            }
        }

        .dataTable__category {
            svg path {
                stroke: $nptswi-navy;
            }
        }

        .btn--clearfilters {
            font-weight: 400;

            text-decoration: underline !important;

            color: $nptswi-main;

            @media all and (min-width: 992px) {
                margin-top: 0;
            }
        }

        tr {
            position: relative;
        }

        .btn {
            .fa {
                color: $nptswi-main;
            }
        }

        .form-control {
            border-color: $limestone;
            border-radius: 4px;
        }

        table {
            a {
                text-decoration: underline;
            }
        }

        .dataTable {
            &.cards {
                tr:nth-child(odd) {
                    background-color: $nptswi-main;
                }

                tr:nth-child(even) {
                    background-color: $nptswi-navy;
                }
            }

            &:not(.cards) {
                .dtr-data,
                .dataTable__actions {
                    .btn {
                        background-color: $nptswi-main;
                    }
                }

                table.dataTable.no-footer {
                    border-top-color: $nptswi-main;
                }

                td {
                    border: 0 !important;
                }

                th {
                    padding-top: 20px;
                    padding-bottom: 20px;

                    color: white;
                    border: 0 !important;
                    background-color: $nptswi-main;
                }

                th,
                td {
                    border-top: 1px solid $nptswi-main !important;
                }
            }

            tr.odd {
                h2 {
                    color: $nptswi-main;
                }

                i {
                    color: $nptswi-main;
                }

                .dataTable__category {
                    svg path {
                        stroke: $nptswi-main;
                    }
                }

                .dataTable__category,
                .dataTable__date {
                    color: $nptswi-main;
                }

                + .child {
                    color: $nptswi-main;
                }
            }

            tr.even {
                h2 {
                    color: $nptswi-navy;
                }

                i {
                    color: $nptswi-navy;
                }

                .dataTable__category,
                .dataTable__date {
                    color: $nptswi-navy;
                }

                .dtr-data,
                .dataTable__actions {
                    .btn {
                        color: $nptswi-navy;
                    }
                }

                + .child {
                    color: $nptswi-navy;
                }
            }

            &.cards {
                tbody tr {
                    border: 0;
                    box-shadow: none;
                }

                .dataTable__date {
                    position: absolute;
                    top: 50px;
                    left: 50px;

                    width: auto;
                }

                td,
                th {
                    border: 0 !important;
                }
            }
        }

        .dtr-data,
        .dataTable__actions {
            .btn {
                color: $nptswi-main;
            }
        }
    }

    /////////////////////////////////////
    // MODULE 7
    /////////////////////////////////////

    &.inner {
        .module-7 {
            .slideShowFact {
                .wrap {
                    .content {
                        & > div {
                            h2 {
                                font-size: 28px;
                            }
                        }
                    }
                }
            }
        }
    }

    .module-7 {
        max-width: inherit;

        .module-title {
            font-family: "Montserrat", sans-serif;
            font-weight: 800;
        }

        .slick-slide {
            position: relative;

            &:after {
                position: absolute;
                right: 0;
                bottom: 0;

                content: url("/en/media/2017siteassets/contentfiles/images/nptswi/module-7-slide-after.png");

                @media all and (max-width: 1200px) {
                    max-width: 180px;
                }
            }
        }

        .content {
            position: relative;

            .main-heading {
                font-family: "Montserrat", sans-serif !important;
                font-weight: 800 !important;
            }

            .btn {
                font-family: "Montserrat", sans-serif !important;
                font-weight: 800 !important;

                color: $nptswi-navy;

                i {
                    color: $nptswi-navy;
                }
            }
        }

        .slideShowFact {
            .slick-prev,
            .slick-next {
                &:before {
                    color: $nptswi-main !important;
                }
            }

            &.bg-crest-white .bg-img,
            &.bg-tint-crest-white .bg-img,
            &.bg-crest-black .bg-img,
            &.bg-crest-red .bg-img,
            &.bg-crest-blue .bg-img,
            &.bg-crest-yellow .bg-img,
            &.bg-sky-blue .bg-img,
            &.bg-quad-green .bg-img,
            &.bg-earth-red .bg-img,
            &.bg-ogham-stone .bg-img,
            &.bg-dawn-mauve .bg-img,
            &.bg-limestone .bg-img,
            &.bg-brand-twitter .bg-img,
            &.bg-brand-facebook .bg-img,
            &.bg-brand-instagram .bg-img,
            &.bg-brand-youtube .bg-img,
            &.bg-brand-linkedin .bg-img {
                &:before {
                    border-top-color: $nptswi-main !important;
                }
            }
        }

        .slick-slide {
            background-color: $nptswi-main;

            .content {
                &:before {
                    border-left-color: $nptswi-main;
                }
            }
        }
    }



    /////////////////////////////////////
    // MODULE 8
    /////////////////////////////////////


    .module-8 {
        .module-title {
            font-family: "Montserrat", sans-serif;
            font-weight: 800;
        }

        .slideShowSingle {
            .youtubevideo {
                &:before {
                    font-size: 96px;

                    color: $nptswi-main;
                }
            }
        }

        .slideShowSingle__nav {
            .outer {
                .inner {
                    div {
                        transition: 0.4s;

                        border: 5px solid $nptswi-main;
                    }
                }

                &.slick-current {
                    .inner {
                        div {
                            border: 5px solid $nptswi-secondary;
                        }
                    }
                }
            }
        }

        .slick-slide {
            .outer {
                blockquote {
                    font-family: "Montserrat", sans-serif;
                    font-weight: 400;
                    font-style: italic;

                    &:before,
                    &:after {
                        color: $nptswi-secondary;
                    }
                }

                h3 {
                    font-family: "Montserrat", sans-serif;
                    font-weight: 700;

                    & + p {
                        font-family: "Montserrat", sans-serif;
                        font-weight: 400;

                        color: $nptswi-navy;
                    }
                }

                .btn {
                    font-family: "Montserrat", sans-serif;

                    i {
                        color: $nptswi-navy;
                    }
                }
            }
        }
    }



    /////////////////////////////////////
    // MODULE 9
    /////////////////////////////////////


    .module-9 {
        .module-title {
            font-family: "Montserrat", sans-serif;
            font-weight: 800;
        }

        .slick-arrow {
            background-color: #FFFFFF;

            &:before {
                color: $nptswi-navy;
            }

            &:hover {
                background-color: $nptswi-navy;

                &:before {
                    color: #FFFFFF;
                }
            }
        }

        .multiple-wrap {
            position: relative;

            &:nth-child(1),
            &:nth-child(4),
            &:nth-child(7) {
                &:after {
                    position: absolute;
                    right: 0;
                    bottom: 0;

                    content: url("");
                }
            }

            &:nth-child(2),
            &:nth-child(5),
            &:nth-child(8) {
                &:after {
                    position: absolute;
                    top: 0;
                    right: 0;

                    content: url("/en/media/2017siteassets/contentfiles/images/nptswi/module-9-multiple-wrap-after-a.png");
                }
            }

            &:nth-child(3),
            &:nth-child(6),
            &:nth-child(9) {
                &:after {
                    position: absolute;
                    top: 0;
                    left: 0;

                    content: url("/en/media/2017siteassets/contentfiles/images/nptswi/module-9-multiple-wrap-after-b.png");
                }
            }

            .overlay:not([class*="bg-"]) {
                background-color: $nptswi-main !important;

                &:after {
                    color: #FFFFFF !important;
                }

                h2 {
                    font-family: "Montserrat", sans-serif;
                    font-weight: 800;

                    color: #FFFFFF !important;

                    i {
                        color: #FFFFFF !important;
                    }
                }

                p {
                    font-family: "Roboto", sans-serif;

                    color: #FFFFFF !important;
                }
            }

            &:nth-child(even) {
                .overlay:not([class*="bg-"]) {
                    background-color: $nptswi-secondary;

                    h2,
                    p,
                    &:after {
                        color: $nptswi-navy;
                    }
                }
            }
        }
    }



    /////////////////////////////////////
    // MODULE 9b
    /////////////////////////////////////


    .module-9b {
        .module-title {
            font-family: "Montserrat", sans-serif;
            font-weight: 800;
        }

        .functionalBox {
            position: relative;

            &:nth-child(1),
            &:nth-child(4),
            &:nth-child(7) {
                &:after {
                    position: absolute;
                    right: 0;
                    bottom: 0;

                    content: url("/en/media/2017siteassets/contentfiles/images/nptswi/module-9b-functionalBox-after-a.png");
                }
            }

            &:nth-child(2),
            &:nth-child(5),
            &:nth-child(8) {
                &:after {
                    position: absolute;
                    top: 0;
                    right: 0;

                    content: url("/en/media/2017siteassets/contentfiles/images/nptswi/module-9b-functionalBox-after-b.png");
                }
            }

            &:nth-child(3),
            &:nth-child(6),
            &:nth-child(9) {
                &:after {
                    position: absolute;
                    top: 0;
                    left: 0;

                    content: url("/en/media/2017siteassets/contentfiles/images/nptswi/module-9b-functionalBox-after-c.png");
                }
            }

            .overlay:not([class*="bg-"]) {
                z-index: 100;

                background-color: $nptswi-main;

                h2 {
                    font-family: "Montserrat", sans-serif;
                    font-weight: 800;
                }

                p {
                    font-family: "Roboto", sans-serif;
                }
            }

            &:nth-child(2),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(7),
            &:nth-child(10),
            &:nth-child(12) {
                .overlay:not([class*="bg-"]) {
                    background-color: $nptswi-secondary;

                    h2,
                    p,
                    &:after {
                        color: $nptswi-navy;
                    }
                }
            }
        }
    }


    /////////////////////////////////////
    // MODULE 11
    /////////////////////////////////////


    .module-11 {
        position: relative;

        color: #FFFFFF;
        background-color: $nptswi-main;

        &:after {
            position: absolute;
            right: 0;
            bottom: 0;

            content: url("/en/media/2017siteassets/contentfiles/images/nptswi/module-11-after.png");

            @media all and (max-width: 1200px) {
                max-width: 180px;
            }
        }

        .module-title {
            font-family: "Montserrat", sans-serif;
            font-weight: 800;
        }

        .list-inline {
            li {
                i,
                a {
                    color: #FFFFFF;
                }
            }
        }
    }



    /////////////////////////////////////
    // MODULE 12
    /////////////////////////////////////

    .module-12 {
        .module-title {
            font-family: "Montserrat", sans-serif;
            font-weight: 800;
        }

        .slideShowLogos {
            .slick-prev,
            .slick-next {
                &:before {
                    color: $nptswi-main !important;
                }
            }
        }
    }



    /////////////////////////////////////
    // MODULE 14
    /////////////////////////////////////


    .module-14 {
        .module-title {
            font-family: "Montserrat", sans-serif;
            font-weight: 800;

            & + p {
                font-family: "Roboto", sans-serif;
                line-height: normal;
            }
        }

        .date {
            color: #FFFFFF;
            background-color: $nptswi-main;
        }

        .btn {
            font-family: "Montserrat", sans-serif;

            color: #FFFFFF;
            background-color: $nptswi-navy;

            &:hover {
                color: #FFFFFF;
                background-color: $nptswi-main;
            }

            i {
                color: #FFFFFF;
            }
        }
    }



    /////////////////////////////////////
    // MODULE 15 & 24
    /////////////////////////////////////

    .module-15,
    .module-24 {
        font-family: "Roboto", sans-serif;

        .module-title {
            font-family: "Montserrat", sans-serif;
            font-weight: 800;

            & + p {
                font-family: "Roboto", sans-serif;
                line-height: normal;
            }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: "Montserrat", sans-serif;
            font-weight: 800;
        }

        .date {
            color: #FFFFFF;
            background-color: $nptswi-main;
        }

        .related-articles {
            .related-articles__item {
                background-color: $nptswi-main;
            }

            .slick-arrow {
                background-color: $nptswi-secondary;
            }
        }

        ul:not([class]) {
            li {
                &:before {
                    color: $nptswi-secondary;
                }
            }
        }

        blockquote {
            font-family: "Montserrat", sans-serif;
            font-weight: 400;
            font-style: italic;

            &:before,
            &:after {
                color: $nptswi-secondary;
            }
        }

        .btn {
            font-family: "Montserrat", sans-serif;

            color: #FFFFFF;
            background-color: $nptswi-navy;

            &.btn--yellow {
                color: $nptswi-navy;
                background-color: $nptswi-secondary;

                i {
                    color: $nptswi-navy;
                }
            }

            &:hover {
                color: #FFFFFF;
                background-color: $nptswi-main;

                i {
                    color: #FFFFFF;
                }
            }

            i {
                color: #FFFFFF;
            }
        }
    }


    /////////////////////////////////////
    // MODULE 29
    /////////////////////////////////////

    &.inner {
        .module-29 {
            &:after {
                content: inherit;
            }
        }
    }

    .module-1,
    .module-29 {
        position: relative;

        .bg-ogham-stone {
            background-color: $nptswi-main;
        }

        &:after {
            position: absolute;
            z-index: 10;
            right: 0;
            bottom: 0;

            content: url("/en/media/2017siteassets/contentfiles/images/nptswi/module-29-after.png");

            @media all and (max-width: 1200px) {
                max-width: 180px;
            }
        }

        .slider-progress {
            .progress {
                background-color: $nptswi-secondary;
            }
        }

        .date {
            max-width: 100% !important;
            margin-top: 30px !important;
        }

        .main-heading {
            font-family: "Montserrat", sans-serif;
            font-weight: 800;

            padding: 15px 30px !important;

            background-color: $nptswi-main;
        }

        svg {
            circle {
                fill: $nptswi-main;
            }
        }

        .sub-heading {
            font-family: "Montserrat", sans-serif;

            padding: 15px 30px !important;

            background-color: $nptswi-main;
        }

        .slick-dots {
            li {
                button {
                    &:before {
                        background-color: $nptswi-main;
                    }
                }

                &.slick-active {
                    button {
                        &:before {
                            background-color: $nptswi-navy;
                        }
                    }
                }
            }
        }

        .btn {
            font-family: "Montserrat", sans-serif;
            font-weight: 700;

            &.btn--arrowed {
                i {
                    color: $nptswi-main;
                }
            }

            &.linktype {
                color: $nptswi-navy;
                background-color: $nptswi-accent;

                i {
                    color: $nptswi-navy;
                }
            }
        }

        .slide-square {
            background-color: $nptswi-main;

            *:not(.btn):not(.fa) {
                color: $crest-white;
            }
        }

        .countdown {
            padding: 10px;

            //position: relative;
            .countdown__timer {
                margin-bottom: 10px;

                .date {
                    font-size: 14px;
                }

                .countdown__number {
                    .number {
                        background-color: $nptswi-main;
                    }
                }
            }
        }
    }


    /////////////////////////////////////
    // MODULE 24
    /////////////////////////////////////

    .module-24 {
        ul {
            li {
                &:before {
                    color: $nptswi-secondary;
                    background-color: $nptswi-secondary;
                }
            }
        }
    }

    /////////////////////////////////////
    // MODULE 33
    /////////////////////////////////////


    &.inner {
        .module-33 {
            .module-33__item {
                @media all and (min-width: 1200px) {
                    &:nth-child(2),
                    &:nth-child(4),
                    &:nth-child(6),
                    &:nth-child(8),
                    &:nth-child(10),
                    &:nth-child(12) {
                        .slide-square {
                            background-color: $nptswi-main;

                            .slide-square__title {
                                color: #FFFFFF;
                            }

                            .slide-square__contact {
                                li {
                                    a {
                                        color: #FFFFFF;
                                    }
                                }
                            }

                            .slide-square__copy {
                                color: #FFFFFF;
                            }
                        }
                    }

                    &:nth-child(1),
                    &:nth-child(3),
                    &:nth-child(5),
                    &:nth-child(7),
                    &:nth-child(9),
                    &:nth-child(11) {
                        .slide-square {
                            background-color: $nptswi-secondary;

                            .slide-square__title {
                                color: $nptswi-navy;
                            }

                            .slide-square__contact {
                                li {
                                    a {
                                        color: $nptswi-navy;
                                    }
                                }
                            }

                            .slide-square__copy {
                                color: $nptswi-navy;
                            }
                        }
                    }
                }
            }

            .grid-item {
                width: 33.333333% !important;

                @media all and (max-width: 1199px) {
                    width: 50% !important;
                }

                @media all and (max-width: 767px) {
                    width: 100% !important;
                }
            }
        }
    }

    .module-33 {
        .module-title {
            font-family: "Montserrat", sans-serif;
            font-weight: 800;

            & + p {
                font-family: "Roboto", sans-serif;
                line-height: normal;
            }
        }

        .btn-group {
            button {
                font-family: "Roboto", sans-serif;
                font-size: 16px;
            }
        }

        .module-33__item {
            .slide-square {
                background-color: $nptswi-secondary;

                .slide-square__title {
                    font-family: "Montserrat", sans-serif;

                    color: $nptswi-navy;
                }

                .slide-square__contact {
                    li {
                        a {
                            color: $nptswi-navy;
                        }
                    }
                }

                .slide-square__copy {
                    color: $nptswi-navy;
                }

                .btn {
                    font-family: "Montserrat", sans-serif;

                    color: $nptswi-navy;
                    background-color: #FFFFFF;

                    i {
                        color: $nptswi-navy;
                    }
                }
            }

            @media all and (min-width: 1600px) {
                &:nth-child(2),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(7),
                &:nth-child(10),
                &:nth-child(12) {
                    .slide-square {
                        background-color: $nptswi-main;

                        .slide-square__title {
                            color: #FFFFFF;
                        }

                        .slide-square__contact {
                            li {
                                a {
                                    color: #FFFFFF;
                                }
                            }
                        }

                        .slide-square__copy {
                            color: #FFFFFF;
                        }
                    }
                }
            }

            @media all and (min-width: 1200px) and (max-width: 1599px) {
                &:nth-child(2),
                &:nth-child(4),
                &:nth-child(6),
                &:nth-child(8),
                &:nth-child(10),
                &:nth-child(12) {
                    .slide-square {
                        background-color: $nptswi-main;

                        .slide-square__title {
                            color: #FFFFFF;
                        }

                        .slide-square__contact {
                            li {
                                a {
                                    color: #FFFFFF;
                                }
                            }
                        }

                        .slide-square__copy {
                            color: #FFFFFF;
                        }
                    }
                }
            }

            @media all and (min-width: 768px) and (max-width: 1199px) {
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(10),
                &:nth-child(11) {
                    .slide-square {
                        background-color: $nptswi-main;

                        .slide-square__title {
                            color: #FFFFFF;
                        }

                        .slide-square__contact {
                            li {
                                a {
                                    color: #FFFFFF;
                                }
                            }
                        }

                        .slide-square__copy {
                            color: #FFFFFF;
                        }
                    }
                }
            }

            @media all and (min-width: 0) and (max-width: 767px) {
                &:nth-child(2),
                &:nth-child(4),
                &:nth-child(6),
                &:nth-child(8),
                &:nth-child(10),
                &:nth-child(12) {
                    .slide-square {
                        background-color: $nptswi-main;

                        .slide-square__title {
                            color: #FFFFFF;
                        }

                        .slide-square__contact {
                            li {
                                a {
                                    color: #FFFFFF;
                                }
                            }
                        }

                        .slide-square__copy {
                            color: #FFFFFF;
                        }
                    }
                }
            }
        }
    }



    /////////////////////////////////////
    // MODULE 37
    /////////////////////////////////////



    /////////////////////////////////////
    // Footer
    /////////////////////////////////////

    .footer {
        font-family: "Roboto", sans-serif;

        background-color: $nptswi-navy;

        h2 {
            font-family: "Montserrat", sans-serif;
            font-weight: 800;
        }
    }

    .cookiePolicy.bottom {
        border-top: none;
        background-color: $nptswi-navy;

        p {
            font-family: "Montserrat", sans-serif;
            font-weight: 400;
        }

        .btn {
            font-family: "Montserrat", sans-serif;
            font-size: 20px;
            font-weight: 700;

            padding: 18px 30px;

            color: $nptswi-navy;
            border: 2px solid #FFFFFF;
            border-radius: 0;
            background-color: #FFFFFF;

            &:hover {
                transform: scale(1.02);

                color: #FFFFFF;
                background-color: $nptswi-navy;
            }
        }
    }
}


body.ucc-theme--nptswi {
    #header {
        .greedy-menu {
            .toggle-links {
                background-color: #19646A;

                &:hover {
                    background-color: #00C690;
                }

                &.counter::before {
                    background-color: #19646A;
                }
            }

            ul.hidden-links {
                border-top-color: #19646A;
                background-color: #032E3E;

                li {
                    a,
                    a.dropdown-toggle {
                        color: white !important;
                    }
                }
            }
        }
    }
}

.ucc-theme--nptswi {
    --active-arrow: #{$nptswi-accent};
    --active-background: #{$nptswi-main};
    --active-link: white;
    --arrow-first-level: #{$nptswi-accent};
    --arrow-go-back: #{$nptswi-accent};
    --arrow-second-level: #{$nptswi-accent};
    --greedy-menu-background: #{$crest-black};
    --link-base: #{$crest-black};
    --link-base-arrow-hover: #{$nptswi-accent};
    --link-base-background-hover: #{$nptswi-main};
    --link-base-desktop: white;
    --link-base-hover: white;
    --link-dropdown: #{$crest-black};
    --link-dropdown-arrow-hover: #{$nptswi-accent};
    --link-dropdown-background-hover: #{$nptswi-main};
    --link-dropdown-desktop: #{$crest-black};
    --link-dropdown-hover: white;
    --nav-background: #{$nptswi-navy};
    --onpath-arrow: #{$nptswi-accent};
    --onpath-background: #{$nptswi-main};
    --onpath-link: white;
}