.module-4 {
    @include spacer-p-t;

    position: relative;
    li {
        line-height: 1.25;
    }
    .wrapper {
        position: relative;
        .inner {
            position: relative;

            float: left;
            overflow: hidden;

            width: 50%;
            padding-bottom: 50%;

            border: 5px solid $crest-white;
            @media (min-width: $screen-sm) {
                width: 33.333333%;
                padding-bottom: 33.333333%;
            }
            @media (min-width: $screen-md) {
                width: 25%;
                padding-bottom: 25%;
            }
            .inner__wrap {
                position: absolute;
                top: 0;
                left: 0;

                width: 100%;
                height: 100%;
                > .inner__img {
                    @include absolute-cover;
                    @include bg-cover-center;
                    &.image:after {
                        @include gradient-black-up;

                        height: 100%;
                    }
                }
                > .inner__icon {
                    position: absolute;

                    width: 50%;
                    height: 50%;

                    border: 5px solid $crest-white;
                    &:nth-of-type(1) {
                        top: 0;
                        left: 0;

                        border-top: 0;
                        border-left: 0;
                    }
                    &:nth-of-type(2) {
                        top: 0;
                        left: 50%;

                        border-top: 0;
                        border-right: 0;
                    }
                    &:nth-of-type(3) {
                        top: 50%;
                        left: 0;

                        border-bottom: 0;
                        border-left: 0;
                    }
                    &:nth-of-type(4) {
                        top: 50%;
                        left: 50%;

                        border-right: 0;
                        border-bottom: 0;
                    }
                    .inner__img {
                        @include absolute-cover;

                        background-repeat: no-repeat;
                        // @include bg-center;
                        background-position: center 25%;
                        background-size: 50%;
                        .ucc-theme--plrg & {
                            background-size: 80px auto;
                        }
                        &.bg-brand-facebook,
                        &.bg-brand-twitter {
                            // removed 8th Nov 2021 - https://granite.eu.teamwork.com/desk/tickets/87151371
                            // background-size: 33%;
                        }
                    }
                    p {
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 1.15;

                        position: absolute;
                        right: 0;
                        bottom: -5px;
                        left: 0;
                        //line-height: 1;

                        text-align: center;

                        color: $crest-white;
                        @media (min-width: 550px) {
                            font-size: $font-size-base;

                            bottom: 0;
                        }
                        @media (min-width: $screen-md) {
                            font-size: $font-size-base;
                            //padding: 30px;
                        }
                        // @media (min-width: $screen-lg) {
                        // 	font-size: $font-size-sm;
                        // }
                    }
                    .bg-crest-yellow + p {
                        color: $crest-black;
                    }
                    .bg-tint-crest-white + p {
                        color: $crest-black;
                    }
                    .bg-limestone + p {
                        color: $crest-black;
                    }
                    .bg-crest-white + p {
                        color: $crest-black;
                    }
                }
                &.has-image {
                    .inner__info {
                        position: absolute;
                        bottom: 0;

                        width: 100%;
                        &::before {
                            position: absolute;
                            right: 0;
                            bottom: 0;
                            left: 0;

                            width: 100%;
                            height: 100%;

                            content: "";

                            background: linear-gradient(to top, #171717 -126%, rgba(23,23,23,0.8) 80%, rgba(255,255,255,0) 100%);
                        }
                        p {
                            position: relative;
                            z-index: 1;

                            text-shadow: 2px 2px 0 rgba(#191919,0.4);
                        }
                    }
                }
                .inner__content {
                    @include absolute-cover;
                    a {
                        text-decoration: underline;

                        color: $crest-white;
                    }
                    .inner__info {
                        position: absolute;
                        bottom: 0;

                        width: 100%;
                        padding: 15px;

                        color: $crest-white;
                        @media (max-width: 450px) {
                            font-size: 15px;
                            line-height: 1.6;

                            padding: 15px 15px 15px;
                        }
                        a {
                            font-weight: 900;

                            display: block;
                        }
                        @media (min-width: 550px) {
                            font-size: 16px;
                            line-height: 20px;
                        }
                        @media (min-width: $screen-sm)and (max-width: $screen-md - 1) {
                            font-size: 18px;
                        }
                        @media (min-width: $screen-md) {
                            font-size: 14px;
                        }
                        @media (min-width: $screen-lg) {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
    .social-block {
        display: flex;
        flex-direction: row;

        margin: 0;
        padding: 0 10px 5px;

        justify-content: space-between;
        li {
            text-align: center;

            border: 5px solid $crest-white;

            flex: 1;
            @media (max-width: $screen-md) {
                position: relative;

                overflow: hidden;

                height: 0;
                padding-bottom: 20%;
            }
            a {
                display: block;

                color: $crest-white;
                @media (max-width: $screen-md) {
                    position: absolute;
                    top: 0;
                    left: 0;

                    width: 100%;
                    height: 100%;
                }
                @media (min-width: $screen-md) {
                    line-height: 50px;

                    height: 50px;
                }
                @media (max-width: $screen-md) {
                    i {
                        @include centered-h-v;

                        font-size: 30px;
                    }
                }
            }
            &.twitter {
                a {
                    background-color: $brand-twitter;
                }
            }
            &.facebook {
                a {
                    background-color: $brand-facebook;
                }
            }
            &.instagram {
                a {
                    background-color: $brand-instagram;
                }
            }
            &.youtube {
                a {
                    background-color: $brand-youtube;
                }
            }
            &.linkedin {
                a {
                    background-color: $brand-linkedin;
                }
            }
        }
    }
}

.module-4:after,
.module-4 .wrapper:after {
    display: table;
    clear: both;

    content: "";
}

/*MODULE 4 CONNECT
*****************************************************************/

.module-4 .wrapper {
    height: auto;
    padding-bottom: 0;
}

body .page-wrap .module-4 .wrapper {
    display: flex;

    margin-right: -3px;
    margin-left: -3px;

    flex-wrap: wrap;
    justify-content: center;
}

body .page-wrap .module-4 .wrapper .inner {
    position: relative;
    top: auto;
    left: auto;

    float: left;
    overflow: hidden;

    width: 100%;
    height: 0;
    padding-bottom: 100%;

    border: 3px solid #FFFFFF;
    @media (min-width: 480px) {
        width: 50%;
        padding-bottom: 50%;
    }
}

.module-4 .wrapper .inner .inner__content .inner__info a {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
}

.module-4 .wrapper .inner .inner__content .inner__info .link-name {
    font-weight: 700;

    position: relative;

    display: block;

    min-height: 20px;
    padding-left: 35px;

    text-decoration: none;
}

.module-4 .wrapper .inner .inner__content .inner__info .link-name .fa {
    font-size: 24px;

    position: absolute;
    top: -1px;
    left: 0;
}
.module-4 .wrapper .inner .inner__content .inner__info .link-name .fa.fa-long-arrow-right {
    font-size: 20px;

    position: relative;
    top: 0;

    float: right;
}
.module-4 .linktype--external .fa-long-arrow-right:before {
    content: "\f08e";
}
.module-4 .linktype--modal .fa-long-arrow-right:before {
    content: "\f2d0";
}
.module-4 .linktype--video .fa-long-arrow-right:before {
    content: "\f01d";
}
.module-4 .linktype--document .fa-long-arrow-right:before {
    content: "\f016";
}

.module-4 .wrapper .inner:nth-of-type(6) .inner__icon {
    border: 3px solid #FFFFFF;
}

.module-4 .social-block {
    margin: 0 -6px;
}

.module-4 .social-block li {
    margin: 3px 3px;

    border: 0;
}

.module-4 .social-block li a {
    font-size: 20px;

    width: 100%;
}


@media all and (min-width: 768px) {
    .module-4 .wrapper .inner .inner__content .inner__info .link-name {
        margin: 15px 0 0;
        padding-left: 35px;
    }

    .module-4 .wrapper .inner .inner__content .inner__info .link-name .fa {
        font-size: 32px;

        top: -5px;
    }
}

@media all and (min-width: 992px) {
    body .page-wrap .module-4 .wrapper {
        margin-right: -5px;
        margin-bottom: 5px;
        margin-left: -5px;
    }

    body .page-wrap .module-4 .wrapper .inner {
        width: 25%;
        padding-bottom: 25%;

        border: 5px solid #FFFFFF;
    }

    .module-4 .wrapper .inner .inner__content .inner__info {
        font-size: 18px;
    }

    .module-4 .wrapper .inner:nth-child(4n+4) .inner__content .inner__info {
        //padding-right: 70px;
    }

    .module-4 .wrapper .inner:nth-of-type(6) .inner__icon {
        border: 5px solid #FFFFFF;
    }

    .module-4 .social-block {
        position: absolute;
        z-index: 100;
        top: calc(50% + 81.5px);
        right: 0;

        display: block;

        transform: translateY(-50%);

        background: #FFFFFF;
        @media (min-width: $screen-lg) {
            top: calc(50% + 103.5px);
        }
    }

    .module-4 .social-block li {
        margin: 10px 5px 10px 0;
    }
    .module-4 .social-block li:last-child {
        margin-bottom: 5px;
    }

    .module-4 .social-block li a {
        width: 50px;
    }
}

@media all and (min-width: $screen-lg) {
    .module-4 .wrapper .inner .inner__content .inner__info .link-name {
        font-size: 18px;

        margin: 20px 0 5px;
        padding-left: 45px;
    }

    .module-4 .wrapper .inner .inner__content .inner__info .link-name .fa {
        font-size: 34px;

        top: -6px;
    }
}

//hide huge blocks of text

.module-4 {
    .inner__info {
        p {
            //position: relative;
            //line-height: 1.25em;
            //max-height: 9.95em;
            //overflow: hidden;
        }
    }
}


.module-4 {
    .wrapper {
        .inner {
            .inner__wrap {
                > .inner__img {
                    &:after {
                        display: none;
                    }
                }
            }
            .inner__info {
                opacity: 0.9;
            }
            &.bg-crest-blue {
                .image + .inner__content {
                    .inner__info {
                        background-color: $crest-blue;
                    }
                }
            }
            &.bg-crest-red {
                .image + .inner__content {
                    .inner__info {
                        background-color: $crest-red;
                    }
                }
            }
            &.bg-crest-yellow {
                .image + .inner__content,
                .bg-crest-yellow + .inner__content {
                    .inner__info {
                        background-color: $crest-yellow;
                        * {
                            color: $crest-black;
                        }
                    }
                }
            }
            &.bg-quad-green {
                .image + .inner__content {
                    .inner__info {
                        background-color: $quad-green;
                    }
                }
            }
            &.bg-sky-blue {
                .image + .inner__content {
                    .inner__info {
                        background-color: $sky-blue;
                    }
                }
            }
            &.bg-dawn-mauve {
                .image + .inner__content {
                    .inner__info {
                        background-color: $dawn-mauve;
                    }
                }
            }
            &.bg-earth-red {
                .image + .inner__content {
                    .inner__info {
                        background-color: $earth-red;
                    }
                }
            }
            &.bg-ogham-stone {
                .image + .inner__content {
                    .inner__info {
                        background-color: $ogham-stone;
                    }
                }
            }
            &.bg-tint-crest-white {
                .image + .inner__content,
                .bg-tint-crest-white + .inner__content {
                    .inner__info {
                        background-color: $tint-crest-white;
                        * {
                            color: $crest-black;
                        }
                    }
                }
            }
            &.bg-limestone {
                .image + .inner__content,
                .bg-limestone + .inner__content {
                    .inner__info {
                        background-color: $limestone;
                        * {
                            color: $crest-black;
                        }
                    }
                }
            }
            &.bg-crest-white {
                .image + .inner__content,
                .bg-crest-white + .inner__content {
                    .inner__info {
                        background-color: $crest-white;
                        * {
                            color: $crest-black;
                        }
                    }
                }
            }
            &.bg-crest-black {
                .image + .inner__content {
                    .inner__info {
                        background-color: $crest-black;
                    }
                }
            }
            &.bg-brand-facebook {
                .image + .inner__content {
                    .inner__info {
                        background-color: $brand-facebook;
                    }
                }
            }
            &.bg-brand-twitter {
                .image + .inner__content {
                    .inner__info {
                        background-color: $brand-twitter;
                    }
                }
            }
        }
    }
}


//inner pages

.content-wrap__wrapper {
    .col-lg-12,
    .col-lg-9 {
        .module-4 {
            .wrapper {
                .inner {
                    @media (min-width: 768px) and (max-width: 1500px) {
                        width: 50%;
                        padding-bottom: 50%;
                    }
                    .inner__wrap > .inner__icon {
                        p {
                            @media (min-width: $screen-xs-min) {
                                font-size: 16px;
                                line-height: 1.25;
                            }
                        }
                        .inner__img {
                            @media (min-width: $screen-xs-min) and (max-width: 1600px) {
                                background-size: 35%;
                            }
                        }
                    }
                }
            }
        }
    }
}
