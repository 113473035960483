// Used by Case Law content type

.module-39 {
    margin-top: 30px;

    background-color: #FFFFFF;

    @media all and (min-width: 1200px) {
        margin-top: 70px;
    }

    form {
        background: transparent;
    }


    .dataTables_wrapper {
        margin-bottom: 20px;
        @media all and (min-width: 992px) {
            margin-bottom: 40px;
        }
    }
    .dataTables_wrapper .dataTables_paginate .paginate_button.current,
    .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
        color: white !important;
        border: 0;
        background: $crest-blue;
        box-shadow: none;

        appearance: none;
    }

    .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
        color: white !important;
        background: $crest-blue;
    }

    .dataTables_paginate {
        .paginate_button {
            border: 1px solid #EFEFEF;

            appearance: none;
        }
    }

    .dataTables_wrapper select + label + select {
        margin-left: 0;

        @media all and (min-width: 992px) {
            margin-left: 5px;
        }

        @media all and (max-width: 991px) {
            margin-top: 10px;
        }
    }

    .dtr-data {
        display: flex;
        flex-direction: column;
    }

    .dataTables_wrapper .dataTables_filter {
        margin-top: 0;
        margin-bottom: 0;
    }

    .dataTables_wrapper .dataTables_filter input {
        margin-left: 0;
    }

    .btn--clearfilters {
        margin-top: 10px;
        margin-left: 5px;
        padding: 2px 4px !important;

        text-decoration: underline;

        color: black;
        border-radius: 0 !important;
        background-color: transparent;
    }

    .dataTables_length {
        label {
            font-weight: 400;
        }

        @media all and (max-width: 767px) {
            padding-top: 10px;
            padding-bottom: 10px;

            text-align: left !important;
        }
    }

    .form-control {
        @media all and (max-width: 991px) {
            width: calc(100% - 10px);
        }

        @media all and (min-width: 992px) {
            max-width: 280px;
        }
    }

    #datatablesFilter-2 {
        @media all and (min-width: 992px) {
            max-width: 150px;
        }
    }

    .dataTables_wrapper {
        form {
            justify-content: center;
            flex-wrap: wrap;
        }
    }

    select {
        margin-right: 2px;
        margin-left: 2px;
        padding: 8px 15px;

        border: 1px solid #CFCFCF !important;


        @media all and (min-width: 1200px) {
            margin-right: 5px;
            margin-left: 5px;
        }
        .ucc-theme--plrg & {
            border: 1px solid #BBBCBC !important;
            border-radius: 10px !important;
        }
    }

    input[type="search"] {
        border: 1px solid #BBBCBC;
        border-radius: 10px;

        @media all and (min-width: 992px) {
            max-width: 300px;
        }
    }

    .btn__wrap {
        display: flex;

        margin-top: 10px;
        margin-bottom: 10px;

        align-items: center;

        @media all and (min-width: 1200px) {
            margin-left: 30px;
        }

        .btn--grid {
            background-color: $crest-blue;

            .fa {
                color: white;
            }
        }

        &.list-active {
            .btn--grid {
                background-color: #EFEFEF;

                .fa {
                    color: $crest-blue;
                }
            }

            .btn--list {
                background-color: $crest-blue;

                .fa {
                    color: white;
                }
            }
        }
    }

    .btn {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;

        padding: 12px 15px;

        transition: none;

        border-radius: 10px;

        &:before {
            transition: none;
        }

        + .btn {
            @media all and (max-width : 991px) {
                // margin-top: 10px;
            }

            @media all and (min-width : 992px) {
                margin-left: 10px;
            }
        }
    }

    

    .dataTables_wrapper {
        // display: flex;
        // flex-direction: column;

        .btn--cardview {
            margin-left: 5px;
        }

        .dataTables_filter {
            margin-right: 5px;
            margin-left: 5px;

            @media all and (min-width: 1200px) {
                margin-right: 15px;
                margin-left: 15px;
            }

            label {
                width: 100%;

                @media all and (min-width : 992px) {
                    float: right;

                    width: 100%;
                    max-width: 300px;
                }

                input {
                    width: 100%;
                }
            }
        }
    }

    .dataTable {
        &.cards {
            tbody {
                justify-content: start;
            }

            .dataTable__category {
                padding-bottom: 20px!important;
                img {
                    margin-bottom: 10px;
                }
            }
            td {
                vertical-align: top;
    
                &:last-child {
                    @media all and (min-width: 992px) {
                        min-width: 0;
                    }
                }
            }
            td,
            th {
                padding: 0 15px;

                border: 0 !important;
            }
            td {
                .btn + .btn {
                    @media all and (max-width : 991px) {
                        margin-top: 10px;
                    }
                }
            }
            tr {
                position: relative;

                padding-top: 30px;
                padding-bottom: 20px;
            }

            tbody {
                tr {
                    @media all and (min-width: 992px) {
                        width: calc(100%/3 - 5px);
                        margin-right: 5px;
                        margin-bottom: 5px;
                    }
                }
            }

            .dataTable__title {
                h2 {
                    font-size: 22px;
                    font-weight: 400;
                    line-height: 150%;

                    margin-bottom: 10px;
                }
            }

            .dataTable__abstract {
                margin-bottom: 20px;
            }

            .dataTable__actions {
                @media all and (max-width: 1199px) {
                    display: flex;
                    flex-direction: column;
                    width: calc(100% - 30px);
                    min-width: 0 !important;
                    .btn + .btn {
                        margin-left: 0 !important;
                        margin-top: 5px;
                    }
                }
                @media all and (min-width:1200px) and (max-width: 1439px) {
                    .btn {
                        padding:10px;
                    }
                }
            }
        }

        thead {
            th:last-child:after {
                content: none;
            }
        }

        td {
            vertical-align: top;

            &:last-child {
                @media all and (min-width: 992px) {
                    min-width: 336px;
                }
            }
        }

        td {
            min-width: 100px;

            h2 {
                font-weight: 400;
            }

            p,
            li {
                line-height: 1.3333333;
            }

            p {
                margin-bottom: 5px;
            }
        }
    }

    table.dataTable.no-footer {
        border-bottom: 0;
    }

    table.dataTable tbody th,
    table.dataTable tbody td {
        @media all and (min-width: 1200px) {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    .dataTable__actions {
        // display: flex;
        // align-items: center;

        .btn {
            color: black;
            background-color: #FFFFFF;
        }

        a.linktype {
            &:after {
                font-family: FontAwesome;
                padding-left: 5px;
                content: "\f101";
            }

            &.linktype--internal:after {
                content: "\f178";
            }

            &.linktype--external:after {
                content: "\f08e";
            }

            &.linktype--modal:after {
                content: "\f2d0";
            }

            &.linktype--video:after {
                content: "\f01d";
            }

            &.linktype--document:after {
                content: "\f016";
            }
        }

    }

    .dataTable__category {
        img,
        svg {
            float: left;

            max-width: 40px;
            margin-right: 10px;
        }

        p {
            float: right;

            width: calc(100% - 50px);
        }
    }

    .dataTable__title {
        h2 {
            font-size: 16px;

            margin-top: 0;
            margin-bottom: 0;
        }
    }

}
