//responsive videos
.responsive-video {
    position: relative;

    clear: both;

    width: 100%;
    height: 0;
    margin: 15px auto 15px;
    padding-bottom: 56.25%;
    iframe {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
    }
    &--sm {
        max-width: 800px;
    }
    &--md {
        max-width: 1000px;
    }
    &--lg {
        max-width: 1200px;
    }
}

//responsive iframes
.responsive-iframe {
    position: relative;

    clear: both;

    width: 100%;
    height: 0;
    margin: 15px auto 15px;
    padding-bottom: 56.25%;
    iframe {
        border: 0;
    }
}
