.module-30 {
  margin-top: 30px;
  @media(min-width:768px) {
    margin-top: 45px;
  }
  @media(min-width:992px) {
    margin-top: 60px;
  }
  .row {
    @media(min-width: 992px) {
      display: flex;
    }

    .col-md-6:first-child {
      @media(min-width: 992px) {
        padding-right: 5px;
      }
    }

    .col-md-6:last-child {
      @media(min-width: 992px) {
        padding-left: 5px;
      }
    }
  }
  label.hideLabel {
    display: none;
  }
  .form-inline {
    display: flex;
    margin-bottom: 10px;
    > select {
      flex: 1 0 0;
    }
    @media(min-width:768px) {
      .form-control {
        width: 100%;
      }
    }
    @media(max-width:767px) {
      display: unset;
    }
  }
  .dataTables_wrapper {
    overflow-x: auto;
    .dataTables_length {
      select {
        background-color: $crest-white;
      }
    }
    .dataTables_filter {
      input {
        background-color: $crest-white;
      }
    }
  }
}

.module-30 .sports--results {
  margin-bottom: 10px;

  @media(min-width: 992px) {
    margin-bottom: unset;
  }

  .btn {
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
  }
}

.module-30 .sports--fixtures {
  display: flex;
  flex-direction: column;

  height: 100%;

  p {
    align-self: center;

    max-width: 338px;
    margin: 0 auto;

    text-align: center;
  }
}

.module-30 .sports__wrapper {
  @media(min-width: 992px) {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;

    height: 100%;
  }
}

.module-30 .sports__button__block {
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;

  @media(min-width: 576px) {
    flex-flow: row wrap;
    flex-direction: row;

    height: fit-content;
  }
  .btn {
    min-width: 240px;
  }
}