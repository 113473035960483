.row + .module18 {
	margin-top: -20px;
}

/*.az-index {
	display: none;
}*/

.content-wrap ul.az-index {
	display: block;
	background-color: $tint-crest-white;
	padding: 10px 0 10px !important;
	margin-bottom: 20px;
	margin-left: 0;
	margin-top: 0;
	overflow: hidden;
	li {
		width: calc(100% / 26.1);
		padding: 0;
		text-align: center;
		display: block;
		float: left;
		&:before {
			content: none !important;
		}
	}
}

@media (max-width: $screen-sm) {
	.content-wrap ul.az-index {
		li {
			width: calc(100% / 13.1);
		}
	}
}

.az-people .az-group{
	li {
		position: relative;
    	margin: 5px 0;
		padding-bottom: 30px !important;
		padding-left: 15px;
		padding-right: 15px;
		&:before {
			content: none !important;
		}
		figure {
			padding: 15px;
			background-color: $tint-crest-white;
			.img-wrap {
				position: relative;
				padding-bottom: 100%;
				height: 0;
				overflow: hidden;
				> div {
					@include img-cover;
					@include bg-cover-center;
				}
			}
			figcaption > div {
				position: relative;
				display: block;
				height: 50px;
				background-color: white;
				border-bottom: 1px solid $tint-crest-white;
				font-size: 16px;
				line-height: 18px;
				span {
					position: absolute;
					top: 0;
					left: 0;
					width: 50px;
					height: 50px;
					background-color: white;
					border-right: 1px solid $tint-crest-white;
					border-bottom: 1px solid $tint-crest-white;
					i {
						@include centered-h-v;
						color: $crest-red;
					}
				}
				p {
					@include centered-v;
					left: 50px;
					line-height: 18px;
					padding: 0 10px;
					a {
						word-break: break-all;
						display: block;
					}
				}
			}
		}
		.btn {
			font-size: 14px;
			padding-left: 5px;
			padding-right: 5px;
		}
		@media (min-width: 650px) and (max-width: $screen-md - 1) {
			width: 50%;
			float: left;
		}
		@media (min-width: 1080px) {
			width: 50%;
			float: left;
		}
		@media (min-width: 1450px) {
			width: 33.333333%;
			float: left;
		}
	}
}

@media (min-width: 1450px) {
	.content-wrap__wrapper .col-lg-9 .az-people .az-group li {
	    width: 50%;
	}
}


.pagination-wrap {
	text-align: center;
	.pagination {
		padding-left: 0 !important;
		li:before {
			content: none !important;
		}
	}
}