.module-28 {
	margin: 50px auto;
	padding: 0 15px;
	@media (max-width: 992px) {
		padding: 0 15px;
	}
}

.content-wrap__inner {
	.module-28 {
		max-width: 100%;
		margin-top: 0 !important;
		margin-bottom: 0 !important;
		+ .module-28 {
			margin-top: 0;
			margin-bottom: 0;
		}
	}
}

.cookiePolicy {
	.module-28 {
		max-width: 100%;
		margin-top: 0 !important;
		margin-bottom: 0 !important;
		background: transparent;
		padding: 0;
	}
}

body.home {
	.module-wrap {
		.module-28 {
			max-width: 1400px;
			margin: 0 auto;
			padding-top: 30px;
			padding-bottom: 30px;
			@media (min-width: $screen-sm-min) {
				padding-top: 45px;
				padding-bottom: 45px;
			}
			@media (min-width: $screen-md-min) {
				padding-top: 60px;
				padding-bottom: 60px;
			}
			> .inner {
				margin-left: auto;
				margin-right: auto;
				@media (min-width: $screen-sm-min) {
					width: 83.33333%;
				}
				@media (min-width: $screen-md-min) {
					width: 66.66667%;
				}
				> p:last-child {
					margin-bottom: 0;
				}
			}
			h2 {
				@include serif-r;
				font-weight: 400;
				font-size: $font-size-h3;
				text-align: center;
				margin-top: 0;
				margin-bottom: 0;
				padding-bottom: 30px;
				@media (min-width: $screen-md) {
					font-size: $font-size-h2;
					//padding-bottom: 40px;
				}
				@media (min-width: $screen-lg) {
					font-size: $font-size-md;
					//padding-bottom: 70px;
				}
				@media (min-width: 1600px) {
					font-size: 50px;
				}
			}
		}
	}
}
