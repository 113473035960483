.module-8 {
	position: relative;
	margin-bottom: 25px;
	@include spacer-p-t;
	margin-bottom: 55px;
	.slick-dots {
		margin: 0;
		padding: 0;
		bottom: -35px;
		li:before {
			display: none;
		}
		button {
			padding: 0;
		}
		button:before {
			font-size: 10px;
			content: '•';
			opacity: 1;
			color: $crest-yellow;
		}
		.slick-active button:before {
			color: $crest-red;
			opacity: 1;
		}
	}
	.slideShowSingle {
		figure {
			> div {
				@include bg-cover-center;
				// height: 400px;
				@media all and (max-width: 767px) {
					position: absolute;
					top:0;
					left: 0;
					height: 100%;
					width: 100%;
					z-index: -1;
					opacity: 1;
				}
				@media (min-width: $screen-sm) {
					height: 450px;
				}
			}
			> div > a.youtubevideo:before {
				content:"\f144";
				@media all and (max-width: $screen-sm - 1 ) {
					display: none;
				}
			}
			@media (min-width: $screen-sm) {
				> div > a.youtubevideo,
				> div > a.modaldescriptionbox {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				&:before {
					font-family: "FontAwesome";
					color: $crest-red;
					font-size: 56px;
					line-height: 56px;
					width: 56px;
					position: absolute;
					left: 25%;
					top: 50%;
					margin-left: -28px;
					margin-top: -28px;
					}
				}
			}
			@media (min-width: $screen-lg) {
				> div > a.youtubevideo,
				> div > a.modaldescriptionbox {
				&:before {
					font-size: 84px;
					line-height: 84px;
					width: 84px;
					margin-left: -42px;
					margin-top: -42px;
					}
				}
			}
			figcaption {
				// position: absolute;
				@media (min-width: $screen-sm) {
				top: 0;
				right: 0;
					bottom: 0;
					left: 0;
					left: 50%;
					position: absolute;
				}
				.outer {
					background-color: rgba(266,266,266,0.9);
					padding: 15px;
					@media (min-width: $screen-sm) {
						position: absolute;
						right: 0;
						top: 0;
						bottom: 0;
						left: 0;

					}
					h2 {
						font-family: 'Playfair Display', serif;
						font-weight: 400;
						margin-top: 0;
						margin-bottom: 20px;
					}
					h3 {
						margin: 0 0 8px 0;
						padding-left: 20px;
						text-align: right;
						@include serif-b;
						font-size: $font-size-h6;
						color: $crest-blue;
					}
					.spotlight {
						margin-bottom: 0;
						line-height: 1.5;
						font-size: 16px;
					}
					blockquote {
						position: relative;
						font-size: $font-size-h5;
						line-height: 1.25;
						@include serif-r;
						font-style: italic;
						color: $crest-blue;
						margin: 0 0 25px 0;
						padding: 10px 20px 0 20px;
						border: 0;
						&:before,
						&:after {
							content: "\201C";
							font-family: FontAwesome;
							color: $crest-yellow;
							font-size: 40px;
							position: absolute;
						}
						&:before {
							top: -4px;
							left: 0;
						}
						&:after {
							right: 0px;
							bottom: 0;
							transform: rotate(180deg);
						}
						.col-md-9.col-lg-9.content-wrap__wrapper &{
							font-size: $font-size-h7;
						}
					}
					p:not(.spotlight) {
						position: static;
						bottom: 0;
						padding: 0;
						margin: 8px 0 0 0;
						max-width: 280px;
						font-size: $font-size-xs;
						margin-left: auto;
						text-align: right;
					}
					a {
						margin-top: 20px;
						@media all and (min-width: 768px) {
							float: right;
						}
					}
					@media (min-width: 500px) {
						padding: 30px;
						h3 {
							font-size: $font-size-h5;
						}
						blockquote {
							font-size: $font-size-h4;
							.col-md-9.col-lg-9.content-wrap__wrapper &{
								font-size: $font-size-h6;
							}
						}
						// p:not(.spotlight) {
							//font-size: $font-size-h7;
						// }
					}
					@media (min-width: $screen-sm) {
						left: 0%;
						h3 {
							font-size: $font-size-h4;
						}
						blockquote {
							font-size: $font-size-h3;
							.col-md-9.col-lg-9.content-wrap__wrapper &{
						    	font-size: $font-size-h5;
						    }
						}
						p:not(.spotlight) {
							font-size: 16px;
								line-height: 1.2;
						}
						.btn {
							position: absolute;
							bottom: 100px;
							right: calc(100% + 30px);
							white-space: normal;
							min-width: 250px;
						}
					}
					@media (min-width: $screen-md) {
						padding: 60px;
					}
				}

				.linktype--external .fa-long-arrow-right:before {
		        	content: "\f08e";
		        }
		
		        .linktype--modal .fa-long-arrow-right:before {
		        	content: "\f2d0";
		        }
		
		        .linktype--video .fa-long-arrow-right:before {
		        	content: "\f01d";
		        }
		
		        .linktype--document .fa-long-arrow-right:before {
		        	content: "\f016";
		        }

			}
		}
	}

	.slideShowSingle__nav {
		position: absolute;
		right: 0;
		bottom: -25px;
		left: 0;
		max-width: 1400px;
		margin: 0 auto;
		text-align: center;
		.slick-track {
			transform: none !important;
			width: 100% !important;
		}
		.slick-list {
			padding: 0 !important;
		}
		.outer {
			//float: right;
			&:only-child {
				display: none !important;
			}
			width: 42px !important;
			margin: 0 2px;
			outline: none;
			float: none;
			display: inline-block;
			.inner {
				position: relative;
				height: 42px;
				width: 42px;
				div {
					position: absolute;
					height: 100%;
					width: 100%;
					@include bg-cover-center;
					background-position: left center;
					border-radius: 100px;
					border: 3px solid $crest-yellow;
				}
			}
			&:hover {
				cursor: pointer;
			}
			&.slick-current {
				.inner div {
					border-color: $crest-red;
				}
			}
		}


		@media (min-width: 500px) {
			bottom: -35px;
			.outer {
				width: 60px !important;
				margin: 0 5px;
				.inner {
					height: 60px;
					width: 60px;
				}
			}
		}

		@media (min-width: 1400px) {
			bottom: -55px;
			text-align: right;
			padding-right: 50px;
			.outer {
				width: 100px !important;
				margin: 0 10px;
				.inner {
					height: 100px;
					width: 100px;
					div {
						border-width: 6px;
					}
				}
			}
		}

	}


}

body.inner .module-wrap .module-8 {
	&.capped {
		.module-title,
		.module-sub-text {
			max-width: 1400px;
			margin-left: auto !important;
			margin-right: auto !important;
			padding-left: 15px !important;
			padding-right: 15px !important;
		}
	}
	
}