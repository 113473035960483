
.mitigation-process{
    background-color: #fff;   
    padding-bottom: 450px;
	
	h3 
	{
	    margin-top: 15px;
	}

	.container
	{
	    width: 100%;
	}

	.navbar
	{
	    min-height: 0px;
	}

	.caret-outline
	{
	    float: right;
	}

	.caret {
	    vertical-align: middle;
	    border-top: 6px dashed;
	    border-top: 4px solid\9;
	    border-right: 6px solid transparent;
	    border-left: 6px solid transparent;
	}

	.open .caret
	{
	    border-top-width: 0;
	    border-bottom: 6px solid;
	}

	.nav-tabs
	{
	    background-color: #fff;

		.dropdown
		{
		    width: 100%;
		    border: 1px solid #ddd;
		    cursor: default;
		    padding: 10px;
			a
			{
				width: 100%;
			    margin-right: 0px;
			    border-radius: 0;
			    border-bottom: 0;
			}
		    .dropdown-menu {
		    	left: 0;
				right: 0;
				min-width: 100%;
				padding: 0;
				margin: 0;
				top: 60px;
				.active>a {
					color: #ebebed;
				}&:hover{
					color:red;
				}

				li>a {
				    font-weight: 500;
				    color: #231f20;
				} 

		    }
		}

		.open {
			background-color: #fff;	
		}	
		
	}


	.nav-tabs > li:nth-child(1) > a:hover,
	.nav-tabs > li:nth-child(1) > a:focus,
	.nav-tabs > li:nth-child(1).active > a,
	.nav-tabs > li:nth-child(1).active > a:focus,
	.nav-tabs > li:nth-child(1).active > a:hover {
		border-bottom: 0;
	}

	.nav-tabs > li.active > a:after,
	.nav-tabs > li.active > a:focus:after,
	.nav-tabs > li.active > a:hover:after {
		content: "";
		display: none;
	}

	.tab-content 
	{
	    background-color: #fff;
	    padding: 20px;
	    border: none;
	    margin-bottom: 20px;
	}

	.appeal-less-than-two-weeks,
	.appeal-greater-than-two-weeks 
	{
	    margin-top: 15px;
	}


}
