.left-side-nav,
div.content-wrap__wrapper .left-side-nav {
	//margin-left: -15px;
	//margin-right: -15px;
	&.affix {
		position: relative;
	}
	button {
		position: relative;
		width: 100%;
		text-align: left;
		border: 0;
		//margin-bottom: 30px;
		background-color: $crest-red;
		color: $crest-white;
		padding: 15px;
		//@include serif-r;
		font-size: $font-size-h5;
		font-weight: 700;
		font-size: 25px;
		outline: none;
		&.btn--blue {
			background-color: $crest-blue;
		}
		&:after {
			content: "\f107";
			@include awesome;
			position: absolute;
			right: 15px;
			top: 10px;
			font-size: $font-size-h2;
			color: $crest-white;
			transition: .3s ease;
		}
		&[aria-expanded="true"]:after {
			transform: rotate(-180deg);
		}

	}
	@media (max-width: $screen-md) {
		button + .collapse form {
			padding-top: 25px;
		}
	}
	@media (max-width: 1400px) {
		button {
			font-size: 16px;
			&:after {
				font-size: 25px;
			}
		}
	}
	&.collapsed {
		margin-bottom: 0;
	}
	.left-side-nav__nav {
		margin: 0;
		padding: 0;
		ul {
			margin: 0;
			padding: 0;
		}
		li {
			padding: 0;
			margin: 0;
			line-height: 1.25;
			&:before {
				display: none;
			}
			a, span {
				position: relative;
				display: block;
				//padding: 15px 0;
				background-color: $crest-white;
				margin-bottom: 2px;
				text-decoration: none;
				color: $crest-black;
				&:after {
					content: "\f105";
					@include awesome;
					position: absolute;
					right: 10px;
					top: 15px;
					color: inherit;
				}
				&:hover, &:hover a,
				&.current-active {
					background-color: $crest-red !important;
					color: $crest-white !important;
				}
			}
			span a {
				//padding: 0;
				background: transparent !important;
				&:after {
					display: none;
				}
			}
			&.sel > a, .currentsection {
				background-color: $crest-red;
				color: $crest-white;
			}
			ul {
				display: none;
			}
			span + ul {
				display: block;
			}

			.currentbranch0 {
				color: #fff;
				background: $crest-red;
				> a {
					color: #fff;
				}

				& + .multilevel-linkul-0 {
					li {
						span {
							color: #fff !important;
							background: $crest-red !important;
							//padding-left: 20px;
							//background: darken($crest-red, 10%) !important;
							a {
								color: #fff;
							}
						}
						li {
							span {
								background: $crest-red !important;
								//background: darken($crest-red, 20%) !important;
							}
						}
					}
					.currentbranch1 {
						background: #f6f6f6;

						& + .multilevel-linkul-1 {
							li {
								span {
									background: #f3f3f3;
									//padding-left: 40px;
								}
							}
						}
					}
				}
			}

			span[class*="current"] {
				&.sel {
					a {
						font-weight: 900;
					}
				}
			}
			//== paddings
			a {
				padding: 15px 30px 15px 15px;
			}
			ul[class*="multi"] {
				a {
					background-color: #cecece;
					padding-left: 25px;
				}
				ul[class*="multi"] {
					a {
						padding-left: 35px;
					}
				}
			}


			/*
			.multilevel-linkul-0 {
				a {
					padding-left: 15px !important;
					padding-right: 15px !important;
					background: #cecece;
				}
			}
			ul a {
				padding-left: 15px !important;
				padding-right: 15px !important;
				background: #cecece;
				//background: #f6f6f6;
			}
			ul ul a {
				padding-left: 30px !important;
				padding-right: 15px !important;
				background: #cecece;
				//background: #f6f6f6;
			}
			ul ul ul a {
				padding-left: 45px !important;
				padding-right: 15px !important;
			}
			*/
		}
	}
}

@media (min-width: $screen-md) {
	.left-side-nav {
		padding-top: 68px;
		//border-top: 10px solid #fff;
		>.collapse {
			display: block;
		}
		&.affix {
			position: fixed !important;
			width: 25%;
			top: 0;
			padding-top: 0;
		}
		button.text-capitalize {
			border: 0;
			pointer-events: none;
			font-size: 24px;
			color: $crest-black;
			background: transparent;
			margin-top: 0;
			margin-bottom: 0;
			padding-top: 0;
			padding-bottom: 0;
			&:after {
				content: none;
			}
		}
	}
	.left-side-nav button.text-capitalize + .collapse {
		margin-top: 10px;
	}
	.well {
		border: 0;
		box-shadow: none;
	}
}

@media (min-width: $screen-lg) {

	.left-side-nav {
		padding-top: 68px;
		//padding-left: 30px;
		//padding-right: 30px;
	}

}


// /////////////////////////////////////////////////////////////////////////
// Sidebar Filters, e.g. on course listing pages
// /////////////////////////////////////////////////////////////////////////

.left-side-nav fieldset.well {
	margin-bottom: 0;
	padding-top: 0;
	&:first-child {
		margin-top: 20px;
	}

}

.left-side-nav legend {
	font-family: 'Playfair Display', serif;
	font-weight: 700;
	font-size: 20px;
    margin-bottom: 1px;
    padding: 15px;
    color: $crest-blue;
    background: #fff;
    border: 0;
    position: relative;
    cursor: pointer;
    &:after {
    	content:"\f107";
		font-family: 'FontAwesome';
		font-weight: normal;
		font-weight: normal;
		position: absolute;
		right: 15px;
		top: 10px;
		font-size: 30px;
		transition: .3s ease;
    }
    &[aria-expanded="true"]:after {
	    transform: rotate(-180deg);
	}
}

.left-side-nav label {
    position: relative;
    display: block;
    padding: 15px 15px 15px 35px;
    background-color: #fff;
    margin-bottom: 2px;
    text-decoration: none;
    color: #231f20;
}

.left-side-nav .sel label {
    background-color: #cd202c;
    color: #fff;
}

.left-side-nav .styled label:before {
	content:"\f10c";
	font-family: "FontAwesome";
	color: #cd202c;
	position: absolute;
	top: 14px;
	left: 15px;
}

.left-side-nav .styled.sel label:before {
	content:"\f058";
	color: #fff;
}

.left-side-nav .styled.radio input[type="radio"],
.left-side-nav .styled.radio-inline input[type="radio"],
.left-side-nav .styled.checkbox input[type="checkbox"],
.left-side-nav .styled.checkbox-inline input[type="checkbox"] {
	margin-left: -5000px;
}
