.content-wrap__inner .articleDetail {
	.module-3c__feature {
		bottom: 0;
		top: auto;
		padding: 10px 15px !important;
	}
	.date {
		background: $crest-yellow;
		font-weight: 700;
		text-align: center;
		position: absolute;
		z-index: 20;
		top: 0;
		left: 0;
		font-size: 12px;
		line-height: 1;
		padding: 8px 12px;
		@media (min-width: $screen-sm-min) {
			font-size: 14px;
			padding: 12px 15px;
		}
		span {
			display: block;
			font-size: 24px;
		}
	}
	.image {
		width: 100%;
		max-width: 800px;
		overflow: hidden;
		position: relative;
		margin: 0px 0 30px;
		background-color: $tint-crest-white;
		img {
			width: auto;
		}
		&:before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+50,0.7+100 */
			//background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0.7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		}
	}
	figcaption {
		// color: $crest-black;
		// background-color: #ebebed;
		// padding: 15px;
		// font-style: italic;
		display: inline-block;

		font-style: italic;
		font-weight: 400;
		font-size: 16px;
		line-height: 175%;
		margin-bottom: 0;
		color: $crest-black;
		background-color: $tint-crest-white;
		padding: 15px;
	}

	//override wysiwyg image settings and float every second image to the left or right
	img.img-index:not(.img-index-0) {
		width: 100%;
		max-width: 100%;
		height: auto;
		@media (min-width: 768px) {
			max-width: 50% !important;
			height: auto !important;
			float: left;
			margin: 0 30px 30px 0;
			&.img-index-2,
			&.img-index-4,
			&.img-index-6,
			&.img-index-8,
			&.img-index-10
			&.img-index-12,
			&.img-index-14,
			&.img-index-16,
			&.img-index-18,
			&.img-index-20 {
				float: right;
				margin: 0 0 30px 30px;
			}
		}
	}

	figure:not(.image) {
		border: 5px solid red;
		width: 50%;
		float: left;
		img:not(.img-index-0) {
			@media (min-width: 768px) {
				max-width: 100% !important;
				float: none;
				margin: 0;
			}
		}
	}

	//responsive videos
	@import "responsive-iframes";

	.buttons {
		clear: both;
	}


}

.articleFooter {
	clear: both;
	overflow: hidden;
}
