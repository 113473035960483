.module-2 {
	margin-bottom: 10px;
	background-color: $tint-crest-white;
	//@include spacer-p-t-b;
	padding-top: 55px;
	padding-bottom: 15px;
	//text-align: center;
	h2 {
		@include serif-b;
		font-size: 16px;
		margin-top: 15px;
		color: $crest-blue;
		@media (min-width: $screen-sm) {
			font-size: 18px;
		}

	}
	.row {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}
	.item {
		//float: none;
		//display: inline-block;
		margin-left: -1px;
	}
	.wrapper {
		display: block;
		margin-bottom: 30px;
		&.linktype--internal h2 i:before {
			content: "\f178";
		}
		&.linktype--external h2 i:before {
			content: "\f08e";
		}
		&.linktype--modal h2 i:before {
			content: "\f2d0";
		}
		&.linktype--video h2 i:before {
			content: "\f01d";
		}
		&.linktype--document h2 i:before {
			content: "\f016";
		}
		&:hover {
			text-decoration: none;
		}
		.inner {
			position: relative;
			width: 90px;
			height: 90px;
			margin-left: auto;
			margin-right: auto;
			border-radius: 50%;
			box-shadow: 0 0 0 3px $quad-green;
			&:after {
				content: '';
				pointer-events: none;
		    position: absolute;
		    top: -1px;
		    left: -1px;
		    width: 92px;
		    height: 92px;
		    z-index: 1;
		    transform: scale(1);
		    background-color: $quad-green;
		    border-radius: 50%;
		    transition: transform 0.2s, opacity 0.2s;
			}
			&:hover {
				&:after {
					transform: scale(0.85);
				}
			}
			img {
				@include centered-h-v;
				width: 70%;
				z-index: 2;
			}
			@media (min-width: $screen-sm) {
				width: 110px;
				height: 110px;
				&:after {
					width: 112px;
					height: 112px;
				}
			}
			@media (min-width: $screen-md) {
				width: 125px;
				height: 125px;
				&:after {
					width: 127px;
					height: 127px;
				}
			}
			@media (min-width: $screen-lg) {
				width: 150px;
				height: 150px;
				&:after {
					width: 152px;
					height: 152px;
				}
			}
		}
		.inner {
			box-shadow: none;
			&::after {
				background: transparent;
			}
			&.bg-crest-white {
				background-color: transparent;
				box-shadow: 0 0 0 3px $crest-white;
				&::after {
				background-color: $crest-white;
				}
			}
			&.bg-tint-crest-white {
				background-color: transparent;
				box-shadow: 0 0 0 3px $tint-crest-white;
				&::after {
				background-color: $tint-crest-white;
				}
			}
			&.bg-crest-black {
				background-color: transparent;
				box-shadow: 0 0 0 3px $crest-black;
				&::after {
				background-color: $crest-black;
				}
			}
			&.bg-crest-red {
				background-color: transparent;
				box-shadow: 0 0 0 3px $crest-red;
				&::after {
				background-color: $crest-red;
				}
			}
			&.bg-crest-blue {
				background-color: transparent;
				box-shadow: 0 0 0 3px $crest-blue;
				&::after {
				background-color: $crest-blue;
				}
			}
			&.bg-quad-green {
				background-color: transparent;
				box-shadow: 0 0 0 3px $quad-green;
				&::after {
				background-color: $quad-green;
				}
			}
			&.bg-crest-yellow {
				background-color: transparent;
				box-shadow: 0 0 0 3px $crest-yellow;
				&::after {
				background-color: $crest-yellow;
				}
			}
			&.bg-earth-red {
				background-color: transparent;
				box-shadow: 0 0 0 3px $earth-red;
				&::after {
				background-color: $earth-red;
				}
			}
			&.bg-ogham-stone {
				background-color: transparent;
				box-shadow: 0 0 0 3px $ogham-stone;
				&::after {
				background-color: $ogham-stone;
				}
			}
			&.bg-dawn-mauve {
				background-color: transparent;
				box-shadow: 0 0 0 3px $dawn-mauve;
				&::after {
				background-color: $dawn-mauve;
				}
			}
			&.bg-limestone {
				background-color: transparent;
				box-shadow: 0 0 0 3px $limestone;
				&::after {
				background-color: $limestone;
				}
			}
			&.bg-brand-twitter {
				background-color: transparent;
				box-shadow: 0 0 0 3px $brand-twitter;
				&::after {
				background-color: $brand-twitter;
				}
			}
			&.bg-brand-facebook {
				background-color: transparent;
				box-shadow: 0 0 0 3px $brand-facebook;
				&::after {
				background-color: $brand-facebook;
				}
			}
		}
	}
	div[class*="col"] {
		width: 50%;
		&:nth-of-type(2n+1) {
			clear: left;
		}
	}
	@media (min-width: 600px) {
		div[class*="col"] {
			width: 33.333333%;
			&:nth-of-type(2n+1) {
				clear: none;
			}
			&:nth-of-type(3n+1) {
				clear: left;
			}
		}
	}
	@media (min-width: $screen-md) {
		div[class*="col"] {
			width: 16.66666666%;
			&:nth-of-type(3n+1) {
				clear: none;
			}
		}
	}
	&.application-logos-links {
		.wrapper {
			.inner {
				box-shadow: none;
				&::after {
					background: transparent;
				}
				&.bg-crest-white {
					box-shadow: 0 0 0 3px $crest-white;
					&::after {
					background-color: $crest-white;
					}
				}
				&.bg-tint-crest-white {
					box-shadow: 0 0 0 3px $tint-crest-white;
					&::after {
					background-color: $tint-crest-white;
					}
				}
				&.bg-crest-black {
					box-shadow: 0 0 0 3px $crest-black;
					&::after {
					background-color: $crest-black;
					}
				}
				&.bg-crest-red {
					box-shadow: 0 0 0 3px $crest-red;
					&::after {
					background-color: $crest-red;
					}
				}
				&.bg-crest-blue {
					box-shadow: 0 0 0 3px $crest-blue;
					&::after {
					background-color: $crest-blue;
					}
				}
				&.bg-quad-green {
					box-shadow: 0 0 0 3px $quad-green;
					&::after {
					background-color: $quad-green;
					}
				}
				&.bg-crest-yellow {
					box-shadow: 0 0 0 3px $crest-yellow;
					&::after {
					background-color: $crest-yellow;
					}
				}
				&.bg-earth-red {
					box-shadow: 0 0 0 3px $earth-red;
					&::after {
					background-color: $earth-red;
					}
				}
				&.bg-ogham-stone {
					box-shadow: 0 0 0 3px $ogham-stone;
					&::after {
					background-color: $ogham-stone;
					}
				}
				&.bg-dawn-mauve {
					box-shadow: 0 0 0 3px $dawn-mauve;
					&::after {
					background-color: $dawn-mauve;
					}
				}
				&.bg-limestone {
					box-shadow: 0 0 0 3px $limestone;
					&::after {
					background-color: $limestone;
					}
				}
				&.bg-brand-twitter {
					box-shadow: 0 0 0 3px $brand-twitter;
					&::after {
					background-color: $brand-twitter;
					}
				}
				&.bg-brand-facebook {
					box-shadow: 0 0 0 3px $brand-facebook;
					&::after {
					background-color: $brand-facebook;
					}
				}
			}
		}
		@media all and (min-width: $screen-md) {
			div[class*="col"] {
				width: 50%;
				&:nth-of-type(2n+1) {
					clear: left;
				}
				&:nth-of-type(2n+1) {
					clear: none;
				}
			}
		}
		@media (min-width: 1440px) {
			div[class*="col"] {
				width: 33.333333%;
				&:nth-of-type(2n+1) {
					clear: none;
				}
				&:nth-of-type(3n+1) {
					clear: left;
				}
			}
		}
	}
}

.content-wrap__wrapper {
	.module-2 {
		.container {
			padding-top: 20px;
		}
		.item {
			@media (max-width: 1300px) {
				flex-basis: 33.33334%;
			}
		}
		h2 {
			font-size: 20px;
			@media (max-width: 1400px) {
				font-size: 17px;
			}
		}
		.wrapper {
			.inner {
				@media (max-width: 1650px) {
					width: 130px;
					height: 130px;
					&:after {
						width: 132px;
						height: 132px;
					}
				}
				@media (max-width: 1500px) {
					width: 120px;
					height: 120px;
					&:after {
						width: 122px;
						height: 122px;
					}
				}
				@media (max-width: 1400px) {
					width: 100px;
					height: 100px;
					&:after {
						width: 102px;
						height: 102px;
					}
				}
			}
		}
	}
	.col-md-12.col-lg-9 {
		.module-2 {
			.item {
				@media (max-width: 2400px) {
					flex-basis: 33.33334%;
				}
			}
		}
	}
}