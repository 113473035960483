///////////////////////////////////////////////////////////
// grid
///////////////////////////////////////////////////////////

.container {
	width: 100%;
	//max-width: 1800px;
}

.module-wrap {
	img {
    	height: auto !important;
    }
	&.left-nav,&.rhs {
		background-color: $tint-crest-white;
		//border-top: 10px solid #fff;
		.content-wrap,
		.side-items {
			background-color: $crest-white;
		}
	}
}


// varied sticky footer and body for digi signage 

.digi-signage main {
	margin-bottom: 145px;
}

.digi-signage {
	.container {
		margin-top: 15px;
	}
	footer .container {
		margin-top: 0px;
	}
	footer {
		position: absolute;
    	bottom: 0;
    	width: 100%;
    	height: 145px;
    	line-height: 145px;
    	background-color: $crest-yellow;
    	padding: 0px;
    	border: none;
	}
	footer h2{
		font-weight: bold;
		line-height: 145px;
		font-size: 44px;
		color: #000;
		margin: 0;
		padding: 0;
	}
	.rss-logo {
		background-image: url('https://www.irishtimes.com/polopoly_fs/1.2302052.1438259328!/image/image.png');
    	background-size: contain;
    	background-repeat: no-repeat;
    	height: 145px;
    	background-position: top left;
	}
	footer .digSignRSS {
		height: 145px;
	}
	.slick-initialized.slick-slide {
		height: 145px !important;
	}


} 

@media (min-width: $screen-md) {
	
	.module-wrap.left-nav > .container > .row {
		display: flex;
		align-items: stretch;
	}

}


