//===============================================================
// independeant thinking css
//===============================================================

//== border variables

$border-sm: 15px;
$border-md: 25px;
$border-lg: 40px;

//== padding variables

$padding-xs: 10px;
$padding-sm: 30px;
$padding-md: 60px;
$padding-lg: 120px;

//== spacing variables

$division-sm: 30px;


//== modifiers

div[class*="independent-thinking__"] {
    &.bg-color--grey {
        // padding: 0 $padding-xs $padding-xs;
        margin-top: $division-sm;
        margin-right: -$padding-xs;
        margin-left: -$padding-xs;
        padding-top: $division-sm;

        background-color: $tint-crest-white-darker;

        +.bg-color--grey {
            margin-top: 0;
        }
    }

    .module-title {
        font-size: 24px;

        padding-top: $padding-sm;

        @media(min-width: $screen-md) {
            font-size: 30px;
        }

        @media(min-width: $screen-lg) {
            font-size: 36px;
        }
    }
}

.independent-thinking+#contact,
.independent-thinking+.module-11 {
    border-top: 0;
}

//== news block

.independent-thinking__news {
    @include spacer-p-t;

    position: relative;

    .wrapper {
        position: relative;

        width: 100%;
        height: 0;
        padding-bottom: 200%;

        @media (min-width: $screen-sm) {
            padding-bottom: 50%;
        }

        @media (min-width: $screen-md) {
            padding-bottom: 60%;
        }

        .inner {
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            a {
                position: absolute;

                display: block;

                border: 10px solid white;

                .inner__img {
                    @include absolute-cover;

                    @include bg-cover-semi-center-home;

                    &.inner__img {
                        &:after {
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;

                            content: "";

                            background-color: $crest-black-opac-light;
                        }

                        &.inner--solid:after {
                            opacity: 1;
                        }
                    }
                }

                .inner__content {
                    @include absolute-cover;

                    display: flex;
                    flex-direction: column;

                    padding: 0 20px;

                    color: $crest-white;

                    align-items: flex-start;
                    justify-content: center;

                    h3 {
                        font-family: $font-family-sans-serif;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 1.15;

                        position: relative;

                        padding: 0;
                    }

                    @media(min-width: 480px) {
                        h3 {
                            font-size: 16px;
                        }
                    }

                    @media(min-width: $screen-sm) {
                        padding: 20px;
                    }

                    @media(min-width: $screen-md) {
                        h3 {
                            font-size: 18px;

                            max-width: 140px;
                        }
                    }

                    @media(min-width: $screen-lg) {
                        h3 {
                            font-size: 20px;
                        }
                    }

                    @media(min-width: 1400px) {
                        padding: 30px;
                        // h3 {
                        // 	max-width: 60%;
                        // }
                    }
                }

                &:not(.independent-thinking__featured) {
                    .inner__content h3 {
                        line-height: 1;

                        margin-top: -10px;

                        &:last-of-type {
                            font-size: 80%;

                            display: none;
                        }

                        @media(min-width: 480px) {
                            margin-top: 0;

                            &:last-of-type {
                                font-size: 80%;

                                display: block;
                            }
                        }

                        @media(min-width: $screen-md) {
                            &:last-of-type {
                                font-size: 90%;
                            }
                        }
                    }
                }
            }
        }
    }
}

//== news block grid alignments

.independent-thinking__news .inner a {

    &:nth-of-type(2),
    &:nth-of-type(4),
    &:nth-of-type(3),
    &:nth-of-type(5) {
        z-index: 10;
    }

    @media(max-width: $screen-sm - 1) {

        &:nth-of-type(2),
        &:nth-of-type(4),
        &:nth-of-type(3),
        &:nth-of-type(5) {
            border-top: 0;
        }

        &:nth-of-type(1) {
            top: 0;
            left: 0;

            width: 100%;
            height: 50%;

            border-right: 0;
            border-left: 0;
        }

        &:nth-of-type(2) {
            top: 50%;
            left: 0;

            width: 50%;
            height: 25%;

            border-right: 0;
            border-left: 0;
        }

        &:nth-of-type(3) {
            top: 50%;
            left: 50%;

            width: 50%;
            height: 25%;

            border-right: 0;
        }

        &:nth-of-type(4) {
            top: 75%;
            left: 0;

            width: 50%;
            height: 25%;

            border-top: 0;
            border-right: 0;
            border-left: 0;
        }

        &:nth-of-type(5) {
            top: 75%;
            left: 50%;

            width: 50%;
            height: 25%;

            border-right: 0;
        }
    }

    @media (min-width: $screen-sm) {

        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(4),
        &:nth-of-type(3),
        &:nth-of-type(5) {
            border-top: 0;
        }

        &:nth-of-type(1) {
            top: 0;
            left: 0;

            width: 50%;
            height: 100%;

            border-left: 0;
        }

        &:nth-of-type(2) {
            left: 50%;

            width: 25%;
            height: 50%;

            border-right: 0;
            border-left: 0;
        }

        &:nth-of-type(3) {
            top: 0;
            left: 75%;

            width: 25%;
            height: 50%;

            border-right: 0;
        }

        &:nth-of-type(4) {
            top: 50%;
            left: 50%;

            width: 25%;
            height: 50%;

            border-right: 0;
            border-left: 0;
        }

        &:nth-of-type(5) {
            top: 50%;
            left: 75%;

            width: 25%;
            height: 50%;

            border-right: 0;
        }
    }

    @media (min-width: $screen-md) {
        &:nth-of-type(1) {
            width: 60%;
        }

        &:nth-of-type(2) {
            left: 50%;

            width: 30%;

            border-left: 10px solid white;
        }

        &:nth-of-type(3) {
            top: 10%;
            left: 80%;

            width: 20%;
            height: 40%;
        }

        &:nth-of-type(4) {
            left: 55%;

            width: 25%;
            height: 40%;

            border-left: 10px solid white;
        }

        &:nth-of-type(5) {
            left: 80%;

            width: 20%;
            height: 40%;
        }
    }

    @media(min-width: 1400px) {
        &:nth-of-type(2) {
            border-right: 20px solid white;
            border-bottom: 20px solid white;
            border-left: 20px solid white;
        }

        &:nth-of-type(3) {
            border-bottom: 20px solid white;
            border-left: 0;
        }

        &:nth-of-type(4) {
            border-right: 20px solid white;
            border-bottom: 20px solid white;
            border-left: 20px solid white;
        }

        &:nth-of-type(5) {
            border-bottom: 20px solid white;
            border-left: 0;
        }
    }
}


// IE 11 - flex direction column fix
@media all and (-ms-high-contrast:none) {
    .independent-thinking__news .wrapper .inner a .inner__content h3 {
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;

        display: block;

        margin: 0 auto;
        padding: 5px;

        transform: translateY(-50%);

        @media all and (min-width: $screen-md-min) {
            padding: 15px;
        }

        @media all and (min-width: $screen-lg-min) {
            padding: 30px;
        }
    }
}

//== wrap

.independent-thinking {
    &--wrap {
        position: relative;

        padding: 0 $padding-xs;

        border-width: $border-sm;
        border-style: solid;
        border-color: $crest-yellow;

        @media(min-width: $screen-md) {
            border-width: $border-md;
        }

        @media(min-width: $screen-lg) {
            border-width: $border-lg;
        }

        &.independent-thinking--landing {
            @media(min-width: $screen-md) {
                padding: 0 $padding-md;
            }

            @media(min-width: $screen-lg) {
                padding: 0 $padding-lg;
            }
        }
    }
}

//== head

.independent-thinking__head {
    position: relative;
    z-index: 1000;
    left: 50%;

    display: inline-block;

    width: 100%;
    max-width: 400px;
    padding: 20px;

    transform: translateX(-50%);
    text-align: center;

    background-color: $crest-yellow;

    a {
        color: $crest-black;
    }

    h2 {
        font-weight: 700;

        margin-top: 0;
    }
}

//== menu

.independent-thinking__menu {
    z-index: 100;

    width: 100%;
    max-width: 400px;
    margin: 0 auto;

    text-align: center;

    .dropdown-backdrop {
        display: none;
    }

    //== toggle
    .nav {
        >li {
            >a {
                font-family: $font-family-sans-serif;
                font-size: 18px !important;
                font-weight: 400;
                line-height: 25px;

                // background-color: $crest-yellow;

                color: $crest-black;

                @media(min-width: $screen-md) {
                    padding: 40px 15px 20px;

                    text-align: right;
                }

                @media(min-width: 1400px) {
                    padding: 40px 30px 20px;

                    text-align: right;
                }

                i {
                    transition: 0.3s ease all;
                    transform: rotate(0);
                }
            }

            &.open>a i {
                transform: rotate(-180deg);
            }

            .dropdown-menu--it {
                display: none;
            }

            &.open {
                .dropdown-menu--it {
                    display: block;
                }
            }
        }

        .open>a,
        .open>a:hover,
        .open>a:focus {
            color: $crest-black;
            background-color: $crest-yellow;
        }
    }

    @media(min-width: $screen-md) {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;

        max-width: 100%;
        padding: 0 $padding-md;

        // width: 20%;
        // text-align: right;
        .nav {
            float: right;

            width: 20%;

            text-align: right;

            @media(min-width: $screen-md) and (max-width: 1399px) {
                border-left: 10px solid white;
            }
        }
    }

    @media(min-width: $screen-lg) {
        padding: 0 $padding-lg;
    }

    //== dropdown
    .dropdown {
        background-color: transparent !important;

        .dropdown-menu--it {
            position: relative;
            top: auto;
            left: 0;

            min-width: 100%;

            // text-align: center;
            padding: 0 20px 20px;

            background-color: $crest-yellow;

            @media(min-width: $screen-md) {
                padding: 0 15px 40px;
            }

            @media(min-width: 1400px) {
                padding: 0 30px 40px;
            }

            >li>a {
                font-family: $font-family-sans-serif;
                font-weight: 700;

                color: $crest-black;
            }
        }
    }
}

.nav .open>a,
.nav .open>a:hover,
.nav .open>a:focus //== news blocks

.independent-thinking.independent-thinking--wrap {
    .content-wrap__inner .page-title {
        font-weight: 700 !important;
    }
}

.independent-thinking__news a:not(.independent-thinking__featured) {
    .inner__content {
        h3 {
            font-size: 11px;

            margin-top: -20px;
        }
    }
}

.independent-thinking__blocked {
    position: relative;

    margin-top: -20px;
    padding: 10px 0 10px 10px;

    border-left: 2px solid $crest-yellow;

    &:before,
    &:after {
        position: absolute;
        left: 0;

        width: 20px;

        content: "";

        border-top: 2px solid $crest-yellow;
    }

    &:before {
        top: 0;
    }

    &:after {
        bottom: 0;
    }

    h2 {
        font-size: 30px;
        font-weight: 700;

        display: block;

        max-width: 80%;
        margin-top: 0;

        text-transform: uppercase;
    }

    h3 {
        max-width: 140px;
    }

    p {
        font-size: 19px;
        line-height: 25px;

        margin-bottom: 0;

        +p {
            margin-top: 13px;
        }
    }
}

.independent-thinking__content {
    >p:first-of-type:first-letter {
        font-family: $font-family-serif;
        font-size: 100px;
        font-weight: 700;
        line-height: 60px;

        float: left;

        padding-top: 4px;
        padding-right: 8px;
        padding-bottom: 10px;
        padding-left: 3px;

        color: $crest-black;
    }
}

//== date time

.independent-thinking__timings {
    position: absolute;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: row;

    &.timings--article {
        position: relative;

        margin-bottom: 20px;

        .date {
            font-size: 12px;
            line-height: 12px;

            width: 60px;
            height: 40px;
            padding-top: 10px;
        }

        .time {
            padding: 8px 10px 0;
        }
    }

    .date {
        font-size: 8px;
        font-weight: 700;
        line-height: 8px;

        z-index: 20;

        width: 25px;
        height: 25px;
        padding-top: 6px;

        text-align: center;
        text-transform: uppercase;

        color: $crest-black;
        background: $crest-yellow;

        span {
            font-size: 14px;

            display: block;
        }

        @media(min-width: $screen-md) {
            width: 37px;
            height: 37px;
            padding-top: 12px;
        }
    }

    .time {
        font-size: 11px;
        font-weight: 700;
        line-height: 25px;

        padding: 0 10px;

        // line-height: 40px;

        text-transform: uppercase;

        color: white;
        background-color: $crest-red;

        @media(min-width: $screen-md) {
            padding: 6px 10px;
        }

        span {
            text-transform: lowercase;
        }
    }
}

//== featured edition

.independent-thinking__edition {
    margin-top: $division-sm;
    margin-right: -$padding-xs;
    margin-left: -$padding-xs;
    padding: 0 $padding-xs;

    &.bg-color--grey {
        padding-top: $division-sm;

        background-color: $tint-crest-white-darker;
    }

    .inner {
        position: relative;

        overflow: hidden;

        height: 0;
        padding-bottom: 100%;

        >a {
            position: absolute;

            width: 50%;
            height: 50%;

            &:nth-of-type(n+5) {
                display: none;
            }

            &:first-of-type {
                top: 0;
                left: 0;

                border-right: 10px solid white;
                border-bottom: 10px solid white;
            }

            &:nth-of-type(2) {
                top: 0;
                right: 0;

                border-bottom: 10px solid white;
            }

            &:nth-of-type(3) {
                bottom: 0;
                left: 0;

                border-right: 10px solid white;
            }

            &:nth-of-type(4) {
                right: 0;
                bottom: 0;
            }

            .inner__content {
                @include absolute-cover;

                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                display: flex;
                flex-direction: column;

                padding: 10px;

                color: $crest-white;

                align-items: flex-start;
                justify-content: center;

                h3 {
                    font-family: $font-family-sans-serif;
                    font-size: 11px;
                    font-weight: 400;

                    position: relative;

                    margin: 0;
                    margin-top: -20px;
                    padding: 0;
                }

                @media(min-width: 480px) {
                    h3 {
                        font-size: 16px;
                    }
                }

                @media(min-width: $screen-sm) {
                    padding: 20px;
                }

                @media(min-width: $screen-lg) {
                    h3 {
                        max-width: 80%;
                    }
                }

                @media(min-width: 1400px) {
                    padding: 30px;

                    h3 {
                        max-width: 60%;
                    }
                }
            }

            .inner__img {
                @include absolute-cover;

                @include bg-cover-semi-center-home;

                &.inner__img {
                    &:after {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;

                        content: "";

                        background-color: $crest-black-opac-light;
                    }

                    &.inner--solid:after {
                        opacity: 1;
                    }
                }
            }
        }

        &.related-with-tagcloud {
            > a {
                &:first-of-type {
                    border-right: 10px solid #EBEBED;
                    border-bottom: 10px solid #EBEBED;
                }

                &:nth-of-type(2) {
                    border-bottom: 10px solid #EBEBED;
                }

                &:nth-of-type(3) {
                    border-right: 10px solid #EBEBED;
                }

                &:nth-of-type(n+4) {
                    display: none;
                }
            }
        }
    }
}

@media(min-width: $screen-sm) {
    .independent-thinking__edition {
        margin-top: $division-sm;
        margin-right: -$padding-xs;
        margin-left: -$padding-xs;
        padding: 0 $padding-xs;

        .inner {
            padding-bottom: 25%;

            >a {
                position: absolute;

                width: 25%;
                height: 100%;

                &:first-of-type {
                    top: 0;
                    left: 0;

                    border-right: 10px solid white;
                    border-bottom: 0;
                }

                &:nth-of-type(2) {
                    top: 0;
                    right: auto;
                    left: 25%;

                    border-right: 10px solid white;
                    border-bottom: 0;
                }

                &:nth-of-type(3) {
                    bottom: 0;
                    left: 50%;

                    border-right: 10px solid white;
                }

                &:nth-of-type(4) {
                    right: auto;
                    bottom: 0;
                    left: 75%;

                    border-right: 0;
                }

                .inner__content {
                    padding: 20px;
                }
            }

            &.related-with-tagcloud {
                >a {
                    &:first-of-type {
                        border-right: 10px solid #EBEBED;
                        border-bottom: 0;
                    }

                    &:nth-of-type(2) {
                        border-right: 10px solid #EBEBED;
                        border-bottom: 0;
                    }

                    &:nth-of-type(3) {
                        border-right: 10px solid #EBEBED;
                    }

                }
            }
        }
    }
}

//== related article links

.grid-clasification {
    display: none;
    flex-direction: row;

    h3 {
        font-size: 20px;

        margin-top: 5px;
        margin-bottom: 20px;
    }

    h3:first-of-type {
        flex: 3;
    }

    h3:last-of-type {
        flex: 1;
    }
}

@media(min-width: 768px) {
    .grid-clasification {
        display: flex;
    }
}

@media(min-width: 992px) {
    .grid-clasification {
        h3 {
            font-size: 22px;

            margin-bottom: 25px;
        }
    }
}

@media(min-width: 1400px) {
    .grid-clasification {
        h3 {
            font-size: 26px;

            margin-bottom: 30px;
        }
    }
}

.tagCloud {
    position: absolute;
    top: 50%;
    left: 50%;

    display: flex;

    width: 50%;
    height: auto;

    // align-items: center;

    background-color: transparent;

    @media(min-width: 768px) {
        top: 0;
        left: 75%;

        width: 25%;
        // height: 100%;
    }
}

.tagCloud__list {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    align-items: flex-start;
    @media(min-width:576px) and (max-width:767px) {
        flex-direction: column;
    }
    @media(min-width:1200px) {
        flex-direction: column;
    }

    li {
        line-height: 1;
        &:first-of-type {
            a {
                background-color: #BABBBB;
            }
        }

        &:nth-of-type(2) {
            a {
                background-color: #7E5CD9;
            }
        }

        &:nth-of-type(3) {
            a {
                background-color: #C08C45;
            }
        }

        &:nth-of-type(4) {
            a {
                background-color: #AF6D57;
            }
        }

        &:nth-of-type(5) {
            a {
                background-color: #71AA54;
            }
        }

        &:nth-of-type(6) {
            a {
                background-color: #75AEE5;
            }
        }
    }

    a {
        font-size: 11px;
        border-right: 2px solid #ebebed;
        display: inline-block;

        width: 100%;
        margin-bottom: 2px;
        padding: 12px 4px;

        transition: all 0.3s ease;

        color: white;

        &:hover {
            opacity: 0.7;
        }

        @media(min-width: 576px) {
            font-size: 16px;

            // padding: 8px;
        }

        @media(min-width: 768px) {
            font-size: 14px;
            margin-bottom: 2px;
            // padding: 4px;
        }

        @media(min-width: 992px) {
            font-size: 16px;
            padding: 12px;
        }

        @media(min-width: 1200px) {
            padding: 6px 20px;
            margin-bottom: 3px;
        }

        @media(min-width: 1400px) {
            padding: 10px 20px;
        }

        @media(min-width: 1600px) {
            font-size: 17px;
            margin-bottom: 5px;
            padding: 15px 20px;
        }
    }
}

//== previous issues

.independent-thinking__issues {
    padding-top: $padding-sm;
    padding-bottom: $padding-xs;

    @media(min-width: $screen-md) {
        padding-bottom: $padding-sm;
    }

    @media(min-width: $screen-lg) {
        padding-bottom: $padding-md;
    }

    @media(min-width: $screen-lg) {
        padding-bottom: $padding-lg;
    }

    input {
        width: 100%;
        max-width: 400px;
        margin: 0 auto 30px auto;

        border-radius: 0;
    }

    .inner {
        display: flex;

        flex-flow: row wrap;
        justify-content: center;

        a {
            width: 50%;
            max-width: 200px;

            border: 10px solid #EBEBED;
        }

        img {
            width: 100%;
            // max-width: 200px;
        }
    }
}

.independent-thinking__issues-title {
    display: block;

    padding: 10px 0;

    text-align: center;

    color: white;
    background-color: #CD202C;
}

// ================================================
// article page
// ================================================

//== wrap

.independent-thinking {
    .content-wrap__wrapper.col-lg-9 {
        @media(max-width: $screen-md - 1) {
            padding: 0;
        }

        @media(min-width: $screen-md) {
            padding-right: $padding-md;
            padding-left: 30px;
            ;
        }

        @media(min-width: $screen-lg) {
            padding: 0 $padding-lg;
        }

        .col-lg-9 {
            width: 100%;
            padding: 0;
        }
    }
}

//== side bar

.independent-thinking .left-side-nav {
    .well {
        h2 {
            font-size: 16px;
            font-weight: 700;

            margin-bottom: 0;
            padding: 15px;

            background-color: #FBF9F9;
        }
    }

    .left-side-nav__nav {
        li a {
            margin-bottom: 0;
            padding: 15px;

            background-color: #FBF9F9;

            &:after {
                display: none;
            }

            p {
                font-size: 80%;

                margin-bottom: 0;
            }
        }
    }
}

//== content

.independent-thinking #content {
    margin-top: 10px;

    .independent-thinking__blocked {
        margin: 20px 0;

        @media(min-width: 640px) {
            padding: 20px 0 20px 20px;
        }
    }

    figure {
        margin: 30px 0;

        figcaption {
            padding: 5px 15px;

            background-color: $crest-yellow;
        }
    }

    blockquote {
        font-family: $font-family-sans-serif;
        font-style: normal;

        position: relative;

        margin: 20px;
        padding: 20px;

        border: 2px solid $crest-yellow;

        @media(min-width: 640px) {
            margin: 40px;
            padding: 30px;
        }

        &:before,
        &:after {
            position: absolute;
            top: 20px;
            bottom: 20px;

            width: 20px;

            content: "";

            background-color: white;
        }

        &:before {
            left: -10px;
        }

        &:after {
            right: -10px;
        }
    }
}

.independent-thinking__tagged-articles {
    .module-title {
        text-align: center;
    }
}

//== video

.independent-thinking__video {
    position: relative;

    overflow: hidden;

    height: 0;
    padding-bottom: 56%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
    }
}

//== related articles {

.independent-thinking__edition.bg-color--grey {
    @media(min-width: $screen-md) {
        padding: 0 $padding-md;
    }

    @media(min-width: $screen-lg) {
        padding: 0 $padding-lg;
    }
}

// ================================================
// contact page
// ================================================

.independent-thinking__contact {
    margin-right: -$padding-xs;
    margin-left: -$padding-xs;

    @media(min-width: $screen-md) {
        margin-right: -$padding-md;
        margin-left: -$padding-md;
    }

    @media(min-width: $screen-lg) {
        margin-right: -$padding-lg;
        margin-left: -$padding-lg;
    }

    .inner {
        position: relative;

        padding-bottom: 200px;

        background-color: $tint-crest-white-darker;
    }
}



.independent-thinking__contact-content {
    font-weight: 700;

    padding: 30px 30px 60px;

    p {
        line-height: 1.5;
    }

    ul {
        margin-top: 30px;
    }

    li {
        position: relative;

        &:first-of-type {
            margin-bottom: 30px;
        }

        a {
            position: absolute;
            left: 30px;

            color: $crest-black;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.independent-thinking__contact-image {
    @include bg-cover-center;

    @include absolute-cover;

    top: auto;

    height: 200px;
}

@media(min-width: $screen-sm) {
    .independent-thinking__contact .inner {
        padding-bottom: 350px;
    }

    .independent-thinking__contact-content {
        @include centered-v;

        width: 50%;
        padding: 0 30px;
    }

    .independent-thinking__contact-image {
        @include bg-cover-center;

        @include absolute-cover;

        left: 50%;

        width: 50%;
        height: 100%;
    }
}

@media(min-width: $screen-lg) {
    .independent-thinking__contact .inner {
        padding-bottom: 400px;
    }

    .independent-thinking__contact-content {
        @include centered-v;

        width: 45%;
        padding: 0 60px 20px;

        p {
            max-width: 80%;
        }
    }

    .independent-thinking__contact-image {
        @include bg-cover-center;

        @include absolute-cover;

        left: 45%;

        width: 55%;
        height: 100%;
    }
}

@media(min-width: 1400px) {
    .independent-thinking__contact .inner {
        padding-bottom: 500px;
    }
}

//== ezine

.independent-thinking__ezine {
    margin-right: -10px;
    margin-left: -10px;
    padding: 50px 0;

    text-align: center;

    background-color: $crest-yellow;

    input.form-control {
        color: $crest-black;
        border: 1px solid $crest-black;
        border-radius: 0;
        background-color: $crest-yellow;

        &::placeholder {
            opacity: 1;

            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $crest-black;

            /* Firefox */
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $crest-black;
        }

        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $crest-black;
        }
    }

    p {
        margin-top: 30px;

        &:first-of-type {
            margin-top: 0;
        }
    }

    .btn--black {
        font-family: $font-family-base;
        font-size: 18px;
        font-size: 14px;
        font-weight: 100;
        line-height: 40px;

        display: block;

        width: 100%;
        min-width: 150px;
        height: 40px;
        min-height: 40px;
        padding: 0 12px;

        color: white;
        background-color: $crest-black;
    }

    a {
        color: $crest-black;
    }

    @media(min-width: $screen-sm) {
        .btn--black {
            display: inline-block;

            width: auto;
        }
    }

    @media(min-width: $screen-md) {
        margin-right: -$padding-md;
        margin-left: -$padding-md;
    }

    @media(min-width: $screen-lg) {
        margin-right: -$padding-lg;
        margin-left: -$padding-lg;
    }
}