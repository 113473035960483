// variable

$transition: 0.3s transform cubic-bezier(0.445, 0.05, 0.55, 0.95);

.mobile-nav-active {
    overflow: hidden;
}

.has-header-carousel {
    @media all and (max-width: 767px) {
        .header.unpinned {
            transform: translateY(calc(-100% - 70px));
        }
    }

    .page-wrap {
        margin-top: 200px;

        @media all and (min-width: 768px) {
            margin-top: 130px;
        }
    }
}

.has-headline:not(.has-subtext) {
    .navbar__club__logo > a.inner {
        h1 {
            @media all and (min-width: 768px) {
                font-size: 18px;
            }

            @media all and (min-width: 992px) {
                font-size: 22px;
            }
        }
    }
}

.has-header-carousel.has-image.has-headline:not(.has-subtext) {
    .navbar__club__logo {
        img {
            @media all and (min-width: 1200px) and (max-width: 1439px) {
                height: 50px;
            }
        }
    }

    .navbar__club__logo > a.inner {
        h1 {
            @media all and (min-width: 1200px) {
                font-size: 22px;
            }
        }
    }
}

.has-image.has-headline:not(.has-subtext):not(.has-header-carousel) {
    .navbar__club__logo > a.inner {
        h1 {
            @media all and (min-width: 768px) {
                font-size: 18px;
            }

            @media all and (min-width: 992px) {
                font-size: 22px;
            }
        }
    }
}

.has-image.has-headline {
    @media all and (max-width: 767px) {
        .header.unpinned {
            transform: translateY(calc(-100% - 75px));
        }
    }

    .page-wrap {
        margin-top: 204px;
    }

    .navbar__club__logo {
        > a.inner {
            position: absolute;
            top: 113px;
            left: -111px;

            display: flex;
            align-items: center;
            justify-content: center;

            width: 100vw;
            height: 75px;
            padding-top: 11px;
            padding-right: 15px;
            padding-bottom: 9px;
            padding-left: 15px;

            text-align: center;

            background: #FFFFFF;

            h1 {
                font-size: 16px;

                max-width: 300px;
                margin: 0 auto;

                @media all and (max-width: 991px) {
                    max-width: none;
                }

                span {
                    font-size: 14px;

                    width: 100%;
                    max-width: none;
                    margin-top: 3px;
                }
            }
        }
    }
}

.has-header-carousel.has-image.has-headline {
    @media all and (max-width: 991px) {
        .header.unpinned {
            transform: translateY(calc(-100% - 70px - 75px));
        }
    }

    .page-wrap {
        margin-top: 274px;

        @media all and (min-width: 768px) {
            margin-top: 204px;
        }

        @media all and (min-width: 992px) {
            margin-top: 184px;
        }

        @media all and (min-width: 1200px) {
            margin-top: 200px;
        }
    }

    .navbar-carousel {
        bottom: -144px;

        @media all and (min-width: 992px) and (max-width: 1339px) {
            width: 150px;
        }
    }

    .navbar__club__logo {
        img {
            @media all and (min-width: 992px) and (max-width: 1439px) {
                height: 50px;
            }
        }

        @media all and (min-width: 992px) {
            padding-left: 15px;
        }

        @media all and (min-width: 1440px) {
            padding-left: 25px;
        }
    }

    .navbar__club__logo > a.inner {
        @media all and (min-width: 992px) {
            position: relative;
            top: 0;
            left: 0;

            width: auto;
            padding-top: 0;
            padding-right: 0;
            padding-bottom: 0;
            padding-left: 15px;

            text-align: left;
        }

        @media all and (min-width: 1440px) {
            padding-left: 25px;
        }

        h1 {
            @media all and (min-width: 768px) {
                font-size: 18px;
            }

            span {
                @media all and (min-width: 768px) {
                    font-size: 16px;
                }
            }
        }
    }
}

.has-image.has-headline:not(.has-header-carousel) {
    .page-wrap {
        @media all and (min-width: 768px) {
            margin-top: 130px;
        }

        @media all and (min-width: 992px) {
            margin-top: 184px;
        }

        @media all and (min-width: 1200px) {
            margin-top: 200px;
        }
    }

    .navbar__club__logo > a.inner {
        @media all and (min-width: 768px) {
            position: relative;
            top: 0;
            left: 0;

            width: auto;
            padding-top: 0;
            padding-right: 0;
            padding-bottom: 0;
            padding-left: 10px;

            text-align: left;
        }

        @media all and (min-width: 992px) {
            padding-left: 25px;
        }

        h1 {
            @media all and (min-width: 992px) {
                font-size: 18px;

                max-width: 280px;
            }

            @media all and (min-width: 1200px) {
                font-size: 22px;

                max-width: 340px;
            }

            span {
                @media all and (min-width: 1200px) {
                    font-size: 20px;
                }
            }
        }
    }
}

.has-headline.has-header-carousel:not(.has-image) {
    .ucc-class > a.inner {
        h1 {
            @media all and (min-width: 992px) {
                font-size: 18px;

                max-width: 280px;
            }

            @media all and (min-width: 1440px) {
                font-size: 22px;

                max-width: 340px;
            }

            span {
                @media all and (min-width: 1440px) {
                    font-size: 20px;
                }
            }
        }
    }
}

.has-image.has-headline.has-subtext.has-header-carousel {
    .navbar-carousel {
        @media all and (min-width: 1480px) and (max-width: 1535px) {
            max-width: 250px;
        }

        @media all and (min-width: 1660px) and (max-width: 1709px) {
            width: 400px;
        }
    }

    .navbar__club__logo > a.inner h1 {
        @media all and (min-width: 1200px) and (max-width: 1299px) {
            max-width: 190px;
        }

        @media all and (min-width: 1340px) and (max-width: 1379px) {
            max-width: 260px;
        }

        @media all and (min-width: 1480px) and (max-width: 1535px) {
            max-width: 280px;
        }

        @media all and (min-width: 1660px) and (max-width: 1709px) {
            max-width: 280px;
        }
    }
}

// header

.ucc--header.header {
    @media (min-width: 992px) {
        height: 184px;
    }

    @media (min-width: 1200px) {
        height: 202px;
    }
}

.header {
    .navbar-carousel {
        position: absolute;
        top: 0;
        right: 500px;

        display: flex;
        align-items: center;

        width: 665px;
        height: 100%;

        @media all and (max-width: 1849px) {
            width: 480px;
        }

        @media all and (max-width: 1659px) {
            width: 300px;
        }

        @media all and (max-width: 1479px) {
            width: 200px;
        }

        @media all and (max-width: 1199px) {
            right: 184px;

            width: 480px;
            height: 124px;
        }

        @media all and (max-width: 1130px) {
            width: 300px;
        }

        @media all and (max-width: 991px) {
            right: 120px;

            width: 300px;
            height: 70px;
        }

        @media all and (max-width: 767px) {
            top: auto;
            right: 0;
            bottom: -70px;
            left: 0;

            width: 100%;
            height: 70px;
            margin: 0 auto;
            padding-right: 50px;
            padding-left: 50px;

            border-top: 1px solid #E5E5E5;
            background-color: #FFFFFF;
        }

        // @media all and (max-width: 737px){
        //   width: 200px;
        // }
        // @media all and (max-width: 629px){
        //   width: 100px;
        // }
        .navbar-carousel__slide {
            margin-right: 10px;

            @media all and (min-width: 992px) {
                margin-right: 20px;
            }
        }

        .slick-slide {
            img {
                height: 30px;

                @media all and (min-width: 992px) {
                    height: 50px;
                }
            }
        }

        .slick-arrow:before {
            font-family: "FontAwesome";
            font-size: 40px;
            line-height: 1;

            opacity: 1;
            color: black;
        }

        .slick-prev {
            right: -35px;
            left: auto;

            @media all and (max-width: 767px) {
                right: auto;
                left: 10px;
            }
        }

        .slick-next {
            right: -60px;

            @media all and (max-width: 767px) {
                right: 10px;
            }
        }

        .slick-next,
        .slick-prev {
            height: 30px;

            @media all and (max-width: 767px) {
                height: 100px;
            }
        }

        .slick-arrow.slick-next:before {
            content: "\f105";
        }

        .slick-arrow.slick-prev:before {
            content: "\f104";
        }
    }

    .ucc-class {
        position: absolute;
        top: 19px;
        left: 110px;

        display: flex;
        align-items: center;
        // height: 27%;

        padding-left: 10px;

        border-left: 2px solid $crest-black;
        // display: none;
        // background: #000;

        .inner {
            color: $crest-black;
        }

        h1,
        h2,
        h3 {
            margin: 0;
        }

        h1,
        h2 {
            @include sans-b;

            font-size: 14px;

            max-width: 220px;

            @media all and (max-width: 415px) {
                font-size: 12px;

                max-width: 190px;
            }
        }

        h3,
        span {
            @include sans-r;

            font-size: 12px;

            display: block;

            max-width: 190px;
            margin-top: 2px;

            @media all and (max-width: 415px) {
                font-size: 10px;
            }
        }

        // @media all and(min-width: 450px) {
        //     margin-top: 3px;
        // }

        @media all and(min-width: $screen-md-min) {
            top: 28px;
            left: 190px;

            height: 70px;
        }

        @media all and(min-width: 1340px) {
            top: 27px;
            left: 205px;

            height: 70px;
            padding-left: 22px;

            h1,
            h2 {
                font-size: 24px;

                max-width: 430px;
            }

            h3,
            span {
                font-size: 22px;

                max-width: none;
            }

            span {
                margin-top: 2px;
            }
        }
    }
}

// Club logo
.ucc--header .navbar__club__logo {
    position: absolute;
    top: 17px;
    left: 109px;

    display: flex;
    align-items: center;

    height: 40px;
    padding-left: 10px;

    border-left: 2px solid $crest-black;

    @media (min-width: 992px) {
        top: 28px;
        left: 202px;

        height: 70px;
        padding-left: 25px;
    }

    // &.ucc-class {
    // 	a {
    // 		margin-left: 10px;
    // 	}
    // }

    img {
        height: 40px;
        margin: 0;

        @media (min-width: 992px) {
            height: 70px;
        }
    }

    h2 {
        font-size: 14px;
        font-weight: 900;

        max-width: 100px;
        margin: 0 0 0 10px;

        @media all and (max-width: 415px) {
            font-size: 12px;
        }

        @media (min-width: 768px) {
            font-size: 18px;

            max-width: unset;
        }

        @media (min-width: 992px) {
            font-size: 21px;
        }

        @media (min-width: 1200px) {
            font-size: 24px;
        }
    }
}

// Main navigation toggle

.ucc-navigation-toggle {
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
        background-color: $crest-red;

        button {
            color: white;
        }
    }

    button {
        font-size: 12px;
        font-weight: 600;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        border: 0;
        background-color: transparent;

        &.show {
            display: flex !important;

            .open {
                display: none;
            }

            .close {
                display: block;

                opacity: 1;
            }
        }

        svg {
            margin-bottom: 5px;

            path {
                stroke: none;
            }

            &.open {
                display: block;
            }

            &.close {
                display: none;
            }
        }
    }

    @media (min-width: 1200px) {
        display: none !important;
    }
}

// Main navigation nav

.ucc-nav {
    @media (max-width: 1199px) {
        position: fixed;
        top: 130px;
        right: 0;
        bottom: 0;

        overflow-x: hidden;
        // overflow-x: hidden;

        width: 300px;
        height: calc(100vh - 130px);
        padding: 30px 0;

        transition: $transition;
        transform: translateX(300px);

        border-top: 3px solid $crest-red;
        background-color: white;

        &.show {
            display: block !important;
        }

        .sub-menu-opened {
            overflow-x: hidden;
        }

        &.show {
            transform: translateX(0);
        }
    }

    @media (min-width: 992px) {
        top: 184px;
    }

    @media (min-width: 1200px) {
        position: absolute;
        top: 124px;
        right: 0;
        left: 0;
        border-bottom: 2px solid $crest-black;
    }
}

.ucc-nav__main {
    @media (min-width: 1200px) {
        display: flex;
        justify-content: center;
    }



    li {
        @media (max-width: 1199px) {
            border-bottom: 1px solid #E0E0E0;
        }

        @media (min-width: 1200px) {
            display: flex;

            padding: 0;
        }

        a {
            display: flex;
            justify-content: space-between;

            width: 100%;
            padding: 15px 30px;

            @media (min-width: 1200px) {
                padding: 22px 25px;
            }
        }
    }

    > li > a {
        @media (min-width: 1200px) {
            font-weight: 700;

            text-transform: uppercase;
        }
    }
}

// dropdown li

@media (min-width: 1200px) {
    .ucc-nav__main {
        > .ucc-nav__dropdown {
            position: relative;

            &:last-child,
            &:nth-last-child(2) {
                // &:nth-last-child(3) {

                > .ucc-nav__dropdown__menu {
                    right: 0;
                    // right: 313px;
                    left: unset;

                    &.sub-menu-opened {
                        right: 313px;
                    }
                }
            }

            &.active {
                > a {
                    svg {
                        transform: rotate(-90deg) !important;
                    }
                }
            }
        }

        .ucc-nav__dropdown {
            cursor: pointer;

            &.long-dropdown {
                .ucc-nav__dropdown {
                    flex-wrap: wrap;
                }

                > .ucc-nav__dropdown__menu {
                    overflow-y: auto;

                    max-height: calc(100vh - 201px);

                    @at-root .unpinned #{&} {
                        visibility: hidden;
                    }

                    .ucc-nav__dropdown.active {
                        svg {
                            transform: rotate(-90deg);
                        }
                    }

                    .ucc-nav__dropdown__menu--slide {
                        position: relative;
                        top: 0;
                        left: 0;
                    }
                }
            }

            a {
                position: relative;

                padding: 22px 45px 22px 25px;

                &:after {
                    position: absolute;
                    right: 25px;
                    bottom: 0;
                    left: 25px;

                    height: 1px;

                    content: "";

                    background-color: #E0E0E0;
                }
            }


            > .dropdown-toggle span {
                margin-top: 2px;
                margin-left: 10px;

                svg {
                    transition: 0.1s all ease;
                    transform: rotate(90deg);
                }
            }
        }
    }
}

// dropdown menu

.ucc-nav__dropdown__menu {
    @media (max-width: 1199px) {
        position: absolute;
        top: 0;
        left: 300px;

        width: 300px;
        height: 100%;
        padding: 30px 0;

        transition: $transition;
        transform: translateX(0);

        background-color: white;

        @media (orientation: landscape) {
            height: 200%;
        }

        &--slide {
            transform: translateX(-300px);
        }
    }
}

.ucc-nav__dropdown__menu {
    @media (min-width: 1200px) {
        position: absolute;
        top: 74px;
        left: 0;

        display: none;
        overflow-y: auto;

        width: 313px;
        min-height: 250px;
        // border-top: 2px solid $crest-red;

        border-top: 2px solid $crest-blue;
        background-color: white;
    }

    .ucc-nav__dropdown__menu {
        @media (min-width: 1200px) {
            top: -2px;
            left: 313px;

            overflow-y: auto;

            background-color: #F1F1F2;

            @at-root .pinned #{&} {
                max-height: calc(100vh - 202px);
            }

            @at-root .unpinned #{&} {
                max-height: calc(100vh - 2px);
            }
        }
    }
}

// dropdown toggle

.ucc--header .dropdown-toggle,
.ucc-nav__dropdown__toggle {
    display: flex;
    justify-content: space-between;
}

.ucc--header .ucc-nav__main > .ucc-nav__dropdown > .dropdown-toggle {
    &:after {
        content: none !important;
    }
}

// back button

.ucc-nav__back {
    @media (min-width: 1200px) {
        display: none !important;
    }
}

// topnav

.ucc--header .top-nav {
    border-color: #E5E5E5;

    @media (min-width: 1200px) {
        right: 135px;

        display: flex;
    }

    > li {
        align-items: center;
        justify-content: center;

        border-color: #E5E5E5;

        &:first-child {
            @media (min-width: 1200px) {
                order: 3;
            }
        }

        a {
            padding-top: unset;
        }

        &.bg-crest-yellow,
        &.icon-sky-blue {
            background-color: transparent;

            i {
                font-size: 28px;
            }

            span {
                font-size: 16px;
                font-weight: 600;

                @media (min-width: 1200px) {
                    white-space: nowrap;
                }
            }
        }

        &.bg-crest-yellow {
            i {
                color: $crest-yellow;
            }

            @media (min-width: 1200px) {
                background-color: $crest-yellow;

                i {
                    color: $crest-black;
                }
            }
        }

        &.satellite-search-toggle {
            padding-left: 20px;

            i {
                color: $crest-black;
            }

            &.open * {
                color: inherit;
            }

            span {
                @media (min-width: 1200px) {
                    display: none;
                }
            }
        }
    }

    .dropdown.dropdown--lang {
        top: unset;

        float: none;

        margin: 0;

        button {
            padding: 8px;
        }
    }
}

// searchwrap

.ucc-satellite .page-wrap.show-search .search-wrapper {
    top: 0;
}

// explore

.explore-toggle {
    position: relative;

    float: right;

    width: 50px;
    height: 70px;

    border: 0;
    outline: none;
    background-color: $tint-crest-white-darker;

    @media (min-width: 992px) {
        width: 114px;
        height: 124px;

        &.active {
            height: 124px;
        }

        &.active span {
            color: #FFFFFF;
        }
    }

    span {
        font-size: 8px;
        line-height: 1;

        position: absolute;
        top: 39px;
        right: 0;
        left: 0;

        display: inline-block;

        transition: 0.5s ease;
        letter-spacing: 0;

        color: $crest-black;

        @media (min-width: 992px) {
            font-size: 14px;

            top: 70px;
            right: 0;
            left: 0;

            width: 100%;

            letter-spacing: 1px;
        }
    }

    &::after {
        position: absolute;
        top: 7px;
        left: 50%;

        width: 32px;
        height: 32px;

        content: "";
        // transition: .5s ease;
        transform: translateX(-50%);
        text-align: center;

        background-image: url("/en/media/2017siteassets/images/icon-ucc.png");
        background-repeat: no-repeat;
        background-size: contain;

        @media (min-width: 992px) {
            top: 21px;

            width: 42px;
            height: 42px;
        }
    }

    &.show {
        background-color: $crest-yellow;

        &::after {
            background-image: url("/en/media/2017siteassets/images/icon-ucc--close.svg");
        }
    }
}

.explore-ucc-menu {
    position: absolute;
    z-index: 10;
    top: 70px;
    right: 0;

    display: none;

    width: 100%;
    max-width: 700px;
    padding: 20px;

    background-color: $crest-yellow;

    &.show {
        display: block;
    }

    @media (min-width: 768px) {
        padding: 40px;
    }

    @media (min-width: 992px) {
        top: 124px;
    }

    h2 {
        font-size: 45px;
        font-weight: 900;

        margin: 0;
    }
}

.explore-ucc-menu__main {
    display: flex;
    justify-content: space-between;

    margin-top: 22px;

    li {
        line-height: 1.65;

        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }

        a {
            text-transform: uppercase;

            color: $crest-black;

            &:after {
                margin-top: 2px;
                margin-left: 13px;

                content: url("/en/media/2017siteassets/images/small-chevron.svg");
            }
        }
    }

    img {
        display: none;

        @media (min-width: 576px) {
            display: block;

            width: 280px;
            height: 100%;
        }
    }
}

.ucc-nav__main {
    @media all and (min-width: 1200px) {
        .ucc-nav__dropdown__menu {
            overflow-x: hidden;
            overflow-y: auto;
        }

        li {
            position: static;

            .wrapper {
                position: absolute;
                z-index: 10;
                top: 74px;

                display: none;

                .ucc-nav__dropdown__menu {
                    position: static;
                }
            }

            &.active > .wrapper {
                display: block;

                li.active > .wrapper {
                    top: 0;
                    left: 313px;
                }

                .ucc-nav__dropdown__menu {
                    display: block;
                }
            }
        }
    }
}
