.module-9 {
	margin: 0 -5px;
	@include spacer-p-t;
	.slideShowMultiple {
		.slick-track {
			margin: 0 auto;
			text-align: center;
		}
		.multiple-wrap {
			position: relative;
			margin: 0 5px;
			float: none !important;
			display: inline-block !important;
			a {
				color: $crest-white;
				&.linktype--internal .overlay:after {
					content: "\f178";
				}
				&.linktype--external .overlay:after {
					content: "\f08e";
				}
				&.linktype--modal .overlay:after {
					content: "\f2d0";
				}
				&.linktype--video .overlay:after {
					content: "\f01d";
				}
				&.linktype--document .overlay:after {
					content: "\f016";
				}
				&:hover {
					text-decoration: none;
				}
				.image-wrap {
					position: relative;
					height: 0;
					overflow: hidden;
					padding-bottom: 100%;
					.inner__img {
						position: absolute;
						top: 0;
						right: 0;
						bottom: 50%;
						left: 0;
						@include bg-cover-center;
						height: 100%;
					}
				}
				.overlay {
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				padding: 20px;
				background-color: $crest-blue;
				text-align: left;
				opacity: 0.9;
				transition: .3s ease;
				&:after {
					content: "\f105";
					font-family: 'FontAwesome';
					color: $crest-white;
					font-weight: normal;
					font-weight: normal;
					padding-left: 10px;
					font-size: 100%;
					position: absolute;
					right: 20px;
					top: 26px;
				}
				h2 {
					font-size: 24px;
					margin: 0;
					padding-right: 15px;
					line-height: 1.2;
					font-family: $font-family-serif;
					font-weight: 400;
				}
				p {
					margin: 10px 0 0;
					line-height: 1.5;
				}
				&.bg-crest-white {
					background-color: $crest-white;
					*,
					&:after {
						color: $crest-black;
					}
				}
				&.bg-tint-crest-white {
					background-color: $tint-crest-white;
					*,
					&:after {
						color: $crest-black;
					}
				}
				&.bg-crest-black {
					background-color: $crest-black;
				}
				&.bg-crest-red {
					background-color: $crest-red;
				}
				&.bg-crest-blue,
				&.bg-brand-facebook {
					background-color: $crest-blue;
				}
				&.bg-quad-green {
					background-color: $quad-green;
				}
				&.bg-crest-yellow {
					background-color: $crest-yellow;
					*,
					&:after {
						color: $crest-black;
					}
				}
				&.bg-earth-red {
					background-color: $earth-red;
				}
				&.bg-ogham-stone {
					background-color: $ogham-stone;
				}
				&.bg-dawn-mauve {
					background-color: $dawn-mauve;
				}
				&.bg-limestone {
					background-color: $limestone;
					*,
					&:after {
						color: $crest-black;
					}
				}
				&.bg-sky-blue,
				&.bg-brand-twitter {
					background-color: $sky-blue;
					*,
					&:after {
						color: $crest-white;
					}
				}
				&:hover {
					opacity: 1;
					padding: 20px 20px 30px;
				}
			}
			}
		}



	}

	/*.slick-list {
		padding-right: 10%;
	}*/
}

.content-wrap__inner {
	.module-9 {
		.slideShowMultiple {
			.multiple-wrap {
				a {
					.overlay {
						&:after {
							top: 23px;
						}
						h2 {
							font-size: 20px;
							font-family: $font-family-sans-serif;
						}
					}
				}
			}
		}
	}
}
