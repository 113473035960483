

.module-21 {
	margin: 10px auto 9px;
	padding: 20px 0;
	.social-block li {
		display: inline-block;
		margin: 0 3px;
		border: 3px solid #fff;
		a {
			width: 42px;
			height: 42px;
			display: block;
			color: $crest-white;
			font-size: 20px;
			line-height: 30px;
			padding-top: 6px;
			
			transition: .8s ease;
		}
		a:hover {
			transform: rotate(360deg);
		}
		&.twitter {
			background-color: $brand-twitter;
		}
		&.facebook {
			background-color: $brand-facebook;
		}
		&.instagram {
			background-color: $brand-instagram;
		}
		&.youtube {
			background-color: $brand-youtube;
		}
		&.linkedin {
			background-color: $brand-linkedin;
		}
	}
}

.module-11 + .module-21 {
	position: relative;
	z-index: 1002;
	margin-top: -20px;
	padding: 0 0 30px;
}

@media (min-width: $screen-md) {
	.module-11 + .module-21 {
		margin-top: -45px;
		padding: 0 0 80px;
	}
}