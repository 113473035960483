.module-7 {
	@include spacer-p-t;
	&.capped {
		max-width: 1400px;
		margin-left: auto;
		margin-right: auto;
		//@include spacer-p-t-b-x2;
	}
	.spotlight {
		font-family: $font-family-serif;
		font-size: 18px;
	}
	.slideShowFact {
		.wrap {
			position: relative;
			height: auto;
			overflow: hidden;
			@media (min-width: $screen-sm) {
				height: 400px;
			}
			@media (min-width: $screen-md) {
				height: 500px;
			}
			@media (min-width: 1600px) {
				height: 550px;
			}
			.bg-img {
				position: absolute;
				bottom: 0;
				@include bg-cover-center;
				height: 0;
				width: 100%;
				height: 300px;
				border-top: 6px solid $crest-white;
				&:before {
					position: absolute;
					z-index: 10;
					top: -6px;
					margin-bottom: -28px;
					margin-left: -30px;
					left: 50%;
					width: 0;
					height: 0;
					content: '';
					//transform: translateX(-50%);
					border-top: 28px solid transparent;
					border-right: 28px solid transparent;
					border-left: 28px solid transparent;
					background-repeat: no-repeat;
					background-size: 100%;
					@media (min-width: $screen-sm) {
						display: none;
					}
				}
				&:after {
					position: absolute;
					z-index: 9;
					top: -6px;
					margin-bottom: -38px;
					margin-left: -40px;
					left: 50%;
					width: 0;
					height: 0;
					content: '';
					//transform: translateX(-50%);
					border-top: 38px solid #fff;
					border-right: 38px solid transparent;
					border-left: 38px solid transparent;
					background-repeat: no-repeat;
					background-size: 100%;
					@media (min-width: $screen-sm) {
						display: none;
					}
				}
			}
			.content {
				position: relative;
				top: 0;
				left: 0;
				height: auto;
				width: 100%;
				padding-bottom: 300px;
				> div {
					padding: 30px;
					position: relative;
					
					@media (min-width: $screen-md) {
						@include centered-v;
					}
					h2 {
						@include serif-r;
						margin-bottom: 15px;
						// font-size: $font-size-h4;
						font-size: 20px;
						//color: $crest-white;
						// @media (min-width: 400px) {
						// 	font-size: $font-size-h3;
						// }
						@media (min-width: 500px) and (max-width: $screen-sm) {
							font-size: $font-size-h2;
						}
						@media (min-width: $screen-md) {
							font-size: 22px;
						}
						@media (min-width: $screen-lg) {
							font-size: 32px;
							// font-size: $font-size-h2;
						}
					}
					.spotlight + h2 {
						margin-top: 10px;
					}
					p:not(.spotlight) {
						//display: none;
					}
					.btn {
						margin-top: 10px;
					}
					@media (min-width: $screen-lg) {
						padding: 50px;
						p:not(.spotlight) {
							display: block;
							margin-bottom: 20px;
							font-weight: 300;
						}
						h2 {
							margin-bottom: 20px;
							font-size: 32px;
						}
						p:not(.spotlight) {
							font-size: 16px;
						}
					}
					@media (min-width: $screen-lg) {
						padding: 100px;
						h2 {
							font-size: 32px;
						}
					}
					@media (min-width: 1600px) {
						h2 {
							font-size: 42px;
						}
					}
				}
			}
			@media (min-width: $screen-sm) {
				//padding-bottom: 10%;
				@media (min-width: 1400px) {
				//	padding-bottom: 8%;
				}
				.bg-img {
					height: 100%;
					width: 50%;
					top: 0;
					left: 50%;
					border-top: 0;
					border-left: 6px solid $crest-white;
				}
				.dig-signage-img {
					width: 100%;
					left: 0;
					border-left: none;
				}
				.content {
					height: 100%;
					width: 50%;
					left: 0;
					position: relative;
					&::before {
						position: absolute;
						z-index: 10;
						top: 50%;
						right: -28px;

						width: 0;
						height: 0;

						content: '';
						transform: translateY(-50%);

						border-top: 28px solid transparent;
						border-bottom: 28px solid transparent;
						border-left: 28px solid transparent;
					}
					&::after {
						position: absolute;
						z-index: 9;
						top: 50%;
						right: -38px;

						width: 0;
						height: 0;

						content: '';
						transform: translateY(-50%);

						border-top: 38px solid transparent;
						border-bottom: 38px solid transparent;
						border-left: 38px solid #fff;
					}
				}
			}
		}
		&.bg-crest-red {
			.content, h2 {
				color: $crest-white;
				&::before {
					// background-image: url('../../images/triangle-crest-red.png');
					border-left-color: #cd212c;
				}
			}
			.wrap {
				.bg-img:before {
					border-top-color: $crest-red;
				}
			}
		}
		&.bg-crest-yellow {
			.content, h2 {
				color: $crest-black;
				&::before {
					// background-image: url('../../images/triangle-crest-yellow.png');
					border-left-color: #ffb500;
				}
			}
			.wrap {
				.bg-img:before {
					border-top-color: $crest-yellow;
				}
			}
		}
		&.bg-crest-blue {
			.content, h2{
				color: $crest-white;
				&::before {
					// background-image: url('../../images/triangle-crest-blue.png');
					border-left-color: #003c69;
				}
				>div:before {
					border-top-color: $crest-blue;
				}
			}
			.wrap {
				.bg-img:before {
					border-top-color: $crest-blue;
				}
			}
		}
		&.bg-quad-green {
			.content, h2{
				color: $crest-white;
				&::before {
					// background-image: url('../../images/triangle-crest-blue.png');
					border-left-color: $quad-green;
				}
				>div:before {
					border-top-color: $quad-green;
				}
			}
			.wrap {
				.bg-img:before {
					border-top-color: $quad-green;
				}
			}
			.slick-prev,.slick-next {
				&:before {
					color: $quad-green;
				}
			}
		}
		&.bg-dawn-mauve {
			.content, h2{
				color: $crest-white;
				&::before {
					// background-image: url('../../images/triangle-crest-blue.png');
					border-left-color: $dawn-mauve;
				}
				>div:before {
					border-top-color: $dawn-mauve;
				}
			}
			.wrap {
				.bg-img:before {
					border-top-color: $dawn-mauve;
				}
			}
			.slick-prev,.slick-next {
				&:before {
					color: $dawn-mauve;
				}
			}
		}
		&.bg-crest-white {
			.content, h2{
				color: $crest-blue;
				&::before {
					// background-image: url('../../images/triangle-crest-blue.png');
					border-left-color: $crest-white;
				}
				>div:before {
					border-top-color: $crest-white;
				}
			}
			.wrap {
				.bg-img:before {
					border-top-color: $crest-white;
				}
				.btn--white {
					background-color: $crest-blue;
					color: $crest-white;
				}
			}
			.slick-prev,.slick-next {
				background-color: $crest-red;
				&:before {
					color: $crest-white;
				}
			}
		}
		&.bg-tint-crest-white {
			.content, h2{
				color: $crest-blue;
				&::before {
					// background-image: url('../../images/triangle-crest-blue.png');
					border-left-color: $tint-crest-white;
				}
				>div:before {
					border-top-color: $tint-crest-white;
				}
			}
			.wrap {
				.bg-img:before {
					border-top-color: $tint-crest-white;
				}
				.btn--white {
					background-color: $crest-blue;
					color: $crest-white;
				}
			}
			.slick-prev,.slick-next {
				background-color: $crest-red;
				&:before {
					color: $crest-white;
				}
			}
		}
		&.bg-crest-black {
			.content, h2{
				color: $crest-white;
				&::before {
					// background-image: url('../../images/triangle-crest-blue.png');
					border-left-color: $crest-black;
				}
				>div:before {
					border-top-color: $crest-black;
				}
			}
			.wrap {
				.bg-img:before {
					border-top-color: $crest-black;
				}
			}
			.slick-prev,.slick-next {
				&:before {
					color: $crest-blue;
				}
			}
		}
		&.bg-earth-red {
			.content, h2{
				color: $crest-white;
				&::before {
					// background-image: url('../../images/triangle-crest-blue.png');
					border-left-color: $earth-red;
				}
				>div:before {
					border-top-color: $earth-red;
				}
			}
			.wrap {
				.bg-img:before {
					border-top-color: $earth-red;
				}
			}
			.slick-prev,.slick-next {
				&:before {
					color: $earth-red;
				}
			}
		}
		&.bg-ogham-stone {
			.content, h2{
				color: $crest-white;
				&::before {
					// background-image: url('../../images/triangle-crest-blue.png');
					border-left-color: $ogham-stone;
				}
				>div:before {
					border-top-color: $ogham-stone;
				}
			}
			.wrap {
				.bg-img:before {
					border-top-color: $ogham-stone;
				}
			}
			.slick-prev,.slick-next {
				&:before {
					color: $ogham-stone;
				}
			}
		}
		&.bg-limestone {
			.content, h2{
				color: $crest-black;
				&::before {
					// background-image: url('../../images/triangle-crest-blue.png');
					border-left-color: $limestone;
				}
				>div:before {
					border-top-color: $limestone;
				}
			}
			.wrap {
				.bg-img:before {
					border-top-color: $limestone;
				}
			}
			.slick-prev,.slick-next {
				&:before {
					color: $crest-blue;
				}
			}
		}
		&.bg-sky-blue {
			background-color: $sky-blue;
			.content, h2{
				color: $crest-white;
				&::before {
					// background-image: url('../../images/triangle-crest-blue.png');
					border-left-color: $sky-blue;
				}
				>div:before {
					border-top-color: $sky-blue;
				}
			}
			.wrap {
				.bg-img:before {
					border-top-color: $sky-blue;
				}
			}
			.slick-prev,.slick-next {
				&:before {
					color: $sky-blue;
				}
			}
		}
		&.bg-brand-twitter {
			.content, h2{
				color: $crest-white;
				&::before {
					// background-image: url('../../images/triangle-crest-blue.png');
					border-left-color: $brand-twitter;
				}
				>div:before {
					border-top-color: $brand-twitter;
				}
			}
			.wrap {
				.bg-img:before {
					border-top-color: $brand-twitter;
				}
			}
			.slick-prev,.slick-next {
				&:before {
					color: $brand-twitter;
				}
			}
		}
		&.bg-brand-facebook {
			.content, h2{
				color: $crest-white;
				&::before {
					// background-image: url('../../images/triangle-crest-blue.png');
					border-left-color: $brand-facebook;
				}
				>div:before {
					border-top-color: $brand-facebook;
				}
			}
			.wrap {
				.bg-img:before {
					border-top-color: $brand-facebook;
				}
			}
			.slick-prev,.slick-next {
				&:before {
					color: $brand-facebook;
				}
			}
		}
		.slick-prev,.slick-next {
			position: absolute;
			//top: calc(50% - 25px);
			top: auto;
			bottom: 300px;
			height: 50px;
			width: 50px;
			background-color: $crest-white;
			z-index: 10;
			@media (min-width: $screen-sm) {
				top: calc(100% - 25px);
			}
			&:before {
				@include awesome;
				font-size: 36px;
			}
		}
		.slick-next {
			right: 0;
			@media (min-width: $screen-sm) {
				right: calc(50% - 50px);
			}
			&:before {
				content: "\f105";
			}
		}
		.slick-prev {
			right: 56px;
			left: auto;
			@media (min-width: $screen-sm) {
				right: 50%;
			}
			&:before {
				content: "\f104";
			}
		}
		&.bg-crest-red {
			&:before {
				// background-image: url('../../images/triangle-crest-red.png');
				border-top-color: #cd212c;
			}
			.slick-prev,.slick-next {
				&:before {
					color: $crest-red;
				}
			}
		}
		&.bg-crest-blue {
			&:before {
				// background-image: url('../../images/triangle-crest-blue.png');
				border-top-color: #003c69;
			}
			.slick-prev,.slick-next {
				&:before {
					color: $crest-blue;
				}
			}
		}
		&.bg-crest-yellow {
			&:before {
				// background-image: url('../../images/triangle-crest-yellow.png');
				border-top-color: #ffb500;
			}
			.slick-prev,.slick-next {
				&:before {
					color: $crest-blue;
				}
			}
		}
	}
	.digSignSlideShow {
		.slick-slider{ height: 100%;} 
		.wrap {
			position: relative;
			min-height: 905px;
			overflow: hidden;
			.bg-img {
				position: absolute;
				@include bg-top-left;
				height: 100%;
				width: 100%;
				background-size: contain;
				top: 0;
				left: 50%;
				border-top: 0;
			}
			.dig-signage-img {
				width: 100%;
				left: 0;
				border-left: none;
			}
		
		}
	}
	@media (max-width: $screen-sm-min - 1) {
		.slick-track {
			display: flex;
			.slick-slide {
			  display: flex;
			  height: auto;
			}
		}
	}
}

@media (max-width: $screen-sm-min - 1) {
	.module-7 {
		.slideShowFact {
			.wrap {
				//height: auto;
			}
		}
	}
}

.module-7 {
	.linktype {
		&.linktype--external .fa-long-arrow-right:before {
			content: "\f08e";
		}

		&.linktype--modal .fa-long-arrow-right:before {
			content: "\f2d0";
		}

		&.linktype--video .fa-long-arrow-right:before {
			content: "\f01d";
		}

		&.linktype--document .fa-long-arrow-right:before {
			content: "\f016";
		}
	}
}


///////////////////////////////////////////////////////////
// within the content
///////////////////////////////////////////////////////////

// @media (min-width: 1200px) {
// 	.content-wrap__wrapper {
// 		.module-7 {
// 			.slideShowFact {
// 				.wrap {
// 					position: relative;
// 					height: 650px;
// 					overflow: hidden;
// 					@media (min-width: 1600px) {
// 						height: 700px;
// 					}
// 					@media (min-width: 1800px) {
// 						height: 550px;
// 					}
// 					.bg-img {
// 						position: absolute;
// 						left: 0;
// 						top: 50%;
// 						@include bg-cover-center;
// 						height: 70%;
// 						width: 100%;
// 						border-top: 6px solid $crest-white;
// 						border-left: 0;
// 					}
// 					.content {
// 						position: absolute;
// 						top: 0;
// 						left: 0;
// 						height: 50%;
// 						width: 100%;
// 						&:after {
// 							display: none;
// 							@media (min-width: 1600px) {
// 								display: block;
// 							}
// 						}
// 						> div {
// 							@include centered-v;
// 							padding: 0 30px !important;
// 						}
// 					}
// 					@media (min-width: 1700px) {
// 						.bg-img {
// 							height: 100%;
// 							width: 50%;
// 							top: 0;
// 							left: 50%;
// 							border-top: 0;
// 							border-left: 6px solid $crest-white;
// 						}
// 						.content {
// 							height: 100%;
// 							width: 50%;
// 							left: 0;
// 							// &:after {
// 							// 	content: '';
// 							// 	position: absolute;
// 							// 	top: 50%;
// 							// 	right: -60px;
// 							// 	transform: rotate(-90deg);
// 							// 	background-size: 100%;
// 							// 	background-repeat: no-repeat;
// 							// 	width: 80px;
// 							// 	height: 40px;
// 							// 	z-index: 10;
// 							// }
// 						}
// 					}
// 				}
// 				&:before {
// 					display: block;
// 					content: '';
// 					position: absolute;
// 					top: 50%;
// 					left: 50%;
// 					transform: translateX(-50%);
// 					background-size: 100%;
// 					background-repeat: no-repeat;
// 					width: 80px;
// 					height: 40px;
// 					z-index: 10;
// 					@media (min-width: 1600px) {
// 						display: none;
// 					}
// 				}
// 				.slick-prev,.slick-next {
// 					position: absolute;
// 					top: calc(50% - 25px);
// 					height: 50px;
// 					width: 50px;
// 					background-color: $crest-white;
// 					z-index: 10;
// 					@media (min-width: 1600px) {
// 						top: calc(100% - 25px);
// 					}
// 					&:before {
// 						@include awesome;
// 						font-size: 36px;
// 					}
// 				}
// 				.slick-next {
// 					right: 0;
// 					@media (min-width: 1600px) {
// 						right: calc(50% - 50px);
// 					}
// 					&:before {
// 						content: "\f105";
// 					}
// 				}
// 				.slick-prev {
// 					right: 56px;
// 					left: auto;
// 					@media (min-width: 1600px) {
// 						right: 50%;
// 					}
// 					&:before {
// 						content: "\f104";
// 					}
// 				}
// 			}
// 		}
// 	}
// }


@media (min-width: $screen-md) and (max-width: 1700px) {

	.col-lg-9.content-wrap__wrapper .module-7 .slideShowFact .wrap .content>div p:not(.spotlight) {
		//display: none;
	}

	.col-lg-9.content-wrap__wrapper .module-7 .slideShowFact .wrap .content>div {
		padding-left: 30px;
		padding-right: 30px;
	}

	// .col-lg-9.content-wrap__wrapper .module-7 .slideShowFact .wrap .content:after {
	// 	display: block;
	// 	content: '';
	// 	position: absolute;
	// 	top: 100%;
	// 	right: 50%;
	// 	margin-right: -40px;
	// 	-webkit-transform: rotate(0deg);
	// 	-ms-transform: rotate(0deg);
	// 	-o-transform: rotate(0deg);
	// 	transform: rotate(0deg);
	// 	-webkit-background-size: 100% 100%;
	// 	background-size: 100%;
	// 	background-repeat: no-repeat;
	// 	width: 80px;
	// 	height: 40px;
	// 	z-index: 10;
	// }

}
