///////////////////////////////////////////////////////////
// variables
///////////////////////////////////////////////////////////

$side-img-xs:	100px;
$side-img-md:	150px;
$side-img-lg:	200px;

///////////////////////////////////////////////////////////
// styling
///////////////////////////////////////////////////////////

* + .side-items {
	margin-top: 30px;
}

.module-wrap.left-nav .side-items,
.module-wrap.rhs .side-items {
	background: transparent;
	@media all and (min-width: $screen-md-min) {
	  padding-top: 120px;
	}
}


/*
@media (min-width: 320px) and (max-width: 1200px - 1) {
		.side-items {
			margin-top: 15px;
			a {
				text-decoration: none;
				transition: 0.3s all ease;
				text-decoration: none;
				&:hover figure {
					opacity: 0.7;
				}
			}
			figure {
				margin-bottom: 15px;
				position: relative;
				height: $side-img-xs;
				.side-item__wrap {
					position: absolute;
					overflow: hidden;
					right: 0;
					&.bg-img {
						width: $side-img-xs;
						height: $side-img-xs;
						> div {
							@include bg-cover-center;
							@include img-cover;
						}
					}
				}
				@media (min-width: 500px) {
					height: $side-img-md;
					.side-item__wrap {
						&.bg-img {
							width: $side-img-md;
							height: $side-img-md;
						}
					}
				}
				@media (min-width: $screen-sm) {
					height: $side-img-lg;
					.side-item__wrap {
						&.bg-img {
							width: $side-img-lg;
							height: $side-img-lg;
						}
					}
				}
				&.video .side-item__wrap {
					&:before {
						content: "\f144";
						@include awesome;
						@include centered-h-v;
						color: $crest-white;
						font-size: 60px;
						z-index: 1;
						color: rgba(205, 32, 44, 0.8);
					}
				}
				figcaption {
					@include absolute-cover;
					right: $side-img-xs;
					padding: 15px;
					h2 {
						@include centered-v;
						font-size: $font-size-h5;
						padding-right: 10px;
						@media (max-width: 500px) {
							font-size: 16px;
						}
						color: $crest-white;
						margin: 0;
					}
					@media (min-width: 500px) {
						right: $side-img-md;
					}
					@media (min-width: $screen-sm) {
						padding: 30px;
						right: $side-img-lg;
					}
				}
			}
		}

}
*/



.side-items {
	a {
		display: block;
		//margin: 10px 0;
	}
	figure {
		//margin-bottom: 15px;
		.side-item__wrap {
			position: relative;
			height: 0;
			overflow: hidden;
			&.bg-img {
				//padding-bottom: 100%;
				padding-bottom: 67%;
				> div {
					@include bg-cover-center;
					@include img-cover;
				}
			}
		}
		&.video .side-item__wrap {
			&:before {
				content: "\f144";
				@include awesome;
				@include centered-h-v;
				color: $crest-white;
				font-size: 100px;
				z-index: 1;
				color: rgba(205, 32, 44, 0.8);
			}
			/*
			&:after {
				content: "\f16a";
				@include awesome;
				@include centered-h-v;
				color: $brand-youtube;
				font-size: $font-size-h1;
				z-index: 2;
			}
			*/
		}
		figcaption {
			padding: 15px;
			h2 {
				font-size: $font-size-h5;
				color: $crest-white;
				margin: 0;
				@media (min-width: 1600px) {
					font-size: $font-size-h4;
				}
			}
		}
	}
}



.side-items figure.bg-limestone figcaption h2 {
	color: $crest-black;
}




.side-items > *:not(.a) {
	margin-bottom: 10px;
}

/*Twitter widget*/

.twitter-widget iframe {
	border: 1px solid $tint-crest-white-darker !important;
	width: 100% !important;
}

/*related links*/

.side-items .bg-grey {
	background: $tint-crest-white;
	padding: 15px 30px 30px;
}

.related-links ul {
	margin: 0 0 30px;
	li {
		margin: 20px 0;
		a:hover {

			h3 {
				text-decoration: underline;
			}
			.date:after {
				padding-left: 20px;
			}
		}
	}
}

.content-wrap__wrapper .related-links .date {
	font-family: 'Playfair Display', serif;
	color: $crest-blue;
	font-weight: 700;
	font-size: 18px;
	&:after {
		content:"\f178";
		font-family: "FontAwesome";
		font-size: 14px;
		padding-left: 10px;
		text-decoration: none;
		transition: .2s ease;
	}
}

.content-wrap__wrapper .related-links h3 {
	font-family: 'Roboto', sans-serif;
	color: $crest-black;
	font-weight: 400;
	margin: 5px 0 0;
	font-size: 18px;
	line-height: 1.5;
}

.sidebar-contact {
	p > .fa {
		display: block;
		float: left;
		color: $sky-blue;
		padding-top: 6px;
	}
	span {
		display: block;
		padding-left: 28px;
	}
}

@media (min-width: $screen-sm) and (max-width: $screen-md) {

	.side-items {
		overflow: hidden;
		display: flex;
		align-items: stretch;
		justify-content: left;
		flex-flow: row wrap;
		align-content: flex-end;
		margin-left: -15px;
		margin-right: -15px;
		a {
			width: 33.33333%;
			float: left;
			display: flex;
			align-items: stretch;
			padding: 0 15px;
		}
		figure {
			width: 100%;
		}
	}
}


@media (min-width: $screen-lg) {
	.content-wrap__wrapper,
	.side-items {
		//padding-top: 10px;
		a {
			text-decoration: none;
			&:hover figure {
				opacity: 0.7;
			}
		}
	}

	.side-items .bg-grey {
		margin-left: -50px;
		margin-right: -100px;
		padding-left: 50px;
		padding-bottom: 50px;
		padding-right: 55px;
	}
}



@media (min-width: 1200px) and (max-width: 1400px) {

	.side-items {
		figure figcaption h2 {
			font-size: 16px;
		}
	}


}



/*QUICK LINKS
************************************************************/

.quick-links:first-child {
	margin-top: -10px;
}

.quick-links .btn {
	max-width: 100%;
	text-align: left;
	white-space: normal;
	margin-bottom: 15px;

}
