.module-9b {
	margin: 0 -5px;
	@include spacer-p-t;
	.multiple-wrap {
		position: relative;
		margin: 0 -5px;
		text-align: center;
		.functionalBox{
			padding: 0px;
			border:3px solid white;
			border-top-width: 5px;
			border-bottom-width: 5px;
			float: none;
    		display: inline-block;
		}
		a {
			color: $crest-white;
			&.linktype--internal .overlay:after {
				content: "\f178";
			}
			&.linktype--external .overlay:after {
				content: "\f08e";
			}
			&.linktype--modal .overlay:after {
				content: "\f2d0";
			}
			&.linktype--video .overlay:after {
				content: "\f01d";
			}
			&.linktype--document .overlay:after {
				content: "\f016";
			}
      &[data-video] {
        &::after {
          content: '';
          background-image: url('/en/media/2017siteassets/images/play-icon.svg');
          position: absolute;
          width: 97px;
          height: 97px;
          top: calc(50% - 34px);
          left: 50%;
          transform: translate3d(-50%,-50%,0);
        }
      }
			&:hover {
				text-decoration: none;
			}
			.image-wrap {
				position: relative;
				height: 0;
				overflow: hidden;
				padding-bottom: 100%;
				.inner__img {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 50%;
					left: 0;
					@include bg-cover-center;
					height: 100%;
				}
			}
			.overlay {
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				padding: 20px;
				background-color: $crest-blue;
				text-align: left;
				opacity: 0.9;
				transition: .3s ease;
				&:after {
					content: "\f105";
					font-family: 'FontAwesome';
					color: $crest-white;
					font-weight: normal;
					font-weight: normal;
					padding-left: 10px;
					font-size: 100%;
					position: absolute;
					right: 20px;
					top: 26px;

				}
				h2 {
					font-size: 24px;
					margin: 0;
					padding-right: 15px;
					line-height: 1.2;
					font-family: $font-family-serif;
					font-weight: 400;
				}
				p {
					margin: 10px 0 0;
					line-height: 1.5;
				}
				&.bg-crest-white {
					background-color: $crest-white;
					*,
					&:after {
						color: $crest-black;
					}
				}
				&.bg-tint-crest-white {
					background-color: $tint-crest-white;
					*,
					&:after {
						color: $crest-black;
					}
				}
				&.bg-crest-black {
					background-color: $crest-black;
				}
				&.bg-crest-red {
					background-color: $crest-red;
				}
				&.bg-crest-blue,
				&.bg-brand-facebook {
					background-color: $crest-blue;
				}
				&.bg-quad-green {
					background-color: $quad-green;
				}
				&.bg-crest-yellow {
					background-color: $crest-yellow;
					*,
					&:after {
						color: $crest-black;
					}
				}
				&.bg-earth-red {
					background-color: $earth-red;
				}
				&.bg-ogham-stone {
					background-color: $ogham-stone;
				}
				&.bg-dawn-mauve {
					background-color: $dawn-mauve;
				}
				&.bg-limestone {
					background-color: $limestone;
					*,
					&:after {
						color: $crest-black;
					}
				}
				&.bg-sky-blue,
				&.bg-brand-twitter {
					background-color: $sky-blue;
					*,
					&:after {
						color: $crest-white;
					}
				}
				&:hover {
					opacity: 1;
					padding: 20px 20px 30px;
				}
			}
		}
	}

}


.module-9b {
	.hoverpanel--hidden {
		display: none;
		&.hoverpanel {
			display: block;
		}
	}
	.hoverpanel {
		position: absolute;
		z-index: 20;
		left: 0;
		right: 0;
		top: -5000px;
		opacity: 0;
		background: $crest-red;
		transition: .5s ease opacity;
		.close {
			font-size: 14px;
			color: #fff;
			opacity: 1;
			width: 30px;
			height: 30px;
			background: rgba($crest-black,0.2);
		}
		> a {
			margin-top: 30px;
			border-bottom: 1px solid rgba(255,255,255,0.2);
		}
		ul,li {
			margin: 0;
			padding: 0;
		}
		li:before {
			content: '';
		}
		a {
			display: block;
			position: relative;
			padding: 6px 15px;
			border-bottom: 1px solid rgba(255,255,255,0.2);
			&:after {
				content:"\f105";
				font-family: FontAwesome;
				position: absolute;
				top: 6px;
				right: 15px;
			}
		}
	}
	.functionalBox.open .hoverpanel {
		top: 0;
		opacity: 1;
	}
	> a {
		font-size: 18px;
	}
}



.content-wrap__inner {
	.module-9b {
		.multiple-wrap {
			a {
				.overlay {
					&:after {
						top: 23px;
					}
					h2 {
						font-size: 20px;
						font-family: $font-family-sans-serif;
					}
				}
			}
		}
	}
}
/*
.module-9b .functionalBox:hover .hoverpanel {
	top: 0;
	opacity: 1;
}

.module-9b .hoverpanel ul,
.module-9b .hoverpanel li {
	margin: 0;
	padding: 0;
}

.module-9b .hoverpanel li:before {
	content: "";
}

.module-9b .hoverpanel a {
	display: block;
	position: relative;
	padding: 6px 15px;
	border-bottom: 1px solid rgba(255,255,255,0.2);
}

.module-9b .hoverpanel a:after {
	content:"\f105";
	font-family: FontAwesome;
	position: absolute;
	top: 6px;
	right: 15px;
}

.module-9b .hoverpanel > a {
	font-size: 18px;
}
*/

@media (max-width: 767px) {

	.module-9b .multiple-wrap .functionalBox {
		width: 100%;
	}

}

@media (min-width: 768px) {

	.module-9b .multiple-wrap .functionalBox {
		width: 49.6%;
	}

}

@media (min-width: 992px) {

	.module-9b .multiple-wrap .functionalBox {
		width: 33%;
	}

}

@media (min-width: 1200px) {

	.module-9b .multiple-wrap .functionalBox {
		width: 24.7%;
	}

}



@media (min-width: 300px) {

	.content-wrap__wrapper .module-9b .multiple-wrap .functionalBox {
		width: 100%;
	}

}

@media (min-width: 768px) {

	.content-wrap__wrapper .module-9b .multiple-wrap .functionalBox {
		width: 49.5%;
	}

}

@media (min-width: 992px) {

	.content-wrap__wrapper .module-9b .multiple-wrap .functionalBox {
		width: 49.5%;
	}

}

@media (min-width: 1300px) {

	.content-wrap__wrapper .module-9b .multiple-wrap .functionalBox {
		width: 32.7%;
	}

}
