.module-27 {
	position: relative;
	border-bottom: 10px solid #fff;
	.module-27 {
		border-bottom: 0;
	}
	div {
		.slideShowSingle {
			figure {
				> div {
					@include bg-cover-center;
					height: 450px;
					&:after {
						@include gradient-black-up;
						height: 100%;
					}
				}
			}
			@media (min-width: $screen-sm - 1) {
				display: none;
			}
		}


		.slideShowSingle {
			display: block;
		}

		figcaption {
			position: absolute;
			z-index: 10;
			right: 0;
			bottom: 40px;
			left:0;
			width: 90%;
			padding: 0 15px;
			color: $crest-white;
			@media (max-width: 400px) {
				padding: 0 0 0 40px;
			}
			.btn {
				white-space: normal;
			}
			&.center {
				right: 0;
				bottom: 15px;
				left: 0;
				width: 100%;
				margin-left: auto;
				margin-right: auto;
				text-align: center;
				@media (min-width: $screen-sm) {
					bottom: 50px;
				}
				@media (min-width: 1400px) {
				.main-heading {
						font-size: $font-size-lg;
					}
				}
			}
			@media (min-width: 500px) {
				width: 70%;
				max-width: 1150px;
				left: 8%;
			}
			@media (min-width: 992px) {
				bottom: 40px;
			}
			@media (min-width: 1400px) {
				bottom: 100px;
			}
			.main-heading {
				@include serif-r;
				margin: 0 0 20px;
				font-size: 22px;
				color: white;
			}
			.sub-heading {
				margin-bottom: 20px;
			}
			.spotlight {
				@include serif-r;
			}
		}
	}
}


body {
	&.home .module-27 {
		.slideShowSingle figure > div {
			height: 450px;
			@include bg-cover-center;
			@media (min-width: 1400px) {
				height: 650px;
			}
		}
		@media (min-width: $screen-sm) {
			.main-heading {
				font-size: $font-size-md;
			}
			.sub-heading,.btn {
				font-size: $font-size-h5;
			}
		}
		@media (min-width: $screen-md) {
			.main-heading {
				font-size: $font-size-h1;
				margin-bottom: 20px;
			}
			.sub-heading {
				margin-bottom: 30px;
			}
		}
		@media (min-width: $screen-lg) {
			//bottom: 100px;
			.main-heading {
				font-size: $font-size-lg;
				margin-bottom: 30px;
			}
			.btn {
				font-size: $font-size-sm;
			}
			.sub-heading {
				margin-bottom: 40px;
				font-size: 20px;
			}
		}
		@media (min-width: 1400px) {
			.main-heading {
				font-size: $font-size-xlg;
			}
		}
	}
	&.inner .module-27 {
		.slideShowSingle figure > div {
			height: 450px;
			@include bg-cover-center;
			@media (min-width: 1400px) {
				height: 450px;
			}
		}
		figcaption {
			.btn-block {
				.btn {
					margin-bottom: 5px;
					width: 100%;
				}
			}
		}
		@media (min-width: $screen-sm) {
			.main-heading {
				font-size: 32px;
			}
			.sub-heading,.btn {
				font-size: 18px;
			}
		}
		@media (min-width: $screen-md) {
			.main-heading {
				font-size: 40px;
				margin-bottom: 20px;
			}
			.sub-heading {
				margin-bottom: 30px;
			}
			.btn {
				padding-left: 50px;
				padding-right: 50px;
			}
		}
		@media (min-width: 1600px) {
			.main-heading {
				font-size: 50px;
				margin-bottom: 30px;
			}
			.sub-heading,.btn {
				font-size: $font-size-sm;
			}
			.sub-heading {
				margin-bottom: 40px;
			}
			.btn {
				padding: 22px 80px;
			}
		}
	}
}


/*Q4 UPDATES - REDUCED HEIGHT
*************************************************************/

body.home {
	.module-27 {
		&.reduced-height {
			.slideShowSingle {
				figure {
					> div {
						height: 65vh;
					}
				}
			}
		}
		.main-heading {
			//font-size: 60px;
		}
	}
}



/*Q4 UPDATES - COLOUR BLOCK TEXT
*************************************************************/

body.home,
.content-wrap__inner {
	.module-27 {
		.slide--countdown-feature {
			figcaption .main-heading {
				max-width: 100% !important;
			}
		}
		figcaption {
			background: transparent;
			bottom: 0;
			top: 0;
			display: flex;
			align-items: center;
			width: auto;
			max-width: 100%;
			right: 0;
			.outer {
				width: 92%;
			}
			@media (max-width: $screen-md-min) {
				padding-top: 50px;
			}
		}
		.slideShowSingle {
			figure {
				> div {
					&:after {
						display: none;
					}
				}
			}
		}
		.main-heading,
		.sub-heading {
			margin: 3px 0 !important;
			line-height: 1.3;
			padding: 15px 30px;
			opacity: 0.90;
		}
		.main-heading {
			max-width: 45%;
			font-size: 3vw;
			display: inline-block;
			padding-top: 10px;
			@media (max-width: 1200px) {
				max-width: 45%;
				//font-size: 35px;
			}
			@media (max-width: $screen-sm-min) {
				max-width: 90%;
				padding-left: 20px;
				padding-right: 20px;
				font-size: 34px;
			}
			@media (max-width: 479px) {
				max-width: 100%;
				font-size: 25px;
			}
		}
		.sub-heading {
			max-width: 50%;
			font-size: 18px;
			display: inline-block;
			@media (max-width: $screen-lg-min) {
				max-width: 50%;
			}
			@media (max-width: $screen-sm-min) {
				max-width: 100%;
				padding-left: 20px;
				padding-right: 20px;
			}
		}
		.btn {
			margin: 3px 0;
		}

		.bg-crest-red .main-heading,
		.bg-crest-red .sub-heading, 
		.bg-crest-red .number,
		.slide--countdown-feature .bg-crest-red .btn {
			color: white;
			background-color: $crest-red;
		}

		.bg-crest-blue .main-heading,
		.bg-crest-blue .sub-heading, 
		.bg-crest-blue .number,
		.slide--countdown-feature .bg-crest-blue .btn {
			color: white;
			background-color: $crest-blue;
		}
	

		.bg-crest-yellow .main-heading,
		.bg-crest-yellow .sub-heading, 
		.bg-crest-yellow .number,
		.slide--countdown-feature .bg-crest-yellow .btn {
			color: black;
			background-color: $crest-yellow;
			.fa {
				color: $crest-white;
			}
		}
		.bg-crest-yellow .btn--yellow {
			background-color: white;
			.fa {
				color: $crest-yellow;
			}
		}

		.bg-quad-green .main-heading,
		.bg-quad-green .sub-heading, 
		.bg-quad-green .number,
		.slide--countdown-feature .bg-quad-green .btn {
			color: white;
			background-color: $quad-green;
		}

		.bg-dawn-mauve .main-heading,
		.bg-dawn-mauve .sub-heading, 
		.bg-dawn-mauve .number,
		.slide--countdown-feature .bg-dawn-mauve .btn {
			color: white;
			background-color: $dawn-mauve;
		}
	

		.bg-crest-white .main-heading,
		.bg-crest-white .sub-heading, 
		.bg-crest-white .number,
		.slide--countdown-feature .bg-crest-white .btn {
			color: $crest-blue;
			background-color: white;
		}


		.bg-tint-crest-white .main-heading,
		.bg-tint-crest-white .sub-heading,
		.bg-tint-crest-white .number,
		.slide--countdown-feature .bg-tint-crest-white .btn {
			color: $crest-blue;
			background-color: $tint-crest-white;
		}
	

		.bg-crest-black .main-heading,
		.bg-crest-black .sub-heading,
		.bg-crest-black .number,
		.slide--countdown-feature .bg-crest-black .btn {
			color: white;
			background-color: $crest-black;
		}
	
	
		.bg-earth-red .main-heading,
		.bg-earth-red .sub-heading, 
		.bg-earth-red .number,
		.slide--countdown-feature .bg-earth-red .btn {
			color: white;
			background-color: $earth-red;
		}
		.bg-earth-red .btn--yellow {
			background-color: white;
			.fa {
				color: $crest-yellow;
			}
		}

	
		.bg-ogham-stone .main-heading,
		.bg-ogham-stone .sub-heading,
		.bg-ogham-stone .number,
		.slide--countdown-feature .bg-ogham-stone .btn {
			color: white;
			background-color: $ogham-stone;
		}
		.bg-ogham-stone .btn--yellow {
			background-color: white;
			.fa {
				color: $crest-yellow;
			}
		}


		.bg-limestone .main-heading,
		.bg-limestone .sub-heading,
		.bg-limestone .number,
		.slide--countdown-feature .bg-limestone .btn {
			color: $crest-black;
			background-color: $limestone;
		}
	
	
		.bg-sky-blue .main-heading,
		.bg-sky-blue .sub-heading,
		.bg-sky-blue .number,
		.slide--countdown-feature .bg-sky-blue .btn {
			color: $crest-black;
			background-color: $sky-blue;
		}
	
	//these default to crest-blue
	
		.bg-brand-twitter .main-heading,
		.bg-brand-twitter .sub-heading,
		.bg-brand-twitter .number,
		.slide--countdown-feature .bg-brand-twitter .btn {
			color: white;
			background-color: $crest-blue;
		}
	
	
		.bg-brand-facebook .main-heading,
		.bg-brand-facebook .sub-heading,
		.bg-brand-facebook .number,
		.slide--countdown-feature .bg-brand-facebook .btn {
			color: white;
			background-color: $crest-blue;
		}

	}
}

.scroll-to-content {
	position: absolute;
	z-index: 100;;
	left: 50%;
	display: inline-block;
	width: 60px;
	height: 60px;
	transform: translateX(-50%) translateY(-50%);
	margin-top: 5px;
	border-radius: 50%;
	text-align: center;
	background: $crest-white;
	color: $crest-blue;
	border: 3px solid $crest-blue;
	transition: .5s ease;
	&:hover,
	&:active {
		background: $crest-blue;
		color: $crest-white;
		border-color: $crest-blue;
	}
	.fa {
		display: block;
		font-size: 36px;
		line-height: 1;
		transform: translateY(34%);
	}
}



/*COUNTDOW TIMER
**********************************************/

.countdown {
	display: block !important;
	height: auto !important;
	&.countdown-mini {
		position: absolute;
		z-index: 10;
		top: 15px;
		right: 15px;
		background: rgba($crest-white,0.9);
		padding: 10px 8px 7px;
		min-width: 240px;
		@media (min-width: $screen-md-min) {
			min-width: 300px;
		}
		.countdown__timer {
			@include clearfix;
		}
		.countdown__number {
			width: 25%;
			float: left;
			padding: 0 2px;
		}
		.number {
			display: block;
			width: 100%;
			text-align: center;
			font-weight: 700;
			font-size: 24px;
			line-height: 1;
			padding: 10px 0 9px;
			@media (min-width: $screen-md-min) {
				font-size: 32px;
				padding: 14px 0 13px;
			}
		}
		.label {
			display: block;
			width: 100%;
			text-align: left;
			text-transform: uppercase;
			font-weight: 400;
			font-size: 10px;
			color: $crest-black;
			margin: 7px 10px 0 0;
			padding: 0;
			@media (min-width: $screen-md-min) {
				font-size: 12px;
			}
		}
		.date {
			display: none;
			@media (min-width: $screen-md-min) {
				display: block;
				font-size: 14px;
				margin: 5px 0 2px;
				padding: 0;
				text-align: center;
				color: $crest-black;
			}
		}
	}
}

.module-27 {
	.slide--countdown-feature {
		figcaption {
			padding: 0 40px !important;
			left: 0;
			.outer {
				width: 100% !important;
			}.inner {
				background-color: rgba($crest-white,0.9);
				text-align: center;
				padding: 15px;
				@media (min-width: $screen-sm-min) {
					padding: 25px 30px 30px;
					max-width: 700px;
					margin: 0 auto;
				}
				@media (min-width: $screen-md-min) {
					padding: 25px 30px 30px;
					max-width: 850px;
					margin: 0 auto;
				}
				.main-heading {
					color: $crest-black !important;
					background: transparent !important;
					display: block;
					width: auto;
					max-width: 100%;
					margin: 0;
					padding: 0;
					font-size: 30px;
					@media (min-width: $screen-md-min) {
						font-size: 44px;
					}
				}
				.sub-heading {
					color: $crest-black !important;
					background: transparent !important;
					display: block;
					width: auto;
					max-width: 100%;
					margin: 5px 0 15px;
					padding: 0;
					font-size: 14px;
					@media (min-width: $screen-md-min) {
						font-size: 16px;
						margin: 10px 0 20px;
					}
				}
				.btn {
					margin: 5px 0;
					min-height: 46px;
					@media (min-width: $screen-sm-min) {
						font-size: 18px;
						padding: 14px 24px;
					}
				}
				br {
					display: none;
				}
			}
		}
		.countdown {
			&.countdown-feature {
				.countdown__timer {
					@include clearfix;
					width: 100%;
					max-width: 300px;
					margin: 10px auto;
					@media (min-width: $screen-sm-min) {
						max-width: 400px;
					}
					@media (min-width: $screen-md-min) {
						max-width: 580px;
					}
				}
				.countdown__number {
					width: 25%;
					float: left;
					padding: 0 2px;
					@media (min-width: $screen-sm-min) {
						padding: 0 5px;
					}
				}
				.number {
					display: block;
					width: 100%;
					text-align: center;
					font-weight: 700;
					font-size: 26px;
					line-height: 1;
					padding: 14px 0;
					@media (min-width: 360px) {
						font-size: 30px;
						padding: 18px 0;
					}
					@media (min-width: $screen-sm-min) {
						font-size: 38px;
						padding: 30px 0;
					}
					@media (min-width: $screen-md-min) {
						font-size: 70px;
					}
				}
				.label {
					display: block;
					width: 100%;
					text-align: left;
					text-transform: uppercase;
					font-weight: 400;
					font-size: 10px;
					color: $crest-black;
					margin: 7px 10px 0 0;
					padding: 0;
					@media (min-width: $screen-sm-min) {
						font-size: 14px;
					}
				}
				.date {
					display: block;
					font-size: 14px;
					margin: 15px 0;
					padding: 0;
					text-align: center;
					color: $crest-black;
				}
			}
		}
	}
}

//hacks to get banner to display nicely on inner page content
.content-wrap__inner {
	.module-27 {
		padding: 0 !important;
		.scroll-to-content {
			display: none;
		}
		div {
			.slideShowSingle {
				figure>div:after {
					display: none;
				}
			}
		}
	}
}

body .content-wrap__inner {

	.module-27 .slideShowSingle .main-heading {
		max-width: 75%;
		font-size: 28px;
		@media (max-width: $screen-sm-min) {
			font-size: 24px;
		}
	}

	.module-27 .slideShowSingle .sub-heading {
		max-width: 100%;
		font-size: 16px;
	}

	.module-27 .slick-dots li {
		margin: 5px !important;
	}

	.module-27 .slideShowSingle figure>div {
		@media (max-width: $screen-sm-min) {
			height: 500px;
		}
	}

}

