.module-12 {
  position: relative;
  z-index: 1001;

  margin-bottom: 10px;

  padding-top: 45px;
  padding-right: 15px;
  padding-bottom: 70px !important;
  padding-left: 15px;

  border-top: 10px solid $crest-white;

  &.logos {
    //margin-right: -65px;
    margin-bottom: 0;
    //margin-left: -65px;

    background: $tint-crest-white;

    &.module-12--featured {
      background: #fff;
      // padding-bottom: 30px !important;
    }

    @media all and(min-width: $screen-lg-min) {
      //margin-right: -210px;
      //margin-left: -210px;
    }

    .slideShowLogos {
      .multiple-wrap {
        a {
          display: block;
        }

      }

      .slick-arrow {
        //display: none!important;
      }
    }
  }


  .slideShowLogos {
    .slick-track {
      display: flex;
      justify-content: center;

      min-width: 100%;
    }

    .multiple-wrap {
      position: relative;

      margin: 0 5px;

      a {
        color: $crest-white;

        &:hover {
          text-decoration: none;
        }
      }

      .image-wrap {
        position: relative;

        overflow: hidden;

        height: 0;
        padding-bottom: 50%;

        .inner__img {
          position: absolute;
          top: 0;
          right: 15px;
          bottom: 0;
          left: 15px;

          @include bg-contain-center;

          @media(min-width: $screen-md) {
            right: 30px;
            left: 30px;
          }

          @media(min-width: $screen-lg) {
            right: 50px;
            left: 50px;
          }

          @media(min-width: 1400px) {
            right: 20%;
            left: 20%;
          }
        }
      }
    }

    .slick-prev,
    .slick-next {
      position: absolute;
      z-index: 100;
      z-index: 10;
      top: auto;
      bottom: -95px;

      width: 50px;
      height: 50px;

      background-color: $crest-white;

      &:before {
        font-size: 36px;

        @include awesome;
      }
    }

    .slick-next {
      right: 0;

      @media(min-width: $screen-sm) {
        right: calc(50% - 50px);
      }

      &:before {
        content: "\f105";

        color: $crest-red;
      }
    }

    .slick-prev {
      right: 56px;
      left: auto;

      @media(min-width: $screen-sm) {
        right: 50%;
      }

      &:before {
        content: "\f104";

        color: $crest-red;
      }
    }
  }
}

.module-12--featured.logos {
  overflow-x: hidden;
  overflow-y: auto;

  margin-right: 0;
  margin-left: 0;

  .slick-list {
    @media(min-width: 1440px) {
      overflow: unset;
    }
  }

  .slideShowLogos {
    @media(min-width: 472px) {
      margin-left: calc(50vw + 10px);
    }

    @media(min-width: 992px) {
      margin-left: 482px;
    }

    .slick-arrow {
      @media(min-width: 472px) {
        display: block !important;
      }
    }

    .slick-prev,
    .slick-next {
      right: unset;
    }

    .slick-next {
      left: 40px;
    }

    .multiple-wrap .image-wrap {
      border: 1px solid #BBBCBC;

      .inner__img {
        // right: 0;
        // left: 0;
        top: 20px;
        bottom: 20px;
      }
    }
  }
}

.logos__featured {
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;

  &:before {
    @media(min-width: 1440px) {
      position: absolute;
      z-index: 10;



      // right: calc(100% - 492px);
      bottom: 0px;

      // width: 1000px;
      height: 400px;

      content: "";

      background-color: white;
      right: 0;
      left: 0;
      margin: auto;
      transform: translateX(calc(-1440px + 482px));
      width: 1440px;
    }
  }

  &>img,
  &>a>img {
    position: relative;
    z-index: 10;

    width: calc(100% - 10px);
    margin-bottom: 10px;
    margin-left: 5px;

    border: 1px solid #BBBCBC;
    min-height: 250px;
    object-fit: contain;

    @media(min-width: 472px) {
      float: left;

      width: 50vw;
    }

    @media(min-width: 992px) {
      width: 472px;
      padding: 20px;
      margin: 0;
      min-height: 290px;
    }
  }
}

.footer {
  .module-12 {
    &.logos {
      h2 {
        color: $crest-black;
      }

      margin-right: -65px;
      margin-left: -65px;

      @media all and(min-width: $screen-lg-min) {
        margin-right: -210px;
        margin-left: -210px;
      }

      .slideShowLogos {
        .slick-arrow {
          display: none !important;
        }
      }
    }
  }
}