/*
FULL UCC COLOUR LIST FOR REFERENCE
$crest-white
$tint-crest-white
$crest-black
$crest-red
$crest-blue
$crest-yellow
$sky-blue
$quad-green
$earth-red
$ogham-stone
$dawn-mauve
$limestone
$brand-twitter
$brand-facebook
$brand-instagram
$brand-youtube
$brand-linkedin
*/

// !: nav colours


.ucc-satellite {
    // --active-arrow: yellow;
    // --active-background: blue;
    // --active-link: red;
    // --arrow-first-level: white;
    // --arrow-go-back: blue;
    // --arrow-second-level: black;
    // --greedy-menu-background: #{$crest-black};
    // --link-base: black;
    // --link-base-arrow-hover: orange;
    // --link-base-background-hover: violet;
    // --link-base-desktop: white;
    // --link-base-hover: magenta;
    // --link-dropdown: black;
    // --link-dropdown-arrow-hover: crimson;
    // --link-dropdown-background-hover: grey;
    // --link-dropdown-desktop: purple;
    // --link-dropdown-hover: orange;
    // --nav-background: #{$crest-black};
    // --onpath-arrow: green;
    // --onpath-background: red;
    // --onpath-link: white;
    --active-arrow: #{$crest-white};
    --active-background: #{$crest-blue};
    --active-link: white;
    --arrow-first-level: #{$crest-yellow};
    --arrow-go-back: #{$crest-red};
    --arrow-second-level: #{$crest-yellow};
    --greedy-menu-background: #{$crest-black};
    --link-base: #{$crest-black};
    --link-base-arrow-hover: #{$crest-yellow};
    --link-base-background-hover: #{$crest-blue};
    --link-base-desktop: white;
    --link-base-hover: white;
    --link-dropdown: #{$crest-black};
    --link-dropdown-arrow-hover: #{$crest-yellow};
    --link-dropdown-background-hover: #{$crest-blue};
    --link-dropdown-desktop: #{$crest-black};
    --link-dropdown-hover: white;
    --nav-background: #{$crest-black};
    --onpath-arrow: #{$crest-yellow};
    --onpath-background: #{$crest-blue};
    --onpath-link: white;

    .ucc-nav {
        @media all and (min-width: 1200px) {
            background-color: var(--nav-background);
        }

        // !: Back button (mobile)
        .ucc-nav__back {
            svg path {
                fill: var(--arrow-go-back);
            }
        }

        // !: 1st level arrow
        .dropdown-toggle {
            svg path {
                stroke: var(--arrow-first-level);
            }
        }

        // !: 2nd level arrow
        .ucc-nav__dropdown__toggle {
            svg path {
                stroke: var(--arrow-second-level);
            }
        }

        // !: base colours
        .ucc-nav__main {
            > li > a {
                color: var(--link-base);

                @media all and (min-width: 1200px) {
                    color: var(--link-base-desktop);
                }

                &:hover {
                    @media all and (min-width: 1200px) {
                        color: var(--link-base-hover);
                        background-color: var(--link-base-background-hover);

                        svg path {
                            stroke: var(--link-base-arrow-hover);
                        }
                    }
                }
            }

            ul > li > a {
                color: var(--link-dropdown);

                @media all and (min-width: 1200px) {
                    color: var(--link-dropdown-desktop);
                }

                &:hover {
                    @media all and (min-width: 1200px) {
                        color: var(--link-dropdown-hover);
                        background-color: var(--link-dropdown-background-hover);

                        svg path {
                            stroke: var(--link-dropdown-arrow-hover);
                        }
                    }
                }
            }
        }

        // !: onpath colours
        .onpath > a {
            color: var(--onpath-link) !important;
            background-color: var(--onpath-background) !important;

            svg path {
                stroke: var(--onpath-arrow) !important;
            }
        }

        // !: active colours
        .active > a {
            color: var(--active-link) !important;
            background-color: var(--active-background) !important;

            svg path {
                stroke: var(--active-arrow) !important;
            }
        }

        // !: greedy menu
        &.greedy-menu .hidden-links {
            @media all and (min-width: 1200px) {
                background-color: var(--greedy-menu-background);
            }
        }
    }
}

.ucc-satellite {
    .ucc-navigation-toggle {
        color: white;
        background: #000000;

        svg {
            path {
                fill: $crest-yellow;
            }
        }
    }

    .ucc-nav__main .ucc-nav__dropdown__menu {
        @media all and (min-width: 1200px) {
            min-height: 0;
            max-height: calc(100vh - 200px);
            // overflow:auto;
        }
    }
}

.ucc-satellite .page-wrap {
    @media (min-width: 1200px) {
        margin-top: 200px;
    }
}

.ucc-satellite .content-wrap__inner div[class*="module"]:not(.module-36):not(.module-36-container) {
    padding: 0 0 30px;

    .row > div[class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }
}

.ucc-satellite .footer {
    .module-12 {
        display: none;
    }
}

.ucc-satellite .header {
    @media (min-width: 1200px) {
        height: 200px;
    }
}

.ucc-satellite .btn {
    &.linktype--external {
        i:before {
            content: "\f08e";
        }
    }

    &.linktype--modal {
        i:before {
            content: "\f2d0";
        }
    }

    &.linktype--video {
        i:before {
            content: "\f01d";
        }
    }

    &.linktype--document {
        i:before {
            content: "\f016";
        }
    }
}

.ucc-satellite .content-wrap__inner ul {
    //margin: 0;
    //padding-left: 0;
    li:before {
        //content: none;
    }
}



// MODULE 9

.ucc-satellite .content-wrap__wrapper .module-9b .multiple-wrap .functionalBox {
    @media (min-width: 300px) {
        //width: 100%;
    }

    @media (min-width: 768px) {
        //width: 49.6%;
    }

    @media (min-width: 992px) {
        //width: 33%;
    }

    @media (min-width: 1200px) {
        //width: 24.7%;
    }
}

// MODULE 11

.ucc-satellite.inner .content-wrap__wrapper .module-11 {
    .module-title {
        text-align: center;
    }
}

// MODULE 15
.ucc-satellite {
    .content-wrap__inner {
        .module-15 {
            .articleDetail {
                .image {
                    display: inline-block;

                    width: auto;

                    img {
                        width: auto;
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

// MODULE 21

.ucc-satellite.inner .content-wrap__wrapper .module-21 {
    margin: 30px 0 0 !important;
    padding: 0 !important;

    .social-block {
        padding: 20px 0;
    }
}

// MODULE 29

.ucc-satellite .module-29 .scroll-to-content {
    display: block;
}

.ucc-satellite .module-29 {
    &:not(.module-29--box--active) {
        figcaption {
            @media (min-width: $screen-md-min) {
                .inner {
                    //width: calc(100% - 55px);
                }

                .outer {
                    h1,
                    h2,
                    h3,
                    h4,
                    p {
                        //display: inline-block;
                        //max-width: 100%;
                    }
                }
            }
        }

        .slide--video {
            figcaption {
                @media (min-width: $screen-md-min) {
                    .outer {
                        p {
                            //max-width: calc(50% - 68px);
                        }
                    }
                }
            }
        }
    }

    &.module-29--box--active figcaption {
        .inner {
            //width: 100%;
        }

        .main-heading {
            //max-width: calc(100% - 55px);
        }
    }

    .slide-square {
        img[src=""] {
            //opacity: 0;
        }
    }
}

.home.ucc-satellite .module-29 {
    &:not(.module-29--box--active) {
        figcaption {
            @media (min-width: $screen-md-min) {
                .outer {
                    h1,
                    h2,
                    h3,
                    h4,
                    p {
                        //max-width: 536px;
                    }

                    p {
                        //max-width: 491px;
                    }
                }
            }
        }
    }
}

.ucc-satellite .left-nav {
    .module-6 {
        .form-bespoke .form-group .form-selector .filter-option,
        .form-bespoke .form-group label {
            @media (max-width: 1600px) {
                font-size: 20px;
            }
        }

        .module-title {
            text-align: center;
        }
    }

    .module-30 {
        .row {
            display: block;

            @media (min-width: 1800px) {
                display: flex;
            }
        }

        div.col-md-6 {
            width: 100%;

            @media (min-width: 1800px) {
                width: 50%;
            }
        }
    }

    .module-33 {
        img[src=""] {
            opacity: 0;
        }

        &__item {
            @media (min-width: 768px) {
                width: 50%;
            }

            @media (min-width: 1200px) {
                width: 33.333%;
            }

            @media (min-width: 1600px) {
                width: 25%;
            }
        }
    }
}

.ucc-satellite.scroll-up .social-wrap__inner.affix {
    top: 165px;
}

//HYPER LIST BUTTON
/**********************************************/

//on inner pages

.ucc-satellite.home .module-wrap:not(.course) .hyper-list-wrap {
    background-color: #D6D6D6;

    button {
        @media (max-width: 1199px) {
            background-color: #D6D6D6;
        }
    }
}

.ucc-satellite.inner .module-wrap:not(.course) .hyper-list-wrap {
    background-color: #D6D6D6;

    button {
        @media (max-width: 1199px) {
            background-color: #D6D6D6;
        }
    }
}

// Start Greedy Menu
.greedy-menu {
    .toggle-links {
        display: none;
    }

    @media (min-width: 1200px) {
        display: flex;
        justify-content: center;

        .visible-links {
            > li {
                > a {
                    white-space: nowrap;
                }
            }
        }

        /* The button which hides/shows links */
        .toggle-links {
            font-size: 18px;
            line-height: 1;

            position: relative;
            top: 0;
            right: 0;

            display: flex;
            visibility: hidden;

            width: 60px;
            height: 74px;

            cursor: pointer;

            border: 0;
            border: none;
            background-color: darken(saturate($crest-yellow, 25%), 6%);

            align-self: flex-end;
            flex: 0 0 60px;
            order: 2;

            &:hover {
                background-color: darken(saturate($crest-red, 25%), 8%);

                &::before {
                    transform: scale(1.05);
                }
            }

            &:focus {
                outline: none;
            }

            &.visible {
                visibility: visible;
            }

            /* Firefox specific style reset */
            &::-moz-focus-inner {
                padding: 0;

                border: 0;
            }

            /*  The hamburger icon */
            &::after {
                position: absolute;
                top: 30px;
                left: 12px;

                display: block;

                width: 36px;
                height: 4px;

                content: "";
                transition: transform 0.3s cubic-bezier(0.77, 0, 0.175, 1),
                box-shadow 0.2s ease 0.1s;

                background-color: white;
                box-shadow: 0 8px 0 white,
                0 16px 0 white;
            }

            /* The circle icon */
            &.counter::before {
                font-size: 16px;
                line-height: 1;

                position: absolute;
                z-index: 2;
                top: 23px;
                left: -17px;

                display: block;

                box-sizing: border-box;
                width: 34px;
                padding: 6px 1px;

                content: attr(data-count);
                text-align: center;

                color: white;
                border: 3px solid white;
                border-radius: 50%;
                background-color: darken(saturate($crest-yellow, 20%), 7%);
            }
        }

        ul.hidden-links.hidden {
            display: none;
        }

        ul.hidden-links {
            position: absolute;
            top: 74px;
            right: 0;

            display: flex;

            width: 313px;
            min-height: 93px;

            flex-wrap: wrap;

            &.links-invisible {
                display: none;
            }

            .ucc-nav__dropdown__menu {
                z-index: 1;

                box-shadow: 0 14px 15px -6px rgba(black, 0.4);

                .ucc-nav__dropdown__menu {
                    z-index: 2;
                }
            }

            li {
                display: flex;

                width: 100%;
                padding: 0;

                a,
                a.dropdown-toggle {
                    position: relative;

                    display: flex;
                    justify-content: space-between;

                    width: 100%;
                    padding: 22px 45px 22px 25px;

                    &:after {
                        position: absolute;
                        right: 25px;
                        bottom: 0;
                        left: 25px;

                        display: block !important;

                        height: 1px;

                        content: "" !important;

                        background-color: #E0E0E0;
                    }
                }
            }

            > li.ucc-nav__dropdown.active {
                > a {
                    + .ucc-nav__dropdown__menu {
                        right: 0 !important;
                    }
                }
            }

            .sub-menu-opened {
                .ucc-nav__dropdown__menu {
                    right: 313px;
                    left: auto;
                }
            }
        }
    }
}

// END Greedy Menu

// .ucc-satellite {
//     .table {
//         border: 1px solid #cfcfcf;
//     }
// }
