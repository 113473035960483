// Used by Case Law content type

.module-34 {
  .btn {
    @media all and (min-width: 768px) {
      padding: 15px 20px;
    }
  }
  .articleDetail {

    .date {
      background: $crest-yellow;
      font-weight: 700;
      //font-size: 14px;
      //line-height: 20px;
      text-align: center;
      //text-transform: uppercase;
      //width: 60px;
      //height: 60px;
      position: absolute;
      z-index: 20;
      top: 0;
      left: 0;
      //padding-top: 12px;
      //new
      font-size: 12px;
      line-height: 1;
      padding: 8px 12px;
      @media (min-width: $screen-sm-min) {
        font-size: 14px;
        padding: 12px 15px;
      }
      span {
        display: block;
        font-size: 24px;
      }
    }
    .image {
      width: 100%;
      overflow: hidden;
      position: relative;
      margin: 0px 0 30px;
      img {
        width: 100%;
      }
      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+50,0.7+100 */
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0.7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      }
    }
    figcaption {
      position: absolute;
      z-index: 30;
      bottom: 5px;
      left: 8px;
      color: $crest-white;
      font-size: .7em;
      @media all and (min-width: 500px) {
        font-size: 1em;
      }
    }

  }
  .caseLawListTable {
    ul {
      li {
        &::before { 
          content: none;
        }
      }
    }
  }
  .dataTables_wrapper {
    display: flex;
    flex-direction: column;
    div:first-child {
      order: 2;
    }
    .dataTables_filter {
      order: 1;
      margin-left: 15px;
      label {
        float: right;
        width: 100%;
        max-width: 300px;
        input {
          width: 100%;
        }
      }
    }
    .dataTables_length {
      order: 3;
    }
    .caseLawListTable {
      order: 4;
    }
    .dataTables_info {
      order: 5;
    }
    .dataTables_paginate {
      order: 6;
    }
  }
  .dataTable {
    thead {
      th:last-child:after {
        content: none;
      } 
    }
    td {
      p, li {
        line-height: 1.3333333;
      }
      p {
        margin-bottom: 5px;
      }
    }
  }
}
