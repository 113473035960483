.module-23 {
	position: relative;
	overflow: hidden;
	@include spacer-p-t;
	+ .footer {
		margin-top: 50px;
	}
	.item {
		padding: 5px;
	}
	a {
		display: block;
		position: relative;
		.caption {
			position: absolute;
			z-index: 10;
			bottom: 0;
			left: 0;
			right: 0;
			margin: 0;
			padding: 0;
			color: white;
			font-weight: 400;
			font-size: 22px;
			line-height: 1.5;
			text-shadow: 0 0 10px rgba(0,0,0,1);
			@media (max-width: $screen-lg-min - 1) {
				font-size: 18px;
			}	
			span {
				display: block;
				padding: 30px 70px 30px 30px;
				position: absolute;
				z-index: 10;
				bottom: 0;
				left: 0;
				right: 0;
				transition: 1s ease;
			}
			.short {
				white-space: nowrap; 
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.long {
				opacity: 0;
			}
		}
		.image {
			position: relative;
			z-index: 1;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			width: 100%;
			height: 0;
			padding-bottom: 100%;
			&:before {
				content: "";
				display: block;
				position: absolute;
				display: block;
				z-index: 2;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				transition: .5s ease;
				background: linear-gradient(to bottom, rgba(0,0,0,0) 50%,rgba(0,0,0,0.75) 75%,rgba(0,0,0,1) 100%);
				opacity: 0.75;
			}
			&:after {
				content:"\f00e";
				font-family: FontAwesome;
				position: absolute;
				z-index: 10;
				display: block;
				bottom: 28px;
				right: 30px;
				font-weight: 400;
				font-size: 22px;
				transition: .5s ease;
				color: white;
			}
		}
		&:hover {
			.image {
				&:before {
					opacity: 0.75;
				}
				&:after {
					transform: scale(1.5);
				}
			}
			.caption {
				.short {
					opacity: 0;
				}
				.long {
					opacity: 1;
				}
			}
		}
	}
	.slick-slide {
		//opacity: 0.3;
		&.slick-active {
			opacity: 1;
		}
	}
	.item-wrap {
		> div:nth-child(3n+1) {
			width: 41.66667%;
			float: left;
			.square {
				display: none;
			}
		}
		> div:nth-child(3n+2) {
			width: 25%;
			float: left;
			.square {
				width: 30.3%;
				height: 0;
				padding-bottom: 30.3%;
				background: $crest-yellow;
				position: absolute;
				top: 102.4%;
				right: 0;
			}
		}
		> div:nth-child(3n+3) {
			width: 33.33333%;
			float: left;
			a {
				padding-top: 25.5%;
			}
			.square {
				width: 22%;
				height: 0;
				padding-bottom: 22%;
				background: $crest-red;
				position: absolute;
				top: 0;
				right: 0;
			}
		}
		&:nth-child(3n+2) {
			> div:nth-child(3n+2) {
				.caption {
					background: $crest-red;
				}
				.square {
					background: $crest-yellow;
				}
			}
			> div:nth-child(3n+3) {
				.square {
					background: $crest-blue;
				}
			}
		}
		&:nth-child(3n+3) {
			 > div:nth-child(3n+2) {
				.caption {
					background: $crest-yellow;
					//color: black;
					text-shadow: none;
				}
				.square {
					background: $crest-red;
				}
			}
			> div:nth-child(3n+3) {
				.square {
					background: $crest-blue;
				}
			}
		}		
	}
	.slick-arrow {
		width: 60px;
		height: 100%;
		z-index: 100;
		&:before {
			font-family: FontAwesome;
			color: black;
			font-size: 80px;
			line-height: 1;
			opacity: 1;
			background-color: rgba($crest-white,0.75);
			padding: 0 10px;
		}
		&.slick-prev {
			left: 3%;
			&:before {
				content:"\f104";
			}
		}
		&.slick-next {
			right: 3%;
			&:before {
				content:"\f105";
			}
		}	
	}
	.slick-dots { 
		bottom: -35px;
		margin: 0;
		padding: 0;
		li {
			margin: 0 5px;
			padding: 0;
			&:before {
				display: none;
			}
			button {
				&:before {
					font-size: 10px;
					color: $crest-yellow;
					opacity: 1;
				}
			}
			&.slick-active {
				button {
					&:before {
						color: $crest-red;
					}
				}
			}
		}
	}
	&.wall {
		.item-wrap {
			overflow: hidden;
			margin: 0 -5px;
			&:nth-child(2n+2) {
				> div:nth-child(3n+1) {
					float: right;
				}
			}
		}
	}
}

@media (max-width: $screen-lg-min - 1) {
	.module-23 {
		&.carousel {
			.slick-arrow {
				width: 26px;
				&:before {
					font-size: 50px;
				}
				&.slick-prev {
					left: 2%;
				}
				&.slick-next {
					right: 2%;
				}	
			}
		}
	}
}


@media (max-width: $screen-md-min - 1) {
	.module-23 {
		&.carousel {
			.slick-slide {
				//opacity: 0.3;
				&.slick-active {
					opacity: 1;
				}
			}
			.item-wrap > div.item {
				width: 100%;
			}
			.slick-arrow {
				width: 26px;
				&:before {
					font-size: 50px;
				}
				&.slick-prev {
					left: 1%;
				}
				&.slick-next {
					right: 1%;
				}	
			}
			.slick-dots { 
				li { 
					width: 10px;
				}
			}
		}
	}
}


@media (max-width: $screen-md-min - 1) {
	.module-23 {
		&.wall {
			.item-wrap > div:nth-child(3n+1) {
				width: 100%;
			}
			.item-wrap > div:nth-child(3n+2) {
				width: 42.9%;
			}
			.item-wrap > div:nth-child(3n+3) {
				width: 57.1%;
			}
		}
	}
}



// GRID
// simplified version for video galleries

.module-23 {
	&.grid:not(.force-mobile) {
		.item-wrap > div:nth-child(3n+1),
		.item-wrap > div:nth-child(3n+2),
		.item-wrap > div:nth-child(3n+3) {
			width: 33.33333%;
		}
		.item-wrap {
			.item {
				a {
					padding-top: 0;
				}
			}
		}
		.item-wrap {
			.square {
				display: none;
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.module-23 {
		&.grid.wall {
			.item-wrap > div:nth-child(3n+1),
			.item-wrap > div:nth-child(3n+2) {
				width: 50%;
			}
			.item-wrap > div:nth-child(3n+3) {
				width: 100%;
				.image {
					width: calc(50% - 5px);
					padding-bottom: 50%;
				}
				.caption {
					right: 50%;
				}
				.square {
					display: block;
					right: auto;
					left: calc(50% + 5px);
				}
			}
			.item-wrap:nth-child(2n+2) > div:nth-child(3n+3) {
				.image {
					left: calc(50% + 5px);
				}
				.caption {
					right: 0;
					left: 50%;
				}
				.square {
					left: 0;
				}
			}			
		}
	}
}

@media (max-width: 767px) {
	.module-23 {
		&.grid.wall {
			.item-wrap > div:nth-child(3n+1),
			.item-wrap > div:nth-child(3n+2) {
				width: 100%;
			}			
		}
	}
}

// Video gallery

.module-23 {
	&.video {
		.item-wrap {
			.item {
				float: left !important;
			}
		}
		a {
			&:after {
				/*content:"\f04b";
				font-family: FontAwesome;
				display: block;
				position: absolute;
				z-index: 100;
				top: 50%;
				left: 50%;
				margin: -35px 0 0 -35px;
				color: white;
				font-size: 32px;
				width: 70px;
				height: 70px;
				border-radius: 50%;
				border: 2px solid white;
				background: rgba(0,0,0,0.4);
				text-align: center;
				padding-top: 13px;
				padding-left: 3px;
				transition: .5s ease;*/
			}
			.image {
				&:after {
					content:"\f04b";
					font-family: FontAwesome;
					color: white;
					font-size: 18px;
					width: 48px;
					height: 48px;
					border-radius: 50%;
					border: 2px solid white;
					background: rgba(0,0,0,0.4);
					text-align: center;
					padding-top: 12px;
					padding-left: 3px;
					transition: .5s ease;
					bottom: 28px;
				}
			}
			&:hover {
				.image {
					&:after {
						transform: scale(1.1);
					}
				}
			}
			.caption {
				span {
					padding-right: 85px;
				}
			}
		}
	}
}


.col-lg-9 {
	.content-wrap__inner {
		.module-23 {
			a .caption {
				font-size: 18px;
				span {
					padding-left: 15px;
				}
			}
			&.video a .image:after {
				width: 36px;
				height: 36px;
				font-size: 14px;
				right: 15px;
				padding-top: 7px;
			}
		}
	}
}


//UPDATED CAROUSEL STYLES
//required as we had to remove .item-wrap div to allow for un even numbers of images
.carousel {
	.slick-track {
		//1
		> div:nth-child(3n+1) {
			width: 300px;
			float: left;
			.square {
				display: none;
			}
			
			@media (min-width: 768px) {
				width: 600px;
			}
			@media (min-width: 992px) {
				width: 450px;
			}
		}
		//2
		> div:nth-child(3n+2) {
			width: 300px;
			float: left;
			.square {
				width: 30.3%;
				height: 0;
				padding-bottom: 30.3%;
				background: $crest-yellow;
				position: absolute;
				top: 104%;
				right: 0;
			}
			@media (min-width: 768px) {
				width: 600px;
			}
			@media (min-width: 992px) {
				width: 250px;
			}
		}
		//3
		> div:nth-child(3n+3) {
			width: 300px;
			float: left;
			a {
				padding-top: 25.5%;
			}
			.square {
				width: 22%;
				height: 0;
				padding-bottom: 22%;
				background: $crest-red;
				position: absolute;
				top: 0;
				right: 0;
			}
			@media (min-width: 768px) {
				width: 600px;
			}
			@media (min-width: 992px) {
				width: 361px;
			}
		}


		&:nth-child(3n+2) {
			> div:nth-child(3n+2) {
				.caption {
					background: $crest-red;
				}
				.square {
					background: $crest-yellow;
				}
			}
			> div:nth-child(3n+3) {
				.square {
					background: $crest-blue;
				}
			}
		}
		&:nth-child(3n+3) {
			 > div:nth-child(3n+2) {
				.caption {
					background: $crest-yellow;
					//color: black;
					text-shadow: none;
				}
				.square {
					background: $crest-red;
				}
			}
			> div:nth-child(3n+3) {
				.square {
					background: $crest-blue;
				}
			}
		}		
	}
}


