.module-32 {
  //margin-top: 30px;
  @media(min-width:768px) {
    margin-top: 45px;
  }
  @media(min-width:992px) {
    margin-top: 60px;
  }
  .module-title {
    text-align: left;
  }
  p {
    font-size: 16px;
  }
  label.hideLabel {
    display: none;
  }
  .dataTables_wrapper {
    overflow-x: auto;
    .dataTables_length {
      select {
        background-color: $crest-white;
      }
    }
    .dataTables_filter {
      input {
        background-color: $crest-white;
      }
    }
  }
}