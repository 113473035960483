.module-10 {
	margin: 0 -5px;
	@include spacer-p-t;
	.slideShowMultiple {
		.multiple-wrap {
			position: relative;
			margin: 0 5px;
			a {
				color: $crest-white;
				&:hover {
					text-decoration: none;
				}
				.image-wrap {
					position: relative;
					height: 0;
					overflow: hidden;
					padding-bottom: 90%;
					.inner__img {
						position: absolute;
						top: 0;
						right: 0;
						bottom: 50%;
						left: 0;
						@include bg-cover-center;
						height: 100%;
					}
				}
				.overlay {
					position: relative;;
					height: 0;
					padding: 0 30px 60%;
					h2 {
						@include serif-r;
						margin-bottom: 8px;
						font-size: $font-size-h4;
						@media (min-width: $screen-sm) {
							font-size: $font-size-h4;
						}
						@media (min-width: $screen-md) {
							font-size: $font-size-h4;
						}
						@media (min-width: $screen-lg) {
							font-size: $font-size-h3;
						}
						@media (min-width: 1750px) {
							font-size: $font-size-h2;
						}
					}
					.course-qual,.course-level {
						position: absolute;
						bottom: 30px;
						margin-bottom: 0;
					}
					.course-qual {
						left: 30px;
					}
					.course-level {
						right: 30px;
					}
				}
			}
		}
		.slick-next {
			top: 42%;
			bottom: 29%;
		}
	}
}