.module-sub-text {
	padding-bottom: 30px;
	font-weight: 300;
	text-align: center;
	font-size: 16px;
	max-width: 900px;
	margin-left: auto;
	margin-right: auto;
}

.module-title+.module-sub-text {
	margin-top: -10px;
	margin-bottom: 0;
	+ .filters {
		margin-top: 40px;
	}
}

@media (min-width: $screen-md) {

	.module-sub-text {
		font-size: 22px;
		padding-bottom: 40px;
		margin-bottom: 0;
	}


	// .module-title + .module-sub-text {
	// 	margin-top: -40px;
	// }

}

.content-wrap__inner {
	div[class*="module"]:not(.module-36):not(.module-36-container):not(div[class*="module-3c"]) {
		.module-title {
			font-weight: 700;
			margin: 30px 0 20px !important;
			padding-left: 5px !important;
			font-size: 32px;

			@media (max-width: $screen-md) {
				font-weight: 400;
				font-size: 32px;
			}
		}

		.module-sub-text {
			padding-left: 5px !important;
			font-weight: 400;
			text-align: left;
			padding: 0;
			margin: 0;
		}
	}
}

.content-wrap__inner {

	div.module-12,
	div.module-19 {

		h2,
		.module-title {
			margin: 15px 0 20px !important;
			padding-left: 15px !important;
			font-size: 32px;
		}

		.module-sub-text {
			padding-left: 15px !important;
		}
	}
}

.colorbox {
	display: flex;
	margin-bottom: 10px;

	div {
		height: 10px;

		&:first-of-type {
			width: 70%;
		}

		&:nth-of-type(2) {
			width: 10%;
		}

		&:last-of-type {
			width: 20%;
		}
	}
}

.content-wrap__wrapper,
.module-24 {
	background-color: $crest-white;

	.content-wrap__inner {
		overflow: hidden;
	}

	@media (min-width: $screen-md) {
		padding-left: 120px;
		padding-right: 120px;

		.content-wrap__inner {
			padding-bottom: 30px;
		}
	}

	div[class*="module"]:not(.module-36):not(.module-36-container):not(div[class*="module-3c"]) {
		padding: 30px 0;
	}

	h1.page-title+div[class*="module"]:not(.module-36):not(.module-36-container) {
		padding-top: 0;
	}

	div[class*="module"]:not(.module-36):not(.module-36-container)+div[class*="module"]:not(.module-36):not(.module-36-container) {
		padding-top: 0;
	}


	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: 700;
		margin: 30px 0 15px;

		a {
			text-decoration: none;
		}
	}

	h2 {
		font-size: 28px;
		font-weight: 400;

		@media (min-width: 992px) {
			font-size: 30px;
		}

		@media (min-width: 1200px) {
			font-size: 32px;
		}
	}

	h3 {
		font-size: 24px;
	}

	/*
	h2 {
		font-size: $font-size-h3;
	}

	h3 {
		font-size: $font-size-h5;
	}

	h4 {
		font-size: $font-size-h6;
	}

	h5 {
		font-size: $font-size-h7;
	}

	h6 {
		font-size: $font-size-base;
	}
	*/

	p>a {
		//color: $crest-red;
		//font-style: italic;
		text-decoration: underline;
	}

	blockquote {
		font-style: italic;
		font-size: 18px;
		font-family: 'Playfair Display', serif;
		color: rgba($crest-black, 0.8);
		border: 0;
		position: relative;
		padding-left: 55px;
		overflow: hidden;

		&:before {
			content: "\201C";
			font-family: FontAwesome;
			color: $crest-yellow;
			font-size: 40px;
			font-style: normal;
			position: absolute;
			top: -4px;
			left: 0;
		}

		&:after {
			content: "\201C";
			font-family: FontAwesome;
			color: $crest-yellow;
			font-size: 40px;
			font-style: normal;
			position: absolute;
			right: 0px;
			bottom: 0;
			transform: rotate(180deg);
		}

		p {
			line-height: 1.7;
		}
	}
}



@media (min-width: $screen-md) {
	.content-wrap__wrapper {
		background-color: $crest-white;

		.content-wrap__inner {
			overflow: hidden;
		}

		div[class*="module"]:not(.module-36):not(.module-36-container):not(div[class*="module-3c"]) {
			padding: 30px 0;
		}

		h2,
		h3,
		h4,
		h5,
		h6 {
			font-weight: 700;
			margin: 30px 0 20px;
		}

		h3 {
			font-size: 22px;
			font-weight: 400;
		}

		h4 {
			font-size: 18px;
			font-weight: 400;
			//font-family: $font-family-sans-serif;
		}

		.module-8+h2 {
			margin-top: -30px;
		}

	}
}

@media (min-width: $screen-lg) {
	.content-wrap__wrapper>.row>.col-md-12.col-lg-9 {
		padding-right: 100px;
	}

	.content-wrap__wrapper {
		.content-wrap__inner {
			padding-bottom: 100px;
		}
	}
}

.social-wrap__inner {
	position: absolute;
	width: 120px;
	padding: 56px 25px 0 0;
	margin-left: -120px;
	top: auto;
	//display: none;
	//position: fixed;
	//top: 144px;
	//left: -115px;
	//left: 24.1%;
	//width: 120px;
	transition: .5s ease;
	display: block;
	text-align: right;

	&.affix {
		position: fixed;
		z-index: 900;
		top: 0;
		//padding-top: 84px;
	}

	p,
	div,
	ul {
		text-align: right;
	}

	.date {
		font-size: 14px;
		margin: -7px 0 20px;
	}

	>div {
		margin-bottom: 15px;
		text-align: right;
	}

	p {
		text-align: right;
		margin-bottom: 2px;
		font-size: $font-size-xs;

		&.text-uppercase {
			font-weight: 700;
		}

		strong {
			font-weight: 400;
			color: #777;
		}
	}

	a {
		color: $crest-black;
	}

	.shortlisted i.fa {
		color: $crest-yellow;
		font-size: 28px;
		transition: .3s ease;
	}

	.shortlisted:hover i.fa-star-o:before {
		content: "\f005";
	}

	ul li {
		height: 40px;
		width: 40px;
		margin-bottom: 2px;
		margin-right: inherit;
		float: right;
		clear: right;

		a {
			display: table;
			text-align: center;
			height: 100%;
			width: 100%;

			&:hover {
				opacity: 0.7;
				text-decoration: none;
			}

			i {
				display: table-cell;
				vertical-align: middle;
			}
		}

		&.twitter i {
			background-color: $brand-twitter;
			color: white;
		}

		&.facebook i {
			background-color: $brand-facebook;
			color: white;
		}

		&.linkedin i {
			background-color: $brand-linkedin;
			color: white;
		}

		&.email i {
			background-color: $crest-red;
			color: white;
		}
	}
}

body.scroll-up .social-wrap__inner.affix {
	top: 115px;
}

.course .social-wrap__inner.affix {
	padding-top: 84px;
}



/*
.course .social-wrap__inner {
	position: absolute;
	padding-top: 12px;
	top: 23px;
	left: -115px;
	&.affix {
		position: fixed;
		left: -10px;
		top: 70px;
	}
	> div {
		margin-bottom: 15px;
		text-align: right;
		p {
			margin-bottom: 2px;
		}
		a {
			color: $crest-black;
		}
	}
	p {
		text-align: right;
		&.text-uppercase {
			font-weight: 700;
		}
		strong {
			font-weight: 400;
			color: #777;
		}
	}
	ul li {
		margin-right: inherit;
	}
	.shortlisted i.fa {
		color: $crest-yellow;
		font-size: 28px;
		transition: .3s ease;
	}
	.shortlisted:hover i.fa-star-o:before {
		content:"\f005";
	}


}

.social-wrap__inner {
	padding-top: 12px;
	> div {
		margin-bottom: 15px;
		text-align: right;
		p {
			margin-bottom: 2px;
		}
		a {
			color: $crest-black;
		}
	}
	p {
		text-align: right;
	}
	ul li {
		margin-right: inherit;
	}
	.shortlisted i.fa {
		color: $crest-yellow;
	}

	.not-shortlisted i.fa:before {
		content:"\f006";
		color: $crest-yellow;
	}
	.not-shortlisted i.fa:hover:before {
		content:"\f005";
		color: $limestone;
	}

}

.left-nav .social-wrap__inner {
	&.affix {
		left: 24.1%;
		top: 0;
	}
}
*/
//social column as a sticky footer
body.inner .content-wrap__wrapper .social-wrap__inner .social-toggle {
	display: none;
}

@media (max-width: $screen-md) {
	body.inner .content-wrap__wrapper .social-wrap__inner {
		padding-top: 8px;
		display: block;
		position: fixed;
		z-index: 1002;
		bottom: 0;
		left: 0;
		right: 0;
		margin: 0;
		padding: 10px 15px 0;
		background: $crest-white;
		border-top: 1px solid $limestone;
		width: 100%;
		height: auto;
		top: auto !important;

		.social-toggle {
			display: block;
			background: #ffffff;
			position: absolute;
			padding: 5px 10px;
			height: 32px;
			top: -31px;
			left: 0px;
			border-top: 1px solid $limestone;
			border-right: 1px solid $limestone;
			color: $crest-blue;
			font-weight: bold;
			text-transform: uppercase;
			font-size: 12px;

			&:before {
				content: "\f107";
				font-family: FontAwesome;
				font-size: 14px;
				position: relative;
				display: inline-block;
				margin-right: 5px;
				transition: .3s ease;
			}
		}

		&.social-hidden {
			height: 0px;
			padding-top: 0px;

			.social-toggle {
				&:before {
					transform: rotate(180deg);
				}
			}
		}

		>div {
			float: left;
			margin-bottom: 10px;
		}

		.update {
			display: none;
		}

		.shortlisted {
			width: 40%;
			text-align: left;

			p {
				text-align: left;
			}

			.fa {
				margin-top: 5px;
				font-size: 32px !important;
				padding: 4px 0 0 5px;
				margin-top: 0;
				width: 40px;
				height: 40px;
				background: $tint-crest-white-darker;

				&.fa-star-o:before {
					content: "\f006";
				}

				&.saved:before {
					content: "\f005";
				}
			}
		}

		.share-links {
			width: 60%;
			float: right;

			ul {
				float: right;
			}

			li {
				float: left;
			}
		}
	}
}




pre {
	margin: 50px auto 20px;
	padding: 20px;
	//font-weight: 700;
	font-size: 14px;
	max-width: 1170px;
	overflow-x: auto;
	white-space: pre-wrap;
}





.page-nav {
	button {
		position: relative;
		width: 100%;
		text-align: left;
		border: 0;
		padding: 15px;
		@include serif-r;
		font-size: $font-size-h5;
		outline: none;
		box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);

		&:after {
			content: "\f0c9";
			@include awesome;
			position: absolute;
			right: 15px;
			top: 13px;
			font-size: $font-size-h4;
			color: $crest-yellow;
		}

		&[aria-expanded="true"] {
			border-bottom: 1px solid rgba(266, 266, 266, 0.2);
		}

		&[aria-expanded="true"]:after {
			content: "\f00d";
		}
	}

	.nav {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		min-height: 60px;
		background-color: $crest-blue;

		li {
			flex-grow: 1;
		}

		a {
			display: block;
			width: 100%;
			color: $crest-white;
			@include sans-l;

			&:hover,
			&:focus,
			&.focus {
				color: $crest-white;
				background-color: transparent;
			}
		}

		li.sel a {
			@include sans-b;
		}
	}


	@media (min-width: $screen-md) {
		button {
			display: none;
		}

		#page-nav {
			display: block;
		}

		.nav {
			//padding-left: 120px;
			//padding-right: 120px;
			flex-direction: row;
			text-align: center;
			align-items: stretch;
			padding-left: 120px;

			//justify-content: flex-start;
			>li {
				transition: .5s ease;
				margin-right: 1px;
				display: flex;
				align-items: center;
				flex-grow: 0;

				&:hover,
				&:focus,
				&.focus,
				&.active,
				&:active {
					background-color: #336387;

					&:after {
						content: '';
						width: 0;
						height: 0;
						border-left: 10px solid transparent;
						border-right: 10px solid transparent;
						border-top: 10px solid #336387;
						position: absolute;
						bottom: -10px;
						left: 50%;
						margin-left: -10px;
						z-index: 1;
					}
				}

				>a {
					position: relative;
					padding: 14px 15px 10px;

					&:hover,
					&:focus,
					&.focus {
						background-color: transparent;
					}
				}
			}
		}
	}
}

.page-nav.affix {
	top: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	transition: .25s ease;
}

.scroll-up .page-nav.affix {
	top: 130px;
	transition: .5s ease;
}

@media (min-width: $screen-md) {
	.scroll-up .page-nav.affix {
		top: 124px;

	}
}

@media (min-width: 1480px) {

	.page-nav .nav>li>a {
		padding-left: 30px;
		padding-right: 30px;
	}

}

.content-wrap__inner {
	.course__facts {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		margin: 0 -1px;
		padding-left: 0;
		background: rgba($crest-yellow, 0.2);

		li {
			width: 100%;
			padding: 15px;
			margin: 0 !important;
			border: 1px solid white;
			background-color: $crest-yellow;
			list-style: none;

			@media (min-width: 500px) {
				width: 50%;
			}

			@media (min-width: $screen-sm) {
				width: 33.3333333%;
			}

			@media (min-width: 1400px) {
				width: 25%;
			}

			&:before {
				content: none;
			}

			h2 {
				margin-top: 0;
				@include sans-b;
				text-transform: uppercase;
				font-size: 18px;
				color: $crest-black;
			}

			p {
				margin: 0;
			}

			a {
				color: $crest-black;
			}
		}
	}

	.course__modules {
		display: flex;
		flex-direction: column;
		padding: 0;
		margin: 20px -1px;

		@media (min-width: $screen-sm) {
			flex-direction: row;
		}

		li {
			//width: 100%;
			flex-grow: 1;
			list-style: none;
			color: white;
			border: 1px solid $crest-white;

			a {
				display: block;
				color: $crest-white;
				background-color: $crest-blue;
				padding: 15px;
			}

			&:before {
				content: none;
			}
		}
	}

	ul:not([class]) {
		padding-left: 20px;
		margin: 20px auto 20px 20px;
		overflow: hidden;

		li {
			position: relative;
			margin: 5px 0;

			&:before {
				content: "\f0c8";
				@include awesome;
				position: absolute;
				top: 7px;
				left: -16px;
				color: $crest-red;
				font-size: 8px;
			}
		}
	}

	ol {
		overflow: hidden;
	}

	ul.pagination {
		margin-left: 0;
		padding-left: 0;

		li {
			&:before {
				display: none;
			}
		}
	}

	.video-img {
		position: relative;

		.bg-img>div {
			height: 350px;

			@media (min-width: $screen-sm) {
				height: 450px;
			}

			@media (min-width: 1400px) {
				height: 550px;
			}

			@include bg-cover-center;
			box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.3);

			&:before {
				@include gradient-black-up;
				height: 50%;
			}
		}

		figcaption {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			padding: 15px;
			color: $crest-white;
		}

		&:before {
			content: "\f144";
			@include awesome;
			@include centered-h-v;
			color: $crest-white;
			font-size: 100px;
			z-index: 1;
			color: rgba(205, 32, 44, 0.8);
		}
	}

	.module-8 .slideShowSingle__nav {
		bottom: 0;
	}

	.slideShowSingle .slick-next,
	.slideShowSingle .slick-prev {
		display: none !important;
	}

	@media (min-width: $screen-md) {
		.module-8 .slideShowSingle figure figcaption .outer {
			padding: 20px;
		}
	}

	@media (min-width: 1200px) {
		.module-8 .slideShowSingle figure figcaption .outer {
			padding: 40px;
		}
	}

	@media (min-width: 1400px) {
		.module-8 .slideShowSingle figure figcaption .outer {
			padding: 60px;
		}
	}
}

.content-search {

	.input-group {
		input {
			height: 60px;
			border-radius: 0;
			color: $crest-blue;
		}

		.input-group-addon {
			background: white !important;
			border: 1px solid $crest-yellow;
			border-left: 0;
			border-radius: 0;
			height: 60px;
		}

		.form-control {
			border-right: 0;
			box-shadow: none;
			border: 0;
			border-top: 1px solid $crest-yellow;
			border-bottom: 1px solid $crest-yellow;
			border-left: 1px solid $crest-yellow;
			appearance: none;
		}

		button {
			border: 0;
			background: transparent;

			span {
				font-size: $font-size-h3;
				color: $crest-red;
			}
		}

		.reset {
			background: $tint-crest-white-darker;
			color: $crest-black;
			border: 0;
			font-size: 12px;
			font-weight: 700;
			padding: 3px 6px 2px;
			height: auto;
			position: absolute;
			top: 20px;
			right: 58px;
			z-index: 100;
			text-transform: uppercase;
		}

		@media (min-width: $screen-md) {
			input {
				font-size: $font-size-h6;
				padding: 0 15px;
			}

			button {
				width: 60px;
			}

			input,
			.input-group-addon {
				height: 100px;
			}
		}
	}

	&.thin-border {

		input,
		.input-group-addon,
		select {
			height: 60px;
		}

		.btn-default {
			height: 60px;
			border: 1px solid $crest-yellow;
		}
	}

	&.wide-border {
		background-color: $crest-blue;
		padding: 10px;

		.stylish-input-group {
			margin-top: 0;
		}

		.form-control {
			border: 0;
		}

		.input-group-addon {
			border: 0;
		}

		button {
			span {
				color: $crest-yellow;
			}
		}

		@media (min-width: $screen-md) {
			padding: 20px;

			button {
				width: 60px;
			}

			input,
			.input-group-addon {
				height: 60px;
				padding-right: 0;
			}
		}
	}

	.reset {
		background: $tint-crest-white-darker;
		border: 0;
		font-size: 14px;
		padding: 3px 6px;
	}
}


.form-group.thin-border {
	height: 60px;

	.glyphicon-ok:before {
		@include awesome;
		content: "\f00c";
	}

	.btn-default {
		height: 60px;
		border: 1px solid $crest-yellow;
		@include sans-r;

		@media (min-width: $screen-md) {
			font-size: 22px;
			padding-top: 16px;
		}
	}

	.caret {
		border-top: 8px dashed;
		border-right: 8px solid transparent;
		border-left: 8px solid transparent;
	}

	.dropdown-menu {
		top: 60px;
	}
}




/*standard content images with captions
******************************************************/

.content-wrap__wrapper .content-image {
	text-align: center;
	padding: 0;
}

.content-wrap__wrapper .content-image.pull-right {
	margin: 0 0 30px 30px;
}

.content-wrap__wrapper .content-image.pull-left {
	margin: 0 30px 30px 0;
}

.content-wrap__wrapper .content-image img {
	display: block;
	width: 100%;
}

.content-wrap__wrapper .content-image figcaption {
	font-style: italic;
	opacity: 0.8;
	padding: 10px;
	border-top: 1px solid $crest-yellow;
	border-bottom: 1px solid $crest-yellow;
	color: $crest-blue;
}

/*contact details
***********************************************/

.content-wrap__inner .contact-details li:before {
	content: "";
}

.content-wrap__inner .contact-details {
	border-top: 1px solid $tint-crest-white-darker;
	margin: 30px auto;
	padding: 0;
}

.contact-details li {
	border-bottom: 1px solid $tint-crest-white-darker;
	padding: 10px 0;
	margin: 0;
}

.contact-details .fa {
	color: $crest-yellow;
	font-size: 22px;
	float: left;
}

.contact-details span {
	display: block;
	margin-left: 40px;
}


/*Header image
****************************************************/

.headerImage {
	margin: 20px auto;
	position: relative;

	img {
		width: 100%;
	}

	h2 {
		position: absolute;
		z-index: 20;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 10px 15px;
		background: rgba($crest-blue, 0.7);
		color: #fff;
		font-size: 18px;
	}
}




@media (min-width: $screen-lg) {

	.headerImage h2 {
		font-size: 22px;
		padding: 15px 30px;
	}


}


/*Tables
*****************************************************/

.content-wrap__inner table {
	width: 98% !important;
	margin: 30px auto;
}

.content-wrap__inner table th,
.content-wrap__inner table td {
	border-bottom: 1px solid #ddd;
	border-top: 1px solid #ddd;
	padding: 10px;
}

.content-wrap__inner table th {
	font-weight: 700;
	color: $crest-blue;
	background: $tint-crest-white;
}




/*REMODAL CONTENT
*********************************************************/

.remodal.copy {
	text-align: left;

	ul,
	ol {
		margin-top: 20px;
		margin-bottom: 20px;
	}

	li {
		position: relative;
		padding: 10px 0 10px 20px;

		&:before {
			content: "\f0c8";
			font-family: 'FontAwesome';
			font-weight: normal;
			font-weight: normal;
			position: absolute;
			top: 15px;
			left: 5px;
			color: #cd202c;
			font-size: 8px;
		}
	}
}



/*AZ LIST
****************************************************************/


.az-list {
	overflow: hidden;
	margin: 30px auto 0;

	ul {
		margin: 0;
		padding: 0;

		li {
			margin: 0 5px 5px 0;
			padding: 0;
			float: left;

			&:before {
				display: none;
			}
		}

		a {
			display: block;
			padding: 2px 10px;
			background: $tint-crest-white-darker;
			color: $crest-blue;
			text-transform: uppercase;
		}
	}
}



/*TABS
******************************************************************/

.content-wrap__inner .nav-tabs {
	margin: 30px 0;
	padding: 0;
}

.nav-tabs {
	border: 0;
	margin: 0;
	padding: 0;
	display: flex;
	flex-wrap: no-wrap;
	align-items: stretch;
}

.nav-tabs li {
	border: 0;
	margin: 0;
	padding: 0;
	flex-grow: 1;
	flex-basis: 0;
	display: flex;
	align-items: flex-end;
}

.nav-tabs li:before {
	display: none;
}

.nav-tabs>li>a {
	display: block;
	width: 100%;
	border-bottom: 8px solid $tint-crest-white-darker;
	border-right: transparent;
	border-left: transparent;
	border-top: transparent;
	font-size: 18px;
	padding: 8px 0;
	color: $limestone-darker;
	transition: .5s ease;
}

.nav-tabs>li>a:hover,
.nav-tabs>li>a:focus,
.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
	background: transparent !important;
	border-bottom: 8px solid $sky-blue;
	border-right: transparent;
	border-left: transparent;
	border-top: transparent;
	color: $crest-black;
}

.nav-tabs>li.active>a:after,
.nav-tabs>li.active>a:focus:after,
.nav-tabs>li.active>a:hover:after {
	content: "";
	display: block;
	width: 0;
	height: 0;
	border-top: 8px solid $sky-blue;
	border-right: 8px solid transparent;
	border-left: 8px solid transparent;
	position: absolute;
	top: 100%;
	margin-top: 10px;
	left: 50%;
	margin-left: -8px;
}

.nav-tabs>li:nth-child(1)>a:hover,
.nav-tabs>li:nth-child(1)>a:focus,
.nav-tabs>li:nth-child(1).active>a,
.nav-tabs>li:nth-child(1).active>a:focus,
.nav-tabs>li:nth-child(1).active>a:hover {
	border-bottom: 8px solid $crest-blue;
}

.nav-tabs>li:nth-child(1).active>a:after,
.nav-tabs>li:nth-child(1).active>a:focus:after,
.nav-tabs>li:nth-child(1).active>a:hover:after {
	border-top: 8px solid $crest-blue;
}

.nav-tabs>li:nth-child(3)>a:hover,
.nav-tabs>li:nth-child(3)>a:focus,
.nav-tabs>li:nth-child(3).active>a,
.nav-tabs>li:nth-child(3).active>a:focus,
.nav-tabs>li:nth-child(3).active>a:hover {
	border-bottom: 8px solid $crest-yellow;
}

.nav-tabs>li:nth-child(3).active>a:after,
.nav-tabs>li:nth-child(3).active>a:focus:after,
.nav-tabs>li:nth-child(3).active>a:hover:after {
	border-top: 8px solid $crest-yellow;
}

.nav-tabs>li:nth-child(4)>a:hover,
.nav-tabs>li:nth-child(4)>a:focus,
.nav-tabs>li:nth-child(4).active>a,
.nav-tabs>li:nth-child(4).active>a:focus,
.nav-tabs>li:nth-child(4).active>a:hover {
	border-bottom: 8px solid $crest-red;
}

.nav-tabs>li:nth-child(4).active>a:after,
.nav-tabs>li:nth-child(4).active>a:focus:after,
.nav-tabs>li:nth-child(4).active>a:hover:after {
	border-top: 8px solid $crest-red;
}

@media all and (max-width: 991px) {

	.tabs-wrapper {
		margin: 0 0 20px;
	}

}


@media all and (max-width: 767px) {

	.nav-tabs {
		-webkit-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.nav-tabs li {
		flex-basis: 50%;
	}

}


/*PAGINATION
*************************************************************/

.pagination>.active>a,
.pagination>.active>a:hover,
.pagination>.active>a:focus,
.pagination>.active>span,
.pagination>.active>span:hover,
.pagination>.active>span:focus {
	background-color: $sky-blue;
	border-color: $sky-blue;
}

/*EVENTS CALENDAR
using code copied from existing UCC website
*******************************************************************/

#calendar_events {
	.calendarHeader {
		color: $crest-blue;
		font-family: $font-family-serif;
		font-size: 24px;
		margin: 30px 0 20px;
	}

	.view_period_switchers {
		color: #fff;

		a {
			display: inline-block;
			padding: 6px 12px;
			background: $crest-yellow;
			color: $crest-blue;
			font-family: $font-family-serif;
			font-weight: bold;
			text-decoration: none;
		}
	}

	table td {
		vertical-align: top;
		padding-top: 30px;
	}

	td:first-child {
		width: 50%;
		padding-right: 20px;
		padding-top: 23px;

		a {
			font-family: $font-family-serif;
			font-size: 22px;
			display: block;
		}
	}

	td:last-child {
		width: 25%;
		text-align: right;
		word-break: break-all;
	}

	.table_text {

		/*background: $crest-red;
		color: #fff;*/
		tr {
			background: $crest-white  !important;

			td:first-child {
				width: 30% !important;
				padding: 10px;
				color: $crest-blue;
			}

			td:last-child {
				width: 70% !important;
				text-align: left;
				padding: 10px;
			}
		}
	}
}


/*************************************************
* T4 Calendar
*/

/*styles from w3c_1140grid_3.css*/

#t4_calendar {
	padding-top: 20px;
}

#calendar .links {
	padding-left: 2px;
	padding-top: 5px;
	font-weight: bold;
}

#calendar .links a {
	font-weight: normal;
}

#calendar caption {
	display: none;
}

#calendarFilter {
	padding-top: 8px;
	padding-left: 25px;
}

#calendar a {
	text-decoration: none;
	display: block;
	color: #000;
	padding-right: 0.3em;
	padding-left: 0.3em;
	padding-top: 0.3em;
	padding-bottom: 0.3em;
	background: none;
}

#calendar a:hover,
#calendar a:active {
	color: #000;
}

#calendar th {
	font-weight: bold;
	color: #000;
	padding: 0.3em;
}

#calendar th a {
	color: #000;
}

// #calendar th a:hover, #calendar a:active {
// }
#calendar td {
	border: 0;
	padding: 0;
}

// #calendar td.filterMainMonth {
// }
// #calendar table tbody tr td.filterMainMonth a.hasEvents {
// }
// #calendar td.filterPreMonth {
// }

// #calendar td.filterPostMonth {
// }

// #calendar td.mainMonth a {
// }

#calendar td.postMonth a {
	color: silver;
}

#calendar td.preMonth a {
	color: silver;
}

#calendar td a:hover {
	color: white;
}


/*inline styles from https://www.ucc.ie/en/events/*/
#calendar th {
	font-weight: bold;
	color: #FFFFFF;
	background: #003C69;
	padding: 0.3em;
	border-top: 0.1em solid #003C69;
	border-right: 0.1em solid #003C69;
	border-bottom: 0.1em solid #003C69;
	border-left: 0.1em solid #003C69;
}

#calendar table tbody tr td.filterMainMonth a.hasEvents {
	background-color: rgba($limestone, 0.75);
	color: $crest-black;
}

#calendar table tbody tr td.filterMainMonth a.hasEvents:hover {
	background-color: #BBBCBC;
}

/* This rule overwrites the display none coming from a seperate sheet */
#searchoptions #categories {
	display: block;
}


/* This rule changes the style for the current day in the calendar block */
.filterMainMonth.today {
	font-weight: bold;
}

/* Light grey colour - set here to overwrite calendar css rule */
#calendarResults table td {
	background-color: #eff0f0;
}

/* #calendar th a {
	color: #000 !important;
  }
  */

#calendar a {
	color: #666;
	cursor: pointer;
}

#calendar th a {
	color: #fff;
	display: block;
}

#calendar th a:hover,
#calendar a:active {
	background: none !important;
	color: #EFF0F0;

}

/*Granite additional styles*/

#calendar table {
	width: 100%;
	//border-spacing: 1px;
	//border-collapse: separate;
}

/*search and filter options*/

.btn-collapse-events-search {
	margin-top: 35px;
}

#view_switchers {
	legend {
		display: none;
	}

	margin: 10px -1% 0;
	padding-top: 7px;
	//color: transparent;
	overflow: hidden;
	font-size: 0;

	a,
	span {
		display: block;
		background: rgba($limestone, 0.75);
		color: $crest-black;
		display: block;
		float: left;
		width: 18%;
		padding: 5px 0 4px;
		margin: 0 1%;
		text-align: center;
		font-size: 12px;
	}

	.current {
		color: $crest-white;
		background: $crest-blue;
		position: relative;

		&:before {
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-bottom: 7px solid $crest-blue;
			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
			position: absolute;
			top: -7px;
			left: 50%;
			margin-left: -7px;
		}
	}
}

#jumptoform,
#calendarSearch,
#categories {
	margin-top: 35px;

	label {
		padding: 0;
		background-color: transparent;
		font-family: 'Playfair Display', serif;
		font-weight: 700;
		font-size: 20px;
	}
}

#jumptoform {
	legend {
		display: none;
	}

	select,
	input {
		display: block;
		float: left;
	}

	select:nth-of-type(1) {
		width: 39%;
		margin-right: 1%;
		height: 37px;
		padding: 6px 6px;
		//-webkit-appearance: menulist-button;
		border-radius: 0;
	}

	select:nth-of-type(2) {
		width: 34%;
		margin-right: 1%;
		height: 37px;
		padding: 6px 6px;
		//-webkit-appearance: menulist-button;
		border-radius: 0;
	}

	input:last-child {
		width: 25%;
		padding: 6px 12px;
		height: 37px;
		background: $crest-yellow;
		color: $crest-blue;
		;
		font-family: "Playfair Display", serif;
		font-weight: bold;
		text-decoration: none;
		border: 0;
		font-size: 20px;
	}
}

#searchoptions {
	>form>fieldset>legend:first-of-type {
		display: none;
	}

	legend {
		margin: 0;
	}

	fieldset {
		fieldset {
			margin-top: 25px;
		}
	}

	input[type="submit"] {
		background: $crest-yellow;
		color: $crest-blue;
		font-family: "Playfair Display", serif;
		font-weight: bold;
		text-decoration: none;
		border: 0;
		display: block;
		padding: 12px 0;
		width: 100%;
		font-size: 20px;
	}
}

#calendarSearch {
	input {
		width: 100%;
		padding: 6px 6px;
		height: 37px;
	}
}

#categories {
	margin-bottom: 35px;

	legend {
		margin-bottom: 25px;
		display: block;
	}

	.mainCategory,
	.subCategory {
		position: relative;
		display: block;
		padding: 10px 15px 10px 35px;
		background-color: #fff;
		margin-bottom: 2px;
		text-decoration: none;
		color: #231f20;

		input[type="checkbox"] {
			position: absolute;
			margin-left: -50000px;
		}

		&:before {
			content: "\f096";
			font-family: "FontAwesome";
			font-size: 18px;
			color: #cd202c;
			position: absolute;
			top: 10px;
			left: 12px;
		}
	}

	.subCategory {
		padding-left: 55px;

		&:before {
			left: 32px;
		}
	}

	.sel {
		background: $crest-red;
		color: $crest-white;

		&:before {
			content: "\f046";
			color: $crest-white;
		}
	}
}


#filterByDay {

	// margin-top: 25px;
	br {
		display: none;
	}

	label {
		position: relative;
		display: block;
		padding: 10px 15px 10px 35px;
		background-color: #fff;
		margin-bottom: 2px;
		text-decoration: none;
		color: #231f20;
		font-weight: 400;

		&:before {
			content: "\f096";
			font-family: "FontAwesome";
			font-size: 18px;
			color: #cd202c;
			position: absolute;
			top: 10px;
			left: 12px;
		}
	}

	input[type="checkbox"] {
		position: absolute;
		margin-left: -50000px;
	}

	.sel {
		background: $crest-red;
		color: $crest-white;

		&:before {
			content: "\f046";
			color: $crest-white;
		}
	}
}

/*add an event form*/

#eventfrm table,
#eventfrm table tr,
#eventfrm table th,
#eventfrm table td,
#eventfrm table tbody,
#eventfrm table thead {
	display: block;
	width: 100%;
	overflow: hidden;
	border: 0;
	padding-top: 0;
	padding-bottom: 5px;
}

#eventfrm table {
	th {
		padding-top: 18px;
		font-family: $font-family-serif;
		font-size: 18px;
		font-weight: 400;
	}

	td:nth-child(3) {
		opacity: 0.7;
		border-bottom: 1px solid #ddd;
		padding-bottom: 10px;
	}

	td[colspan="4"] {
		width: 100%;
		max-width: 100%;
	}

	table {
		margin: 0;

		td {
			padding-left: 0;
			padding-right: 10px;
			padding-bottom: 0;
			border: 0;
		}

		td:nth-child(1) {
			width: 20%;
			float: left;
		}

		td:nth-child(2) {
			width: 40%;
			float: left;
		}

		td:nth-child(3) {
			width: 40%;
			float: left;
			padding-right: 0;
			padding-bottom: 0;
			border: 0;
		}
	}
}




@media (max-width: $screen-sm) {


	#calendar_events table,
	#calendar_events table tr,
	#calendar_events table th,
	#calendar_events table td,
	#calendar_events table tbody,
	#calendar_events table thead {
		display: block;
		width: 100%;
		overflow: hidden;
	}

	#calendar_events table th:nth-child(2),
	#calendar_events table th:nth-child(3) {
		display: none;
	}

	#calendar_events td {
		border: 0;
	}

	#calendar_events tr {
		border-bottom: 1px solid $tint-crest-white;
	}

	#calendar_events td:first-child {
		width: 100%;
		padding: 20px 10px 0;
	}

	#calendar_events td:nth-child(2) {
		padding: 0 10px;

		&:before {
			content: "Organiser:";
			padding-right: 5px;
			font-weight: 700;
		}
	}

	#calendar_events td:last-child {
		width: 100%;
		text-align: left;
		padding: 13px 10px 20px;

		&:before {
			content: "Location:";
			padding-right: 5px;
			font-weight: 700;
		}
	}


}

@media (min-width: 1400px) {

	#view_switchers {

		a,
		span {
			font-size: 14px;
		}
	}

}



/*LEGACY COLLAPSES ACCORDIONS
***************************************************/

.collapse-legacy {
	margin: 10px auto;

	button {
		white-space: normal;
		position: relative;
		width: 100%;
		text-align: left;
		border: 0;
		padding: 15px;
		padding-right: 35px;
		font-family: 'Playfair Display', serif;
		font-weight: 400;
		font-size: 16px;
		outline: none;
		color: #fff;
		background: #003c69;

		&:after {
			content: "\f107";
			font-family: 'FontAwesome';
			font-weight: normal;
			font-weight: normal;
			position: absolute;
			right: 15px;
			top: 12px;
			font-size: 22px;
			color: #ffb500;
			transition: .5s ease;
		}
	}

	.content-search {
		button {
			&:after {
				content: '';
			}

			&[type="submit"] {
				padding: 10px;
			}
		}
	}

	button[aria-expanded="true"] {
		&:after {
			transform: rotate(-180deg);
		}
	}

	.well {
		background: transparent;
		padding-left: 0;
		padding-right: 0;
	}
}

@media (min-width: screen-md) {
	.collapse-legacy {
		button {
			font-size: 22px;

			&:after {
				right: 15px;
				top: 13px;
				font-size: 26px;
			}
		}
	}
}



/*BIO IN MAIN CONTENT AREA
*****************************************************/

.content-wrap__inner .rhs__menu.bio {
	overflow: hidden;
	background: $tint-crest-white;
	padding-top: 30px;

	.bg-img {
		width: 200px;
		padding-bottom: 200px;
		float: none;
		//float: right;
	}

	.contact {
		width: 100%;
		float: none;

		h3 {
			margin: 10px 0 0;
			padding: 0;
			font-size: 16px;
			font-family: $font-family-sans-serif;
			font-weight: 700;
			color: $crest-blue;
			line-height: 1.5;
		}

		p,
		ul,
		ol {
			//float: left;
			margin: 0 0 10px 0;
			padding: 0;
			font-size: 16px;
			line-height: 1.5;
		}
	}

	.contact li:before {
		display: none;
	}
}

@media (min-width: $screen-md) {

	.content-wrap__inner .rhs__menu.bio {
		.contact {
			h3 {
				width: 170px;
				float: left;
				clear: left;
				margin: 0 0 10px;
			}

			p,
			ul,
			ol {
				//float: left;
				margin: 0 0 10px 170px;
			}
		}
	}

}



/*sssn*/

.bio {
	&::after {
		content: '';
		display: table;
		clear: both;
	}

	.bg-img {
		padding-bottom: 100%;
		width: 100%;
		@include bg-cover-center;

		@media (min-width: 500px) and (max-width: 991px) {
			width: 50%;
			padding-bottom: 50%;
			float: left;
			margin-bottom: 30px;
		}
	}

	&.main-section {
		h2 {
			margin-top: 10px;

			@media all and (min-width: $screen-sm-min) {
				margin-top: 0;
			}
		}

		.bg-img {
			width: 250px;
			height: 250px;
			padding-bottom: 0;

			@media all and (min-width: 500px) {
				float: left;
				margin-right: 5%;
				width: 35%;
			}

			@media all and (min-width: $screen-md-min) {
				width: 30%;
			}
		}

		.contact {
			@media all and (min-width: 500px) {
				float: left;
				width: 60%;
			}

			@media all and (min-width: $screen-md-min) {
				width: 65%;
			}
		}

		&+&.main-section {
			border-top: 4px solid $crest-blue;
			padding-top: 30px;
			margin-top: 15px;
		}

		&:nth-child(2n) {
			background: rgba($crest-blue, .03);
		}
	}
}

/*T4 PAGINATION
****************************************/

.pagination-control {
	text-align: center;

	a,
	span {
		display: inline-block;
		margin: 0 2px;
		padding: 3px 9px;
		background: $tint-crest-white;
		color: $crest-blue;
		border: 1px solid $tint-crest-white;
		transition: .3s ease;
	}

	a:hover {
		border-color: $limestone;
	}

	span {
		background: $sky-blue;
		color: white;
		border-color: $sky-blue;
	}
}






// 2018 q4 changes to be approved


.content-wrap__wrapper {
	.content-wrap__inner {
		@media (max-width: $screen-md-min - 1) {
			margin-left: 15px;
			margin-right: 15px;
		}

		p,
		li {
			font-size: 16px;
			line-height: 1.6;

			a {
				//border-bottom: 1px solid $crest-blue;
				text-decoration: underline;
			}
		}

		h2,
		h3,
		h4,
		h5,
		h6 {
			font-family: $font-family-sans-serif;
			line-height: 1.35;

			a {
				text-decoration: none;
			}
		}

		ol:not(.collapse) li,
		ul li {
			margin: 5px 0;
		}

		h3 {
			font-size: 24px;
			font-weight: 700;
			@media all and (max-width: 1439px) {
				margin-bottom: 10px;
				margin-top: 20px;
				
				font-size: 20px;
			}
			
		}
		.slide-square__icon {
			@media all and (max-width: 1439px) {
				height: 100px;
				width: 100px;
			}
		}
		.slide-square__contact {
			@media all and (max-width: 1439px) {
				flex-direction: column;
			}
		}
		.slide-square__copy {
			@media all and (max-width: 1439px) {
				margin-top: 10px;
				font-size: 14px;
			}
		}

		h4 {
			font-weight: 700;
			font-size: 18px;
		}

		ol.collapse {
			a {
				border: 0;
				text-decoration: none;
			}
		}
	}
}
.dataTable a {
	text-decoration: underline;
}

.module-wrap {
	img {
		max-width: 100%;
	}
	.module-24 {
		&.processed {
			.img-index {
				img {
					width: 100%;
				}
			}
			.img-index-0 {
				img {
					width: 100%;
				}
			}
			.img-index:not(.img-index-0) {
				@media (min-width: 768px) {
					width: 50%;
					max-width: 50% !important;
					height: auto !important;
					float: left;
					margin: 0 30px 30px 0;
					&.img-index-2,
					&.img-index-4,
					&.img-index-6,
					&.img-index-8,
					&.img-index-10
					&.img-index-12,
					&.img-index-14,
					&.img-index-16,
					&.img-index-18,
					&.img-index-20 {
						float: right;
						margin: 0 0 30px 30px;
					}
				}
			}
			figure:not(.image) {
				border: 5px solid red;
				width: 50%;
				float: left;
				img:not(.img-index-0) {
					@media (min-width: 768px) {
						max-width: 100% !important;
						float: none;
						margin: 0;
					}
				}
			}
		}
		&.disable-automatic-image-float {
			.image {
				&.img-align-left {
					float: left;
					@media (min-width: 768px) {
						max-width: 50%;
						margin-right: 20px;
					}
				}
				&.img-align-right {
					float: right;
					@media (min-width: 768px) {
						max-width: 50%;
						margin-left: 20px;
					}
				}
				&.img-align-centre {
					width: 100%;
					img {
						width: 100%;
					}
				}
			}
		}
	}
}
