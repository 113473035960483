//
// Custom Variables
// --------------------------------------------------


//== Colors
//

$crest-red:					#cd202c;
$crest-blue:				#003c69;
$crest-yellow:				#ffb500;

$quad-green:				#51832e;		//original #74aa50
$sky-blue:					#2a7bb3;		//original #69b3e7

$dawn-mauve:				#7364dc;		//original #7566dc
$earth-red:					#ae624d;		//original #b46a55
$ogham-stone:				#a06a28;		//original #c6893f

$tint-crest-white:			#f1f1f2;
$limestone:					#bbbcbc;

$crest-black:				#231f20;
$crest-white:				#ffffff;


$tint-crest-white-darker:	#ebebed;
$limestone-darker:			#5c5e5e;

$opacity:					0.7;
$crest-red-opac:			rgba(205,32,44, $opacity);
$crest-blue-opac:			rgba(0,60,105, $opacity);
$crest-yellow-opac:			rgba(255,181,0, $opacity);
$crest-black-opac:			rgba(0,0,0, $opacity);
$crest-black-opac-light:	rgba(0,0,0, $opacity * 0.5);

$brand-twitter:				#2a7bb3;		//changed to sky blue - original #55acee
$brand-facebook:			#003C69;		//changed to crest blue - original #3b5999
$brand-instagram:			#e4405f;
$brand-youtube:				#cd201f;
$brand-linkedin:			#0077B5;

$link-color:				#337ab7;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  	'Roboto', sans-serif;
$font-family-serif:       	'Playfair Display', serif;
$font-family-base:        	$font-family-sans-serif;

$font-size-base:          	20px;
$font-size-h1:            	ceil(($font-size-base * 3)); // ~60px
$font-size-h2:            	ceil(($font-size-base * 1.5)); // ~30px
$font-size-h3:            	ceil(($font-size-base * 1.4)); // ~28px
$font-size-h4:            	ceil(($font-size-base * 1.3)); // ~26px
$font-size-h5:            	ceil(($font-size-base * 1.1)); // ~22 px
$font-size-h6:            	ceil(($font-size-base)); // ~20 px
$font-size-h7:            	ceil(($font-size-base * 0.9)); // ~20 px

$font-size-xlg:				ceil(($font-size-base * 4.5)); // ~90px
$font-size-lg:				ceil(($font-size-base * 3.75)); // ~75px
$font-size-md:				ceil(($font-size-base * 2.25)); // ~45px
$font-size-sm:				ceil(($font-size-base * 1.2)); // ~24px
$font-size-xs:				ceil(($font-size-base * 0.6)); // ~12px

$line-height-base:        	1.333333; // 24/18

//== Grid
//

$grid-gutter-width:         30px;

// Small screen / between tablet and xs
$container-xsm:             (520px + $grid-gutter-width);
$screen-xsm:                550px !default;
$screen-xsm-min:            $screen-xsm !default;