.module-38 {
  margin: 50px 15px 0;
  padding: 0 15px;

  @media (max-width: 992px) {
    padding: 0 15px;
  }

  .highcharts-credits {
    display: none;
  }
}