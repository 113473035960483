.module-6 {
	background-color: $crest-blue;
	margin: 10px auto;
	@include spacer-p-t-b;
	p {
		color: #fff;
	}
	.form-bespoke {
		display: flex;
		flex-direction: column;
		@media (min-width: $screen-sm) {
			flex-direction: row;
			flex-wrap: wrap;
		}
	 	.btn-special {
			padding-left: 120px;
			.btn {
				width: 100%;
				max-width: 100%;
			}
		}
		.form-group {
			.btn-special {
				padding-left: 0;
				@media (min-width: 620px) and (max-width: $screen-sm - 1) {
					padding-left: 120px;
				}
				button {
					min-height: 60px;
				}
			}
		}
		@media (min-width: 320px) and (max-width: 620px) {
			.form-group {
				min-height: 85px;
				&:last-of-type {
					min-height: auto;
				}
				label {
					color: white;
				}
			}
		}
		@media (min-width: 620px) {
			.form-group {
				display: flex;
				align-items: center;
				.filter-option {
					@include sans-b;
				}
				@media (min-width: $screen-sm) {
					width: 50%;
					font-size: $font-size-h5;
					margin-bottom: 15px;
					.form-selector {
						width: 100%;
						font-size: $font-size-h5;
						.filter-option {
							font-size: $font-size-h5;	
						}
					}
					&:nth-of-type(3) {
						width: 70%;
						margin-left: auto;
						margin-right: auto;
						label {
							width: 250px;
						}
					}
					&:last-of-type {
						margin-left: auto;
						margin-right: auto;
					}
					.btn {
						font-size: $font-size-h5;
					}
				}
				@media (min-width: $screen-md) {
					font-size: $font-size-h3;
					.form-selector {
						font-size: $font-size-h4;
						.filter-option {
							font-size: $font-size-h3;	
						}
					}
					.btn {
						font-size: $font-size-h4;
					}
				}
				&:before {
					content: '';
					display: table;
				}
				label {
					display: flex;
					justify-content: flex-end;
					align-items: center;
					color: white;
					width: 60px;
					text-align: right;
					margin-bottom: 0;				
					padding-right: 10px;
					@include sans-l;
					@media (min-width: 500px) {
						width: 120px;
					}
					@media (min-width: $screen-md) {
						width: 180px;
					}
				}
				@media (min-width: 320px) and (max-width: $screen-sm) {
					.form-selector {
						flex: 1;
					}	
				}
			}
			.form-selector,label, .btn-group .btn,.bootstrap-select {
				min-height: 70px;
			}
		}
		.btn-special {
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			display: block;
		}
		.bootstrap-select {
			background-color: transparent;
			@include sans-b;
			.btn {
				@include sans-r;
				background-color: transparent;
				color: white;
				border-color: white;
				border: 1px solid white;
				padding-right: 40px;
			} 
			.caret {
				border: 0;
				&:after {
					content:"\f107";
					@include awesome;
					font-size: $font-size-h3;
					position: absolute;
					top: -17px;
					right: 0;
				}
			}
		}
	}
}

@media all and (min-width: $screen-sm) {


	.module-6 .form-bespoke {
		display: block;
		text-align: center;
	}

	.module-6 .form-bespoke .form-group,
	.module-6 .form-bespoke .form-group:nth-of-type(3) {
		display: inline-block;
		width: auto;
	}

	.module-6 .form-bespoke .form-group:last-child {
		width: 100%;
	}

	.module-6 .form-bespoke .form-group label,
	.module-6 .form-bespoke .form-group:nth-of-type(3) label {
		display: inline-block;
		width: auto;
		text-align: left;
		margin: 0 10px;
		padding-right: 0;
	}

	.module-6 .form-bespoke .form-group .form-selector {
		display: inline-block;
		width: auto;
		margin: 0 10px;
	}

	.module-6 .form-bespoke .bootstrap-select .btn {
		padding-right: 70px;
	}

	.module-6 .form-bespoke .btn-special {
		padding-left: 0;
		max-width: 320px;
	}

	.module-6 .form-bespoke .btn-special .btn {
		padding-top: 20px;
		padding-bottom: 20px;
		margin-top: 30px;
	}

}


