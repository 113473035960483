.module-4b {
    @include spacer-p-t;
    @media all and (min-width: $screen-sm-min) {
        position: fixed;
        z-index: 9001;
        top: 50%;
        right: 0;

        transform: translateY(-50%);
    }
    p,
    li {
        line-height: 1.25;
    }
    .wrapper {
        position: relative;
        .inner {
            position: relative;

            float: left;
            overflow: hidden;

            width: 50%;
            padding-bottom: 50%;

            border: 5px solid $crest-white;
            @media (min-width: $screen-sm) {
                width: 33.333333%;
                padding-bottom: 33.333333%;
            }
            @media (min-width: $screen-md) {
                width: 25%;
                padding-bottom: 25%;
            }
            .inner__wrap {
                position: absolute;
                top: 0;
                left: 0;

                width: 100%;
                height: 100%;
                > .inner__img {
                    @include absolute-cover;
                    @include bg-cover-center;
                    &.image:after {
                        @include gradient-black-up;

                        height: 100%;
                    }
                    .ucc-theme--plrg & {
                        background-size: 80px auto;
                    }
                }
                > .inner__icon {
                    position: absolute;

                    width: 50%;
                    height: 50%;

                    border: 5px solid $crest-white;
                    &:nth-of-type(1) {
                        top: 0;
                        left: 0;

                        border-top: 0;
                        border-left: 0;
                    }
                    &:nth-of-type(2) {
                        top: 0;
                        left: 50%;

                        border-top: 0;
                        border-right: 0;
                    }
                    &:nth-of-type(3) {
                        top: 50%;
                        left: 0;

                        border-bottom: 0;
                        border-left: 0;
                    }
                    &:nth-of-type(4) {
                        top: 50%;
                        left: 50%;

                        border-right: 0;
                        border-bottom: 0;
                    }
                    .inner__img {
                        @include absolute-cover;
                        @include bg-center;

                        background-size: 50%;
                    }
                    p {
                        font-size: $font-size-xs;
                        font-weight: 700;

                        position: absolute;
                        right: 0;
                        bottom: -5px;
                        left: 0;

                        text-align: center;

                        color: $crest-white;
                        @media (min-width: 550px) {
                            font-size: $font-size-base;

                            bottom: 0;
                        }
                        @media (min-width: $screen-md) {
                            font-size: $font-size-base;
                            //padding: 30px;
                        }
                        @media (min-width: $screen-lg) {
                            font-size: $font-size-sm;
                        }
                    }
                }
                .inner__content {
                    @include absolute-cover;
                    a {
                        text-decoration: underline;

                        color: $crest-white;
                    }
                    .inner__info {
                        position: absolute;
                        bottom: 0;

                        width: 100%;
                        padding: 15px;

                        color: $crest-white;
                        @media (max-width: 450px) {
                            font-size: 13px;

                            padding: 6px;
                        }
                        a {
                            font-weight: 900;

                            display: block;
                        }
                        @media (min-width: 550px) {
                            font-size: 16px;
                            line-height: 20px;
                        }
                        @media (min-width: $screen-sm)and (max-width: $screen-md) {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
    .social-block {
        display: flex;
        flex-direction: row;

        margin: 0;
        padding: 0 10px 5px;

        justify-content: space-between;
        @media all and (min-width: $screen-sm-min) {
            display: block;

            padding-top: 1px;

            background: white;
        }
        li {
            text-align: center;

            border: 5px solid $crest-white;

            flex: 1;
            &::before {
                display: none;
            }
            @media (max-width: $screen-md) {
                position: relative;

                overflow: hidden;
            }
            a {
                display: block;

                color: $crest-white;
                @media (min-width: $screen-md) {
                    line-height: 50px;

                    position: relative;

                    height: 50px;
                }
                @media (max-width: $screen-md) {
                    i {
                        @include centered-h-v;

                        font-size: 30px;
                    }
                }
            }
            &.twitter {
                a {
                    background-color: $brand-twitter;
                }
            }
            &.facebook {
                a {
                    background-color: $brand-facebook;
                }
            }
            &.instagram {
                a {
                    background-color: $brand-instagram;
                }
            }
            &.youtube {
                a {
                    background-color: $brand-youtube;
                }
            }
            &.linkedin {
                a {
                    background-color: $brand-linkedin;
                }
            }
        }
    }
}
.module-4b .wrapper .inner .inner__content .inner__info .link-name .fa.fa-long-arrow-right {
    font-size: 20px;

    position: relative;
    top: 0;

    float: right;
}
.module-4b .linktype--external .fa-long-arrow-right:before {
    content: "\f08e";
}
.module-4b .linktype--modal .fa-long-arrow-right:before {
    content: "\f2d0";
}
.module-4b .linktype--video .fa-long-arrow-right:before {
    content: "\f01d";
}
.module-4b .linktype--document .fa-long-arrow-right:before {
    content: "\f016";
}

.module-4b:after,
.module-4b .wrapper:after {
    display: table;
    clear: both;

    content: "";
}

/*MODULE 4 CONNECT
*****************************************************************/

.module-4b .wrapper {
    height: auto;
    padding-bottom: 0;
}

body .page-wrap .module-4b .wrapper {
    margin-right: -3px;
    margin-left: -3px;
}

body .page-wrap .module-4b .wrapper .inner {
    position: relative;
    top: auto;
    left: auto;

    float: left;
    overflow: hidden;

    width: 50%;
    height: 0;
    padding-bottom: 50%;

    border: 3px solid #FFFFFF;
}

.module-4b .wrapper .inner .inner__content .inner__info a {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
}

.module-4b .wrapper .inner .inner__content .inner__info .link-name {
    font-weight: 700;

    position: relative;

    display: block;

    padding-left: 25px;

    text-decoration: none;
}

.module-4b .wrapper .inner .inner__content .inner__info .link-name .fa {
    font-size: 24px;

    position: absolute;
    top: -5px;
    left: 0;
}

.module-4b .wrapper .inner:nth-of-type(6) .inner__icon {
    border: 3px solid #FFFFFF;
}

.module-4b .social-block {
    margin: 0 -6px;
}

.module-4b .social-block li {
    margin: 3px 3px;

    border: 0;
}

.module-4b .social-block li a {
    font-size: 20px;

    width: 100%;
}


@media all and (min-width: 768px) {
    .module-4b .wrapper .inner .inner__content .inner__info .link-name {
        margin: 25px 0 20px;
        padding-left: 35px;
    }

    .module-4b .wrapper .inner .inner__content .inner__info .link-name .fa {
        font-size: 32px;

        top: -5px;
    }
}

@media all and (min-width: 992px) {
    body .page-wrap .module-4b .wrapper {
        margin-right: -5px;
        margin-bottom: 5px;
        margin-left: -5px;
    }

    body .page-wrap .module-4b .wrapper .inner {
        width: 25%;
        padding-bottom: 25%;

        border: 5px solid #FFFFFF;
    }

    .module-4b .wrapper .inner .inner__content .inner__info {
        font-size: 18px;
    }

    .module-4b .wrapper .inner:nth-child(4n+4) .inner__content .inner__info {
        padding-right: 70px;
    }

    .module-4b .wrapper .inner:nth-of-type(6) .inner__icon {
        border: 5px solid #FFFFFF;
    }

    // .module-4b .social-block {
    // 	position: absolute;
    // 	top: calc(50% + 81.5px);
    // 	transform: translateY(-50%);
    // 	right: 0;
    // 	z-index: 100;
    //   display: block;
    //   background: #fff;
    //   @media (min-width: $screen-lg) {
    //   	top: calc(50% + 103.5px);
    //   }
    // }

    .module-4b .social-block li {
        margin: 10px 5px 10px 0;
    }
    .module-4b .social-block li:last-child {
        margin-bottom: 5px;
    }

    .module-4b .social-block li a {
        width: 50px;
    }
}

@media all and (min-width: $screen-lg) {
    .module-4b .wrapper .inner .inner__content .inner__info .link-name {
        font-size: 18px;

        margin: 35px 0 30px;
        padding-left: 45px;
    }

    .module-4b .wrapper .inner .inner__content .inner__info .link-name .fa {
        font-size: 34px;

        top: -6px;
    }
}
