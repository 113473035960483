// General

.sports {
  background-color: #D6D6D6;

  .module-title {
    font-weight: 900;
  }

  .pagination-control {
    margin: 30px auto 0;

    .currentpage {
      border-color: $crest-black;
      background-color: $crest-black;
    }
  }
}

// Results

.sports--results {
  display: flex;
  flex-direction: column;

  padding: 50px 15px;

  @media(min-width: 992px) {
    padding: 50px 30px;
  }
}

.sports__results {
  width: 100%;
  // padding: 12px 0;

  &:nth-child(odd) {
    background-color: white;
  }

  ul {
    @media(min-width: 768px) {
      display: flex;
      justify-content: space-between;
    }
  }

  li {
    margin-bottom: 10px;
    padding: 0 15px;

    @media(min-width: 768px) {
      margin-bottom: 0;
    }
  }
  p {
    font-size: 24px;
    font-weight: 300;
    line-height: 1.5;
  }
}

.sports__results__date {
  font-size: 14px;
}

.sports__results__title {
  font-size: 14px;
  font-weight: 700;

  @media(min-width: 768px) {
    flex: 1 0 0;
  }
}

.sports__results__result {
  font-size: 14px;
}

.sports__results__link {
  font-size: 14px;

  a {
    color: $crest-red;
  }
}

// Fixtures

.sports--fixtures {
  padding: 50px 15px;
  @media(min-width:992px) {
    padding: 50px 30px;
  }

  p {
    font-size: 24px;
    font-weight: 300;
    line-height: 1.5;
  }
}

.sports__button__block {
  width: 100%;
  margin: 30px auto 0;

  .btn {
    font-size: 18px;

    margin-top: 12px;
  }

  .btn {
    min-width: 220px;
    margin: 0 5px 12px;
    padding-left: 15px;

    @media(min-width: 992px) {
      min-width: 200px;
    }

    @media(min-width: 1200px) {
      min-width: 220px;
    }
  }
}

// Filters

.sports__filters {
  display: flex;

  margin: 0 -1px 10px;

  @media(min-width: 768px) {
    margin: 0 -5px 30px;
  }

  .bootstrap-select.form-control>button {
    padding: 13px 10px;
  }

  .form-control {
    height: unset;

    background-color: unset;

    .btn {
      font-size: 14px;

      border-radius: 0;

      &:hover,
      &:focus {
        color: $crest-black;
      }
    }
  }

  .form-group {
    width: 100%;
    margin: 0 1px;

    @media(min-width: 768px) {
      margin: 0 5px;
    }

    @media(min-width: 992px) {
      width: 200px;
      margin: 0 5px;
    }
  }
}

#sportsResults_wrapper,
#sportsEvents_paginate {
  .paginate_button.current {
    border: 1px solid $crest-red;
    background: $crest-red;
    color: white !important;
  }
}
