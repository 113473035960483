.module-1 + .module-5 {
	//margin-top: 10px;
}

.module-5 {
	background-color: $tint-crest-white;
	@include spacer-p-t-b;
	.module-sub-text {
		border-bottom: 1px solid $limestone;
	}
	.no-subnavigation {
		.module-sub-text {
			border-bottom: none;
    		padding-bottom: 0;
		}
	}
	nav ul {
		display: block;
		flex-wrap: wrap;
		justify-content: space-between;
		@media (min-width: 500px) {
			display: flex;
		}
		li {
			display: flex;
			width: 100%;
			min-height: 50px;
			@media (min-width: 500px) {
				width: 48%;
			}
			a {
				position: relative;
				display: flex;
				width: 100%;
				align-items: center;
				border-bottom: 1px solid $limestone;
				color: $crest-black;
				padding-left: 25px;
				&:before {
					@include awesome;
					content: "\f105";
					position: absolute;
					top: 12px;
					left: 0;
					color: $crest-red;
				}
			}
		}
	}
}

//styling for inner pages

.content-wrap__wrapper {
	div.module-5 {
		margin: 30px 0;
		padding-top: 0;
		padding-bottom: 20px;
		div[class*="offset"] {
			margin-left: 0;
		}
		div[class*="col"] {
			width: 100%;
		}
		.module-title {
			margin: 15px 0 !important;
		}
		nav ul {
			margin: 0;
			padding: 0;
			li {
				margin: 0;
				padding: 0;
				&:before {
					display: none;
				}
			}
			a {
				text-decoration: none;
			}
		}
	}
}