
.az-group {
    display: inline-block;
    width: 100%;
    h2 {
    	margin: 20px 0 10px;
    	color: #cd202c;
    }
    ul {
    	margin: 0;
    	padding: 0;
    	li {
	    	margin: 0;
	    	padding: 0;
	    	&:before {
				content: "";
			}
	    }
	    &.row {
	    	margin-left: -15px;
	margin-right: -15px;
	    }
    }
    a {
    	display: inline-block;
    	//padding: 5px 0;
    	cursor: pointer;
      line-height: 1.24;
      margin-bottom: 15px;
    	&:hover {
    		text-decoration: underline;
    	}
    }
 }


@media all and (max-width: 767px) {

	.az-group:first-child h2 {
		margin-top: 0;
	}

}

@media all and (min-width: 768px) {

	.az-full-page {
		-webkit-column-count: 2;
	    -moz-column-count: 2;
	    column-count: 2;
	}

}

@media all and (min-width: 1200px) {

	.az-full-page {
		-webkit-column-count: 3;
	    -moz-column-count: 3;
	    column-count: 3;
	    h2 {
	    	font-size: 45px;
	    }
	    a {
	    	//padding: 10px 0;
	    }
	}

}
