//COLOURS

.module-29 {
    .bg-crest-red .main-heading,
    .bg-crest-red .sub-heading,
    .bg-crest-red .number {
        color: white;
        background-color: $crest-red;
    }
    // .bg-crest-red .inner .btn {
    //     background-color: $crest-white;
    //     color: $crest-black;
    //     .fa {
    //         color: $crest-red;
    //     }
    // }
    .slide--countdown-feature .bg-crest-red .btn,
    .slide--countdown-feature .bg-crest-red .countdown.countdown-feature .number {
        color: white;
        background-color: $crest-red;
    }
    .bg-crest-blue .main-heading,
    .bg-crest-blue .sub-heading,
    .bg-crest-blue .number,
    .slide--countdown-feature .bg-crest-blue .btn,
    .slide--countdown-feature .bg-crest-blue .countdown.countdown-feature .number {
        color: white;
        background-color: $crest-blue;
    }
    .bg-crest-yellow .main-heading,
    .bg-crest-yellow .sub-heading,
    .bg-crest-yellow .number,
    .slide--countdown-feature .bg-crest-yellow .btn,
    .slide--countdown-feature .bg-crest-yellow .countdown.countdown-feature .number {
        color: black;
        background-color: $crest-yellow;
        .fa {
            color: $crest-white;
        }
    }
    .bg-crest-yellow .btn--yellow {
        background-color: white;
        .fa {
            color: $crest-yellow;
        }
    }
    .bg-quad-green .main-heading,
    .bg-quad-green .sub-heading,
    .bg-quad-green .number,
    .slide--countdown-feature .bg-quad-green .btn,
    .slide--countdown-feature .bg-quad-green .countdown.countdown-feature .number {
        color: white;
        background-color: $quad-green;
    }
    .bg-dawn-mauve .main-heading,
    .bg-dawn-mauve .sub-heading,
    .bg-dawn-mauve .number,
    .slide--countdown-feature .bg-dawn-mauve .btn,
    .slide--countdown-feature .bg-dawn-mauve .countdown.countdown-feature .number {
        color: white;
        background-color: $dawn-mauve;
    }
    .bg-crest-white .main-heading,
    .bg-crest-white .sub-heading,
    .bg-crest-white .number,
    .slide--countdown-feature .bg-crest-white .btn,
    .slide--countdown-feature .bg-crest-white .countdown.countdown-feature .number {
        color: $crest-blue;
        background-color: white;
    }
    .bg-tint-crest-white .main-heading,
    .bg-tint-crest-white .sub-heading,
    .bg-tint-crest-white .number,
    .slide--countdown-feature .bg-tint-crest-white .btn,
    .slide--countdown-feature .bg-tint-crest-white .countdown.countdown-feature .number {
        color: $crest-blue;
        background-color: $tint-crest-white;
    }
    .bg-crest-black .main-heading,
    .bg-crest-black .sub-heading,
    .bg-crest-black .number,
    .slide--countdown-feature .bg-crest-black .btn,
    .slide--countdown-feature .bg-crest-black .countdown.countdown-feature .number {
        color: white;
        background-color: $crest-black;
    }
    .bg-crest-black .inner .btn {
        background-color: $crest-white;
        color: $crest-black;
        .fa {
            color: $crest-red;
        }
    }
    .bg-earth-red .main-heading,
    .bg-earth-red .sub-heading,
    .bg-earth-red .number,
    .slide--countdown-feature .bg-earth-red .btn,
    .slide--countdown-feature .bg-earth-red .countdown.countdown-feature .number {
        color: white;
        background-color: $earth-red;
    }
    .bg-earth-red .btn--yellow {
        background-color: white;
        .fa {
            color: $crest-yellow;
        }
    }
    .bg-ogham-stone .main-heading,
    .bg-ogham-stone .sub-heading,
    .bg-ogham-stone .number,
    .slide--countdown-feature .bg-ogham-stone .btn,
    .slide--countdown-feature .bg-ogham-stone .countdown.countdown-feature .number {
        color: white;
        background-color: $ogham-stone;
    }
    .bg-ogham-stone .btn--yellow {
        background-color: white;
        .fa {
            color: $crest-yellow;
        }
    }
    .bg-limestone .main-heading,
    .bg-limestone .sub-heading,
    .bg-limestone .number,
    .slide--countdown-feature .bg-limestone .btn,
    .slide--countdown-feature .bg-limestone .countdown.countdown-feature .number {
        color: $crest-black;
        background-color: $limestone;
    }
    .bg-sky-blue .main-heading,
    .bg-sky-blue .sub-heading,
    .bg-sky-blue .number,
    .slide--countdown-feature .bg-sky-blue .btn,
    .slide--countdown-feature .bg-sky-blue .countdown.countdown-feature .number {
        color: $crest-white;
        background-color: $sky-blue;
    }

    //these default to crest-blue
    .bg-brand-twitter .main-heading,
    .bg-brand-twitter .sub-heading,
    .bg-brand-twitter .number,
    .slide--countdown-feature .bg-brand-twitter .btn,
    .slide--countdown-feature .bg-brand-twitter .countdown.countdown-feature .number {
        color: white;
        background-color: $sky-blue;
    }
    .bg-brand-facebook .main-heading,
    .bg-brand-facebook .sub-heading,
    .bg-brand-facebook .number,
    .slide--countdown-feature .bg-brand-facebook .btn,
    .slide--countdown-feature .bg-brand-facebook .countdown.countdown-feature .number {
        color: white;
        background-color: $crest-blue;
    }
}

//MISC

.content-wrap__wrapper div.module-29,
.content-wrap__wrapper div.module-29--slider {
    padding: 0 !important;
}

.module-29 {
    border-bottom: 10px solid $crest-white;
    margin-bottom: 20px;
    .slider-progress {
        width: 100%;
        height: 5px;
        background: $crest-white;
        margin:0;
        position: absolute;
        z-index: 100;
        left: 0;
        right: 0;
        bottom: 0;
        .progress {
            width: 0%;
            height: 5px;
            background: $crest-yellow;
            margin:0;
        }
    }
    .slideShowSingle .slick-arrow {
		width: 40px;
		height: 45px;
		background: rgba(255,255,255,0.5);
		z-index: 1000;
		&:hover {
			background: #fff;
		}
		&.slick-arrow {
			&:before {
				font-family: FontAwesome;
				font-size: 40px;
				line-height: 25px;
				color: $crest-blue;
		}
		}
		&.slick-prev {
			left: 0;
			&:before {
				content:"\f104";
			}
		}
		&.slick-next {
			right: 0;
			&:before {
				content:"\f105";
			}
		}
	}
	@media (min-width: $screen-md) {
		.slideShowSingle .slick-arrow {
			width: 55px;
			height: 60px;
			&.slick-arrow {
				&:before {
					font-size: 60px;
					line-height: 35px;
				}
			}
		}
	}

    .slick-dots {
		margin: 0;
		padding: 0 5px 5px 5px !important;
		bottom: 20px;
		right: 15px;
	    width: auto;
	    background-color: rgba($crest-white,0.75);
	    li {
	    	margin: 3px !important;
	    	padding: 0;
	    	width: auto;
	    	height: auto;
	    }
		li:before {
			display: none;
		}
		button {
			padding: 0;
			width: 12px;
	    	height: 12px;
		}
		button:before {
			content: '';
			display: block;
			width: 12px;
			height: 12px;
			opacity: 1;
			background-color: $crest-yellow;
		}
		.slick-active button:before {
			background-color: $crest-red;
			opacity: 1;
		}
	}
}

.module-29 {
    .scroll-to-content {
        bottom: -22px;
        transform: translateX(-50%);
    }
}

.module-29 {
    position: relative;
    .slide-square {
        display: none;
    }

    &.module-29--box--active {
        .slide-square {
            display: flex;
            justify-content: center;
        }
    }
}

.slide--video .playIcon {
    position: absolute;
    z-index: 100;
    top: 30%;
    left: 50%;
    transform: translate(-50%,-50%);
    @media (min-width: 1170px) {
        top: 50%;
    }

    img {
        @media(min-width: 1440px) {
            width: 100px;
            height: 100px;
        }
    }
}

//SLIDER STYLES

.module-29 {
    .slideShowSingle {
        position: relative;
        margin-bottom: 0;
        picture img {
            width: 100%;
            height: auto;
            object-fit: cover;

        }
    }
    figcaption {
        overflow: hidden;
        padding: 0 15px 30px;
        br {
            display: none;
        }
        .main-heading {
            font-size: 25px;
            font-weight: 400;
            font-family: 'Playfair Display', serif;
        }
        .btn {
            margin-top: 10px;
        }
    }
    &:not(.module-29--box--active) {
        figcaption {
            @media (min-width: 1400px) {
                padding: 0;
                br {
                    //display: block;
                }
                .outer {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    top: 0;
                    right: 0;
                    z-index: 50;
                    display: flex;
                    align-items: center;
                    padding: 0 0 0 70px;
                    .inner {
                        display: flex;
                        flex-direction: column;
                    }
                    h1, h2, h3, h4, p {
                        width: auto;
                        padding: 5px 10px;
                        margin: 10px 0 0 0;
                        max-width: 40%;
                        display: inline-block;
                        align-self: flex-start;
                    }
                    a {
                        display: inline-block;
                        width: auto;
                        align-self: flex-start;
                    }
                    p {
                        max-width: 37%;
                        @media (max-width: $screen-md-min) {
                            max-width: none;
                        }
                    }
                }
            }
            @media (min-width: $screen-lg-min) {
                .outer {
                    padding: 0 0 0 140px;
                }
                .main-heading {
                    font-size: 36px;
                }
            }
            // @media (min-width: 1400px) {
            //     .main-heading {
            //         font-size: 46px;
            //     }
            // }
        }
    }
    &.module-29--box--active {
        .slideShowSingle {
            @media (min-width: 1170px) and (max-width: 1700px) {
                picture img {
                    min-height: 510px !important;
                }
            }
            @media (min-width: 1701px) {
                picture img {
                    min-height: 600px !important;
                }
            }

        }
        figcaption {
            @media (min-width: 1400px) {
                padding: 0;
                br {
                    //display: block;
                }
                .outer {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    top: 0;
                    right: 0;
                    z-index: 50;
                    display: flex;
                    align-items: flex-end;
                    padding: 0 0 40px 30px;
                    .inner {
                        display: flex;
                        flex-direction: column;
                    }
                    h1, h2, h3, h4, p {
                        //display: inline-block;
                        width: auto;
                        padding: 5px 10px;
                        // border-radius: 8px;
                        margin: 10px 0 0 0;
                        max-width: 45%;
                        display: inline-block;
                        align-self: flex-start;
                    }
                    p {
                        max-width: 40%;
                    }
                    a {
                        display: inline-block;
                        width: auto;
                        align-self: flex-start;
                    }
                }
                .inner {
                    margin: auto 0;
                    flex: 1;
                }
            }
            @media (min-width: $screen-lg-min) {
                .main-heading {
                    font-size: 36px;
                }
            }
            @media (min-width: 1280px) {
                .main-heading {
                    font-size: 36px;
                }
            }
        }
    }
}


//BOX ACTIVE

.module-29.module-29--box--active {
    .slideShowSingle figure > div {
        width: 100%;
    }
    .slideShowSingle {
        @media(min-width: 1600px) {
            width: calc(100% - 635px);
            margin-left: 635px;
        }
    }
}
.module-29 {
    .slide-square {
        @media(min-width: 1600px) {
            position: absolute;
            top: 0;
            width: 350px;
            height: 100%;
            margin: 0;
            border-right: 10px solid white;
        }
        @media(min-width: 1600px) {
            width: 635px;
        }
        * {
            max-width: 330px;
        }
        .slide-square__icon {
            @media (min-width: 1600px) {
                width: 30%;
                height: auto;
            }
        }
    }
}

//BOX ACTIVE LHS PAGES
.col-lg-9.content-wrap__wrapper {

    .module-29.module-29--box--active {
        .slideShowSingle figure > div {
            width: 100%;
        }
        .slideShowSingle {
            @media(min-width: 1170px) {
                width: 100%;
                margin: 0;
            }
        }
    }
    .module-29 {
        .slide-square {
            @media(min-width: 1600px) {
                position: static;
                top: 0;
                width: auto;
                height: auto;
                margin: 10px 0 0 0;
                border-right: 0;
            }
            * {
                max-width: 330px;
            }
            .slide-square__icon {
                @media (min-width: 1600px) {
                    width: 30%;
                    height: auto;
                }
            }
        }
    }

}


 //STYLES FOR SLIDER ON LHS PAGES

.col-lg-9.content-wrap__wrapper {
    .module-29 {
        padding: 0;
        .module-29--slider {
            padding: 0;
        }

        figcaption {
            @media (max-width: 1800px) {
                .outer {

                    h1, h2, h3, h4, p {

                        max-width: 100%;
                    }
                    p {
                        max-width: 100%;
                    }
                }
            }
        }
        .slide--video .playIcon {
            @media(min-width: 769px) {
                top: 20%;
            }
            @media(min-width: 1366px) {
                top: 30%;
            }
            @media(min-width: 1801px) {
                top: 50%;
            }
            img {
                @media(min-width: 769px) {
                    width: 70px;
                    height: 70px;
                }
                @media(min-width: 1801px) {
                    width: 70px;
                    height: 70px;
                }
            }
        }
        .slideShowSingle {
            .slide--countdown-feature {
                figcaption {
                    .outer {
                        @media (min-width: 1801px) {
                            p {
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
            figcaption {
                .outer {
                    @media (min-width: 1801px) {
                        padding: 30px;
                        h2, h3 {
                            max-width: 45%;
                        }
                        p {
                            max-width: 40%;
                        }
                    }
                }
                .inner {
                    @media (min-width: 1024px) and (max-width: 1700px) {
                        //display: flex;
                        //align-items: flex-start;
                    }
                }
            }
            .slide--countdown-feature {
                figcaption {
                    .outer {
                        @media (min-width: 1801px) {
                            h2, h3 {
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
            @media (min-width: 992px) and (max-width: 1170px) {
                picture img {
                    height: 300px !important;
                }
            }
        }
        @media (max-width: 1800px) {
            figcaption {
                overflow: hidden;
                padding: 0 15px 30px;
                br {
                    display: none;
                }
                .main-heading {
                    font-size: 25px;
                    font-weight: 400;
                }
                .btn {
                    margin-top: 10px;
                }
                .outer {
                    position: static;
                    padding: 0;
                    width: auto;
                }
            }
        }
        &.module-29--box--active {
            .slideShowSingle {
                @media (min-width: 1170px) and (max-width: 1700px) {
                    picture img {
                        min-height: auto !important;
                    }
                }
                @media (min-width: 1701px) {
                    picture img {
                        min-height: auto !important;
                    }
                }

            }
            @media (max-width: 1700px) {
                figcaption {
                    overflow: hidden;
                    padding: 0 15px 30px;
                    br {
                        display: none;
                    }
                    .main-heading {
                        font-size: 25px;
                        font-weight: 400;
                    }
                    .btn {
                        margin-top: 10px;
                    }
                    .outer {
                        position: static;
                        padding: 0;
                        width: auto;
                    }
                }
            }
        }
    }
}


//COUNTDOWN

.module-29 {
    .slide--countdown-feature {
        @media (min-width: 1170px) and (max-width: 1900px) {
            picture img {
                min-height: 480px !important;
            }
        }

        figcaption {
            .outer {
                padding: 0;
                align-items: center;
            }
            padding: 0 0 !important;
            left: 0;
            .outer {
                width: 100% !important;
            }
            .inner {
                background-color: rgba($crest-white,1);
                text-align: center;
                padding: 15px;
                @media (min-width: 1170px) {
                    background-color: rgba($crest-white,0.9);
                    padding: 25px 30px 30px;
                    max-width: 700px;
                    margin: 0 auto;
                }
                .main-heading {
                    color: $crest-black !important;
                    background: transparent !important;
                    display: block;
                    width: 100%;
                    max-width: 100%;
                    margin: 0;
                    padding: 0;
                    font-size: 30px;
                    @media (min-width: $screen-md-min) {
                        font-size: 25px;
                    }
                    @media (min-width: $screen-lg-min) {
                        font-size: 36px;
                    }
                }
                .sub-heading {
                    color: $crest-black !important;
                    background: transparent !important;
                    display: block;
                    width: 100%;
                    max-width: 100%;
                    margin: 5px 0 15px;
                    padding: 0;
                    font-size: 14px;
                    @media (min-width: $screen-md-min) {
                        font-size: 16px;
                        margin: 10px 0 20px;
                    }
                }
                .btn {
                    margin: 5px auto;
                    min-height: 46px;
                    @media (min-width: $screen-sm-min) {
                        font-size: 18px;
                        padding: 14px 24px;
                    }
                }
                br {
                    display: none;
                }
            }
        }
        .countdown {
            &.countdown-feature {
                .countdown__timer {
                    @include clearfix;
                    width: 100%;
                    max-width: 300px;
                    margin: 10px auto;
                    @media (min-width: $screen-sm-min) {
                        max-width: 400px;
                    }
                    @media (min-width: $screen-md-min) {
                        max-width: 580px;
                    }
                }
                .countdown__number {
                    width: 25%;
                    float: left;
                    padding: 0 2px;
                    @media (min-width: $screen-sm-min) {
                        padding: 0 5px;
                    }
                }
                .number {
                    display: block;
                    width: 100%;
                    text-align: center;
                    // background: $crest-black;
                    background: $crest-red;
                    color: $crest-white;
                    font-weight: 700;
                    font-size: 26px;
                    line-height: 1;
                    padding: 14px 0;
                    @media (min-width: 360px) {
                        font-size: 30px;
                        padding: 18px 0;
                    }
                    @media (min-width: $screen-sm-min) {
                        font-size: 40px;
                        padding: 20px 0;
                    }
                    @media (min-width: $screen-lg-min) {
                        font-size: 50px;
                    }
                }
                .label {
                    display: block;
                    width: 100%;
                    text-align: left;
                    text-transform: uppercase;
                    font-weight: 400;
                    font-size: 10px;
                    color: $crest-black;
                    margin: 7px 10px 0 0;
                    padding: 0;
                    @media (min-width: $screen-sm-min) {
                        font-size: 14px;
                    }
                }
                .date {
                    display: block;
                    font-size: 14px;
                    margin: 15px 0;
                    padding: 0;
                    text-align: center;
                    color: $crest-black;
                    width: 100%;
                    margin-left: auto;
                    margin-right: auto;
                    
                }
            }
        }
    }
}

.col-lg-9.content-wrap__wrapper {
    .slide--countdown-feature {
        figcaption {
            background-color: rgba($crest-white,1);
            text-align: center;
            padding: 15px;
            @media (min-width: 1170px) {
                background-color: rgba($crest-white,0.9);
            }
        }
    }
}

.module-29 {
    &:not(.module-29--box--active) {
        figcaption {
            @media (min-width: $screen-md-min) {
                .inner {
                    width: calc(100% - 55px);
                }
                .outer {
                    h1, h2, h3, h4, p {
                        //display: inline-block;
                        //max-width: 100%;

                    }
                }
            }
        }
    }
}
