//all styles to go within body.ucc-theme--fossil-heritage

//Font
// @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,600;0,700;0,800;1,400&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,600;0,700;0,800;1,400;&family=Roboto:ital,wght@0,300;0,400;0,500;0,700&display=swap");

//Colors
$nptswi-secondary: #00C690;
$plrg-green: #02CF52;
$plrg-green: #032E3E;
$nptswi-yellow: #FFE061;
$plrg-green: #426630;
$plrg-green-tint: #F2F5F0;
$plrg-purple: #62408A;
$plrg-purple-tint: #F5F2F7;
$plrg-border-radius: 10px;

body.ucc-theme--plrg {
    font-family: "Roboto",
    sans-serif !important;

    .table {
        th {
            padding-top: 10px;
            padding-bottom: 10px;

            color: $plrg-green;
            background-color: $plrg-green-tint;
        }
    }

    // color: $plrg-green;

    .left-side-nav .left-side-nav__nav li a:hover,
    .left-side-nav .left-side-nav__nav li a:hover a,
    .left-side-nav .left-side-nav__nav li a.current-active,
    .left-side-nav .left-side-nav__nav li span:hover,
    .left-side-nav .left-side-nav__nav li span:hover a,
    .left-side-nav .left-side-nav__nav li span.current-active,
    div.content-wrap__wrapper .left-side-nav .left-side-nav__nav li a:hover,
    div.content-wrap__wrapper .left-side-nav .left-side-nav__nav li a:hover a,
    div.content-wrap__wrapper .left-side-nav .left-side-nav__nav li a.current-active,
    div.content-wrap__wrapper .left-side-nav .left-side-nav__nav li span:hover,
    div.content-wrap__wrapper .left-side-nav .left-side-nav__nav li span:hover a,
    div.content-wrap__wrapper .left-side-nav .left-side-nav__nav li span.current-active,
    .left-side-nav .left-side-nav__nav li .currentbranch0,
    div.content-wrap__wrapper .left-side-nav .left-side-nav__nav li .currentbranch0 {
        background-color: $plrg-purple;
    }

    .module-wrap.left-nav,
    .module-wrap.rhs {
        background-color: #FFFFFF;
    }

    .scroll-to-content {
        color: $plrg-purple;
        border-color: $plrg-purple;

        &:hover {
            color: white;
            background-color: $plrg-purple;
        }
    }

    &.has-header-carousel.has-image.has-headline .page-wrap,
    &.has-headline .page-wrap {
        margin-top: 130px;

        @media all and (min-width: 992px) {
            margin-top: 173px;
        }

        @media all and (min-width: 1200px) {
            margin-top: 200px;
        }
    }

    .btn.linktype--external i {
        &::before {
            content: "\f178";
        }
    }

    &.has-image.has-headline .navbar__club__logo > a.inner {
        position: relative;
        top: auto;
        left: auto;

        justify-content: flex-start;

        text-align: left;

        @media all and (max-width: 767px) {
            max-width: 200px;
        }
    }

    .top-nav .bg-crest-yellow,
    .top-nav li.header-custom-button-1 {
        span {
            color: black !important;
        }
    }

    .col-lg-9.content-wrap__wrapper .module-29 .slide--countdown-feature figcaption .inner {
        @media all and (max-width: 1800px) {
            background-color: transparent;
        }
    }

    .col-lg-9.content-wrap__wrapper .module-29 {
        .countdown.countdown-mini {
            top: 0;

            background: white;
        }

        .slide--countdown-feature {
            .countdown__timer {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .countdown.countdown-feature .countdown__number {
                width: 62px;
            }

            .countdown__number {
                .label {
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 12px;
                }

                .number {
                    font-size: 30px;
                    font-weight: 700;
                    line-height: 35px;

                    background: white;
                }
            }

            .sub-heading {
                color: $plrg-green !important;
            }

            .main-heading {
                font-size: 30px;

                padding-top: 0 !important;
                padding-bottom: 0 !important;

                color: $plrg-green !important;
            }

            .date {
                margin-top: 0 !important;

                color: $plrg-green !important;
            }

            figcaption .outer p {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        .slide--countdown-feature figcaption .inner {
            padding: 10px;
        }

        .slide-square .slide-square__icon {
            img {
                max-width: 100px;
            }
        }
    }

    .bg-crest-black,
    .bg-crest-red,
    .bg-crest-blue,
    .bg-crest-yellow,
    .bg-sky-blue,
    .bg-quad-green,
    .bg-earth-red,
    .bg-ogham-stone,
    .bg-dawn-mauve,
    .bg-limestone,
    .bg-brand-twitter,
    .bg-brand-facebook,
    .bg-brand-instagram,
    .bg-brand-youtube,
    .bg-brand-linkedin {
        background-color: $plrg-green;
    }

    .module-title {
        color: $crest-black;
    }

    .btn {
        font-family: "Roboto";
        font-weight: 500;
        font-style: normal;

        border-radius: $plrg-border-radius;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: "Roboto";
        font-weight: 300;
        font-style: normal;
    }

    &.has-image.has-headline .navbar__club__logo > a.inner {
        background: transparent;
    }

    /////////////////////////////////////
    // global nptswi styles
    /////////////////////////////////////


    .people-by-overall-category {
        .module18 {
            .az-people {
                .az-group {
                    figure {
                        figcaption {
                            span {
                                i {
                                    color: $plrg-green;
                                }
                            }
                        }
                    }

                    .btn.btn-block {
                        // font-weight: 700;
                        max-width: inherit;
                        margin-bottom: 0;

                        color: #FFFFFF;
                        background-color: $plrg-green;

                        &:hover {
                            transform: inherit;

                            color: #FFFFFF;
                            background-color: $plrg-green;
                        }
                    }
                }
            }
        }
    }


    .collapse#collapseExample {
        li a:hover,
        li span {
            background-color: $plrg-purple !important;
        }
    }

    .content-wrap__inner {
        .breadcrumb {
            font-family: "Roboto", sans-serif;
        }
    }

    .search-wrapper {
        border: none !important;
        background-color: $plrg-green;

        .mega-close {
            width: 40px;
            height: 40px;

            background-color: rgba(0, 0, 0, 0);

            &:before {
                font-size: 20px;

                top: -5px;

                width: 40px;
                height: 40px;
            }
        }

        form {
            input {
                border: none;
            }

            .input-group {
                .input-group-addon {
                    border: none !important;
                    background-color: $plrg-green !important;

                    span {
                        color: #FFFFFF;
                    }
                }
            }
        }

        & + .colorbox {
            .bg-crest-red {
                background-color: #62408A;
            }

            .bg-crest-yellow {
                background-color: rgba(#62408A, 0.5);
            }

            .bg-crest-blue {
                background-color: #426630;
            }
        }
    }

    .remodal-arrow,
    .remodal-close {
        color: #FFFFFF;
        background-color: $plrg-green;

        &:hover {
            color: #FFFFFF;
            background-color: $plrg-green;
        }
    }

    .hyper-list-bullet--horizontal.build-expore-list {
        .collapse.in {
            padding: 15px !important;

            li {
                margin-bottom: 0 !important;
                padding-bottom: 0 !important;
            }
        }
    }

    &.has-header-carousel .page-wrap {
        @media all and (max-width: 768px) {
        }

        @media all and (max-width: 600px) {
        }
    }

    #header {
        color: $plrg-green;
        background-color: white;

        .top-nav {
            margin-top: 13px;
        }

        @media all and (max-width: 768px) {
            .top-nav {
            }

            .navbar-carousel {
            }
        }

        .ucc-class {
            h1 {
                font-size: 2.604vw;
                font-weight: 300;
                line-height: 110%;

                width: 100%;
                max-width: none;

                color: $plrg-green;

                @media all and (max-width: 768px) {
                    text-transform: inherit;
                }

                @media all and (max-width: 600px) {
                    font-size: 18px;
                    font-weight: 300;
                    line-height: 100%;

                    max-width: 237px;
                }
            }
        }

        .top-nav {
            @media all and (max-width: 1200px) {
                position: relative;

                .bg-crest-white,
                .bg-tint-crest-white,
                .bg-crest-black,
                .bg-crest-red,
                .bg-crest-blue,
                .bg-crest-yellow,
                .bg-sky-blue,
                .bg-quad-green,
                .bg-earth-red,
                .bg-ogham-stone,
                .bg-dawn-mauve,
                .bg-limestone,
                .bg-brand-twitter,
                .bg-brand-facebook,
                .bg-brand-instagram,
                .bg-brand-youtube,
                .bg-brand-linkedin {
                    overflow: hidden;
                }
            }

            .search.satellite-search-toggle {
                @media all and (max-width: 1200px) {
                    padding-left: 0;

                    i {
                        font-size: 18px;
                    }

                    span {
                        font-size: 12px;
                    }
                }
            }

            // .ucc-navigation-toggle {
            //     background-color: $plrg-green;

            //     svg {
            //         path {
            //             fill: #FFFFFF;
            //         }
            //     }
            // }

            .bg-crest-white,
            .bg-tint-crest-white,
            .bg-crest-black,
            .bg-crest-red,
            .bg-crest-blue,
            .bg-crest-yellow,
            .bg-sky-blue,
            .bg-quad-green,
            .bg-earth-red,
            .bg-ogham-stone,
            .bg-dawn-mauve,
            .bg-limestone,
            .bg-brand-twitter,
            .bg-brand-facebook,
            .bg-brand-instagram,
            .bg-brand-youtube,
            .bg-brand-linkedin {
                padding: 0;

                background-color: inherit;

                a {
                    display: block;

                    width: 140px;
                    padding: 5px;

                    background-color: #FFB500;

                    @media all and (max-width: 1200px) {
                        background-color: inherit;

                        i {
                            font-size: 18px;
                        }

                        span {
                            font-size: 12px;

                            color: #FFFFFF;
                        }
                    }

                    i {
                        vertical-align: -4px;
                    }
                }
            }
        }

        .navbar-carousel {
            @media all and (max-width: 1200px) {
                bottom: -141px;

                background-color: #032E3E;

                .slick-prev,
                .slick-next {
                    height: 40px;
                }
            }

            @media (max-width: 991px) {
                width: 260px;
            }

            @media (max-width: 768px) {
                width: 100%;
            }

            .slick-slide {
                img {
                    padding: 10px;

                    background-color: #FFFFFF;

                    @media all and (max-width: 1200px) {
                        padding: 3px;
                    }
                }
            }

            .slick-arrow:before,
            .slick-arrow:after {
                color: #FFFFFF;
            }
        }
    }

    .module-wrap:not(.course) .hyper-list-wrap.hyper-list-bullet ol li a,
    .module-wrap:not(.course) .hyper-list-wrap.hyper-list-bullet ol li span {
        color: $crest-black;
    }

    .module-wrap:not(.course) .hyper-list-btn ol a {
        color: $crest-black;
    }

    .module-wrap {
        .content-wrap__wrapper {
        }

        .hyper-list-btn {
            a {
                &:hover {
                    color: #FFFFFF;
                    background-color: $plrg-purple;
                }
            }
        }

        .left-side-nav {
            @media all and (max-width: 991px) {
                button {
                    background-color: $plrg-green;
                }
            }
        }

        .hyper-list-wrap {
            &.hyper-list-bullet {
                a {
                    &:before {
                        background-color: $plrg-purple !important;
                    }
                }
            }
        }
    }

    #back-to-top {
        background-color: $plrg-green;
    }

    //////////////////////////////////////
    //header
    //////////////////////////////////////

    // .explore-toggle {
    //     display: none;
    // }


    // .ucc--header .top-nav {
    //     right: 0;
    // }


    //////////////////////////////////////
    //ucc satellite site nav
    //////////////////////////////////////



    /////////////////////////////////////
    // MODULE 1
    /////////////////////////////////////

    .content-wrap__inner .module-1,
    .module-1 {
        .bg-crest-black,
        .bg-crest-red,
        .bg-crest-blue,
        .bg-crest-yellow,
        .bg-sky-blue,
        .bg-quad-green,
        .bg-earth-red,
        .bg-ogham-stone,
        .bg-dawn-mauve,
        .bg-limestone,
        .bg-brand-twitter,
        .bg-brand-facebook,
        .bg-brand-instagram,
        .bg-brand-youtube,
        .bg-brand-linkedin {
            .main-heading {
                // font-size: 36px;
                font-size: 46px;
                font-weight: 300;
                line-height: 120%;
            }

            .sub-heading {
                font-size: 18px;
                font-weight: 400;
                line-height: 21px;

                margin-bottom: 10px;
            }

            .main-heading,
            .sub-heading {
                font-family: "Roboto";

                color: $plrg-green !important;
                border-radius: 10px;
                background-color: white !important;
            }
        }
    }

    .module-1 .slideShowSingle .slick-arrow.slick-arrow::before {
        color: $plrg-purple;
    }

    /////////////////////////////////////
    // MODULE 2
    /////////////////////////////////////

    .module-2 {
        background-color: $plrg-purple-tint;

        .inner {
            &.bg-crest-white,
            &.bg-tint-crest-white,
            &.bg-crest-black,
            &.bg-crest-red,
            &.bg-crest-blue,
            &.bg-crest-yellow,
            &.bg-sky-blue,
            &.bg-quad-green,
            &.bg-earth-red,
            &.bg-ogham-stone,
            &.bg-dawn-mauve,
            &.bg-limestone,
            &.bg-brand-twitter,
            &.bg-brand-facebook,
            &.bg-brand-instagram,
            &.bg-brand-youtube,
            &.bg-brand-linkedin {
                background-color: $plrg-purple;
                box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);

                img {
                    max-width: 70px;
                }

                & + h2 {
                    color: $plrg-purple;

                    i {
                        color: $plrg-purple;
                    }
                }

                &:after {
                    background-color: white;
                }
            }
        }
    }

    &.ucc-satellite .module-wrap:not(.course) .hyper-list-wrap,
    &.ucc-satellite .module-wrap:not(.course) .hyper-list-wrap button {
        color: $crest-black;
        background-color: $plrg-purple-tint;
    }

    .module-wrap:not(.course) .hyper-list-btn ol a {
        border-radius: $plrg-border-radius;
    }

    .module-wrap:not(.course) .hyper-list-wrap .btn--collapse-page-nav::after {
        color: $plrg-purple;
    }

    .hyper-list-container {
        .hyper-list-wrap {
            background-color: $plrg-purple-tint !important;

            h2 {
                font-weight: 900 !important;

                color: $crest-black !important;
            }

            ol {
                li {
                    a {
                        color: $crest-black !important;
                    }
                }
            }
        }
    }

    /////////////////////////////////////
    // MODULE 3b
    /////////////////////////////////////



    .module-3b .inner a .inner__content {
        background: none !important;
    }

    .module-3b {
        .inner {
            // a:hover {
            //     .inner__content {
            //         background-color: none !important;
            //     }
            // }

            .inner__content {
                h3 {
                    color: $crest-black;
                }

                .date {
                    color: $plrg-green;
                    background-color: #FFFFFF;
                }
            }
        }

        .btn {
            // font-weight: 700;
            color: #FFFFFF;
            background-color: $plrg-purple;

            i {
                color: #FFFFFF;
            }
        }
    }

    .module-3c {
        .btn {
            color: white;
            background-color: $plrg-purple;

            i,
            .fa {
                color: white;
            }
        }
    }


    /////////////////////////////////////
    // MODULE 4
    /////////////////////////////////////


    &.inner {
    }

    .module-4 .wrapper .inner .inner__wrap > .inner__icon p {
        font-size: 20px;
        font-weight: 700;
        line-height: 120%;
    }

    .module-4 {
        .module-title {
            // font-weight: 800;
        }

        .inner__wrap {
            .inner__icon {
                p {
                    color: $plrg-green !important;
                }
            }

            .inner__content {
                .inner__info {
                    background-color: rgba($tint-crest-white, 0.9) !important;

                    p,
                    .link-name {
                        color: $plrg-purple;
                    }
                }
            }
        }

        .inner__img {
            background-color: $tint-crest-white;
        }
    }

    /////////////////////////////////////
    // MODULE 5
    /////////////////////////////////////
    .module-5 nav ul li a:before {
        columns: $plrg-green;
    }

    /////////////////////////////////////
    // MODULE 23
    /////////////////////////////////////
    .module-23 a .caption {
        color: $crest-black;
        text-shadow: none;
    }

    .module-23 a .image:before {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 40.18%, rgba(255, 255, 255, 0.8) 72.03%, #FFFFFF 100%);
    }

    .module-23 a .image::after {
        color: $crest-black;
    }

    .module-23 .slick-arrow::before {
        color: $plrg-purple;
    }

    .module-23 {
        .item-wrap > div:nth-child(3n+3) .square {
            background: url(/en/media/academic/obstetricsandgynaecology/icon-sunset.svg);
            background-repeat: no-repeat;
            background-size: contain;
        }

        .item-wrap:nth-child(3n+3) > div:nth-child(3n+3) .square,
        .item-wrap:nth-child(3n+2) > div:nth-child(3n+3) .square {
            background: url(/en/media/academic/obstetricsandgynaecology/icon-flower.svg);
            background-repeat: no-repeat;
            background-size: contain;
        }

        .item-wrap > div:nth-child(3n+2) .square {
            background: url(/en/media/academic/obstetricsandgynaecology/icon-tree.svg);
            background-repeat: no-repeat;
            background-size: contain;
        }

        .item-wrap:nth-child(3n+2) .square {
            background: url(/en/media/academic/obstetricsandgynaecology/icon-sunset.svg);
            background-repeat: no-repeat;
            background-size: contain;
        }


        .slick-track > div:nth-child(3n+3) .square {
            background: url(/en/media/academic/obstetricsandgynaecology/icon-sunset.svg);
            background-repeat: no-repeat;
            background-size: contain;
        }

        .slick-track:nth-child(3n+3) > div:nth-child(3n+3) .square,
        .slick-track:nth-child(3n+2) > div:nth-child(3n+3) .square {
            background: url(/en/media/academic/obstetricsandgynaecology/icon-flower.svg);
            background-repeat: no-repeat;
            background-size: contain;
        }

        .slick-track > div:nth-child(3n+2) .square {
            background: url(/en/media/academic/obstetricsandgynaecology/icon-tree.svg);
            background-repeat: no-repeat;
            background-size: contain;
        }

        .slick-track:nth-child(3n+2) .square {
            background: url(/en/media/academic/obstetricsandgynaecology/icon-sunset.svg);
            background-repeat: no-repeat;
            background-size: contain;
        }

        .slick-dots {
            li {
                button {
                    &:before {
                        color: $plrg-purple;
                    }
                }

                &.slick-active {
                    button {
                        &:before {
                            color: $plrg-green;
                        }
                    }
                }
            }
        }

        // .item-wrap {
        //     &:nth-child(3n+2),
        //     &:nth-child(3n+3) {
        //         & > div:nth-child(3n+2) {
        //             .square {
        //                 width: 22.3%;
        //                 padding-bottom: 22.3%;

        //                 background-color: $plrg-green  !important;
        //             }
        //         }
        //     }
        // }
    }



    /////////////////////////////////////
    // MODULE 24
    /////////////////////////////////////



    /////////////////////////////////////
    // MODULE 7
    /////////////////////////////////////

    &.inner {
        .module-7 {
            .slideShowFact {
                .wrap {
                    .content {
                        & > div {
                            h2 {
                                font-size: 28px;
                            }
                        }
                    }
                }
            }
        }
    }

    .module-7 {
        max-width: inherit;

        .module-title {
            // font-weight: 800;
        }

        .slick-slide {
            position: relative;

            // &:after {
            //     position: absolute;
            //     right: 0;
            //     bottom: 0;

            //     content: url("/en/media/2017siteassets/contentfiles/images/nptswi/module-7-slide-after.png");

            //     @media all and (max-width: 1200px) {
            //         max-width: 180px;
            //     }
            // }
        }

        .content {
            position: relative;


            .btn {
                color: #FFFFFF;
                background-color: $plrg-purple;

                i {
                    color: #FFFFFF;
                }
            }
        }

        .slideShowFact {
            .slick-prev,
            .slick-next {
                &:before {
                    color: $plrg-purple !important;
                }
            }

            &.bg-crest-white .bg-img,
            &.bg-tint-crest-white .bg-img,
            &.bg-crest-black .bg-img,
            &.bg-crest-red .bg-img,
            &.bg-crest-blue .bg-img,
            &.bg-crest-yellow .bg-img,
            &.bg-sky-blue .bg-img,
            &.bg-quad-green .bg-img,
            &.bg-earth-red .bg-img,
            &.bg-ogham-stone .bg-img,
            &.bg-dawn-mauve .bg-img,
            &.bg-limestone .bg-img,
            &.bg-brand-twitter .bg-img,
            &.bg-brand-facebook .bg-img,
            &.bg-brand-instagram .bg-img,
            &.bg-brand-youtube .bg-img,
            &.bg-brand-linkedin .bg-img {
                &:before {
                    border-top-color: $plrg-green-tint !important;
                }
            }

            &.bg-crest-white,
            &.bg-tint-crest-white,
            &.bg-crest-black,
            &.bg-crest-red,
            &.bg-crest-blue,
            &.bg-crest-yellow,
            &.bg-sky-blue,
            &.bg-quad-green,
            &.bg-earth-red,
            &.bg-ogham-stone,
            &.bg-dawn-mauve,
            &.bg-limestone,
            &.bg-brand-twitter,
            &.bg-brand-facebook,
            &.bg-brand-instagram,
            &.bg-brand-youtube,
            &.bg-brand-linkedin {
                h2,
                p {
                    font-family: "Roboto";
                    font-weight: 300;

                    color: $plrg-green;
                }
            }
        }

        .slick-slide {
            background-color: $plrg-green-tint;

            .content {
                &:before {
                    border-left-color: $plrg-green-tint;
                }
            }
        }
    }



    /////////////////////////////////////
    // MODULE 8
    /////////////////////////////////////

    .module-8 .slideShowSingle figure figcaption .outer blockquote,
    .module-8 .slideShowSingle figure figcaption .outer h3 {
        font-family: "Roboto";

        color: $crest-black;
    }

    .module-8 {
        .module-title {
            // font-weight: 800;
        }

        .btn {
            font-size: 16px;
            line-height: 24px;

            min-width: 210px !important;
            padding: 10px 20px;

            @media all and (min-width: 1440px) {
                padding: 12px 39px;
            }
        }

        .slideShowSingle {
            &::before {
                position: absolute;
                bottom: 0;
                left: -220px;

                width: 200px;
                height: 200px;

                content: "";

                background-image: url(/en/media/academic/obstetricsandgynaecology/m8.svg);
            }

            .youtubevideo {
                &:before {
                    font-size: 96px;

                    color: $plrg-purple;
                }
            }
        }

        .slideShowSingle__nav {
            .outer {
                .inner {
                    div {
                        transition: 0.4s;

                        border: 5px solid $plrg-green;
                    }
                }

                &.slick-current {
                    .inner {
                        div {
                            border: 5px solid $plrg-purple;
                        }
                    }
                }
            }
        }

        .slick-slide {
            .outer {
                blockquote {
                    font-weight: 400;
                    font-style: italic;

                    &:before,
                    &:after {
                        color: $plrg-green;
                    }
                }

                h3 {
                    // font-weight: 700;

                    & + p {
                        font-weight: 400;

                        color: $crest-black;
                    }
                }

                .btn {
                    color: white;
                    background-color: $plrg-purple;

                    i {
                        color: white;
                        // color: $plrg-purple;
                    }
                }
            }
        }
    }



    /////////////////////////////////////
    // MODULE 9
    /////////////////////////////////////

    .module-9 .slideShowMultiple .multiple-wrap a .overlay h2 {
        font-family: "Roboto";
    }

    .module-9 {
        .module-title {
            // font-weight: 800;
        }

        .slick-arrow {
            background-color: #FFFFFF;

            &:before {
                color: $plrg-green;
            }

            &:hover {
                background-color: $plrg-green;

                &:before {
                    color: #FFFFFF;
                }
            }
        }

        .multiple-wrap {
            position: relative;

            &:nth-child(1),
            &:nth-child(4),
            &:nth-child(7) {
                &:after {
                    position: absolute;
                    right: 0;
                    bottom: 0;

                    content: url("");
                }
            }

            // &:nth-child(2),
            // &:nth-child(5),
            // &:nth-child(8) {
            //     &:after {
            //         position: absolute;
            //         top: 0;
            //         right: 0;

            //         content: url("/en/media/2017siteassets/contentfiles/images/nptswi/module-9-multiple-wrap-after-a.png");
            //     }
            // }

            // &:nth-child(3),
            // &:nth-child(6),
            // &:nth-child(9) {
            //     &:after {
            //         position: absolute;
            //         top: 0;
            //         left: 0;

            //         content: url("/en/media/2017siteassets/contentfiles/images/nptswi/module-9-multiple-wrap-after-b.png");
            //     }
            // }

            .overlay:not([class*="bg-"]):not([class*="bg-"]) {
                background-color: $plrg-green-tint !important;

                &:after {
                    color: $plrg-green !important;
                }

                h2 {
                    // font-weight: 800;
                    color: $plrg-green !important;

                    i {
                        color: $plrg-green !important;
                    }
                }

                p {
                    font-family: "Roboto", sans-serif;

                    color: $plrg-green !important;
                }
            }

            &:nth-child(odd) {
                color: $plrg-green !important;
                background-color: $plrg-green-tint !important;
            }

            &:nth-child(even) {
                .overlay:not([class*="bg-"]):not([class*="bg-"]) {
                    opacity: 1;
                    background-color: #FFFFFF !important;

                    &:after {
                        color: $plrg-purple !important;
                    }

                    h2 {
                        // font-weight: 800;
                        color: $plrg-purple !important;

                        i {
                            color: $plrg-purple !important;
                        }
                    }

                    p {
                        font-family: "Roboto", sans-serif;

                        color: $plrg-purple !important;
                    }


                    h2,
                    p,
                    &:after {
                        color: $plrg-purple !important;
                    }
                }
            }
        }
    }



    /////////////////////////////////////
    // MODULE 9b
    /////////////////////////////////////
    &.home {
        .module-9b {
            position: relative;

            &:before {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 100px;

                width: 200px;
                height: 200px;

                content: "";

                background: url(/en/media/academic/obstetricsandgynaecology/icon-tree.svg);
                background-size: 100%;
            }
        }
    }

    .module-9b {
        .module-title {
            // font-weight: 800;
        }

        .functionalBox {
            position: relative;

            // &:nth-child(1),
            // &:nth-child(4),
            // &:nth-child(7) {
            //     &:after {
            //         position: absolute;
            //         right: 0;
            //         bottom: 0;

            //         content: url("/en/media/2017siteassets/contentfiles/images/nptswi/module-9b-functionalBox-after-a.png");
            //     }
            // }

            // &:nth-child(2),
            // &:nth-child(5),
            // &:nth-child(8) {
            //     &:after {
            //         position: absolute;
            //         top: 0;
            //         right: 0;

            //         content: url("/en/media/2017siteassets/contentfiles/images/nptswi/module-9b-functionalBox-after-b.png");
            //     }
            // }

            // &:nth-child(3),
            // &:nth-child(6),
            // &:nth-child(9) {
            //     &:after {
            //         position: absolute;
            //         top: 0;
            //         left: 0;

            //         content: url("/en/media/2017siteassets/contentfiles/images/nptswi/module-9b-functionalBox-after-c.png");
            //     }
            // }

            .overlay:not([class*="bg-"]):not([class*="bg-"]) {
                z-index: 100;

                background-color: $plrg-green-tint;

                h2,
                p,
                &:after {
                    color: $plrg-green;
                }

                h2 {
                    font-family: "Roboto", sans-serif;
                    // font-weight: 800;
                }

                p {
                    font-family: "Roboto", sans-serif;
                }
            }

            &:nth-child(2),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(7),
            &:nth-child(10),
            &:nth-child(12) {
                .overlay:not([class*="bg-"]) {
                    opacity: 1;
                    background-color: #FFFFFF;

                    h2,
                    p,
                    &:after {
                        color: $plrg-purple;
                    }
                }
            }
        }
    }

    .content-wrap__inner {
        .module-9b {
            .multiple-wrap {
                .functionalBox {
                    @media all and (max-width: 767px) {
                        &:nth-child(odd) {
                            .overlay:not([class*="bg-"]):not([class*="bg-"]) {
                                background-color: #F2F5F0;

                                &::after,
                                h2,
                                p {
                                    color: $plrg-green;
                                }
                            }
                        }

                        &:nth-child(even) {
                            .overlay:not([class*="bg-"]):not([class*="bg-"]) {
                                background-color: #FFFFFF;

                                &::after,
                                h2,
                                p {
                                    color: $plrg-purple;
                                }
                            }
                        }
                    }

                    @media all and (min-width: 768px) and (max-width: 1299px) {
                        &:nth-child(n),
                        &:nth-child(4n-3) {
                            .overlay:not([class*="bg-"]):not([class*="bg-"]) {
                                background-color: #F2F5F0;

                                &::after,
                                h2,
                                p {
                                    color: $plrg-green;
                                }
                            }
                        }

                        &:nth-child(4n-2),
                        &:nth-child(4n-1) {
                            .overlay:not([class*="bg-"]):not([class*="bg-"]) {
                                background-color: #FFFFFF;

                                &::after,
                                h2,
                                p {
                                    color: $plrg-purple;
                                }
                            }
                        }
                    }

                    @media all and (min-width: 1300px) {
                        &:nth-child(odd) {
                            .overlay:not([class*="bg-"]):not([class*="bg-"]) {
                                background-color: #F2F5F0;

                                &::after,
                                h2,
                                p {
                                    color: $plrg-green;
                                }
                            }
                        }

                        &:nth-child(even) {
                            .overlay:not([class*="bg-"]):not([class*="bg-"]) {
                                background-color: #FFFFFF;

                                &::after,
                                h2,
                                p {
                                    color: $plrg-purple;
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    /////////////////////////////////////
    // MODULE 11
    /////////////////////////////////////


    .module-11 {
        position: relative;
        // color: $crest-white;

        color: $plrg-green;
        background-color: $plrg-green-tint;

        // &:after {
        //     position: absolute;
        //     right: 0;
        //     bottom: 0;

        //     content: url("/en/media/2017siteassets/contentfiles/images/nptswi/module-11-after.png");

        //     @media all and (max-width: 1200px) {
        //         max-width: 180px;
        //     }
        // }

        .module-title {
            // font-weight: 800;
            // color: $crest-white;
            color: $plrg-green;

            & + p {
                color: inherit;
            }
        }

        .list-inline {
            li {
                i,
                a {
                    // color: $crest-white;
                    color: $plrg-green;
                }
            }
        }
    }



    /////////////////////////////////////
    // MODULE 12
    /////////////////////////////////////

    .module-12 {
        .module-title {
            // font-weight: 800;
        }

        .slideShowLogos {
            .slick-prev,
            .slick-next {
                &:before {
                    color: $plrg-purple !important;
                }
            }
        }
    }

    .module-12:not(.module-12--featured) {
        background: $plrg-green-tint;
    }



    /////////////////////////////////////
    // MODULE 14
    /////////////////////////////////////


    .module-14 {
        .module-title {
            // font-weight: 800;

            & + p {
                font-family: "Roboto", sans-serif;
                line-height: normal;
            }
        }

        .date {
            color: #FFFFFF;
            background-color: $plrg-green;
        }

        .btn {
            color: #FFFFFF;
            background-color: $plrg-green;

            &:hover {
                color: #FFFFFF;
                background-color: $plrg-green;
            }

            i {
                color: #FFFFFF;
            }
        }
    }



    /////////////////////////////////////
    // MODULE 15 & 24
    /////////////////////////////////////

    .module-15,
    .module-24 {
        font-family: "Roboto", sans-serif;

        .module-title {
            // font-weight: 800;

            & + p {
                font-family: "Roboto", sans-serif;
                line-height: normal;
            }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            // font-weight: 800;
        }

        .date {
            color: #FFFFFF;
            background-color: $plrg-green;
        }

        .related-articles {
            .related-articles__item {
                background-color: $plrg-green;
            }

            .slick-arrow {
                background-color: $plrg-purple;
            }
        }

        ul:not([class]) {
            li {
                &:before {
                    color: $plrg-purple;
                }
            }
        }

        blockquote {
            font-weight: 400;
            font-style: italic;

            &:before,
            &:after {
                color: $plrg-green;
            }
        }

        .btn {
            color: #FFFFFF;
            background-color: $plrg-green;

            &.btn--yellow {
                color: white;
                background-color: $plrg-purple;

                i {
                    // color: $plrg-green;
                    color: white;
                }
            }

            &:hover {
                color: #FFFFFF;
                background-color: $plrg-green;

                i {
                    color: #FFFFFF;
                }
            }

            i {
                color: #FFFFFF;
            }
        }
    }


    /////////////////////////////////////
    // MODULE 29
    /////////////////////////////////////
    ///
    .col-lg-9.content-wrap__wrapper .module-29 figcaption {
        background: $plrg-green-tint;
    }

    .col-lg-9.content-wrap__wrapper .module-29 figcaption .outer h1,
    .col-lg-9.content-wrap__wrapper .module-29 figcaption .outer h2,
    .col-lg-9.content-wrap__wrapper .module-29 figcaption .outer h3,
    .col-lg-9.content-wrap__wrapper .module-29 figcaption .outer h4,
    .col-lg-9.content-wrap__wrapper .module-29 figcaption .outer p {
        @media all and (max-width: 1800px) {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            padding-left: 0 !important;

            background-color: transparent;
        }
    }

    .module-29:not(.module-29--box--active) figcaption .outer p {
        border-radius: $plrg-border-radius;
    }

    &.inner {
        .module-29 {
            &:after {
                content: inherit;
            }
        }
    }

    .module-29 figcaption .main-heading {
        font-family: "Roboto";

        border-radius: $plrg-border-radius;

        @media all and (max-width: 1399px) {
            padding: 0 !important;
        }
    }

    .module-29 .slide--countdown-feature figcaption .inner .main-heading {
        color: $plrg-green !important;
    }

    .module-29 figcaption .sub-heading {
        @media all and (max-width: 1399px) {
            padding: 0 !important;
        }
    }

    .module-1,
    .module-29 {
        position: relative;

        .bg-crest-black,
        .bg-crest-red,
        .bg-crest-blue,
        .bg-crest-yellow,
        .bg-sky-blue,
        .bg-quad-green,
        .bg-earth-red,
        .bg-ogham-stone,
        .bg-dawn-mauve,
        .bg-limestone,
        .bg-brand-twitter,
        .bg-brand-facebook,
        .bg-brand-instagram,
        .bg-brand-youtube,
        .bg-brand-linkedin {
            background-color: $plrg-green-tint;
        }

        .bg-ogham-stone {
            background-color: $plrg-green-tint;
        }

        .slider-progress {
            .progress {
                background-color: $plrg-purple;
            }
        }

        .date {
            max-width: 100% !important;
            margin-top: 30px !important;
        }

        .main-heading {
            font-weight: 300;

            padding: 15px 30px !important;

            color: $plrg-green;
            background-color: white;

            @media all and (max-width: 1399px) {
                background-color: transparent;
            }
        }

        svg {
            circle {
                fill: $plrg-green;
            }
        }

        .sub-heading {
            padding: 15px 30px !important;

            color: $plrg-green;
            background-color: #FFFFFF;

            @media all and (max-width: 1399px) {
                background-color: transparent;
            }
        }

        .slick-dots {
            li {
                button {
                    &:before {
                        background-color: $plrg-purple;
                    }
                }

                &.slick-active {
                    button {
                        &:before {
                            background-color: $plrg-green;
                        }
                    }
                }
            }
        }

        .btn {
            // font-weight: 700;

            &.btn--arrowed {
                i {
                    color: $plrg-green;
                }
            }

            &.linktype {
                font-family: "Roboto";

                color: #FFFFFF;
                border-radius: $plrg-border-radius;
                background-color: $plrg-purple;

                i {
                    color: #FFFFFF;
                }
            }
        }

        .slide {
            figcaption {
                @media all and (max-width : 1399px) {
                    background-color: $plrg-green-tint;
                }
            }
        }

        .slide-square {
            background-color: $plrg-green-tint;

            *:not(.btn):not(.fa) {
                color: $plrg-green;
            }

            .btn--white {
                color: white;
                background: $plrg-green;

                i,
                svg {
                    color: white;
                }
            }
        }

        .countdown {
            padding: 10px;

            //position: relative;
            .countdown__timer {
                margin-bottom: 10px;

                .date {
                    font-size: 14px;
                }

                .countdown__number {
                    .number {
                        color: $plrg-green;
                        background-color: $plrg-green-tint;
                    }
                }
            }
        }
    }

    .module-1 {
        .slide {
            figcaption {
                background-color: transparent !important;
            }
        }
    }

    /////////////////////////////////////
    // MODULE 24
    /////////////////////////////////////

    .module-24 {
        ul {
            li {
                &:before {
                    color: $plrg-purple;
                    background-color: $plrg-purple;
                }
            }
        }
    }

    /////////////////////////////////////
    // MODULE 33
    /////////////////////////////////////

    &.home {
        .module-33 {
            position: relative;

            &:before {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 100px;

                width: 200px;
                height: 200px;

                content: "";

                background: url(/en/media/academic/obstetricsandgynaecology/icon-sunset.svg);
                background-size: 100%;
            }
        }
    }

    &.inner {
        .module-33 {
            .module-33__item {
                @media all and (min-width: 1200px) {
                    &:nth-child(2),
                    &:nth-child(4),
                    &:nth-child(6),
                    &:nth-child(8),
                    &:nth-child(10),
                    &:nth-child(12) {
                        .slide-square {
                            background-color: $plrg-purple-tint;

                            .btn {
                                color: white;
                                background: $plrg-purple;
                            }

                            .slide-square__copy {
                                color: $plrg-purple;
                            }
                        }
                    }

                    &:nth-child(1),
                    &:nth-child(3),
                    &:nth-child(5),
                    &:nth-child(7),
                    &:nth-child(9),
                    &:nth-child(11) {
                        .slide-square {
                            background-color: $plrg-green-tint;

                            .btn {
                                color: white;
                                background: $plrg-green;
                            }

                            .slide-square__title {
                                color: $plrg-green;
                            }

                            .slide-square__contact {
                                li {
                                    a {
                                        color: $plrg-green;
                                    }
                                }
                            }

                            .slide-square__copy {
                                color: $plrg-green;
                            }
                        }
                    }
                }
            }

            .grid-item {
                width: 33.333333% !important;

                @media all and (max-width: 1199px) {
                    width: 50% !important;
                }

                @media all and (max-width: 767px) {
                    width: 100% !important;
                }
            }
        }
    }

    .module-33 {
        .module-title {
            // font-weight: 800;

            & + p {
                font-family: "Roboto", sans-serif;
                line-height: normal;

                width: 1200px;
                max-width: 100%;
                padding-bottom: 0;
            }
        }

        .btn-group {
            button {
                font-family: "Roboto", sans-serif;
                font-size: 16px;
            }
        }

        .module-33__item {
            .slide-square {
                background-color: $plrg-green-tint;

                .btn {
                    color: white;
                    background: $plrg-green;
                }

                .slide-square__title {
                    font-size: 22px;
                    font-weight: 600;
                    line-height: 150%;

                    color: $plrg-green;
                }

                .slide-square__contact {
                    li {
                        a {
                            font-weight: 600;

                            color: $plrg-green;
                        }
                    }
                }

                .slide-square__copy {
                    color: $plrg-green;
                }

                .btn {
                    // color: $plrg-green;
                    // background-color: #FFFFFF;

                    i {
                        color: #FFFFFF;
                    }
                }
            }

            @media all and (min-width: 1600px) {
                &:nth-child(2),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(7),
                &:nth-child(10),
                &:nth-child(12) {
                    .slide-square {
                        background-color: $plrg-purple-tint;

                        .btn {
                            color: white;
                            background: $plrg-purple;
                        }

                        .slide-square__title {
                            color: $plrg-purple;
                        }

                        .slide-square__contact {
                            li {
                                a {
                                    color: $plrg-purple;
                                }
                            }
                        }

                        .slide-square__copy {
                            color: $plrg-purple;
                        }
                    }
                }
            }

            @media all and (min-width: 1200px) and (max-width: 1599px) {
                &:nth-child(2),
                &:nth-child(4),
                &:nth-child(6),
                &:nth-child(8),
                &:nth-child(10),
                &:nth-child(12) {
                    .slide-square {
                        background-color: $plrg-purple-tint;

                        .btn {
                            color: white;
                            background: $plrg-purple;
                        }

                        .slide-square__title {
                            color: $plrg-purple;
                        }

                        .slide-square__contact {
                            li {
                                a {
                                    color: $plrg-purple;
                                }
                            }
                        }

                        .slide-square__copy {
                            color: $plrg-purple;
                        }
                    }
                }
            }

            @media all and (min-width: 768px) and (max-width: 1199px) {
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(10),
                &:nth-child(11) {
                    .slide-square {
                        background-color: $plrg-purple-tint;

                        .btn {
                            color: white;
                            background: $plrg-purple;
                        }

                        .slide-square__title {
                            color: $plrg-purple;
                        }

                        .slide-square__contact {
                            li {
                                a {
                                    color: $plrg-purple;
                                }
                            }
                        }

                        .slide-square__copy {
                            color: $plrg-purple;
                        }
                    }
                }
            }

            @media all and (min-width: 0) and (max-width: 767px) {
                &:nth-child(2),
                &:nth-child(4),
                &:nth-child(6),
                &:nth-child(8),
                &:nth-child(10),
                &:nth-child(12) {
                    .slide-square {
                        background-color: $plrg-purple-tint;

                        .btn {
                            color: white;
                            background: $plrg-purple;
                        }

                        .slide-square__title {
                            color: $plrg-purple;
                        }

                        .slide-square__contact {
                            li {
                                a {
                                    color: $plrg-purple;
                                }
                            }
                        }

                        .slide-square__copy {
                            color: $plrg-purple;
                        }
                    }
                }
            }
        }
    }



    /////////////////////////////////////
    // MODULE 39
    /////////////////////////////////////

    .module-39 {
        padding-right: 20px;
        padding-left: 20px;

        color: black;

        @media all and (min-width: 992px) {
            padding-right: 40px;
            padding-left: 40px;
        }

        @media all and (min-width: 1200px) {
            padding-right: 80px;
            padding-left: 80px;
        }

        @media all and (min-width: 1440px) {
            padding-right: 120px;
            padding-left: 120px;
        }

        .dataTables_wrapper .dataTables_paginate .paginate_button.current,
        .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
            color: white !important;
            background: $plrg-green;
        }

        .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
            color: white !important;
            background: $plrg-purple;
        }

        .dataTables_length select,
        select.form-control {
            position: relative;

            padding-right: 32px;

            background-image: url(/en/media/academic/obstetricsandgynaecology/chevron.svg);
            background-repeat: no-repeat;
            background-position: calc(100% - 10px) 50%;
            background-size: 15px 8px;

            appearance: none;
        }

        input[type="search"] {
            position: relative;

            background-image: url(/en/media/academic/obstetricsandgynaecology/search.svg);
            background-repeat: no-repeat;
            background-position: calc(100% - 10px) calc(50% + 2px);
            background-size: 16px 16px;
        }

        .btn__wrap {
            display: flex;
            align-items: center;

            margin-top: 10px;
            margin-bottom: 10px;

            @media all and (max-width: 1199px) {
                justify-content: flex-end;

                width: 100%;
            }

            .btn--grid {
                background-color: $plrg-green;

                .fa {
                    color: white;
                }
            }

            &.list-active {
                .btn--grid {
                    background-color: #EFEFEF;

                    .fa {
                        color: $plrg-green;
                    }
                }

                .btn--list {
                    background-color: $plrg-green;

                    .fa {
                        color: white;
                    }
                }
            }
        }

        .dataTable__category {
            svg {
                height: auto !important;
            }

            svg path {
                stroke: $plrg-purple;
            }
        }

        .btn--clearfilters {
            font-weight: 400;

            text-decoration: underline !important;

            color: $plrg-green;

            @media all and (min-width: 992px) {
                margin-top: 0;
            }
        }

        tr {
            position: relative;
        }

        .btn {
            .fa {
                color: $plrg-green;
            }
        }

        .form-control {
            border-color: $limestone;
            border-radius: $plrg-border-radius;
        }

        table {
            a {
                text-decoration: underline;
            }
        }

        .dataTable {
            &.cards {
                tr:nth-child(odd) {
                    background-color: $plrg-green-tint;
                }

                tr:nth-child(even) {
                    background-color: $plrg-purple-tint;
                }
            }

            &:not(.cards) {
                .dtr-data,
                .dataTable__actions {
                    .btn {
                        background-color: $plrg-green-tint;
                    }
                }

                table.dataTable.no-footer {
                    border-top-color: $plrg-green;
                }

                td {
                    border: 0 !important;
                }

                th {
                    padding-top: 20px;
                    padding-bottom: 20px;

                    color: $plrg-green;
                    border: 0 !important;
                    background-color: $plrg-green-tint;
                }

                th,
                td {
                    border-top: 1px solid $plrg-green !important;
                }
            }

            tr.odd {
                h2 {
                    color: $plrg-green;
                }

                i {
                    color: $plrg-green;
                }

                .dataTable__category {
                    svg path {
                        stroke: $plrg-green;
                    }
                }

                .dataTable__category,
                .dataTable__date {
                    color: $plrg-green;
                }

                + .child {
                    color: $plrg-green;
                }
            }

            tr.even {
                h2 {
                    color: $plrg-purple;
                }

                i {
                    color: $plrg-purple;
                }

                .dataTable__category,
                .dataTable__date {
                    color: $plrg-purple;
                }

                .dtr-data,
                .dataTable__actions {
                    .btn {
                        color: $plrg-purple;
                    }
                }

                + .child {
                    color: $plrg-purple;
                }
            }

            &.cards {
                tbody tr {
                    border: 0;
                    box-shadow: none;
                }

                .dataTable__date {
                    position: absolute;
                    top: 50px;
                    left: 50px;

                    width: auto;
                }

                td,
                th {
                    border: 0 !important;
                }
            }
        }

        .dtr-data,
        .dataTable__actions {
            .btn {
                color: $plrg-green;
            }
        }
    }

    /////////////////////////////////////
    // MODULE 43
    /////////////////////////////////////

    .module-43 {
        .dataTables_wrapper .dataTables_paginate .paginate_button.current,
        .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
            color: white !important;
            background: $plrg-green;
        }

        .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
            color: white !important;
            background: $plrg-purple;
        }

        .dataTables_length select,
        select.form-control {
            position: relative;

            padding-right: 32px;

            background-image: url(/en/media/academic/obstetricsandgynaecology/chevron.svg);
            background-repeat: no-repeat;
            background-position: calc(100% - 10px) 50%;
            background-size: 15px 8px;

            appearance: none;
        }

        input[type="search"] {
            position: relative;

            background-image: url(/en/media/academic/obstetricsandgynaecology/search.svg);
            background-repeat: no-repeat;
            background-position: calc(100% - 10px) calc(50% + 2px);
            background-size: 16px 16px;
        }

        .btn__wrap {
            display: flex;
            align-items: center;

            margin-top: 10px;
            margin-bottom: 10px;

            @media all and (max-width: 1199px) {
                justify-content: flex-end;

                width: 100%;
            }

            .btn--grid {
                background-color: $plrg-green;

                .fa {
                    color: white;
                }
            }

            &.list-active {
                .btn--grid {
                    background-color: #EFEFEF;

                    .fa {
                        color: $plrg-green;
                    }
                }

                .btn--list {
                    background-color: $plrg-green;

                    .fa {
                        color: white;
                    }
                }
            }
        }

        .dataTable__category {
            svg path {
                stroke: $plrg-purple;
            }
        }

        .btn--clearfilters {
            font-weight: 400;

            text-decoration: underline !important;

            color: $plrg-green;

            @media all and (min-width: 992px) {
                margin-top: 0;
            }
        }

        tr {
            position: relative;
        }

        .btn {
            .fa {
                color: $plrg-green;
            }
        }

        .form-control {
            border-color: $limestone;
            border-radius: $plrg-border-radius;
        }

        table {
            a {
                text-decoration: underline;
            }
        }

        .dataTable {
            &.cards {
                tr:nth-child(odd) {
                    background-color: $plrg-green-tint;
                }

                tr:nth-child(even) {
                    background-color: $plrg-purple-tint;
                }
            }

            &:not(.cards) {
                .dtr-data,
                .dataTable__actions {
                    .btn {
                        background-color: $plrg-green-tint;
                    }
                }

                table.dataTable.no-footer {
                    border-top-color: $plrg-green;
                }

                td {
                    border: 0 !important;
                }

                th {
                    padding-top: 20px;
                    padding-bottom: 20px;

                    color: $plrg-green;
                    border: 0 !important;
                    background-color: $plrg-green-tint;
                }

                th,
                td {
                    border-top: 1px solid $plrg-green !important;
                }
            }

            tr.odd {
                h2 {
                    color: $plrg-green;
                }

                i {
                    color: $plrg-green;
                }

                .dataTable__category {
                    svg path {
                        stroke: $plrg-green;
                    }
                }

                .dataTable__category,
                .dataTable__date {
                    color: $plrg-green;
                }

                + .child {
                    color: $plrg-green;
                }
            }

            tr.even {
                h2 {
                    color: $plrg-purple;
                }

                i {
                    color: $plrg-purple;
                }

                .dataTable__category,
                .dataTable__date {
                    color: $plrg-purple;
                }

                .dtr-data,
                .dataTable__actions {
                    .btn {
                        color: $plrg-purple;
                    }
                }

                + .child {
                    color: $plrg-purple;
                }
            }

            &.cards {
                tbody tr {
                    border: 0;
                    box-shadow: none;
                }

                .dataTable__date {
                    position: absolute;
                    top: 50px;
                    left: 50px;

                    width: auto;
                }

                td,
                th {
                    border: 0 !important;
                }
            }
        }

        .dtr-data,
        .dataTable__actions {
            .btn {
                color: $plrg-green;
            }
        }
    }

    /////////////////////////////////////
    //s MODULE 40
    /////////////////////////////////////
    .module-40 {
        padding-right: 20px;
        padding-left: 20px;


        @media all and (min-width: 992px) {
            padding-right: 40px;
            padding-left: 40px;
        }

        @media all and (min-width: 1200px) {
            padding-right: 80px;
            padding-left: 80px;
        }

        @media all and (min-width: 1440px) {
            padding-right: 120px;
            padding-left: 120px;
        }

        .module-40-container {
            background-color: $plrg-green-tint;
        }

        .module-40-container a {
            color: #000000;
        }

        .module-40-container.site-details dt {
            font-weight: 700;

            color: $plrg-green;
        }

        .module-40-container.site-details {
            .dl-horizontal {
                margin-bottom: 0;
            }

            .dl-horizontal::after {
                position: absolute;
                bottom: 0;
                left: 45px;

                width: calc(100% - 90px);
                height: 1px;

                content: "";

                background-color: $plrg-green;
            }

            dt:first-child {
                border-top: 0;

                + dd {
                    border-top: 0;
                }
            }

            dd {
                color: black;
                border-top-color: $plrg-green;
            }

            h3 {
                color: black;
            }

            dt {
                color: $plrg-green;
                border-top-color: $plrg-green;
            }

            dd + dd {
                border-top-color: transparent;
            }
        }

        .module-40__wysiwyg {
            color: black;
        }
    }

    /////////////////////////////////////
    // Footer
    /////////////////////////////////////

    .footer {
        font-family: "Roboto", sans-serif;

        background-color: $plrg-green;

        h2 {
            // font-weight: 800;
        }
    }

    .cookiePolicy.bottom {
        border-top: none;
        background-color: $plrg-green;

        p {
            font-weight: 400;
        }

        .btn {
            font-size: 20px;
            // font-weight: 700;

            padding: 18px 30px;

            color: $plrg-green;
            border: 2px solid #FFFFFF;
            border-radius: 0;
            background-color: #FFFFFF;

            &:hover {
                transform: scale(1.02);

                color: #FFFFFF;
                background-color: $plrg-green;
            }
        }
    }

    .content-wrap__inner div[class*="module"]:not(.module-36):not(.module-36-container) .module-title {
        font-weight: 300;
    }

    .content-wrap__inner .module-12 .module-title {
        color: $plrg-green;
    }

    .content-wrap__inner .module-title + p {
        margin-left: 0;

        text-align: left;
    }

    .module-sub-text {
        color: black;
    }

    .content-wrap__inner {
        .module-39 .dataTables_wrapper form {
            justify-content: flex-start;
        }
    }

    .content-wrap__wrapper .content-wrap__inner h3 {
        font-weight: inherit;
    }
}

body.ucc-theme--plrg {

    .explore-toggle {
        background: transparent;

        span {
            @media all and (max-width: 991px) {
                display: inline-block;

                margin-top: 6px;
            }
        }
    }

    .explore-toggle::after {
        background-image: url("/en/media/2017siteassets/images/icon-ucc-black.png");
    }

    &.ucc-satellite .ucc-navigation-toggle {
        background-color: $plrg-green;
    }

    &.ucc-satellite .ucc-navigation-toggle svg path {
        fill: white;
    }

    .ucc-nav {
        @media all and (max-width: 1199px) {
            border-top-color: $plrg-purple;
        }
    }




    .ucc-nav {
        @media all and (min-width: 1200px) {
            border-bottom: $plrg-green-tint;
            background-color: $plrg-green-tint;
        }
    }


    .ucc-nav__main .ucc-nav__dropdown a:after {
        display: none;
    }

    .greedy-menu .toggle-links {
        background-color: $plrg-green;
    }

    .greedy-menu .toggle-links:hover {
        background-color: $plrg-purple;
    }

    .greedy-menu .toggle-links.counter::before {
        background-color: $plrg-purple;
    }

    .ucc-nav__dropdown__menu {
        @media all and (min-width: 1200px) {
            border-top-color: $plrg-purple;
            background-color: $plrg-green-tint;
            // .ucc-nav__dropdown__menu {
            //     border-left: 2px solid $plrg-green;
            // }
        }
    }
}

// !: nav colours


.ucc-theme--plrg {
    --active-arrow: white;
    --active-background: #{$plrg-green};
    --active-link: white;
    --arrow-first-level: #{$plrg-green};
    --arrow-go-back: #{$plrg-green};
    --arrow-second-level: #{$plrg-green};
    --greedy-menu-background: #{$plrg-green-tint};
    --link-base: #{$crest-black};
    --link-base-arrow-hover: white;
    --link-base-background-hover: #{$plrg-green};
    --link-base-desktop: #{$plrg-green};
    --link-base-hover: white;
    --link-dropdown: #{$crest-black};
    --link-dropdown-arrow-hover: white;
    --link-dropdown-background-hover: #{$plrg-green};
    --link-dropdown-desktop: #{$crest-black};
    --link-dropdown-hover: white;
    --nav-background: #{$plrg-green-tint};
    --onpath-arrow: white;
    --onpath-background: #{$plrg-green};
    --onpath-link: #{$plrg-green-tint};
}
