.footer {
	position: relative;
	border-top: 1px solid #fff;
	z-index: 1001;
	background: #003c69;
	color: #fff;
	line-height: 1.75;
	font-weight: 300;
	padding: 50px 30px 20px;
	h2 {
		font-family: 'Playfair Display', serif;
		font-weight: 400;
		font-size: 18px;
		line-height: 1.25;
		margin: 20px 0;
		color: $crest-white;
		@media (min-width: $screen-md) {
			font-size: 18px;
		}
		@media (min-width: 1400px) {
			font-size: 22px;
		}
	}
	a {
		color: #fff;
		display: inline-block;
		padding: 2px 0;
		font-size: 14px;
	}
	.contact {
		span {
			display: block;
			margin-right: 24px;
		}
		.fa {
			display: inline-block;
			width: 16px;
			margin-right: 8px;
		}
	}
	.module-12.logos {
		position: relative;
		bottom: -20px;
		.logos-list {
			margin-left: -65px;
			margin-right: -65px;
			padding: 20px 35px 0;
			background-color: white;
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			li {
				margin-bottom: 10px;
			}
		}
	}
	.bbdc {
		margin-bottom: 50px;
	}
}

@media (max-width: 991px) {
	.footer {
		padding-left: 50px;
		padding-right: 50px;
		a[data-toggle="collapse"] {
			display: block;
			position: relative;
			background: rgba($crest-white,0.1);
			margin: 5px -25px;
			padding: 2px 25px;
			text-decoration: none;
			&:before {
				content:"";
				width: 17px;
				height: 0;
				border-top: 1px solid #fff;
				position: absolute;
				right: 20px;
				top: 33px;		
			}
			&:after {
				content:"";
				width: 0;
				height: 17px;
				border-left: 1px solid #fff;
				position: absolute;
				right: 28px;
				top: 25px;
			}
			&[aria-expanded="true"] {
				&:after {
					border-left: 0;
				}
			}
		}
		.well {
			background-color: transparent;
			padding: 15px 0 0 0;
			p {
				margin-bottom: 0;
			}
		}
		.bbdc {
			text-align: center;
			opacity: 0.75;
			margin-top: 30px;
		}
		.logos .logos-list {
			li {
				width: 33.3333333333%;
				padding: 0 5px 10px;
				margin-bottom: 0;
			}
		}
	}
	body.social-open footer {
        padding-bottom:100px;
    }
}


@media (min-width: $screen-md) {
	.footer {
		a[data-toggle="collapse"] {
			pointer-events: none;
		}
		div.collapse {
			display: block;
			.well {
				background-color: transparent;
			}
		}
	}
}


@media (min-width: $screen-lg) {

	.footer .row:not(:last-child) {
		padding-left: 10%;
		padding-right: 10%;
	}

}


/* back to top */

#back-to-top {
    opacity: 0;
    transition: opacity 0.2s ease;
    position: fixed;
    right: 8px;
    bottom: 88px;
    width: 50px;
    height: 50px;
    line-height: 3px;
    padding-top: 0;
    z-index: 5000;
    background-color: $crest-red;
    border: 1px solid white;
    color: white;
    text-align: center;
    overflow: hidden;
	&:not(.show) {
		pointer-events: none;
	}
}
#back-to-top i {
    font-size: 32px;
    padding: 0;
    opacity: 1;
}

#back-to-top span {
	font-size: 12px;
	display: block;
	text-transform: uppercase;
}

#back-to-top.show {
    opacity: 0.7;
}

@media (min-width: $screen-md) {

	#back-to-top {
		bottom: 8px;
	}
}

.footer {
	&.footer--satellite {
		padding: 30px 15px 40px;
		text-align: center;
		background-color: $crest-black;
		@media (min-width: 992px) {
			padding: 60px 15px 70px;
		}
		.contact {
			p {
				display: flex;
				justify-content: center;
				a {
					font-size: 16px;
				}
			}
		}
		.menu {
			ul {
				width: 100%;
				overflow: hidden;
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
			}
			li {
				&:after {
					content: "|";
					display: inline-block;
					padding: 0 5px;
				}
				&:last-child {
					&:after {
						display: none;
					}
				}
				a {
					font-size: 16px;
				}
			}
		}
		.bbdc {
			margin: 15px 0 0 0;
			font-size: 16px;
			opacity: 1;
		}
	}
}
