///////////////////////////////////////////////////////////
// scroll bar
///////////////////////////////////////////////////////////

// ::-webkit-scrollbar-track {
// 	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
// 	background-color: $crest-blue;
// }

// ::-webkit-scrollbar {
// 	width: 10px;
// 	background-color: $crest-blue;
// }

// ::-webkit-scrollbar-thumb {
// 	background-color: $crest-yellow;
// 	border: 10px solid $crest-yellow;
// }

///////////////////////////////////////////////////////////
// classes
///////////////////////////////////////////////////////////
.bg-crest-white {
	background-color: $crest-white;
}
.bg-tint-crest-white {
	background-color: $tint-crest-white;
}
.bg-crest-black {
	background-color: $crest-black;
}
.bg-crest-red {
	background-color: $crest-red;
}
.bg-crest-blue {
	background-color: $crest-blue;
}
.bg-quad-green {
	background-color: $quad-green;
}
.bg-sky-blue {
	background-color: $sky-blue;
}
.bg-crest-yellow {
	background-color: $crest-yellow;
}
.bg-earth-red {
	background-color: $earth-red;
}
.bg-ogham-stone {
	background-color: $ogham-stone;
}
.bg-dawn-mauve {
	background-color: $dawn-mauve;
}
.bg-limestone {
	background-color: $limestone;
}
.bg-brand-twitter {
	background-color: $brand-twitter;
}
.bg-brand-facebook {
	background-color: $brand-facebook;
}
.module-title {
	@include serif-r;
	font-weight: 400;
	font-size: $font-size-h3;
	text-align: center;
	margin-top: 0;
	margin-bottom: 0;
	padding-bottom: 30px;
	@media (min-width: $screen-md) {
		font-size: $font-size-h2;
		//padding-bottom: 40px;
	}
	@media (min-width: $screen-lg) {
		font-size: $font-size-md;
		//padding-bottom: 70px;
	}
	@media (min-width: 1600px) {
		font-size: 50px;
	}
	+ p {
		color: black;
		text-align: center;
		margin-bottom: 40px;
		max-width: 70%;
		margin-left: auto;
		margin-right: auto;
	}
}

.content-wrap__inner .module-title {
	@include serif-r;
	font-weight: 400;
	font-size: $font-size-h3;
	text-align: left;
	padding: 0;
	padding-left: 5px;
	margin: 20px auto;
	@media (min-width: $screen-sm) {
		font-size: $font-size-h2;
	}
	@media (min-width: $screen-sm) {
		font-size: $font-size-md;
		margin: 30px auto;
	}
}

.content-wrap__inner div[class*="module"] + div[class*="module"] .module-title {
	margin-top: 0;
}

.module-title--reverse {
	color: white;
}

.form-group--m-t {
	margin-top: 30px;
}

.container.capped {
	max-width: 1400px;
}

.dropdown-menu {
	border-radius: 0;
	border: 0;
	margin: 0;
	font-size: 16px;
	@media (min-width: $screen-md) {
		font-size: 18px
	}
	@media (min-width: 1400px) {
		font-size: 20px
	}
}

.img-responsive {
	width: 100%;
}

.well {
	border: 0;
	background-color: $tint-crest-white;
	padding: 15px;
}

.content_banner {
	padding-bottom: 60%;
	background-size: cover;
	background-repeat: no-repeat;
}

.content-wrap__wrapper {
	.content-wrap__inner {
		ol.breadcrumb {
			padding: 8px 0;
			background-color: transparent;
			margin-bottom: 15px;
			> li {
				font-size: 12px;
				margin: 0 !important;
				&+li:before {
					padding: 0 5px 0 3px;
					content:"\f105";
					font-family: "FontAwesome";
				}
				a {
					// color: $limestone;
					color: #657582;
					font-size: 12px;
				}
				&.active a {
					// color: $crest-black;
					color: black;
				}
				&:last-of-type {
					color: #555;
				}
			}
		}
		.page-title + .breadcrumb {
			@media (max-width: 992px) {
				margin-top: -20px;
			}
		}
		@media (min-width: 993px) and (max-width: 1200px) {
			ol.breadcrumb {
				padding-top: 50px;
			}
		}
	}
}

.timeline .breadcrumb {
	padding-top: 0;
}

@media (min-width: $screen-md) {
	.breadcrumb {
		padding-top: 30px;
	}
	.breadcrumb li,
	.breadcrumb li a {
		font-size: 16px;
	}
}

.page-title {
	font-size: 40px;
	font-weight: 400;
	margin: 15px 0 30px;
}

h2 {
	font-size: 28px;
	line-height: 1.25;
}

.iframe-wrap {
	position: relative;
	height: 0;
	overflow: hidden;
	padding-bottom: 56%;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
/*
.content-wrap {
	h1,h2,h3,h4,h5,h6 {
		color: $crest-blue;
	}
}
*/
.twitter-widget {
	//width: 100%;
	height: 400px;
	overflow-y: scroll;
	margin-left: 0;
	margin-right: 0;
	border: 1px solid $tint-crest-white-darker;
	h2 {
		padding: 0 10px;
	}
	@media (min-width: $screen-lg) {
		margin-left: -50px;
		margin-right: -100px;
	}
}

///////////////////////////////////////////////////////////
// buttons
///////////////////////////////////////////////////////////

.btn {
	text-decoration: none !important;
	position: relative;
	font-size: 16px;
	@include serif-b;
	border-radius: 0;
	border: none;
	padding: 15px 20px;
	// min-height: 50px;
	transition: .3s ease;
	@media (min-width: $screen-sm) {
		padding: 18px 30px;
		font-size: 20px;
	}
	&:before {
		content: '';
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    z-index: 1;
	    border-radius: inherit;
	    opacity: 0;
	    transform: scale3d(0.6, 0.6, 1);
	    transition: transform 0.3s, opacity 0.3s;
	    transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
	}
}

.btn:hover {
	color: #fff;
	&:before {
		transform: scale3d(1,1,1);
		opacity: 1;
	}
}

.btn--small {
  font-size: 16px !important;
  padding: 9px 20px !important;
  min-height: unset !important;
}

// yellow
.btn--yellow {
	background-color: $crest-yellow;
	color: $crest-blue;
	.fa {
		color: white;
		padding-left: 5px;
		transition: transform 0.3s, color 0.3s;
	}
	&:hover {
		background-color: $crest-white;
		color: $crest-blue;
		transform: scale(1.01);
		.fa {
			color: $crest-blue;
		}
	}
	//&:hover {
	//	background-color: white;
	//	color: $crest-blue;
	//	.fa {
	//		color: $crest-blue;
	//	}
	//	&:before {
	//		border: 2px solid $crest-yellow;
	//	}
	//}
}

// white
.btn--white {
	background-color: $crest-white;
	color: $crest-blue;
	&:focus,&.focus,&:hover {
		color: $crest-blue;
	}

}
.btn--white .fa {
	color: $crest-yellow;
	padding-left: 5px;
}

// blue
.btn--blue {
	background-color: $crest-blue;
	color: $crest-white;
	&.btn:focus,&.btn.focus {
		color: $crest-white;
	}
}
.btn--blue .fa {
	color: $crest-yellow;
	padding-left: 5px;
}

// red
.btn--red {
	background-color: $crest-red;
	color: $crest-white;
	&:focus,&.focus,&:hover {
		color: $crest-white;
	}
	&:hover {
		background-color: $crest-white;
		color: $crest-blue;
		transform: scale(1.01);
		.fa {
			color: $crest-blue;
		}
	}
}
.btn--red .fa {
	color: $crest-yellow;
	padding-left: 5px;
}
.btn-block {
	max-width: 350px;
	margin-left: auto;
	margin-right: auto;
}

.btn .fa-long-arrow-left {
	padding-left: 0;
	padding-right: 5px;
}

.buttons {
	margin-top: 50px;
	overflow: hidden;
	> a {
		min-width: 200px;
		width: 100%;
		text-align: center;
		&:first-of-type {
			margin-bottom: 2px;
		}
		@media (min-width: 450px) {
			width: auto;
		}
		@media (min-width: $screen-lg) and (max-width: 1300px) {
			width: 100%;
		}
	}
}

.cookie {
	position: fixed;
	padding: 30px;
	z-index: 1000;
	background-color: $crest-black;
	.cookie__inner {
		h2,p,ul {
			float: left;
			color: white;
		}
		a {
			font-style: normal;
			color: $sky-blue;
		}
		p {
			margin-bottom: 0;
		}
	}
	&.example1 {
		&.top {
			top: 0;
			border-bottom: 4px solid $crest-red;
		}
		&.bottom {
			bottom: 0;
			border-top: 4px solid $crest-red;
		}
		h2 {
			font-size: 26px;
			width: 29%;
			margin-right: 1%;
			margin-top: 0;
		}
		p {
			width: 49%;
			margin-right: 1%;
			font-size: 14px;
		}
		a {
			font-style: italic;
		}
		ul {
			width: 20%;
			li {
				position: relative;
				padding-left: 40px;
				&:first-of-type {
					a {
						font-size: 26px;
					}
					i {
						top: 8px;
					}
				}
				a {
						font-size: 14px;
					}
				i {
					position: absolute;
					left: 0;
					top: 5px;
					width: 40px;
				}
			}
		}
	}
	&.cookiePolicy {
		&.top {
			top: 0;
			border-bottom: 4px solid $crest-red;
		}
		&.bottom {
			bottom: 0;
			border-top: 4px solid $crest-red;
		}
		width: 100%;
		.cookie__inner {
			max-width: 800px;
			text-align: left;
			margin-left: auto;
			margin-right: auto;
			p {
				float: none;
				text-align: left;
				font-size: 14px;
				padding-right: 40px;
			}
			.btn {
				@include sans-r;
				border: 2px solid $sky-blue;
				border-radius: 6px;
				transition: all 0.25s ease;
				@media (max-width: $screen-sm) {
					margin-top: 10px;
				}
				&:hover {
					background-color: $sky-blue;
					color: white;
				}
			}
		}
	}
	&.example5 {
		bottom: 50px;
		right: 50px;
		width: 98%;
		max-width: 400px;
		border-radius: 12px;
		padding: 20px;
		h2 {
			font-size: 20px;
			margin-top: 0;
			@include sans-b;
		}
		p {
			font-size: 16px;
			line-height: 18px;
			margin-bottom: 15px;
		}
		.btn--outline {
			border: 1px solid $crest-yellow;
			padding: 2px 8px;
			min-height: 100%;
			width: 100px;
			color: $crest-yellow;
			@include sans-r;
			float: left;
			transition: all 0.2s ease;
			&:hover {
				background-color: $crest-yellow;
				color: $crest-black;
			}
			&.btn--underline {
				border: none;
				text-decoration: underline;
				float: right;
			}
		}
	}
}




/*SLICK
**************************************************/

.slideShowMultiple.slick-slider .slick-next {
	position: absolute;
	top: 35%;
	bottom: 35%;
	right: 0;
	left: auto;
	background: rgba($crest-yellow, 0.6);
	width: 70px;
	height: auto;
    -webkit-transform: translate(0,0);
	-ms-transform: translate(0,0);
	transform: translate(0,0);
	transition: .5s ease;
	&:before {
		content:"\f105";
		font-family: "FontAwesome";
		color: #fff;
		font-size: 74px;
	}
	&:hover {
		background: rgba($crest-yellow, 0.9);
	}
}



/*FORMS
standard forms based on bootstrap styles
*****************************************************/

.form-general legend {
	font-family: $font-family-serif;
	color: $crest-blue;
	border-bottom: 2px solid $crest-yellow;
	padding-bottom: 5px;
	margin-bottom: 15px;
}

.form-general fieldset + fieldset legend {
	padding-top: 20px;
}

.form-general .form-group {
	margin-bottom: 7px;
}

.form-general .form-control {
	font-size: 16px;
}

.form-general label {
	padding-top: 10px;
}

.form-general .checkbox label {
	padding-top: 3px;
}

.form-general .note {
	font-style: italic;
	margin: 0 0 ;
	opacity: 0.75;
}

.form-general .form-control + .note {
	margin-top: 5px;
}

.form-general legend + .note {
	margin-bottom: 26px;
}

.form-general .checkbox .note + label {
	margin-top: 20px;
}

.form-general .btn {
	margin-top: 20px;
}

@media (min-width: $screen-lg) {

	.form-general.well {
		padding: 40px;
		margin: 30px auto;
	}

	.form-general legend {
		padding-bottom: 10px;
	}

	.form-general fieldset + fieldset legend {
		padding-top: 20px;
	}

	.form-general .form-group {
		margin-bottom: 20px;
	}

}


/*FORMS
standard forms based on old website html
*****************************************************/

.content-wrap__inner .search-form form {
	width: 100% !important;
	background: transparent;
	padding: 0;
}

.content-wrap__inner form {
	width: 100% !important;
	background: $tint-crest-white;
	padding: 20px 15px;
}

.content-wrap__inner form table {
	width: 100% !important;
}

.content-wrap__inner form table td:first-child {
	max-width: 140px;
	padding-right: 10px;
}

.content-wrap__inner form table tr {
	border-bottom: 1px solid $tint-crest-white-darker;
}

.content-wrap__inner form table th,
.content-wrap__inner form table td {
	padding: 10px 0;
	vertical-align: top;
	@media all and (min-width: $screen-sm) {
	  padding: 10px 5px;
	}
}

.content-wrap__inner form table h3 {
	font-weight: normal;
	font-size: 18px;
	margin: 0;
	padding-top: 10px;
	color: $crest-blue;
}

.content-wrap__inner form table input[type="text"],
.content-wrap__inner form table input[type="email"],
.content-wrap__inner form table input[type="date"],
.content-wrap__inner form table input[type="password"],
.content-wrap__inner form table textarea,
.content-wrap__inner form table select {
	display: block;
    width: 100%;
    height: 40px;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.33333;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    -webkit-transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;
    -webkit-transition: border-color ease-in-out 0.15s,-webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s,-webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s,-webkit-box-shadow ease-in-out 0.15s;
}

.content-wrap__inner form table textarea {
	height: auto;
}

.content-wrap__inner form table select#DOB_Day {
	width: 25%;
	float: left;
	margin-right: 2.5%;
}

.content-wrap__inner form table select#DOB_Month {
	width: 40%;
	float: left;
	margin-right: 2.5%;
}

.content-wrap__inner form table select#DOB_Year {
	width: 30%;
	float: left;
}


.content-wrap__inner form table button,
.content-wrap__inner form table input[type="reset"],
.content-wrap__inner form table input[type="submit"] {
	display: inline-block;
	font-weight: normal;
	text-align: center;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none;
	border: 1px solid transparent;
	white-space: nowrap;
	padding: 6px 12px;
	font-size: 20px;
	line-height: 1.33333;
	border-radius: 4px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	position: relative;
	font-size: 16px;
	font-family: 'Playfair Display', serif;
	font-weight: 700;
	border-radius: 0;
	border: none;
	padding: 12px 20px;
	min-height: 50px;
	-webkit-transition: background-color 0.3s, color 0.3s;
	-o-transition: background-color 0.3s, color 0.3s;
	transition: background-color 0.3s, color 0.3s;
	padding: 18px 30px;
	font-size: 20px;
	margin: 20px 0;
}

.content-wrap__inner form table input[type="submit"] {
	background-color: #ffb500;
	color: #003c69;
}

.content-wrap__inner form table input[type="reset"] {
	background-color: $limestone;
	color: #fff;
}

@media (max-width: $screen-sm) {

	.content-wrap__inner form {
		padding: 15px;
	}

	.content-wrap__inner form table,
	.content-wrap__inner form table tr,
	.content-wrap__inner form table th,
	.content-wrap__inner form table td,
	.content-wrap__inner form table tbody,
	.content-wrap__inner form table thead {
		display: block;
		width: 100%;
		overflow: hidden;
		border: 0;
	}

	.content-wrap__inner form table td:first-child {
		max-width: 100%;
		padding-right: 0;
		padding-bottom: 0;
	}

	.content-wrap__inner form p {
		margin-bottom: 0;
	}
}

//== bootstrap select

@media (min-width: 320px) and (max-width: 600px) {
	.bootstrap-select.form-control > button {
		padding: 6px 10px;
	}
	.bootstrap-select.btn-group .dropdown-menu li a {
		padding: 3px 10px;
		span.text {
			font-size: 11px;
			@media (min-width: 400px) {
				font-size: 14px;
			}
		}
	}
}

//media buttons

.btn--media {
	position: relative;
	width: 42px;
	height: 42px;
	border-radius: 50%;
	background-color: rgba($crest-black,0.4);
	border: 2px solid $crest-white;
	color: $crest-white;
	font-size: 13px;
	transition: .5s ease;
	&:hover {
		background-color: rgba($crest-black,0.7);
		border: 2px solid $crest-yellow;
		color: $crest-yellow;
	}
	&:before {
		font-family: FontAwesome;
		display: block;
		position: relative;
	}
	&.btn--play {
		&:before {
			content: "\f04b";
			left: 2px;
			//top: 1px;
		}
	}
	&.btn--pause {
		&:before {
			content:"\f04c";
			//top: 1px;
		}
	}
}


//colours

.bg-crest-white {
	&.btn {
		color: $crest-blue;
		border: 2px solid transparent;
		.fa {
			color: $crest-yellow;
		}
		&:hover {
			background: $crest-yellow;
			color: $crest-blue;
			border: 2px solid $crest-white;
			.fa {
				color: $crest-white;
			}
		}
	}
}
.bg-tint-crest-white {
	&.btn {
		color: $crest-blue;
		border: 2px solid transparent;
		.fa {
			color: $crest-yellow;
		}
		&:hover {
			background: $crest-blue;
			color: $crest-white;
			border: 2px solid $crest-white;
			.fa {
				color: $crest-yellow;
			}
		}
	}
}
.bg-crest-black {
	&.btn {
		color: $crest-white;
		border: 2px solid transparent;
		.fa {
			color: $crest-yellow;
		}
		&:hover {
			background: $crest-white;
			color: $crest-black;
			border: 2px solid $crest-black;
			.fa {
				color: $crest-yellow;
			}
		}
	}
}
.bg-crest-red {
	&.btn {
		color: $crest-white;
		border: 2px solid transparent;
		.fa {
			color: $crest-yellow;
		}
		&:hover {
			background: $crest-white;
			color: $crest-blue;
			border: 2px solid $crest-red;
			.fa {
				color: $crest-yellow;
			}
		}
	}
}
.bg-crest-blue {
	&.btn {
		color: $crest-white;
		border: 2px solid transparent;
		.fa {
			color: $crest-yellow;
		}
		&:hover {
			background: $crest-white;
			color: $crest-blue;
			border: 2px solid $crest-yellow;
			.fa {
				color: $crest-yellow;
			}
		}
	}
}
.bg-quad-green {
	&.btn {
		color: $crest-white;
		border: 2px solid transparent;
		.fa {
			color: $crest-yellow;
		}
		&:hover {
			background: $crest-white;
			color: $crest-blue;
			border: 2px solid $quad-green;
			.fa {
				color: $crest-yellow;
			}
		}
	}
}
.bg-sky-blue {
	&.btn {
		color: $crest-white;
		border: 2px solid transparent;
		.fa {
			color: $crest-blue;
		}
		&:hover {
			background: $crest-white;
			color: $crest-blue;
			border: 2px solid $sky-blue;
			.fa {
				color: $crest-blue;
			}
		}
	}
}
.bg-crest-yellow {
	&.btn {
		color: $crest-blue;
		border: 2px solid transparent;
		.fa {
			color: $crest-white;
		}
		&:hover {
			background: $crest-white;
			color: $crest-blue;
			border: 2px solid $crest-yellow;
			.fa {
				color: $crest-yellow;
			}
		}
	}
}
.bg-earth-red {
	&.btn {
		color: $crest-white;
		border: 2px solid transparent;
		.fa {
			color: $crest-yellow;
		}
		&:hover {
			background: $crest-white;
			color: $crest-blue;
			border: 2px solid $earth-red;
			.fa {
				color: $crest-yellow;
			}
		}
	}
}
.bg-ogham-stone {
	&.btn {
		color: $crest-white;
		border: 2px solid transparent;
		.fa {
			color: $crest-yellow;
		}
		&:hover {
			background: $crest-white;
			color: $crest-blue;
			border: 2px solid $ogham-stone;
			.fa {
				color: $crest-yellow;
			}
		}
	}
}
.bg-dawn-mauve {
	&.btn {
		color: $crest-white;
		border: 2px solid transparent;
		.fa {
			color: $crest-yellow;
		}
		&:hover {
			background: $crest-white;
			color: $crest-blue;
			border: 2px solid $dawn-mauve;
			.fa {
				color: $crest-yellow;
			}
		}
	}
}
.bg-limestone {
	&.btn {
		color: $crest-black;
		border: 2px solid transparent;
		.fa {
			color: $crest-yellow;
		}
		&:hover {
			background: $crest-white;
			color: $crest-black;
			border: 2px solid $limestone;
			.fa {
				color: $crest-yellow;
			}
		}
	}
}
.bg-brand-twitter {
	&.btn {
		color: $crest-white;
		border: 2px solid transparent;
		.fa {
			color: $crest-blue;
		}
		&:hover {
			background: $crest-white;
			color: $crest-blue;
			border: 2px solid $brand-twitter;
			.fa {
				color: $crest-blue;
			}
		}
	}
}
.bg-brand-facebook {
	&.btn {
		color: $crest-white;
		border: 2px solid transparent;
		.fa {
			color: $crest-yellow;
		}
		&:hover {
			background: $crest-white;
			color: $crest-blue;
			border: 2px solid $brand-facebook;
			.fa {
				color: $crest-yellow;
			}
		}
	}
}
