body.stop-scrolling {
  overflow: auto !important;
  height: auto !important;
}
.quiz-question {
  //width: 100%;
  //display: block;
  //margin: 0 auto;
  //text-align: center;
  //margin-top: 100px;
  //font-size: 32px;
  //margin-bottom: 20px;
}

.quiz-answers {
  //text-align: center;
  //width: 60%;
  //margin: 0 auto;
  //display: block;
  //padding-bottom: 5px;
}

.sweet-alert button {
 border-radius: 0;
    text-transform: uppercase;
    font-weight: 400;
}
.quiz-button.btn {
 // margin-top: 10px;
  //background-color: #ddd;
   // border-radius: 0;
    //text-transform: uppercase;
}

.quiz-button.btn:hover {
  //background-color: #0096D2;
  //color: #fff;
}

.quiz-button.btn:not(:first-child) {
  //margin-left: 10px;
}

.question-image {
  width: 100%;
  max-width: 500px;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;

  margin: 0 auto 30px;

}

.question-image img,
.results-ratio,
.results-social,
.quiz-title
{
  display: block;
  margin: 0 auto;
  text-align: center;
}

.results-ratio,
.results-social,
.quiz-title {
  max-width: 600px;
}

.question-image img {
  margin-top: 20px;

}
.results-social{
 display: table;
}
.correct-text {
  margin-top: 10px;
  font-style: italic;
  font-family: Georgia, serif;
}

.quiz-title {
  //font-size: 60px;
  //font-weight: 300;
  //margin-top: 100px;
  //margin-bottom: 30px;
}

.results-ratio {
  margin: 20px auto;
  font-size: 24px;
  font-weight: bold;
}

.results-social {
  margin-top: 30px;
}

.results-social a:not(:first-child) {
  margin-left: 20px;
}

.progress-circles {
  //position: relative;
  //padding: 0;
  //margin: 0;
/*  left: 40px;*/
    //margin-left: auto;
    //margin-right: auto;
  //top: 40px;
  display: none;
}

.progress-circles.show {
  display: block;
}

.show{
 display:table !important;
}

.progress-circles li{
  //list-style: none;
  //display: inline-block;
  //background: #ccc;
  //width: 15px;
  //height: 15px;
  //border-radius: 50%;
  //margin-right: 10px;
}
.progress-circles li.dark{
  background: #1696d2;
}


//CUSTOM STYLES

.module-37 {

  .carousel {
    background-color: $crest-black;
    padding: 30px;
    @media (min-width: 993px) {
      padding: 60px 30px;
    }
  }

  *:not(.btn):not(span):not(.module-title) {
    color: $crest-white;
  }

  .quiz-title {
    margin: 0 auto 30px;
    @media (min-width: 993px) {
      font-size: 30px;
    }
  }

  .quiz-button {
    background-color: $crest-yellow;
    color: $crest-black;
    margin: 10px;
    display: inline-block;
    width: calc(50% - 20px);
    padding: 18px 15px;
    @media (max-width: 992px) {
      display: block;
      width: 100%;
      margin: 10px 0;
    }
    &:hover {
      transform: scaleY(1.05)scaleX(1.02);
      background-color: $crest-white;
      color: $crest-black;
    }
  }

  .quiz-button.btn {
  img,
  span {
    display: block;
  }
  img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin: 0 auto 15px;
  }
  img + span {
    background-color: $crest-white;
    color: $crest-black !important;
    border-radius: 8px;
    padding: 18px 15px;
    transition: .3s ease;
  }
  &:hover {
    img + span {
      background-color: $quad-green;
    }
  }
}

  .quiz-question {
    font-size: 24px;
    text-align: center;
    margin: 30px auto;
    @media (min-width: 993px) {
      font-size: 30px;
    }
  }

  .quiz-answers {
    margin: 0 auto;
    text-align: center;
    max-width: 1200px;
  }

  .progress-circles { 
      counter-reset: item;
      list-style-type: none;
      margin: auto !important;
      padding: 0 !important;
  }
  .progress-circles li { 
    display: inline-block;
    margin: 0 5px !important;
    padding: 4px 0 0 0 !important;
    width: 40px;
    height: 40px;
    text-align: center;
    background-color: $crest-white;
    color: $crest-black !important;
    font-size: 20px;
    font-weight: 700;
    border-radius: 50%;
  }
  .progress-circles li:before { 
      content: counter(item) "  "; 
      counter-increment: item 
  }
  .progress-circles li.dark {
    background: $crest-red;
    color: $crest-white !important;
  }

  .sa-icon {
    .sa-success {
      .sa-line {
        background-color: $crest-red;
      }
    }
  }

  .quiz-hint {
    text-align: center;
    .btn {
      color: $crest-white;
      text-decoration: underline !important;
      font-weight: 400;
      border: 0;
      padding: 0;
      margin: 0 auto 30px;
    }
    .well {
      background: transparent;
      margin: 0;
    }
  } 

}

//modal alert

body {

  .sweet-alert .sa-icon.sa-success .sa-line {
    background-color: $quad-green;
  }
  .sweet-alert .sa-icon.sa-success {
    border-color: $quad-green;
  }
  .sweet-alert .sa-icon.sa-success .sa-placeholder {
    border-color: $quad-green;
  }

  .sweet-alert .sa-icon.sa-error .sa-line {
    background-color: $crest-red;
  }
  .sweet-alert .sa-icon.sa-error {
    border-color: $crest-red;
  }
  .sweet-alert .sa-icon.sa-error .sa-placeholder {
    border-color: $crest-red;
  }

  .sweet-alert .sa-icon.sa-warning .sa-line,
  .sweet-alert .sa-icon.sa-warning .sa-body,
  .sweet-alert .sa-icon.sa-warning .sa-dot {
    background-color: $ogham-stone;
  }
  .sweet-alert .sa-icon.sa-warning {
    border-color: $ogham-stone;
  }
  .sweet-alert .sa-icon.sa-warning .sa-placeholder {
    border-color: $ogham-stone;
  }

  .sweet-alert .sa-icon.sa-info .sa-line,
  .sweet-alert .sa-icon.sa-info::after,
  .sweet-alert .sa-icon.sa-info::before {
    background-color: $sky-blue;
  }
  .sweet-alert .sa-icon.sa-info {
    border-color: $sky-blue;
  }
  .sweet-alert .sa-icon.sa-info .sa-placeholder {
    border-color: $sky-blue;
  }

  .sweet-alert h2 {
    color: $crest-black;
    margin: 20px 0 10px;
  }

  .sweet-alert p {
    color: $crest-black;
    font-size: 18px;
    font-weight: 400;
    .correct-text {
      font-family: Roboto, sans-serif;
    }
  }

  .sweet-alert button {
    background-color: $crest-yellow !important;
    color: $crest-black;
    text-decoration: none !important;
    position: relative;
    font-size: 20px;
    font-family: 'Playfair Display', serif;
    font-weight: 700;
    border-radius: 0;
    border: none;
    padding: 15px 20px;
    -webkit-transition: .3s ease;
    transition: .3s ease;
    text-transform: none;
    margin-bottom: 20px;
  }

  .sweet-alert .quiz-explanation{
    background-color: $tint-crest-white;
    padding: 10px;
    margin-top: 20px;
    p {
      margin: 10px 0;
    }
    a {
      text-decoration: underline;
    }
  }

}



