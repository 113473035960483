.gbTimeline {
    display: none;
    /*bgTimeline.jpg*/
    background: $limestone url('/en/media/2017siteassets/images/bgTimeline.jpg') center center no-repeat;
    //box-shadow: inset 0 0 80px rgba(0, 0, 0, 0.7);
    padding-bottom: 30px;
    background-size: cover;
    font-size: 16px;
    line-height: 24px;
}
.timelineLoader {
    width: 100%;
    text-align: center;
    padding: 150px 0;
}
.image_roll_zoom {
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    /*zoomIn.png*/

    background: url('/en/media/2017siteassets/contentfiles/images/zoomIn.png') no-repeat center center;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease;
}
a.image_rollover_bottom:hover .image_roll_zoom {
    top: -5% !important;
    z-index: 10;
}
.image_roll_glass {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /*glass*/

    background: url('/en/media/2017siteassets/contentfiles/images/glass.png') repeat;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease;
}
a.image_rollover_bottom:hover .image_roll_glass {
    opacity: 1;
    z-index: 9;
}
/*TIMELINE TOP SLIDER
*********************************************************************/
/* fixed line holder */

.gbTimeline .timeline_line {
    margin-top: 10px;
    /*margin-bottom:10px;*/

    width: 920px;
}
/* full (including months that are not shown) line holder */

.gbTimeline .t_line_holder {
    height: 130px;
    /*line.png*/

    background: url('/en/media/2017siteassets/images/line.png') repeat-x 0px 100px;
}
/* 2 months are stored in one view */

.gbTimeline .t_line_view {
    height: 20px;
    width: 920px;
}
/* holder for year number */

.gbTimeline h3.t_line_year {
    margin: 0;
    color: #fff;
}
/* holder for 1 month (constist of nodes and month caption) - we use borders to separate months thats why it has width 2px less then 50% */

.gbTimeline .t_line_m {
    margin-top: 35px;
    height: 10px;
    /*border-left:1px solid #fff;
	border-right:1px solid #fff;*/

    width: 459px;
}
/* month on the right side - has left set at 459 so border would overlap border from first element (to evade duplicated borders) */

.gbTimeline .t_line_m.right {
    left: 459px;
    width: 459px;
}
/* month caption */

.gbTimeline h4.t_line_month {
    margin: -30px 0 0;
    color: #fff;
    display: none;
}
/* used in responsive layout when only one month is shown (it is span containing year) */

.t_line_month_year {
    display: none;
}
/* node on the timeline */

.gbTimeline a.t_line_node {
    text-decoration: none;
    padding: 0 0 58px;
    height: 10px;
    font-size: 12px;
    top: 27px;
    /*time line dot.png*/
    background: url('/en/media/2017siteassets/images/dot.png') no-repeat center bottom;
    color: $crest-black;
    min-width: 36px;
}
.gbTimeline a.t_line_node:hover {
    /*time line dot-selected.png*/

    background: url('/en/media/2017siteassets/images/dot-selected.png') no-repeat center bottom;
}
.gbTimeline a.t_line_node.active {
    /*time line dot-selected.png*/

    background: url('/en/media/2017siteassets/images/dot-selected.png') no-repeat center bottom;
}
/* node description */

.gbTimeline .t_node_desc {
    background-color: $crest-red;
    background: rgba(205, 23, 25, 0.7);
    top: 4px;
    left: 25px;
    color: #fff;
    padding: 2px 10px;
    display: none !important;
}
/* descriptions on right side go from right to left */

.gbTimeline .t_line_m.right .t_node_desc {
    right: 0;
    left: auto;
}
/* line arrow left */

.gbTimeline #t_line_left {
    cursor: pointer;
    left: -30px;
    top: 90px;
    width: 14px;
    height: 19px;
    opacity: 0.5;
}
.gbTimeline #t_line_left:hover {
    opacity: 1;
}
.gbTimeline #t_line_left:before {
    content: "\f104";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    /*--adjust as necessary--*/

    color: $crest-black;
    font-size: 24px;
}
/* line arrow right */

.gbTimeline #t_line_right {
    cursor: pointer;
    right: -30px;
    top: 90px;
    width: 14px;
    height: 19px;
    opacity: 0.5;
}
.gbTimeline #t_line_right:hover {
    opacity: 1;
}
.gbTimeline #t_line_right:before {
    content: "\f105";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    /*--adjust as necessary--*/

    color: $crest-black;
    font-size: 24px;
}
/*section nodes*/

span.sectionNode {
    display: block !important;
    position: relative;
    top: -32px;
    line-height: 14px;
    letter-spacing: -0.05em;
}
span.sectionNode strong {
    display: block;
    font-size: 18px;
    line-height: 18px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    letter-spacing: 0;
    font-family: $font-family-serif;
}
/*TIMELINE ITEMS
*********************************************************************/
/* items container */

.gbTimeline .timeline_items {
    padding: 10px 0;
}
/* single item (margines set from javascript) */

.gbTimeline .item {
    background: #030e15;
    //-moz-box-shadow: -3px 1px 6px rgba(0, 0, 0, 0.2);
    //-webkit-box-shadow: -3px 1px 6px rgba(0, 0, 0, 0.2);
    //box-shadow: -3px 1px 6px rgba(0, 0, 0, 0.2);
    width: 420px;
    height: 420px;
    overflow: hidden;
}
.gbTimeline .item.sectionCard {
    background: $crest-yellow;
}
.gbTimeline .item.medium {
    width: 420px;
    height: 420px;
}
.gbTimeline .item.small {
    width: 420px;
    height: 420px;
}
.gbTimeline .item.item_node_hover {
    -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
/* ----- content - non-plugin elements ----- */

.gbTimeline .item.video > a {
    display: block;
}

.gbTimeline .item.video > a:before {
    content:"\f144";
    font-family: "FontAwesome";
    color: $crest-red;
    font-size: 56px;
    line-height: 56px;
    width: 56px;
    position: absolute;
    z-index: 9;
    left: 50%;
    top: 35%;
    margin-left: -28px;
    margin-top: -28px;
}

.gbTimeline .item img {
    margin-top: 0;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: auto;
}
.gbTimeline .item .text {
    margin-top: 0;
    position: absolute;
    z-index: 10;
    bottom: 0;
    background-color: #fff;
    background: rgba(255, 255, 255, 0.8);
    color: #1c2b36;
    font-size: 13px;
    line-height: 19px;
    padding: 20px;
    width: 100%;
}
.gbTimeline .item .text.read_more {
    cursor: pointer;
}
.gbTimeline .item.sectionCard .text {
    margin-top: 0;
    position: absolute;
    z-index: 10;
    bottom: 0;
    background-color: transparent;
    color: #fff;
}
.gbTimeline .item.quote .text {
    position: relative;
    bottom: auto;
    background-color: #030e15;
    background: rgba(3, 14, 21, 0.8);
    color: #ccd4da;
    font-size: 13px;
    line-height: 19px;
    padding: 15px;
    font-style: italic;
    text-align: right;
}
.gbTimeline .item .text.read_more:hover {
    background-color: #fff;
    background: #fff;
}
.gbTimeline .item.quote .text:hover {
    background-color: #030e15;
    background: #030e15;
}
.gbTimeline .item h2 {
    margin: 0 0 5px;
    font-size: 19px;
    color: #030e15;
}
.gbTimeline .item h2 strong,
.gbTimeline .item h2 b {
    color: $crest-red;
    //font-weight: normal;
}
.gbTimeline .item.sectionCard h2 {
    margin: 0 0 5px;
    font-size: 40px;
    line-height: 42px;
    color: #fff;
}
.gbTimeline .item.sectionCard h2 strong,
.gbTimeline .item.sectionCard h2 b {
    color: #fff;
    font-size: 20px;
    display: block;
}
.gbTimeline .item.quote h2 {
    margin: 0 0 8px;
    font-size: 16px;
    line-height: 23px;
    color: #fff;
    text-transform: none;
    font-style: normal;
    text-align: left;
    letter-spacing: 0;
}
.gbTimeline .item.quote h2:before {
    content: "\f10d";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    /*--adjust as necessary--*/

    color: #4e677a;
    font-size: 16px;
    position: relative;
    top: -5px;
    padding-right: 5px;
}
.gbTimeline .item span.read_more {
    text-indent: -5000px;
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: $crest-red;
    position: relative;
    margin: 0 0 0 5px;
}
.gbTimeline .item.quote span.read_more {
    top: 4px;
}
.gbTimeline .item span.read_more:before {
    content: "\f067";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    /*--adjust as necessary--*/

    color: #fff;
    font-size: 10px;
    text-indent: 0;
    display: inline-block;
    position: absolute;
    top: 0px;
    left: 4px;
}
/* ----------------------------------------- */
/* item details (margines set from javascript) */

.gbTimeline .item_open {
    background-color: #fff;
    background: rgba(255, 255, 255, 0.8);
    position: relative;
    color: #1c2b36;
    z-index: 2;
    -moz-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
    width: 420px;
    height: 420px;
    font-size: 13px;
    line-height: 19px;
}
.gbTimeline .item_open.medium {
    /*width: 310px;*/

    height: 310px;
}
.gbTimeline .item_open.small {
    /*width: 200px;*/

    height: 200px;
}
/* item details content wrapper (used for animation - shuld have same width as .item_open) */

.gbTimeline .item_open_cwrapper {
    width: 420px;
}
.gbTimeline .medium .item_open_cwrapper {
    /*width: 310px;*/
}
.gbTimeline .small .item_open_cwrapper {
    /*width: 200px;*/
}
/* ----- content - non-plugin elements ----- */

.gbTimeline .timeline_open_content {
    padding: 20px;
}
.gbTimeline .item_open h2 {
    margin-top: 10px;
    padding-top: 0;
    font-size: 19px;
    color: #030e15;
}
.gbTimeline .item_open h2 strong,
.gbTimeline .item_open h2 b {
    color: $crest-red;
}
.gbTimeline .item_open p {
    padding-bottom: 15px;
}
.gbTimeline .item_open .t_close {
    text-indent: -5000px;
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: $crest-red;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
.gbTimeline .item_open .t_close:before {
    content: "\f00d";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    /*--adjust as necessary--*/

    color: #fff;
    font-size: 12px;
    text-indent: 0;
    display: inline-block;
    position: absolute;
    top: -1px;
    left: 3px;
}
/* ----------------------------------------- */
/* left/right controles */

.gbTimeline .t_controles {
    margin: 10px auto;
    text-align: center;
}
.gbTimeline .t_left,
.gbTimeline .t_right {
    position: absolute;
    display: block;
    height: 100%;
    width: 75px;
    margin: 0;
    cursor: pointer;
    top: 0;
    z-index: 10;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease;
    background-color: #030e15;
    background: rgba(3, 14, 21, 0.4);
    // opacity: 0.8;
}
.gbTimeline .t_right {
    right: 0;
}
.gbTimeline .t_left:hover,
.gbTimeline .t_right:hover {
    background-color: #030e15;
    background: rgba(3, 14, 21, 0.7);
    opacity: 1;
}
.gbTimeline .t_left:before,
.gbTimeline .t_right:before {
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    /*--adjust as necessary--*/

    color: #fff;
    font-size: 60px;
    position: absolute;
    top: 48%;
}
.gbTimeline .t_left:before {
    content: "\f104";
    left: 40%;
}
.gbTimeline .t_right:before {
    content: "\f105";
    right: 40%;
}
/* -----------------------------------------------------------------------*/
/* ------------------------------ RESPONSIVE -----------------------------*/
/* -----------------------------------------------------------------------*/
/* --- 768px --- */

@media screen and (max-width: 959px) {
    .gbTimeline .timeline_line {
        width: 728px;
    }
    .gbTimeline .t_line_view {
        width: 728px;
    }
    .gbTimeline .t_line_m {
        width: 362px;
    }
    .gbTimeline .t_line_m.right {
        left: 363px;
        width: 363px;
    }
    .gbTimeline .t_left,
    .gbTimeline .t_right {
        height: 70%;
        width: 35px;
        top: 23%;
    }
    .gbTimeline .t_left:before,
    .gbTimeline .t_right:before {
        font-size: 32px;
        top: 48%;
    }
}
/* --- 610px --- */

@media screen and (max-width: 767px) {
    .gbTimeline .timeline_line {
        width: 570px;
    }
    .gbTimeline .t_line_view {
        width: 1140px;
    }
    .gbTimeline .t_line_m {
        width: 568px;
    }
    .gbTimeline .t_line_m.right {
        left: 570px;
        width: 568px;
    }
    .gbTimeline .t_line_year {
        opacity: 0;
        filter: alpha(opacity=0)
    }
    .gbTimeline .t_line_month_year {
        display: inline;
    }
    .gbTimeline .t_line_node span {} .gbTimeline .t_node_desc {
        font-size: 8px;
    }
    .timelineLight .t_line_m.right .t_node_desc {
        right: auto;
        left: 0;
    }
}
/* --- 300px --- */

@media screen and (max-width: 609px) {
    .gbTimeline .timeline_line {
        width: 500px;
    }
    .gbTimeline .t_line_view {
        width: 520px;
    }
    .gbTimeline .t_line_m {
        width: 258px;
    }
    .gbTimeline .t_line_m.right {
        left: 260px;
        width: 258px;
    }
    .gbTimeline .item_open {
        width: 290px;
        height: 390px;
    }
    .gbTimeline .item_open_cwrapper {
        width: 290px;
    }
}

@media screen and (max-width: 520px) {
    .gbTimeline .timeline_line {
        width: 260px;
    }
}
/* -----------------------------------------------------------------------*/
/* ------------------------------ CUSTOM ---------------------------------*/
/* -----------------------------------------------------------------------*/


.gbTimeline {
	position: relative;
}

.gbTimeline .t_left, .gbTimeline .t_right {
	max-height: 420px;
	top: 58%;
	transform: translateY(-50%);
}
.gbTimeline .t_left:before, .gbTimeline .t_right:before {
	top: 50%;
    margin-top: -12px;
}
@media (max-width: 959px) {
	.gbTimeline .t_left:before, .gbTimeline .t_right:before {
		top: 129px;
	}
    .gbTimeline .item {
        background: #030e15;
        width: 380px;
        height: 380px;
        overflow: hidden;
    }
    .gbTimeline .t_left, .gbTimeline .t_right {
        max-height: 50px;
        top: 40%;
        transform: none;
    }

    .gbTimeline .t_left:before, .gbTimeline .t_right:before {
        top: 25px;
    }

    .gbTimeline #t_line_right {
        right: -22px;
    }

    .gbTimeline #t_line_left {
        left: -16px;
    }
    .gbTimeline .item span.read_more:before {
        top: 2px;
    }

}




@media (max-width: 700px) {

    .gbTimeline .item,
    .gbTimeline .item.medium,
    .gbTimeline .item.small {
        width: 300px;
        height: 300px;
    }

    .gbTimeline a.t_line_node {
        transform: rotate(-90deg);
        text-align: left !important;
        top: 20px;
        background-position: left bottom !important;
        width: 100px !important;
        padding-left: 34px;
        padding-bottom: 30px;
    }

    span.sectionNode strong {
        font-size: 16px;
        line-height: 16px;
        padding-top: 15px;
    }

}