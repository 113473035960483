.related-articles {
	.related-articles__carousel {
		margin: 0 -5px;
	}
	.slick-track {
		display: flex;
		align-items: stretch;
	}
	.related-articles__item {
		margin: 0 5px;
		background-color: $crest-blue;
		height: auto;
	}

	.related-articles__heading {
		margin: 0;
		padding: 0;
		font-size: 24px;
		font-weight: 400;
		min-height: 250px;
		display: flex;
		a {
			display: block;
			width: 100%;
			color: $crest-white;
			margin: 0;
			padding: 20px 60px 20px 20px;
		}
		.fa {
			display: block;
			margin-top: 15px;
		}
	}

	.slick-next {
		position: absolute;
		top: calc(50% - 35px);
		right: 0;
		left: auto;
		background: rgba($crest-yellow, 1);
		width: 50px;
		height: 70px;
	    -webkit-transform: translate(0,0);
		-ms-transform: translate(0,0);
		transform: translate(0,0);
		transition: .5s ease;
		&:before {
			content:"\f105";
			font-family: "FontAwesome";
			color: $crest-black;
			font-size: 50px;
		}
		&:hover {
			background: rgba($crest-yellow, 0.9);
		}
	}
}

