
.content-wrap__wrapper {
	.article-tags {
		padding: 0 20px 15px 20px;
		display: flex;
		.article-tags__title {
			font-size: 16px;
			font-weight: 400;
			margin: 0;
			padding: 5px 10px 4px 0;
			flex-shrink: 0;
		}
		.article-tags__list {
			margin:0;
			padding: 0;
			list-style: none;
			display: flex;
			flex-wrap: wrap;
			li {
				margin: 0 5px 5px 0;
				list-style: none;
				background-color: $tint-crest-white;
				display: block;
				padding: 4px 10px;
				font-size: 16px;
				&:before {
					display: none;
				}
				a {
					text-decoration: none;
				}
			}
		}
	}
}

.content-wrap__wrapper {
	.articleFooter {
		.article-tags {
			clear: both;
			padding: 20px 20px 15px 20px;
			background-color: $tint-crest-white;
			.article-tags__title {
				font-size: 18px;
				padding-top: 9px;
			}
			.article-tags__list {
				li {
					font-size: 18px;
					background-color: $crest-white;
					padding: 8px 15px;
				}
			}
		}
	}
}

.related-articles + .article-tags {
	margin-top: 50px;
}