.module-31 {
  //margin-top: 30px;
  @media(min-width:768px) {
    margin-top: 45px;
  }
  @media(min-width:992px) {
    margin-top: 60px;
  }
  .sports {
    .module-title {
      @media(min-width: 992px) {
        margin: 0 0 30px;
        padding: 0;
        font-size: 32px !important;
      }
    }
    @media(min-width: 992px) {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
    }
    .sports__wrapper {
      @media(min-width: 992px) {
        flex: 0 0 100%;
      }
    }
    .sports__results ul {
      margin: 0;
      li::before {
        content: none;
      }
    }
    label.hideLabel {
      display: none;
    }
  }
  .dataTables_wrapper {
    overflow-x: auto;
    .dataTables_length {
      select {
        background-color: $crest-white;
      }
    }
    .dataTables_filter {
      input {
        background-color: $crest-white;
      }
    }
  }
}