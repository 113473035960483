.sfi--top {
	position: absolute;
	top: 30px;
	right: 0;
	z-index: 100;
	@media (min-width: 993px) and (max-width: 1200px) {
		top: 60px
	}
	@media (max-width: $screen-md-min) {
		img {
			max-height: 66px !important;
			width: auto !important;
		}
	}
}

.inner .sfi--top {
	@media (min-width: 993px) and (max-width: 1200px) {
		top: 70px
	}
}


.home {
	.sfi--top {
		top: 170px;
	}

}

.sfi--top + .module-wrap {
	.content-wrap__wrapper {
		.content-wrap__inner {
			@media (max-width: $screen-md-min) {
				padding-top: 70px;
			}
			.left-side-nav {
				@media (max-width: $screen-md-min) {
					margin-top: 15px;
				}
			}
			ol.breadcrumb {
				@media (min-width: $screen-md-min) {
					padding-right: 220px;
				}
			}
			.page-title {
				@media (min-width: $screen-md-min) {
					padding-right: 220px;
					padding-bottom: 20px;
				}
			}
		}
	}
}