.hyper-list {
	ol {
		padding: 0;
		counter-reset: li;
		column-count: 1;
		> li {
			position: relative;
			list-style: none;
			margin-bottom: 0;
			min-height: 30px;
			padding: 0 0 0 30px;
			&:before {
				content: counter(li,decimal-leading-zero)'.';
				position: absolute;
				top: -5px;
				left: 0;
				counter-increment: li;
				@include serif-r;
				color: darken($crest-red,10%);

			}
			a {
				position: relative;
				bottom: 5px;
				//border-bottom: 2px solid $limestone;
				font-style: normal;
				text-decoration: underline;
				color: $crest-blue;
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
	&.two-col {
		column-count: 2;
		> li a {
			border-bottom: 2px solid $limestone;
		}
	}
}


.hyper-list-btn {
	h2 button, h3 button {
		border: 0;
		background: transparent;
		padding: 0;
	}
	background: rgba(255,255,255,0.9);
	ol {
		padding: 0;
		margin: 0;
		overflow: hidden;
		> li {
			position: relative;
			list-style: none;
			margin: 2px 2px 2px 0 !important;
			float: left;
		}
		a, span {
			background: $tint-crest-white;
			color: $crest-blue;
			display: block;
			padding: 4px 10px 3px;
			transition: .5s ease;
			text-decoration: none !important;
			&:hover, &.currentsection {
				text-decoration: none;
				background: $crest-yellow;
				color: $crest-blue;
			}
		}
	}
	&.hyper-list-bullet--vertical {
		ol {
			li {
				clear: left;
			}
		}		
	}
}



@media (max-width: $screen-lg-min - 1) {

	.hyper-list-btn {
		h2 button, h3 button {
			position: relative;
			width: 100%;
			text-align: left;
			border: 0;
			padding: 15px;
			font-family: 'Playfair Display', serif;
			font-weight: 400;
			font-size: 22px;
			outline: none;
			color: #fff;
			background: $crest-blue;
			&:after {
			    content: "\f0c9";
			    font-family: 'FontAwesome';
			    font-weight: normal;
			    font-weight: normal;
			    position: absolute;
			    right: 15px;
			    top: 13px;
			    font-size: 26px;
			    color: $crest-yellow;
			}
			&[aria-expanded="true"]:after {
			    content: "\f00d";
			}
		}
	}
}



@media (min-width: $screen-lg-min) {

	.hyper-list-btn {
		h2 button, h3 button {
			outline: none;
		}
		ol.collapse, ol.collapsing {
			display: block !important;
			height: auto !important;
		}
	}

}

.module-wrap:not(.course) {
	.hyper-list-wrap {
		clear: both;
		h2 {
			margin: 0;
			padding: 15px 15px 0;
			//font-size: 16px;
		}
	}
	.hyper-list-btn {
		background-color: $tint-crest-white;
		ol {
			padding: 0 15px 0;
			margin: 0;
			@media (min-width: $screen-lg-min) {
				padding-top: 15px;
			}
			li {
				&:last-child {
					padding-bottom: 15px;
				}
			}
			a {
				background: $crest-white;
				color: $crest-blue;
				&:hover {
					text-decoration: none;
					background: $crest-yellow;
					color: $crest-blue;
				}
			}
		}
	}
}

.module-wrap.course {
	.hyper-list-btn {
		ol {
			a {
				background: $quad-green;
				color: $crest-white;
				&:hover {
					text-decoration: none;
					background: $crest-yellow;
					color: $crest-blue;
				}
			}
		}
	}
}

.module-wrap:not(.course) {
	.hyper-list-wrap {
		background-color: $tint-crest-white;
		// ol {
		// 	&:nth-child(2) {
		// 		padding-top: 15px;
		// 	}
		// }
		+ .hyper-list-wrap {
			margin-top: 15px;
		}
		.btn--collapse-page-nav {
			position: relative;
			background-color: $tint-crest-white;
			color: $crest-blue;
			font-size: 16px;
			font-weight: 700;
			border: 0;
			width: 100%;
			padding: 15px;
			text-align: left;
			outline: none;
			&:after {
				content:"\f107";
				font-family: "FontAwesome";
				color: $crest-red;
				font-size: 24px;
				position: absolute;
				right: 15px;
				top: 10px;
				transition: .3s ease;
			}
			&[aria-expanded="true"] {
				&:after {
					transform: rotate(-180deg);
				}
			}
		}
		&.hyper-list-bullet {
			ol {
				list-style: none;
				margin: 0;
				padding: 0 15px;
				&:nth-child(2) {
					padding-top: 15px;
				}
				&.collapse,
				&.collapsing {
					@media (min-width: $screen-lg-min) {
						display: block !important;
						height: auto !important;
					}
				}
				li {
					margin: 0;
					&:last-child {
						padding-bottom: 15px;
					}
					a,
					span {
						display: block;
						position: relative;
						color: $crest-blue;
						text-decoration: underline;
						padding: 5px 5px 5px 20px;
						&:before {
							content: "";
							display: inline-block;
							width: 10px;
							height: 10px;
							border-radius: 50%;
							position: absolute;
							top: 12px;
							left: 0;
							background-color: $crest-red;
						}
						&.currentsection {
							font-weight: 700;
							text-decoration: none;
							cursor: default;
							&:before {
								background-color: $crest-yellow;
							}
						}
					}
				}
			}
			&.hyper-list-bullet--horizontal {
				@include clearfix;
				ol {
					li {
						float: left;
						padding-right: 7px;
						&:last-child {
							padding-bottom: 15px;
						}
					}
				}
			}
		}
	}
}

.hyper-list-btn.affix {
	top: 59px;
	left: 0;
	right: 0;
	z-index: 900;
	padding: 15px 15px 10px;
	//transition: .25s ease;
	@media (min-width: $screen-md) {
		top: 80px;
	}
	@media (min-width: 1040px) {
		top: 59px;
	}
}
.scroll-up .hyper-list-btn.affix {
	top: 129px;
	transition: .5s ease;
	@media (min-width: $screen-md) {
		top: 204px;
	}
	@media (min-width: 1040px) {
		top: 183px;
	}
}

@media (min-width: $screen-md) {

	.hyper-list-btn.affix {
		left: 120px;
		right: 25%;
		padding: 15px 0 10px;
	}

}


/////////////////////////////

//ON LANDING PAGES


.home .module-wrap:not(.course) {
    @media (max-width: 1199px) {
        .hyper-list-container {
            height: auto!important;
        }
        .hyper-list-wrap {
            //position: relative;
            top: 0!important;
            overflow: hidden;
            ol {
                li {
                    &:last-child {
                        a {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }
}

.home .module-wrap:not(.course) .hyper-list-wrap {
    ol li a {
        text-decoration: none;
        &::before {
            content: none;
        }
    }
    &.hyper-list-bullet ol {
        li {
            a,
            span {
                //color: white;
            }
        }
    }
    button {
        font-family: Roboto, sans-serif;
        font-weight: 700;
    }
    @media(max-width:1199px) {
        ol {
            margin: 0;
            padding: 0;
            border-top: 1px solid #aaa;
            flex: 1;
            li {
                float: none;

                margin: 0;
                padding: 0;
            }
            a,
            span {
                font-size: 16px;
                display: block;
                margin: 0 !important;
                padding: 20px 15px 10px;
                color: $crest-black;
                border-bottom: 0;
                border-radius: 0;
                background: transparent;
            }
        }
    }
    @media(min-width:1200px) {
        display: flex;
        padding: 15px 50px 10px;
        background-color: $tint-crest-white;
        justify-content: center;
        h2 {
            font-size: 20px;
            font-weight: 700;
            margin: 4px 0 0 0;
            padding: 5px 20px;
            color: $crest-black;
            font-family: Roboto, sans-serif;
            flex-shrink: 0;
        }
        ol {
            margin: 0;
            padding: 0;
            li {
                margin: 0;
                padding: 5px 20px;
                a,
	            span {
	                font-size: 20px;
	                margin: 0 !important;
	                padding: 0;
	                color: $crest-black;
	                background: transparent;
	            }
            }
            
        }
    }
}

.home .module-wrap:not(.course) {
    .hyper-list-wrap {
        &.hyper-list-bullet {
            &.hyper-list-bullet--horizontal {
                &:not([class*="affix"]) {
                    @media (min-width: $screen-sm-min) {
                        flex-wrap: wrap;
                        h2 {
                            text-align: center;
                            display: block;
                            width: 100%;
                        }
                        ol {
                            display: flex!important;
                            flex-wrap: wrap;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
}

.home .module-wrap:not(.course) {
    .hyper-list-wrap {
        &.hyper-list-bullet {
            &.hyper-list-bullet--horizontal {
                ol {
                     li {
                     	a, span {
                     		color: $crest-black;
                     		padding-top: 10px;
                     	}
                     }
                }
            }
        }
    }
}


.home .module-5 + .hyper-list-wrap{
	margin-top: 10px;
}



