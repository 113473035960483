.module-3 {
	position: relative;
	@include spacer-p-t;
	.wrapper {
		width: 100%;
		position: relative;
		height: 0;
		padding-bottom: 200%;
		@media (min-width: $screen-sm) {
			padding-bottom: 50%;
		}
		@media (min-width: $screen-md) {
			padding-bottom: 60%;
		}
		.inner {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			a {
				position: absolute;
				display: block;
				border: 10px solid white;
				.inner__img {
					@include absolute-cover;
					@include bg-cover-semi-center-home;
					&.inner__img {
						&:after {
							content: '';
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
						}
						&.inner--solid:after {
							opacity: 1;
						}
					}
				}
				&:nth-child(1) .inner__img:after {
					background-color: $crest-black-opac-light;
				}
				&:nth-child(2) .inner__img:after {
					background-color: $crest-red-opac;
				}
				&:nth-child(3) .inner__img:after {
					background-color: $crest-blue-opac;
				}
				&:nth-child(4) .inner__img:after {
					background-color: $crest-blue-opac;
				}
				&:nth-child(5) .inner__img:after {
					background-color: $crest-yellow;
				}


				&.inner--crest-yellow.solid .inner__img:after {
					background-color: $crest-yellow;
				}
				&.inner--crest-red.solid .inner__img:after {
					background-color: $crest-red;
				}
				&.inner--crest-blue.solid .inner__img:after {
					background-color: $crest-blue;
				}
				&.inner--crest-blue.solid .inner__img:after {
					background-color: $crest-blue;
				}

				.inner__content {
					@include absolute-cover;
					color: $crest-white;
					padding: 10px;
					p {
						position: static;
					    bottom: 0;
					    padding: 0;
					    margin: 8px 0 0 0;
					    font-size: 13px;
					    font-family: 'Roboto', sans-serif;
					    font-weight: 400;
					    padding-left: 20px;
						&.title {
							bottom: 30px;
						}

						@media (min-width: 1600px) {
							font-size: $font-size-h3;
						}
						&:first-of-type:after {
							content:"\f101";
							@include awesome;
							padding-left: 10px;
						}
					}
					blockquote {
						position: relative;
					    font-size: 16px;
					    line-height: 1.25;
					    font-family: 'Playfair Display', serif;
					    margin: 0;
					    padding: 0 0 0 20px;
					    border: 0;
					    &:before {
					    	position: absolute;
								top: -13px;
								left: 0;
								content: "\201C";
								font-size: 40px;
					    }
					}
					@media (min-width: 450px){
						padding: 30px 15px;
						blockquote {
							font-size: $font-size-base;
							line-height: $font-size-base + 4;
							&:before {
								top: 0;
							}
						}
						p {
							font-size: 14px;
							line-height: 1.2;
						}
					}
					@media (min-width: $screen-sm) and (max-width: $screen-md) {
						padding: 10px;
						blockquote {
							display: none;
						}
						p {
							padding-left: 0;
						}
					}
					@media (min-width: $screen-md) {
						padding: 10px;
					}
				}

				&:nth-of-type(2),
				&:nth-of-type(4),
				&:nth-of-type(3),
				&:nth-of-type(5) {
					z-index: 10;
				}
				&:nth-of-type(2) {
					top: 0;
					left: 0;
					width: 50%;
					height: 25%;
					border-left: 0;
				}
				&:nth-of-type(4) {
					top: 0;
					left: 50%;
					width: 50%;
					height: 25%;
					border-left: 0;
					border-right: 0;
				}
				&:nth-of-type(3) {
					top: 25%;
					left: 0;
					width: 50%;
					height: 25%;
					border-top: 0;
					border-left: 0;
				}
				&:nth-of-type(5) {
					top: 25%;
					left: 50%;
					width: 50%;
					height: 25%;
					border-top: 0;
					border-left: 0;
					border-right: 0;
				}
				&:nth-of-type(1) {
					top: 50%;
					left: 0;
					width: 100%;
					height: 50%;
					border-top: 0;
					border-left: 0;
					border-right: 0;
					.inner__content {

						@media (min-width: $screen-md) {
							left: 24%;
							p {
								padding: 60px 30px;
							}
						}
						@media (min-width: 1400px) {
							left: 24%;
							p {
								padding: 60px 30px;
							}
						}
						@media (min-width: 1600px) {
							left: 24%;
						}
					}
				}
				@media (min-width: 550px) {
					&:nth-of-type(2) {
						width: 55%;
						height: 27.5%;
						border-left: 0;
					}
					&:nth-of-type(4) {
						top: 5%;
						left: 55%;
						width: 45%;
						height: 22.5%;
					}
					&:nth-of-type(3) {
						top: 27.5%;
						left: 0;
						width: 55%;
						height: 27.5%;
						border-top: 0;
						border-left: 0;
					}
					&:nth-of-type(5) {
						top: 27.5%;
						left: 55%;
						width: 45%;
						height: 22.5%;
					}
					&:nth-of-type(1) {
						top: 50%;
						left: 0;
						width: 100%;
						height: 50%;
						border-top: 0;
						border-left: 0;
						border-right: 0;
					}
				}
				@media (min-width: $screen-sm) {
					&:nth-of-type(2) {
						width: 25%;
						height: 50%;
						border-left: 0;
					}
					&:nth-of-type(4) {
						top: 0;
						left: 25%;
						width: 25%;
						height: 50%;
						border-right: 10px solid white;
					}
					&:nth-of-type(3) {
						top: 50%;
						left: 0;
						width: 25%;
						height: 50%;
					}
					&:nth-of-type(5) {
						top: 50%;
						left: 25%;
						width: 25%;
						height: 50%;
						border-right: 10px solid white;
					}
					&:nth-of-type(1) {
						top: 0%;
						left: 50%;
						width: 50%;
						height: 100%;
						border-top: 10px solid white;
						border-left: 0;
						border-right: 0;
					}
				}
				@media (min-width: $screen-md) {
					&:nth-of-type(2) {
						width: 30%;
						height: 50%;
						border-left: 0;
					}
					&:nth-of-type(4) {
						top: 10%;
						left: 30%;
						width: 24%;
						height: 40%;
						border-right: 10px solid white;
					}
					&:nth-of-type(3) {
						top: 50%;
						left: 6%;
						width: 24%;
						height: 40%;
					}
					&:nth-of-type(5) {
						top: 50%;
						left: 30%;
						width: 24%;
						height: 40%;
						border-right: 10px solid white;
					}
					&:nth-of-type(1) {
						top: 0%;
						left: 40%;
						width: 60%;
						height: 100%;
						border-top: 10px solid white;
						border-left: 0;
						border-right: 0;
					}
				}
			}
		}
	}
}




/*MODULE 3 NEWS AND VIEWS
****************************************************************/

.module-3 .wrapper .inner a:nth-child(2) .inner__content,
.module-3 .wrapper .inner a:nth-child(5) .inner__content  {
	top: auto;
	bottom: 5px;
}

.module-3 .wrapper .inner a:nth-of-type(1) .inner__content {
	top: auto;
	bottom: 15px;
}

.module-3 .wrapper .inner a:nth-of-type(1) .inner__content blockquote {
	position: relative;
    font-size: 22px;
}

.module-3 .wrapper .inner a.inner--crest-yellow .inner__content p {
	font-weight: 700;
}

.module-3 .wrapper .inner a .inner__content p:after {
    content: "";
}

.module-3 .wrapper .inner a:nth-of-type(1) .inner__img.inner__img:after {;
    //opacity: 0.5;
}

.module-3 .wrapper .inner a:nth-of-type(5) .inner__content {
	color: $crest-black;
}

@media (max-width: 550px) {
	.module-3 .wrapper .inner a .inner__content blockquote {
		display: none;
	}
}

@media all and (max-width: 767px) {

	.module-3 .wrapper .inner a {
		border: 0;
		&:first-child {
			top: 0;
			border-top: 0;
		}
		&:nth-of-type(2) {
			top: 45%;
			width: 55%;
			height: 27.5%;
			border-top: 6px solid #fff;
			border-right: 6px solid #fff;
			border-bottom: 6px solid #fff;
		}
		&:nth-of-type(3) {
			top: 72.5%;
			width: 55%;
			height: 27.5%;
			border-right: 6px solid #fff;
			border-bottom: 6px solid #fff;
		}
		&:nth-of-type(4) {
			top: 48%;
			left: 55%;
			width: 45%;
			height: 24%;
			border-top: 6px solid #fff;
		}
		&:nth-of-type(5) {
			top: 72.5%;
			left: 55%;
			width: 45%;
			height: 24%;
			border-bottom: 6px solid #fff;
		}

	}

	.module-3 .wrapper .inner a .inner__content p {
		padding-left: 0;
	}

}
@media all and (max-width: 1199px ) {
	.module-3 .wrapper .inner a .inner__content p {
		display: none;
	}
}

@media all and (min-width: 1100px) {


	.module-3 .wrapper .inner a .inner__content blockquote {
	    font-size: 22px;
	    line-height: 1.35;
	    padding: 20px 0 0 35px;
	}

	.module-3 .wrapper .inner a:nth-of-type(1) .inner__content blockquote {
	    font-size: 38px;
	}

	.module-3 .wrapper .inner a .inner__content blockquote:before {
		font-size: 60px;
	}

	.module-3 .wrapper .inner a .inner__content p {
	    margin-top: 12px;
	    font-size: 15px;
	    padding-left: 35px;
	}

	.module-3 .wrapper .inner a:nth-of-type(1) .inner__content p {
		margin-top: 20px;
		padding-top: 0;
		padding-left: 35px;
		padding-bottom: 15px;
	}

}


@media all and (min-width: 1600px) {

	.module-3 .wrapper .inner a .inner__content {
		padding: 20px;
	}

	.module-3 .wrapper .inner a:nth-of-type(1) .inner__content {
		padding-right: 100px;
	}

	.module-3 .wrapper .inner a .inner__content blockquote {
	    font-size: 32px;
	    line-height: 1.35;
	    padding: 35px 0 0 50px;
	}

	.module-3 .wrapper .inner a:nth-of-type(1) .inner__content blockquote {
	    font-size: 45px;
	}

	.module-3 .wrapper .inner a .inner__content blockquote:before {
		font-size: 100px;
	}

	.module-3 .wrapper .inner a .inner__content p {
	    font-size: 18px;
	    margin-top: 20px;
	    padding-left: 50px;
	}

	.module-3 .wrapper .inner a:nth-of-type(1) .inner__content p {
		margin-top: 30px;
		padding-left: 50px;
		padding-bottom: 30px;
	}

}


/*INNER PAGE LAYOUT*/

.module-3.innerPage .wrapper .inner a .inner__content {
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	align-items: center;
	.date {
		background: $crest-yellow;
		color: $crest-black;
		font-weight: 700;
		font-size: 11px;
		line-height: 14px;
		text-align: center;
		text-transform: uppercase;
		width: 40px;
		height: 40px;
		position: absolute;
		z-index: 20;
		bottom: 0;
		right: 0;
		padding-top: 8px;
		span {
			display: block;
			font-size: 19px;
		}
	}
}

.module-3.innerPage div.inner-content {
	padding: 0 20px
}

.module-3.innerPage .wrapper .inner a {
	.inner__content h3 {
		position: relative;
		font-size: 16px;
		line-height: 1.25;
		font-family: 'Playfair Display', serif;
		font-weight: 400;
		margin: 0;
		padding: 0;
		&:first-of-type:after {
			content:"\f101";
			@include awesome;
			padding-left: 10px;
		}
	}
	&.has__summary{
		.inner__content h3 {
			&:first-of-type:after {
				content:"";
			}
		}
	}
	.inner__content h4.readmore:after {
		content:"\f101";
		@include awesome;
		padding-left: 10px;
	}
}

.module-3.innerPage .wrapper .inner a.inner--crest-yellow .inner__content h3 {
	font-weight: 700;
}

.module-3.innerPage .wrapper .inner a .inner__content p:first-of-type:after {
	content: "";
}

/*the big one*/


.module-3.innerPage .wrapper .inner a:nth-of-type(1) .inner__content {
	display: block;
	padding-top: 20%;
	padding-left: 10px;
	padding-right: 20%;
	h3 {
		font-size: 22px;
	}
	p {
		padding-bottom: 20px;
	}

}

.module-3 .wrapper .inner a:nth-of-type(1) .inner__content p {
	padding: 0;
}

@media (min-width: $screen-md) {

	.module-3.innerPage .wrapper .inner a .inner__content {
		padding: 20px;
	}

	.module-3.innerPage .wrapper .inner a .inner__content h3 {
		font-size: 20px;
	}

	.module-3.innerPage .wrapper .inner a.inner--crest-yellow .inner__content h3 {
		font-weight: 400;
	}

	.module-3.innerPage .wrapper .inner a:nth-of-type(1) .inner__content {
		padding-top: 15%;
		padding-left: 30%;
		padding-right: 20%;
		h3 {
		font-size: 42px;
		}
		p {
			padding: 0 0 30px 0;
		}
	}

}

@media (min-width: $screen-lg) {
	.module-3.innerPage .wrapper .inner a.small {
		top: 10%;
    left: 6%;
		height: 40%;
		width: 24%;
	}
	.module-3.innerPage .wrapper .inner a .inner__content {
		padding: 50px;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		p {
			display: block;
			padding-left: 0;
			font-size: 13px;
		}
	}


	.module-3.innerPage .wrapper .inner a .inner__content .date {
		font-size: 14px;
		line-height: 18px;
		width: 50px;
		height: 50px;
		padding-top: 10px;
		span {
			font-size: 24px;
		}
	}

	.module-3.innerPage .wrapper .inner a .inner__content h3 {
		font-size: 23px;
	}

	.module-3.innerPage .wrapper .inner a:nth-of-type(1) .inner__content {
		h3 {
			font-size: 52px;
		}

		p {
			padding: 0 40% 40px 0;
		}
		.btn {
			padding: 24px 50px;
			font-size: 22px;
		}
	}



}
@media all and (min-width: 1440px) {
	.module-3.innerPage .wrapper .inner a .inner__content h3 {
		font-size: 28px;
	}
	.module-3.innerPage .wrapper .inner a .inner__content p {
		font-size: 16px;
	}
}
@media (min-width: 1600px) {
	.module-3.innerPage .wrapper .inner a .inner__content h3 {
		font-size: 32px;
	}
	.module-3.innerPage .wrapper .inner a .inner__content .date {
		font-size: 18px;
		line-height: 24px;
		width: 80px;
		height: 80px;
		padding-top: 20px;
		span {
			font-size: 34px;
		}
	}

}



/*3 COLUMN PAGE LAYOUT*/

//@media (min-width: $screen-xs) {

	.content-wrap__inner .module-3 .wrapper {
		height: auto;
		padding-bottom: 0;
		overflow: hidden;
	}

	.content-wrap__inner .module-3 .wrapper .inner {
	    position: relative;
	    width: auto;
	    height: auto;
	    margin-left: -5px;
	    margin-right: -5px;
	}

	.content-wrap__inner .module-3 .wrapper .inner a {
		width: 50%;
		height: 0;
		padding-bottom: 50%;
		border: 0;
		position: relative;
		top: auto;
		left: auto;
		right: auto;
		bottom: auto;
		margin: 0;
		float: left;
		border: 5px solid #fff;
	}

	.content-wrap__inner .module-3 .wrapper .inner a:nth-of-type(1) {
		width: 100%;
		height: 400px;
	}

	.content-wrap__inner .module-3.innerPage .wrapper .inner a .inner__content h3 {
		font-size: 16px;
		color: $crest-white;
		@media (max-width: 425px) {
			font-size: 13px;
			padding-right: 35px;
		}
	}

	.content-wrap__inner .module-3.innerPage .wrapper .inner a:nth-of-type(1) .inner__content {
	    padding: 30px;
	    top: auto;
	    display: block;
	}

	.content-wrap__inner .module-3.innerPage .wrapper .inner a:nth-of-type(1) .inner__content p {
		padding-right: 0;
	}

//}

@media (min-width: 1700px) {

	.content-wrap__inner .module-3 .wrapper .inner a {
		width: 25%;
		padding-bottom: 25%;
	}

	.content-wrap__inner .module-3.innerPage .wrapper .inner a .inner__content h3 {
		font-size: 16px;
	}

	.content-wrap__inner .module-3.innerPage .wrapper .inner a .inner__content {
	    padding: 15px;
	}

	.module-3.innerPage .wrapper .inner a .inner__content .date {
		font-size: 14px;
		line-height: 18px;
		width: 50px;
		height: 50px;
		padding-top: 10px;
		span {
			font-size: 20px;
		}
	}

	.content-wrap__inner .module-3.innerPage .wrapper .inner a:nth-of-type(1) .inner__content h3 {
		font-size: 26px;
	}

	.content-wrap__inner .module-3.innerPage .wrapper .inner a:nth-of-type(1) .inner__content p {
		font-size: 14px;
	}

}

// IE 11 - flex direction column fix
@media all and (-ms-high-contrast:none) {
	.module-3 .wrapper .inner a .inner__content h3 {
		display: block;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		right: 0;
		margin: 0 auto;
		padding: 5px;
		@media all and (min-width: $screen-md-min) {
		  padding: 15px;
		}
		@media all and (min-width: $screen-lg-min) {
		  padding: 30px;
		}
	}
}
