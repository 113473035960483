// Used by Map component

.module-42 {
    margin-bottom: 60px;

    .home & {
        padding: 0 10%;

        @media (max-width: $screen-sm-max) {
            padding: 0 15px;
        }

        .module-title {
            text-align: left;
        }
        .module-sub-text {
            margin-left: 0;

            text-align: left;
        }
    }

    .module-42-container {
        margin-top: 30px;
        margin-bottom: 40px;
        padding: 15px;

        color: $crest-black;
        // background-color: $crest-black;
        background-color: rgba($color: #0092FF, $alpha: 0.05);
        @media all and (min-width:992px) {
            margin-top: 40px;
        }
        @media all and (min-width:992px) {
            margin-bottom: 50px;
            padding: 25px 30px;
        }

        // @media all and (min-width:1200px) {
        //     padding: 30px;
        // }
        .dl-horizontal {
            margin-bottom: 0;
            dd {
                a.linktype {
                    &:after {
                        font-family: FontAwesome;

                        padding-left: 5px;

                        content: "\f101";
                    }

                    &.linktype--internal:after {
                        content: "\f178";
                    }

                    &.linktype--external:after {
                        content: "\f08e";
                    }

                    &.linktype--modal:after {
                        content: "\f2d0";
                    }

                    &.linktype--video:after {
                        content: "\f01d";
                    }

                    &.linktype--document:after {
                        content: "\f016";
                    }
                }
            }
        }
        .more-details {
            padding: 0 15px;
            @media all and (min-width:992px) {
                padding: 0 30px;
            }
            .dl-horizontal {
                padding: 0;
                ul {
                    margin: 0;
                    padding: 0;
                }
                li:before {
                    display: none;
                }
                a {
                    text-decoration: none;
                }
                @media all and (min-width: 768px) {
                    dt {
                        min-width: 230px;
                        padding-right: 10px;

                        white-space: normal;
                    }
                    dd {
                        margin-left: 230px;
                    }
                }
            }
        }
        .btn-more-details {
            font-family: "Roboto", sans-serif;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;

            display: flex;

            width: 100%;
            padding: 10px 15px;

            color: white;
            background-color: $crest-blue;

            align-items: center;
            .fa {
                margin-left: auto;
            }
            &.collapsed .fa:before {
                content: "\f067";
            }
        }
        h2 {
            margin-top: 0;
            margin-bottom: 30px;

            a {
                font-size: initial;
                font-weight: normal;
                line-height: 60px;

                text-decoration: underline;

                color: white;
            }
        }

        .map-home {
            position: relative;

            width: 100%;
            // border-radius: 8px;
            height: 400px;

            border: 1px solid black;

            @media all and (min-width: 992px) {
                height: 800px;
            }
        }

        .folder-content {
            @media all and (min-width: 992px) {
                display: flex;
            }
        }

        .folder-container {
            .folder {
                position: relative;

                margin-bottom: 10px;
                padding: 10px;
                padding-right: 40px;

                color: black;
                border-radius: 8px;
                background-color: white;

                @media all and (min-width: 992px) {
                    padding: 15px 30px;
                    padding-right: 60px;
                }

                h3 {
                    font-size: 18px;

                    margin: 5px 0 5px;

                    @media all and (min-width: 992px) {
                        font-size: 24px;
                    }

                    @media all and (min-width: 1440px) {
                        font-size: 26px;
                    }
                }

                p {
                    font-size: 14px;

                    @media all and (min-width: 992px) {
                        font-size: 16px;
                    }
                }

                label {
                    font-weight: 400;

                    margin-bottom: 0;

                    &:focus-within {
                        color: rebeccapurple;
                    }
                }

                input[type="radio"] {
                    font: inherit;
                    // transform: translateY(-0.075em);

                    display: grid;

                    width: 24px;
                    height: 24px;
                    margin: 0;

                    color: currentColor;
                    border: 1px solid currentColor;
                    border-radius: 50%;
                    background-color: white;

                    -webkit-appearance: none;
                            appearance: none;
                    place-content: center;
                }

                input[type="radio"]::before {
                    width: 24px;
                    height: 24px;

                    content: "";

                    border-radius: 50%;
                    // border:0;
                    // transform: scale(0);


                    // transition: 120ms transform ease-in-out;
                    // box-shadow: inset 1em 1em rebeccapurple;
                    // background-color: CanvasText;
                }

                input[type="radio"]:checked {
                    border: 0;
                    background-image: url(/en/media/2017siteassets/contentfiles/images/checkbox.svg);
                    background-repeat: no-repeat;
                    background-size: 100%;
                }

                input[type="radio"]:checked::before {
                    //   transform: scale(1);
                    width: 23px;
                    height: 23px;
                }

                input[type="radio"]:focus {
                    // outline: 2px solid currentColor;
                    // outline-offset: 2px;
                    outline: none;
                }

                .folder-icon {
                    display: inline-block;
                    // min-width: 42px;

                    margin-right: 27px;
                    padding-top: 5px;

                    vertical-align: top;

                    img {
                        width: 32px;
                        height: 45px;
                    }
                }

                .folder-info {
                    display: inline-block;

                    max-width: calc(100% - 30px);

                    p {
                        margin-bottom: 0;
                    }
                }

                .folder-radio {
                    position: absolute;
                    top: calc(50% - 6px);
                    right: 20px;

                    display: inline-block;
                }
            }
        }

        &.site-details {
            // @media all and (min-width: 992px) {
            //     padding-left: 0;
            // }

            h3 {
                margin-top: 0;
                margin-bottom: 0;

                @media all and (min-width: 768px) {
                    padding: 0 0 30px;
                }

                @media all and (min-width: 992px) {
                    padding: 0 30px 30px;
                }
            }

            dl {
                padding-right: 15px;
                padding-left: 15px;
                @media all and (min-width: 992px) {
                    padding-right: 30px;
                    padding-left: 30px;
                }
            }

            dt,
            dd {
                margin: 0;
                padding-top: 12px;

                text-align: left;
            }

            dt {
                font-size: 16px;
                font-weight: 900;
                line-height: 150%;

                border-top: 1px solid rgba(#003C69, 0.5);

                &:first-child {
                    border-top: 0;
                }
            }

            dd {
                margin-bottom: 12px;
                padding-bottom: 2px;

                color: black;
                &:nth-child(2) {
                    border-top: 0;
                }
                + dd {
                    border-top: 0;
                }

                @media all and (min-width: 768px) {
                    margin-left: 160px;
                    padding-top: 12px;

                    border-top: 1px solid rgba(#003C69, 0.5);
                }

                @media all and (max-width: 991px) {
                    margin-bottom: 10px;
                    padding-bottom: 10px;
                }
            }
        }
    }
    &__wysiwyg {
        .event-item-link {
            a.linktype {
                &:after {
                    font-family: FontAwesome;

                    padding-left: 5px;

                    content: "\f101";
                }

                &.linktype--internal:after {
                    content: "\f178";
                }

                &.linktype--external:after {
                    content: "\f08e";
                }

                &.linktype--modal:after {
                    content: "\f2d0";
                }

                &.linktype--video:after {
                    content: "\f01d";
                }

                &.linktype--document:after {
                    content: "\f016";
                }
            }
        }
    }
    &__cta {
        margin-bottom: 25px;
        &--xs-center {
            text-align: center;
            @media (min-width:768px) {
                text-align: left;
            }
        }
    }
    .dropdown-default {
        position: relative;

        margin-bottom: 2em;
        ul {
            li {
                a {
                    font-size: 16px;
                    font-weight: 400;

                    position: relative;

                    min-width: 205px;
                    padding: 10px 20px;

                    color: black;
                }
            }
        }
        .dropdown-menu {
            &--icon {
                position: relative;

                display: inline-flex;

                width: 20px;
                height: 100%;
                margin-top: -10px;
                margin-right: 15px;
                margin-bottom: -10px;

                justify-content: flex-start;
                align-items: center;
            }
        }
    }
}
