// /////////////////////////////////////////////////////////////////////////
// typography
// /////////////////////////////////////////////////////////////////////////

@mixin serif-r {
	font-family: 'Playfair Display', serif;
	font-weight: 400;
}
@mixin serif-b {
	font-family: 'Playfair Display', serif;
	font-weight: 700;
}
@mixin sans-l {
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
}
@mixin sans-r {
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
}
@mixin sans-b {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
}
/*@mixin sans-alt {
	font-family: 'Anton', sans-serif;
}*/
@mixin awesome {
	font-family: 'FontAwesome';
	font-weight: normal;
	font-weight: normal;
}

// /////////////////////////////////////////////////////////////////////////
// backgrounds
// ///////////-//////////////////////////////////////////////////////////////

@mixin bg-cover-center {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

@mixin bg-cover-semi-center-home {
	background-size: cover;
	background-position: 25% 25%;
	background-repeat: no-repeat;
}

@mixin bg-contain-center {
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
}

@mixin bg-center {
	background-position: center center;
	background-repeat: no-repeat;
}

@mixin bg-top-center {
	background-position: top center;
	background-repeat: no-repeat;
}

@mixin bg-top-left {
	background-position: top left;
	background-repeat: no-repeat;
}

// /////////////////////////////////////////////////////////////////////////
// gradients
// /////////////////////////////////////////////////////////////////////////

// black to transparent - bottom to top
@mixin gradient-black-up {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	// height: 60%;
	background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
	background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
}

// /////////////////////////////////////////////////////////////////////////
// section spacing
// /////////////////////////////////////////////////////////////////////////

@mixin spacer-p-t-b {
	padding-top: 30px;
	padding-bottom: 30px;
	@media (min-width:$screen-sm) {
		padding-top: 45px;
		padding-bottom: 45px;
	}
	@media (min-width:$screen-md) {
		padding-top: 60px;
		padding-bottom: 60px;
	}
	@media (min-width:$screen-lg) {
		//padding-top: 75px;
		//padding-bottom: 75px;
	}
}

@mixin spacer-p-t-b-x2 {
	padding-top: 60px;
	padding-bottom: 60px;
	@media (min-width:$screen-sm) {
		padding-top: 90px;
		padding-bottom: 90px;
	}
	@media (min-width:$screen-md) {
		padding-top: 120px;
		padding-bottom: 120px;
	}
	@media (min-width:$screen-lg) {
		padding-top: 150px;
		padding-bottom: 150px;
	}
}

@mixin spacer-p-t {
	padding-top: 30px;
	@media (min-width:$screen-sm) {
		padding-top: 45px;
	}
	@media (min-width:$screen-md) {
		padding-top: 60px;
	}
	@media (min-width:$screen-lg) {
		//padding-top: 75px;
	}
}

@mixin spacer-p-b {
	padding-bottom: 30px;
	@media (min-width:$screen-sm) {
		padding-bottom: 45px;
	}
	@media (min-width:$screen-md) {
		padding-bottom: 60px;
	}
	@media (min-width:$screen-lg) {
		//padding-bottom: 75px;
	}
}

@mixin spacer-m {
	margin-top: 30px;
	margin-bottom: 30px;
	@media (min-width:$screen-md) {
		margin-top: 80px;
		margin-bottom: 80px;
	}
}
@mixin spacer-m-t {
	margin-top: 30px;
	@media (min-width:$screen-md) {
		margin-top: 80px;
	}
}
@mixin spacer-m-b {
	margin-bottom: 30px;
	@media (min-width:$screen-md) {
		margin-bottom: 80px;
	}
}

@mixin spacer-m-b {
	margin-bottom: 15px;
	@media (min-width:$screen-md) {
		margin-bottom: 40px;
	}
}

// /////////////////////////////////////////////////////////////////////////
// alignment
// /////////////////////////////////////////////////////////////////////////

@mixin centered-h-v {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%,-50%,0);
}

@mixin centered-v {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

@mixin absolute-cover {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

@mixin img-cover {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

// /////////////////////////////////////////////////////////////////////////
// content
// /////////////////////////////////////////////////////////////////////////

@mixin content-section-h3 {
	margin-bottom: 15px;
	@include serif-b;
	font-size: $font-size-h5;
}


