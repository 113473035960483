.module-46 {
    text-align: center;
    @include spacer-p-t;
}

.content-wrap.module-wrap {
    .module-46 {
        text-align: left;
    }
}

.module-46 {
    .btn {
        margin-bottom: 5px;
        .fa-long-arrow-left {
            display: none;
        }

        &.btn--back {
            .fa-long-arrow-right {
                display: none;
            }
            .fa-long-arrow-left {
                display: inline;
            }
        }

        &.linktype {
            &.linktype--external .fa-long-arrow-right:before {
                content: "\f08e";
            }
        
            &.linktype--modal .fa-long-arrow-right:before {
                content: "\f2d0";
            }
        
            &.linktype--video .fa-long-arrow-right:before {
                content: "\f01d";
            }
        
            &.linktype--document .fa-long-arrow-right:before {
                content: "\f016";
            }
        }

    }
}
