
.module-3b {
	position: relative;
	@include spacer-p-t;
	.btn {
		display: block;
		margin: 20px auto 0;
		max-width: 250px;
	}
	.inner {
		margin: 0 -5px;
		overflow: hidden;
		a {
			display: block;
			position: relative;
			overflow: hidden;
			z-index: 5;
			width: 50%;
			float: left;
			//padding: 10px 5px 0;
			border: 3px solid white;
			background-color: #eee;
			color: white;
			h3 {
				margin: 0;
				padding: 0;
				font-weight: 400;
				font-size: 15px;
				line-height: 1.25;
				transition: .5s ease;
				&:after {
					content:"\f101";
					font-family: FontAwesome;
					padding-left: 5px;
				}

			}
			&.linktype--internal h3:after {
				content: "\f178";
			}
			&.linktype--external h3:after {
				content: "\f08e";
			}
			&.linktype--modal h3:after {
				content: "\f2d0";
			}
			&.linktype--video h3:after {
				content: "\f01d";
			}
			&.linktype--document h3:after {
				content: "\f016";
			}
			&:first-child {
				width: 100%;
				h3 {
					font-size: 22px;
				}
			}
			.inner__content {
				position: absolute;
				z-index: 10;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				display: flex;
				align-items: center;
				background: rgba(0,0,0,0.35);
				padding: 0 15px;
				transition: .5s ease;
				h3 {
					position: relative;
				}
				&::before {
					content: '';
					width: 100%;
					height: 100%;
					background-color: white;
					opacity: .45;
					left: 0;
					top: 0;
					position: absolute;
				}
			}
			&.news--external-link {
				h3 {
					&:after {
						//content:"\f14c";
						//font-size: 80%;
						//padding-left: 8px;
						//position: relative;
						//bottom: 2px;
					}
				}
				.date {
					//background: $crest-red;
					//color: $crest-white;
				}
			}
		}
		.inner__img {
			position: relative;
			z-index: 1;
			width: 100%;
			height: 0;
			padding-bottom: 100%;
			background-size: cover;
			background-position: center center;
			transition: .5s ease;
		}
		.date {
			background: $crest-yellow;
			color: $crest-black;
			font-weight: 700;
			//font-size: 11px;
			//line-height: 14px;
			text-align: center;
			//text-transform: uppercase;
			//width: 40px;
			//height: 40px;
			position: absolute;
			z-index: 20;
			bottom: 0;
			right: 0;
			//padding-top: 8px;
			//new
			font-size: 12px;
			line-height: 1;
			padding: 8px 12px;
			@media (min-width: $screen-sm-min) {
				font-size: 14px;
				padding: 12px 15px;
			}
			span {
				display: block;
				font-size: 19px;
			}
		}
	}
}


.module-3b {
	.inner {
		a {
			&:hover {
				.inner__content {
					background: rgba($crest-blue,0.8);
				}
				h3 {
					margin-bottom: 25px;
				}
				.inner__img {
					transform: scale(1.1);
				}
			}
			&:nth-child(2),
			&:nth-child(5) {
				&:hover {
					.inner__content {
						background: rgba($crest-red,0.8);
					}
				}
			}
			&:nth-child(4) {
				&:hover {
					.inner__content {
						background: rgba($crest-yellow,0.8);
					}
				}
			}
		}
	}
}


@media (max-width: $screen-md-min - 1) {
	.module-3b {
		.inner {
			a {
				&:nth-child(2) {
					position: relative;
					width: 55%;
					margin-top: -12%;
					border-top-width: 6px;
					border-right-width: 6px;
				}
				&:nth-child(3) {
					position: relative;
					width: 45%;
					border-left-width: 0;
				}
				&:nth-child(4) {
					position: relative;
					width: 55%;
					margin-top: -2%;
					border-top-width: 0;
					border-right-width: 6px;
				}
				&:nth-child(5) {
					position: relative;
					width: 45%;
					border-left-width: 0;
				}
			}
		}
	}
}

@media (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
	.module-3b {
		.inner {
			a {
				h3 {
					font-size: 22px;
					line-height: 1.35;
				}
				&:first-child {
					h3 {
						font-size: 28px;
					}
				}
			}
			.inner__content {
				padding: 0 30px;
			}
		}
	}
}

@media (min-width: $screen-md-min) {
	.module-3b {
		.btn {
			margin: 0 auto;
		}
		.inner {
			position: relative;
			height: 0;
			padding-bottom: 35%; /*this must be the same as the width of the largest new item*/
			a {
				width: 20%;
				position: absolute;
				border-width: 6px;
				&:nth-child(1) {
					width: 35%;
					z-index: 1;
					left: 0;
					top: 0;
					.inner__content {
						padding-right: 25%;
					}
					.date {
						right: auto;
						left: 0;
					}
				}
				&:nth-child(2) {
					width: 20%;
					z-index: 2;
					left: 27%;
					top: 15%;
					.inner__content {
						padding-right: 10%;
					}
					.date {
						top: 0;
						left: 0;
						bottom: auto;
						right: auto;
					}
				}
				&:nth-child(3) {
					width: 20%;
					z-index: 3;
					left: 45%;
					top: 4%;
					.date {
						right: auto;
						left: 0;
					}
				}
				&:nth-child(4) {
					width: 24%;
					z-index: 1;
					right: 15%;
					top: 12%;
					.inner__content {
						padding-left: 20%;
						padding-right: 12%;
					}
				}
				&:nth-child(5) {
					width: 18%;
					z-index: 3;
					right: 0;
					top: 6%;
				}
			}
		}
	}
}

@media (min-width: $screen-lg-min) {
	.module-3b {
		.btn {
			margin: -5% auto 0;
		}
		.inner {
			a {
				h3 {
					font-size: 20px;
					line-height: 1.35;
				}
				&:nth-child(1) {
					h3 {
						font-size: 28px;
					}
				}
				&:nth-child(4) {
					h3 {
						font-size: 24px;
					}
				}
			}
		}
	}
}

@media (min-width: 1340px) {
	.module-3b {
		.inner {
			a {
				h3 {
					font-size: 22px;
				}
				&:nth-child(1) {
					h3 {
						font-size: 34px;
					}
				}
				&:nth-child(4) {
					h3 {
						font-size: 26px;
					}
				}
				&:nth-child(5) {
					h3 {
						font-size: 20px;
					}
				}
			}
		}
	}
}
