.slide-square {
    position: relative;

    display: flex;
    flex-direction: column;

    height: 100%;
    margin-top: 10px;
    padding: 27px 15px;

    text-align: center;

    color: white;
    background-color: $crest-red;

    align-items: center;
    justify-content: flex-start;
}

// ========================================
// icon
// ========================================

.slide-square__icon {
    display: block;

    width: 120px;
    height: 120px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    svg,
    img {
        width: 100%;
        height: 100%;

        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
        margin: auto;
    }
    @media(min-width: 1200px) {
        width: 180px;
        height: 180px;
    }
}

// .slide-square__icon--canoe {
//   background-image: url(../../../contentFiles/icons/icon-s-1.svg);
// }

// .slide-square__icon--tennis {
//   background-image: url(../../../contentFiles/icons/icon-s-2.svg);
// }

// .slide-square__icon--football {
//   background-image: url(../../../contentFiles/icons/icon-s-3.svg);
// }

// .slide-square__icon--archery {
//   background-image: url(../../../contentFiles/icons/icon-s-4.svg);
// }

// .slide-square__icon--running {
//   background-image: url(../../../contentFiles/icons/icon-s-5.svg);
// }

// .slide-square__icon--basketball {
//   background-image: url(../../../contentFiles/icons/icon-s-6.svg);
// }

// .slide-square__icon--chess {
//   background-image: url(../../../contentFiles/icons/icon-s-7.svg);
// }

// ========================================
// content
// ========================================

.slide-square__title {
    font-weight: 900;

    margin: 20px 0 0;

    @media(min-width: 992px) {
        margin: 25px 0 0;
    }
}

.slide-square__copy {
    margin: 10px 0 0;

    @media(min-width: 992px) {
        margin: 15px 0 0;
    }
}

.slide-square .btn {
    margin: 20px 0 0 !important;

    @media(min-width: 992px) {
        margin: 30px 0 0 !important;
    }
}

.slide-square__contact {
    display: flex;
    margin: 10px 0 0 !important;
    padding: 0;
    li {
        margin: 0 !important;
        padding: 0 5px !important;
        display: inline-block;
    }

    li + li {
        //position: relative;

        &::before {
            //hiding divider as it wsa causing layout issues - FD
            position: static;
            //display: inline-block;
            display: none;
            width: 18px;
            text-align: center;
            content: "\2022" !important;
            color: white;
            font-size: 18px;
            padding: 0 0 0 3px;
        }
    }

    li a {
        text-decoration: underline;
        color: white;
    }
}

.left-nav .slide-square__contact {
    li {
        //margin: 0 5px !important;
    }
}


//COLOURS IN BANNER MODULE 29

.module-29 {
    .bg-crest-white {
        .slide-square {
            background-color: $crest-white;
            *:not(.btn):not(.fa)  {
                color: $crest-black;
            }
        }
    }
    .bg-tint-crest-white {
        .slide-square {
            background-color: $tint-crest-white;
            *:not(.btn):not(.fa)  {
                color: $crest-black;
            }
        }
    }
    .bg-crest-black {
        .slide-square {
            background-color: $crest-black;

        }
    }
    .bg-crest-red {
        .slide-square {
            background-color: $crest-red;
        }
    }
    .bg-crest-blue {
        .slide-square {
            background-color: $crest-blue;
        }
    }
    .bg-crest-yellow {
        .slide-square {
            background-color: $crest-yellow;
            *:not(.btn):not(.fa) {
                color: $crest-black;
            }
        }
    }
    .bg-sky-blue {
        .slide-square {
            background-color: $sky-blue;
            *:not(.btn):not(.fa)  {
                color: $crest-white;
            }
        }
    }
    .bg-quad-green {
        .slide-square {
            background-color: $quad-green;
            *:not(.btn):not(.fa)  {
                color: $crest-white;
            }
        }
    }
    .bg-earth-red {
        .slide-square {
            background-color: $earth-red;
        }
    }
    .bg-ogham-stone {
        .slide-square {
            background-color: $ogham-stone;
            *:not(.btn):not(.fa) {
                color: $crest-white;
            }
        }
    }
    .bg-dawn-mauve {
        .slide-square {
            background-color: $dawn-mauve;

        }
    }
    .bg-limestone {
        .slide-square {
            background-color: $limestone;
            *:not(.btn):not(.fa)  {
                color: $crest-black;
            }
        }
    }
    .bg-brand-twitter {
        .slide-square {
            background-color: $brand-twitter;
        }
    }
    .bg-brand-facebook {
        .slide-square {
            background-color: $brand-facebook;
        }
    }
    .bg-brand-instagram {
        .slide-square {
            background-color: $brand-instagram;
        }
    }
    .bg-brand-youtube {
        .slide-square {
            background-color: $brand-youtube;
        }
    }
    .bg-brand-linkedin {
        .slide-square {
            background-color: $brand-linkedin;
        }
    }
}



