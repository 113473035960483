.module-11 {
	position: relative;
	z-index: 1001;
	margin: 0 auto 10px;
	border-top: 10px solid $crest-white;
	@include spacer-p-t-b;
	.module-title {
		padding-bottom: 0;
		font-size: 30px;
		line-height: 34.5px;
		margin-bottom: 30px;
		@media all and (max-width: 767px) {
			font-size: 24px;
		}
		+ .module-title {
			margin-top: -25px;
		}
	}
	p, li {
		font-size: 18px;
		margin-bottom: 5px;
	}


	.list-inline {
		li {
			padding: 0;
			display: block;
			@media (min-width: 992px) {
				display: inline;
				&:after {
					content: "|";
					display: inline-block;
					padding: 0 10px;
				}
				&:last-child {
					&:after {
						display: none;
					}
				}
			}
			
			
			i {
				color: $crest-black;
				padding-right: 6px;
			}
			a {
				color: $crest-black;
				text-decoration: underline;
			}
		}
	}
	
	
	.social-block {
		margin: 40px auto 10px;
		li {
			display: inline-block;
			padding: 0 5px;
			a {
				display: block;
				position: relative;
				text-align: center;
				background-color: $crest-white;
				width: 36px;
				height: 36px;
				padding-top: 7px;
				@media (min-width: $screen-md-min) {
					padding-top: 4px;
				}
				.fa {
					font-size: 22px;
					padding: 0;
				}
			}
			&.twitter {
				a {
					.fa {
						&:before {
							color: $brand-twitter;
						}
					}
				}
			}
			&.facebook {
				a {
					.fa {
						&:before {
							color: $brand-facebook;
						}
					}
				}
			}
			&.instagram {
				a {
					.fa {
						&:before {
							color: $brand-instagram;
						}
					}
				}
			}
			&.youtube {
				a {
					.fa {
						&:before {
							color: $brand-youtube;
						}
					}
				}
			}
			&.linkedin {
				a {
					.fa {
						&:before {
							color: $brand-linkedin;
						}
					}
				}
			}
		}
	}
}

.module-1 + .module-11 {
	z-index: 11;
	border-top: 0;
}
