
.module-19 {
	position: relative;
	.container-fluid {
		padding: 0;
		margin: 15px 0;
	}
	.container {
		padding: 0;
		margin: 15px auto;
		max-width: 1170px;
		max-width: 100%;
		width: 100%;
	}
	.row {
		margin: 0 -5px;
		@media (min-width: $screen-md-min) {
			display: flex;
			justify-content: center;
		}
		div[class^="col"] {
			padding: 5px 5px;
			@media (min-width: $screen-md-min) {
				padding: 0 5px;
				display: flex;
				max-width: 540px;
			}
		}
	}
	.inner {
		position: relative;
		width: 100%;
		background: $tint-crest-white;
		padding: 15px;
		overflow: hidden;
	}
	.social-embed {
		position: relative;
		width: 100%;
		height: calc(100% - 75px);
		min-height: 450px;
		overflow: hidden;
		//padding-bottom: 170%;
	}
	h2 {
		margin-top: 10px;
	}
	iframe {
		background-color: $crest-white !important;
		//position: absolute !important;
		//top: 0 !important;
		//left: 0 !important;
		//display: block !important;
		//width: 100% !important;
		//height: 100% !important;
	}
	.module-19--facebook {
		iframe {
			position: absolute !important;
			top: 0 !important;
			left: 0 !important;
			display: block !important;
			width: 100% !important;
			height: 100% !important;
		}
	}
	.module-19--twitter {
		iframe {
			position: absolute !important;
			top: 0 !important;
			left: 0 !important;
			display: block !important;
			width: 100% !important;
			height: 100% !important;
		}
	}
	.module-19--instagram {
		iframe {
			max-width: 100% !important;
		}
	}
}

/*
.home .module-19 {
	position: relative;
	@media (min-width: 1350px) {
		&:before {
			content: "";
			display: block;
			width: 5%;
			height: 0;
			padding-bottom: 5%;
			background: $tint-crest-white-darker;
			position: absolute;
			z-index: -1;
			top: 0;
			left: 15px;
		}
		&:after {
			content: "";
			display: block;
			width: 5%;
			height: 0;
			padding-bottom: 5%;
			background: $tint-crest-white-darker;
			position: absolute;
			z-index: -1;
			bottom: 0;
			right: 15px;
		}
		.container {
			&:before {
				content: "";
				display: block;
				width: 2.5%;
				height: 0;
				padding-bottom: 2.5%;
				background: $crest-red;
				position: absolute;
				z-index: -1;
				bottom: 0;
				left: 15px;
			}
			&:after {
				content: "";
				display: block;
				width: 2.5%;
				height: 0;
				padding-bottom: 2.5%;
				background: $crest-yellow;
				position: absolute;
				z-index: -1;
				top: 0;
				right: 15px;
			}
		}
	}
	@media (min-width: 1500px) {
		&:before,
		&:after {
			width: 6%;
			padding-bottom: 6%;
		}
		.container {
			&:before {
				width: 4%;
				padding-bottom: 4%;
				//top: 33%;
			}
			&:after {
				width: 4%;
				padding-bottom: 4%;
				//bottom: 33%;
			}
		}
	}
	@media (min-width: 1800px) {
		&:before,
		&:after {
			width: 12%;
			padding-bottom: 12%;
		}
		.container {
			&:before {
				width: 6%;
				padding-bottom: 6%;
				//top: 60%;
			}
			&:after {
				width: 6%;
				padding-bottom: 6%;
				//bottom: 60%;
			}
		}
	}
}
*/


