.module-26 {
    max-width: 1400px;
    margin: 0 auto;
    padding: 30px 15px;
    @media (min-width: $screen-sm-min) {
        padding-top: 45px;
        padding-bottom: 45px;
    }
    @media (min-width: $screen-md-min) {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    h2.module-title {
        @include serif-r;
        font-weight: 400;
        font-size: $font-size-h3;
        text-align: center;
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: 30px;
        @media (min-width: $screen-md) {
            font-size: $font-size-h2;
            //padding-bottom: 40px;
        }
        @media (min-width: $screen-lg) {
            font-size: $font-size-md;
            //padding-bottom: 70px;
        }
        @media (min-width: 1600px) {
            font-size: 50px;
        }
    }
    .inner {
        .panel-group .panel,
        .panel-body,
        .panel-heading {
            border-radius: 0;
        }
        .panel {
            border:0;
            box-shadow: none;
        }
        .panel-body {
            border:0 !important;
        }
        .panel-group .panel+.panel {
            margin-top: 10px;
        }
        .panel-heading {
            padding:0;
            .panel-title {
                font-size: 18px;
                @media (min-width: $screen-lg) {
                    font-size: 22px;
                }
                margin: 0;
                a {
                    padding:10px 15px;
                    padding-right: 40px;
                    display:block;
                    position: relative;
                    color: white ;
                    background-color: $crest-blue;
                    @media (min-width: $screen-lg) {
                        padding-top: 20px;
                        padding-bottom: 20px;
                    }
                    &:after {
                        content:"\f068";
                        @include awesome;
                        font-size: 12px;
                        position: absolute;
                        color: white;
                        top: calc(50%);
                        transform: translateY(-50%);
                        right: 15px;
                        @media (min-width: $screen-lg) {
                            font-size: 14px;
                            right:30px;
                        }
                    }
                    &.collapsed {
                        background-color: #f2f2f2;
                        color: black;
                        &::after {
                            color: black;
                        }
                        &:hover,
                        &:hover::after {
                           color: $crest-blue;
                        }
                    }
                    &.collapsed:after {
                        content:"\f067";
                    }
                }
            }
        }
    }
}

body.home {
	.module-wrap {
        .module-26 {
            > .inner-content {
                margin-left: auto;
                margin-right: auto;
                @media (min-width: $screen-sm-min) {
                    width: 83.33333%;
                }
                @media (min-width: $screen-md-min) {
                    width: 66.66667%;
                }
            }
            > .inner {
                margin-left: auto;
                margin-right: auto;
                @media (min-width: $screen-sm-min) {
                    width: 83.33333%;
                }
                @media (min-width: $screen-md-min) {
                    width: 66.66667%;
                }
                > p:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
.content-wrap__wrapper .content-wrap__inner  {
    .panel {
        .panel-title {
            font-weight: normal;
            font-family: 'Playfair Display', serif;
        }
    }
}
