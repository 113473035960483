
.module-22 {
	.alert {
		margin: 0;
		padding: 25px 25px;
		border-radius: 0;
		h2 {
			margin-top: 0;
			font-family: $font-family-sans-serif;
			font-weight: 700;
			font-size: 24px;
		}
	}
}
