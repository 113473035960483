.rhs__menu {
	padding: 30px 15px;
	@media (min-width: $screen-lg) {
		padding: 45px 30px;
	}
	&.bio {
		margin: 40px 0;
	}
	h2 {
		font-size: $font-size-h4;
		margin-top: 40px;
		margin-bottom: 20px;
	}
	h3 {
		font-size: $font-size-h5;
	}
	.international {

	}
	.contact {
		li {
			position: relative;
			margin-left: 30px;
			margin-bottom: 15px;
			.fa-fw {
				position: absolute;
				left: -30px;
				top: 3px;
				color: $sky-blue;
			}
			a {
				text-decoration: underline;
				display: block;
				color: $crest-blue;
				word-break: break-all;
				line-height: 20px;
			}
		}
	}
	.similar-courses {

	}
	.btn-links {
		a {
			white-space: normal;
			@media (min-width: $screen-md) {
				display: block;
			}
		}
	}
	&.bio,
	.bio {
		.bg-img {
			padding-bottom: 100%;
			width: 100%;
			@include bg-cover-center;
		}
		@media (min-width: 500px) and (max-width: 991px) {
			.bg-img {
				width: 50%;
				padding-bottom: 50%;
				float: left;
				margin-bottom: 30px;
			}
			.contact {
				width: 48%;
				float: right;
				h2 {
					margin-top: 0;
				}
			}
		}
	}
}

.module-wrap.left-nav.rhs {
	.rhs {
		padding-top: 0;
	}
}

/*course status
***************************************************/

.status-rhs {
	margin: 0 -15px;
	padding: 25px 45px 45px;
	background: rgba(255,255,255,0.5);
	h2 {
		font-size: $font-size-h4;
	}
	.fa {
	padding-right: 15px;
	}
	&.open .fa {
		color: $quad-green;
	}
	&.closed .fa {
		color: $crest-red;
	}
}
