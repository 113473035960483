.module-33 {
    margin-top: 30px;
    @media (min-width: 768px) {
        margin-top: 45px;
    }
    @media (min-width: 992px) {
        margin-top: 60px;
    }
    .ui-group {
        display: flex;
        justify-content: center;
    }

    .filter-select {
        width: 300px !important;
        margin: 0 auto 40px !important;

        border: 1px solid #BBBCBC;

        .btn {
            font-weight: 400 !important;

            padding: 9px 15px;

            color: $crest-black !important;
            border: 0;
            background-color: unset;
            box-shadow: none;

            &:hover,
            &:focus {
                color: $crest-black;
                border: 0;
                background-color: unset;
                box-shadow: none;
            }
        }

        .dropdown-menu.open {
            top: 45px;
        }
    }
}

.module-33__wrapper {
    display: flex;
    flex-direction: column;

    margin: 0 -5px;

    @media (min-width: 768px) {
        flex-direction: row;

        flex-wrap: wrap;
    }
}

.module-33__item {
    width: 100%;
    padding: 0 !important;

    border: 5px solid white;

    &:nth-child(2n) {
        .slide-square {
            background-color: $crest-black;
        }
    }
    &.bg-crest-red {
        .slide-square {
            background-color: $crest-red;
        }
    }
    &.bg-crest-blue,
    &.bg-brand-facebook {
        .slide-square {
            background-color: $crest-blue;
        }
    }
    &.bg-crest-yellow {
        .slide-square {
            background-color: $crest-yellow;
        }
    }
    &.bg-quad-green {
        .slide-square {
            background-color: $quad-green;
        }
    }
    &.bg-sky-blue,
    &.bg-brand-twitter {
        .slide-square {
            background-color: $sky-blue;
        }
    }
    &.bg-dawn-mauve {
        .slide-square {
            background-color: $dawn-mauve;
        }
    }
    &.bg-earth-red {
        .slide-square {
            background-color: $earth-red;
        }
    }
    &.bg-ogham-stone {
        .slide-square {
            background-color: $ogham-stone;
        }
    }
    &.bg-white,
    &.bg-crest-white {
        .slide-square {
            background-color: #FFFFFF;
        }
        .btn--white {
            background-color: $crest-blue;
            color: $crest-white;
        }
    }
    &.bg-tint-crest-white {
        .slide-square {
            background-color: $tint-crest-white;
        }
        .btn--white {
            background-color: $crest-blue;
            color: $crest-white;
        }
    }
    &.bg-limestone {
        .slide-square {
            background-color: $limestone;
        }
    }
    &.bg-crest-black {
        .slide-square {
            background-color: $crest-black;
        }
    }

    &.bg-crest-yellow,
    &.bg-white,
    &.bg-crest-white,
    &.bg-tint-crest-white,
    &.bg-limestone {
        .slide-square__title,
        .slide-square__copy,
        .slide-square__contact a {
            color: black;
        }
    }

    .slide-square {
        margin-top: 0;

        // background-color: $cres;

        .linktype--external .fa-long-arrow-right:before {
            content: "\f08e";
        }

        .linktype--modal .fa-long-arrow-right:before {
            content: "\f2d0";
        }

        .linktype--video .fa-long-arrow-right:before {
            content: "\f01d";
        }

        .linktype--document .fa-long-arrow-right:before {
            content: "\f016";
        }
    }

    @media (min-width: 768px) {
        width: 50%;
    }

    @media (min-width: 1200px) {
        width: 33.333%;
    }

    @media (min-width: 1600px) {
        width: 25%;
    }
}

.left-nav .module-33__item {
    @media (min-width: 992px) {
        width: 50%;
    }
    @media (min-width: 1200px) {
        width: 50%;
    }
    @media (min-width: 1800px) {
        width: 33.333%;
    }
}
