#grid[data-columns]::before {
	content: '2 .column.size-1of2';
}
.column {
	float: left;
}
.size-1of2 {
	width: 50%;
}


.module-16,.module-17 {
	clear: both;
	&:first-of-type {
		padding-top: 0 !important;
	}
	.gallery-wrap {
		margin: 0 -5px;
		overflow: hidden;
		.gallery-wrap-text {
			font-family: 'Playfair Display', serif;
			color: $crest-white;
			font-size: 1em;
			position: absolute;
			bottom: 0;
			width: 100%;
			bottom: 0;
			left: 0;
			right: 0;
			margin: 0 auto;
			z-index: 0;
			padding: 15px;
			padding-top: 30px;
			@media all and (min-width: $screen-sm-min) {
			  font-size: 2em;
				padding: 40px;
				padding-top: 80px;
			}
			&::before {
				z-index: -1;
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;
				bottom: 0;
				left: 0;
				right: 0;
				margin: 0 auto;
				background: linear-gradient(to top, rgba($crest-black, .75) 0%, rgba($crest-black, 0) 100%);
			}
		}
		a {
			width: 50%;
			height: 0;
			padding-bottom: 50%;
			//height: 150px;
			border: 5px solid white;
			float: left;
			overflow: hidden;
			outline: none;
		}
	}
	a {
		position: relative;
		display: block;
		/*@media (min-width: 450px) {
			height: 200px;
		}
		@media (min-width: 600px) {
			height: 270px;
		}*/
		.display {
			position: absolute;
			top: 0;
			left: 0;
			//position: relative;
			width: 100%;
			height: 100%;
			transform: scale(1);
			transition: transform 1s;
			@include bg-cover-center;
			&.image,&.video {
				&:before {
					content: '';
					position: absolute;
					@include absolute-cover;
					//background-color: $crest-blue;
					opacity: 0.6;
					transition: opacity 1s;
				}
				&:after {
					position: absolute;
					top: 10px;
					right: 10px;
					@include awesome;
					font-size: 30px;
					color: $crest-white;
					opacity: 1;
					transition: opacity 1s;
				}
			}
			&.image {
				&:before {
					background-color: $crest-blue;
				}
				&:after {
					content: "\f1c5";
				}
			}
			&.video {
				&:before {
					background-color: $crest-red;
				}
				&:after {
					content: "\f1c8";
				}
			}
		}
		&:hover {
			.display {
				transform: scale(1.15);
				&:before,&:after {
					opacity: 0;
				}
			}
		}
	}
}

.module-16b {
	clear: both;
	&:first-of-type {
		padding-top: 0 !important;
	}
	.gallery-wrap {
		margin: -5px;
		overflow: hidden;
	}
	a {
		position: relative;
		display: block;
		overflow: hidden;
		border: 5px solid white;
		outline: none;
		&:before {
			content: '';
			position: absolute;
			@include absolute-cover;
			background-color: $crest-blue;
			opacity: 0.4;
			transition: opacity 1s;
			z-index: 1;
		}
		&:after {
			position: absolute;
			top: 10px;
			right: 10px;
			content: "\f1c5";
			@include awesome;
			font-size: 30px;
			color: $crest-white;
			opacity: 1;
			transition: opacity 1s;
			z-index: 2;
		}
		img {
			width: 100%;
			transform: scale(1);
			transition: transform 1s;
		}
		&:hover {
			img {
				transform: scale(1.15);
			}
			&:before,&:after {
				opacity: 0;
			}
		}
	}
}
