.module-1 {
	/* progress bar */
	.slider-progress {
		width: 100%;
		height: 5px;
		background: $crest-white;
		margin:0;
		position: absolute;
		z-index: 100;
		left: 0;
		right: 0;
		bottom: 0;
		.progress {
			width: 0%;
			height: 5px;
			background: $crest-yellow;
			margin:0;
			//&.progress--30 {
			//	background: $crest-yellow;
			//}
			//&.progress--60 {
			//	background: $crest-red;
			//}
		}
	}
	.slick-media-btns {
		position: absolute;
		z-index: 100;
		bottom: 20px;
		left: 40px;
		@media (min-width: 501px) {
			left: 20px;
		}
	}
	.slick-dotted.slick-slider {
		margin-bottom: 0;
	}
	.slick-dots {
		margin: 0;
		padding: 0 5px 5px 5px;
		bottom: 20px;
		right: 15px;
	    width: auto;
	    background-color: rgba($crest-white,0.75);
	    li {
	    	margin: 5px;
	    	padding: 0;
	    	width: auto;
	    	height: auto;
	    }
		li:before {
			display: none;
		}
		button {
			padding: 0;
			width: 12px;
	    	height: 12px;
		}
		button:before {
			content: '';
			display: block;
			width: 12px;
			height: 12px;
			opacity: 1;
			background-color: $crest-yellow;
		}
		.slick-active button:before {
			background-color: $crest-red;
			opacity: 1;
		}
	}
	.slideShowSingle .slick-arrow {
		width: 40px;
		height: 45px;
		background: rgba(255,255,255,0.5);
		z-index: 1000;
		&:hover {
			background: #fff;
		}
		&.slick-arrow {
			&:before {
				font-family: FontAwesome;
				font-size: 40px;
				line-height: 25px;
				color: $crest-blue;
		}
		}
		&.slick-prev {
			left: 0;
			&:before {
				content:"\f104";
			}
		}
		&.slick-next {
			right: 0;
			&:before {
				content:"\f105";
			}
		}
	}
	@media (min-width: $screen-md) {
		.slideShowSingle .slick-arrow {
			width: 55px;
			height: 60px;
			&.slick-arrow {
				&:before {
					font-size: 60px;
					line-height: 35px;
				}
			}
		}
	}
	position: relative;
	border-bottom: 10px solid #fff;
	.module-1 {
		border-bottom: 0;
	}
	div {
		.slideShowSingle {
			figure {
				> div {
					@include bg-cover-center;
					height: 450px;
					&:after {
						@include gradient-black-up;
						height: 100%;
					}
				}
			}
			@media (min-width: $screen-sm - 1) {
				display: none;
			}
		}
		.videoWrap {
			display: none;
			position: relative;
			top: 0;
			left: 0;
			width: 100%;
			height: 450px;
			overflow: hidden;
			@media (min-width: $screen-sm) {
				display: block;
			}
			@media (min-width: $screen-md) {
				height: 550px;
			}
			@media (min-width: $screen-lg) {
				height: 650px;
			}
			@media (min-width: 1400px) {
				height: 750px;
			}
			&:before {
				content:'';
				background-color: black;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: -100;
			}
			video {
				position: absolute;
				top: 50%;
				left: 50%;
				min-width: 100%;
				min-height: 100%;
				width: auto;
				height: auto;
				z-index: -100;
				transform: translateX(-50%) translateY(-50%);
				transition: 1s opacity;
				opacity: 0;
				transition: opacity 0.8s linear;
				&.is-playing {
					opacity: 1;
				}
			}
			&:after {
				content: '';
				position: absolute;
				height: 100%;
				width: 100%;
				background-color: rgba(0,0,0,0.2);
			}
			button {
		    	background-color: transparent;
		    	border: 0;
		    	position: absolute;
		    	bottom: 20px;
		    	left: 15px;
		    	z-index: 20;
		    	height: 50px;
				width: 50px;
				outline: 0;
		    	&:before,
		    	&.pause:before {
		    		position: absolute;
		    		top: 0;
		    		left: 0;
		    		height: 50px;
		    		width: 50px;
		    	}
		    	&:before {
		    		content:'';
		    		background-image: url('/en/media/2017siteassets/images/pause.jpg')
		    	}
		    	&.pause:before {
		    		content:'';
		    		background-image: url('/en/media/2017siteassets/images/play.jpg')
		    	}
		    }
		    .slide-down {
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				z-index: 10;
				opacity: 0.4;
				&:hover {
					opacity: 0.7;
				}
			}
		}
		&.no-video {
			.slideShowSingle {
				display: block;
			}
		}
		figcaption,.video-overlay {
			position: absolute;
			z-index: 10;
			right: 0;
			bottom: 40px;
			left:0;
			width: 90%;
			padding: 0 15px;
			color: $crest-white;
			@media (max-width: 400px) {
				padding: 0 0 0 40px;
			}
			.btn {
				white-space: normal;
			}
			&.center {
				right: 0;
				bottom: 15px;
				left: 0;
				width: 100%;
				margin-left: auto;
				margin-right: auto;
				text-align: center;
				@media (min-width: $screen-sm) {
					bottom: 50px;
				}
				@media (min-width: 1400px) {
				.main-heading {
						font-size: $font-size-lg;
					}
				}
			}
			@media (min-width: 500px) {
				width: 70%;
				max-width: 1150px;
				left: 8%;
			}
			@media (min-width: 992px) {
				bottom: 40px;
			}
			@media (min-width: 1400px) {
				bottom: 100px;
			}
			.main-heading {
				@include serif-r;
				margin: 0 0 20px;
				font-size: 22px;
				color: white;
			}
			.sub-heading {
				margin-bottom: 20px;
			}
			.spotlight {
				@include serif-r;
			}
		}
	}
}

.course .module-1 {
	border-bottom: 0;
}

.module-1.no-video figure > div:after {
	content: "";
	display: block;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+30,0.7+100 */
	background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0.7) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 30%,rgba(0,0,0,0.7) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 30%,rgba(0,0,0,0.7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.module-1.no-video.info-banner figure > div:after {
	background: transparent;
}

body {
	&.home .module-1 {
		.slideShowSingle figure > div {
			height: 450px;
			@include bg-cover-center;
			@media (min-width: 1400px) {
				height: 650px;
			}
		}
		@media (min-width: $screen-sm) {
			.main-heading {
				font-size: $font-size-md;
			}
			.sub-heading,.btn {
				font-size: $font-size-h5;
			}
		}
		@media (min-width: $screen-md) {
			.main-heading {
				font-size: $font-size-h1;
				margin-bottom: 20px;
			}
			.sub-heading {
				margin-bottom: 30px;
			}
		}
		@media (min-width: $screen-lg) {
			//bottom: 100px;
			.main-heading {
				font-size: $font-size-lg;
				margin-bottom: 30px;
			}
			.btn {
				font-size: $font-size-sm;
			}
			.sub-heading {
				margin-bottom: 40px;
				font-size: 20px;
			}
		}
		@media (min-width: 1400px) {
			.main-heading {
				font-size: $font-size-xlg;
			}
		}
	}
	&.inner .module-1 {
		.slideShowSingle figure > div {
			height: 450px;
			@include bg-cover-center;
			@media (min-width: 1400px) {
				height: 450px;
			}
		}
		figcaption {
			.btn-block {
				.btn {
					margin-bottom: 5px;
					width: 100%;
				}
			}
		}
		@media (min-width: $screen-sm) {
			.main-heading {
				font-size: 32px;
			}
			.sub-heading,.btn {
				font-size: 18px;
			}
		}
		@media (min-width: $screen-md) {
			.main-heading {
				font-size: 40px;
				margin-bottom: 20px;
			}
			.sub-heading {
				margin-bottom: 30px;
			}
			.btn {
				padding-left: 50px;
				padding-right: 50px;
			}
		}
		@media (min-width: 1600px) {
			.main-heading {
				font-size: 50px;
				margin-bottom: 30px;
			}
			.sub-heading,.btn {
				font-size: $font-size-sm;
			}
			.sub-heading {
				margin-bottom: 40px;
			}
			.btn {
				padding: 22px 80px;
			}
		}
	}
}

.course .module-1 {
	background: #000;
	figure > div {
		opacity: 0.7;
	}
	figcaption {
		width: 90%;
		max-width: 90%;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		padding: 0;
		@media (min-width: 500px) {
			width: 90%;
			max-width: 90%;
			left: 0;
			padding: 0;
		}
		@media (min-width: $screen-md) {
			padding-right: 550px;
			text-align: left;
			.btn-block {
				position: absolute;
				bottom: -20px;
				right: 0;
				.btn {
					margin-bottom: 10px !important;
				}
			}
		}
		@media (min-width: 1400px) {
			.btn-block {
				right: 120px;
			}
		}
		@media (min-width: 1600px) {
			.btn-block {
				max-width: 400px;
				.btn {
					padding: 18px 30px;
					font-size: 18px;
				}
			}
		}
		@media (min-width: 1400px) {
			max-width: 100%;
			width: 100%;
			padding-left: 120px;
		}
	}
}

.stopfade {
  opacity: .5;
}

.clearVideo {
	display: none;
}
@media (min-width: $screen-sm) {
	.clearVideo {
		display: block;
		margin-top: 450px;
	}
}

body.inner .module-1.info-banner {
	.slideShowSingle figure {
		min-height: 50px;
		height: auto;
		padding: 20px 0;
	}
	&.image .slideShowSingle figure > div {
		height: auto;
		@include absolute-cover;
	}
	.main-heading {
		font-family: "Roboto",sans-serif;
		font-weight: 900;
		font-size: 80px;
		margin: 0;
	}
	.sub-heading {
		font-family: 'Playfair Display', serif;
		margin: 0;
	}
	&.yellow .slideShowSingle figure {
		background-color: $crest-yellow;
		.sub-heading {
			color: $crest-blue;
		}
	}
	&.red .slideShowSingle figure {
		background-color: $crest-red;
	}
	&.image .slideShowSingle figure {
		.main-heading {
			color: $crest-yellow;
		}
	}
	figcaption {
		position: relative;
		top: auto;
		left: auto;
		right: auto;
		bottom: auto;
		width: 100%;
		max-width: 100%;
	}
	.quote {
			background: $crest-yellow;
			color: $crest-blue;
			padding: 5px 30px 20px;
			margin-top: 10px;
			h3 {
				font-weight: 700;
				margin-bottom: 5px;
			}
			blockquote {
				border: 0;
				font-style: italic;
				position: relative;
				padding: 0 0 0 40px;
				margin-top: 10px;
				font-weight: 400;
				&:before {
					content:"\f10d";
					font-family: "FontAwesome";
					color: #fff;
					font-style: normal;
					font-size: 32px;
					position: absolute;
					top: -12px;
					left: 0;
				}
			}
		}
		&.yellow .quote {
			background: $crest-blue;
			color: #fff;
		}
		&.image .quote {
			background: rgba(0,60,105,0.7);
			color: #fff;
			h3 {
				color: $crest-yellow;
			}
			@media (min-width: $screen-md) {
				padding: 10px 40px 30px;
				p {
					margin: 5px 0 20px;
					font-size: 22px;
					font-weight: 300;
				}
				blockquote {
					font-size: 22px;
					line-height: 1.5;

				}
			}

		}
}


@media (min-width: $screen-lg) {

	body.inner .module-1.info-banner {
		.slideShowSingle figure  {
			padding: 80px;
			overflow: hidden;
			display: flex;
			align-items: flex-end;
		}
		&.image .slideShowSingle figure {
			min-height: 500px;
		}
		.outer {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
		}
		.inner {
			display: flex;
			align-items: center;
			width: 65%;
			float: left;
		}
		&.image .inner {
			align-items: flex-end;
			flex-wrap: wrap;
			.main-heading {
				width: 100%;
			}
			.sub-heading {
				max-width: 50%;
			}
		}
		.main-heading {
			font-size: 10vw;
			padding-right: 50px;
			max-width: 40%;
		}
		.sub-heading {
			font-size: 2.3vw;
			line-height: 1.25;
			margin: -2% 0 0 0;
			max-width: 60%;
		}
		.quote {
			width: 30%;
			float: right;
		}
	}
}

@media (min-width: $screen-md) {

	body.inner .module-1.spotlight-small .slideShowSingle figure > div {
		height: 300px;
	}

	.module-1.spotlight-small div figcaption {
		top: auto;
		left: 15px;
		right: 0;
		bottom: 30px;
	}

	body.inner .module-1.spotlight-small .main-heading {
		font-size: 32px;
		margin-bottom: 20px;
	}

	body.inner .module-1.spotlight-small .sub-heading {
		font-size: 15px;
		line-height: 1.3;
		margin-bottom: 20px;
	}

	body.inner .module-1.spotlight-small .btn {
		font-size: 16px;
		padding: 10px 20px;
		min-height: 0;
	}

}



/*Q4 UPDATES - REDUCED HEIGHT
*************************************************************/

body.home {
	.module-1 {
		&.reduced-height {
			.slideShowSingle {
				figure {
					> div {
						height: 65vh;
					}
				}
			}
		}
		.main-heading {
			//font-size: 60px;
		}
	}
}


/*Q4 UPDATES - COLOUR BLOCK TEXT
*************************************************************/

body.home,
.content-wrap__inner {
	.module-1 {
		&.colour-block-text {
			.slide--countdown-feature {
				figcaption .main-heading {
					max-width: 100% !important;
				}
			}
			figcaption {
				background: transparent;
				bottom: 0;
				top: 0;
				display: flex;
				align-items: center;
				width: auto;
				max-width: 100%;
				right: 0;
				.outer {
					width: 92%;
				}
				@media (max-width: $screen-md-min) {
					padding-top: 50px;
				}
			}
			.slideShowSingle {
				figure {
					> div {
						&:after {
							display: none;
						}
					}
				}
			}
			.main-heading,
			.sub-heading {
				margin: 3px 0;
				line-height: 1.3;
				padding: 15px 30px;
				opacity: 0.90;
			}
			.main-heading {
				max-width: 45%;
				font-size: 3vw;
				display: inline-block;
				padding-top: 10px;
				@media (max-width: 1200px) {
					max-width: 45%;
					//font-size: 35px;
				}
				@media (max-width: $screen-sm-min) {
					max-width: 90%;
					padding-left: 20px;
					padding-right: 20px;
					font-size: 34px;
				}
				@media (max-width: 479px) {
					max-width: 100%;
					font-size: 25px;
				}
			}
			.sub-heading {
				max-width: 50%;
				font-size: 18px;
				display: inline-block;
				@media (max-width: $screen-lg-min) {
					max-width: 50%;
				}
				@media (max-width: $screen-sm-min) {
					max-width: 100%;
					padding-left: 20px;
					padding-right: 20px;
				}
			}
			.btn {
				margin: 3px 0;
			}



			.bg-crest-red .main-heading,
			.bg-crest-red .sub-heading,
			.bg-crest-red .number,
			.slide--countdown-feature .bg-crest-red .btn {
				color: white;
				background-color: $crest-red;
			}

			.bg-crest-blue .main-heading,
			.bg-crest-blue .sub-heading,
			.bg-crest-blue .number,
			.slide--countdown-feature .bg-crest-blue .btn {
				color: white;
				background-color: $crest-blue;
			}


			.bg-crest-yellow .main-heading,
			.bg-crest-yellow .sub-heading,
			.bg-crest-yellow .number,
			.slide--countdown-feature .bg-crest-yellow .btn {
				color: black;
				background-color: $crest-yellow;
				.fa {
					color: $crest-white;
				}
			}
			.bg-crest-yellow .btn--yellow {
				background-color: white;
				.fa {
					color: $crest-yellow;
				}
			}

			.bg-quad-green .main-heading,
			.bg-quad-green .sub-heading,
			.bg-quad-green .number,
			.slide--countdown-feature .bg-quad-green .btn {
				color: white;
				background-color: $quad-green;
			}

			.bg-dawn-mauve .main-heading,
			.bg-dawn-mauve .sub-heading,
			.bg-dawn-mauve .number,
			.slide--countdown-feature .bg-dawn-mauve .btn {
				color: white;
				background-color: $dawn-mauve;
			}


			.bg-crest-white .main-heading,
			.bg-crest-white .sub-heading,
			.bg-crest-white .number,
			.slide--countdown-feature .bg-crest-white .btn {
				color: $crest-blue;
				background-color: white;
			}


			.bg-tint-crest-white .main-heading,
			.bg-tint-crest-white .sub-heading,
			.bg-tint-crest-white .number,
			.slide--countdown-feature .bg-tint-crest-white .btn {
				color: $crest-blue;
				background-color: $tint-crest-white;
			}


			.bg-crest-black .main-heading,
			.bg-crest-black .sub-heading,
			.bg-crest-black .number,
			.slide--countdown-feature .bg-crest-black .btn {
				color: white;
				background-color: $crest-black;
			}


			.bg-earth-red .main-heading,
			.bg-earth-red .sub-heading,
			.bg-earth-red .number,
			.slide--countdown-feature .bg-earth-red .btn {
				color: white;
				background-color: $earth-red;
			}
			.bg-earth-red .btn--yellow {
				background-color: white;
				.fa {
					color: $crest-yellow;
				}
			}


			.bg-ogham-stone .main-heading,
			.bg-ogham-stone .sub-heading,
			.bg-ogham-stone .number,
			.slide--countdown-feature .bg-ogham-stone .btn {
				color: white;
				background-color: $ogham-stone;
			}
			.bg-ogham-stone .btn--yellow {
				background-color: white;
				.fa {
					color: $crest-yellow;
				}
			}


			.bg-limestone .main-heading,
			.bg-limestone .sub-heading,
			.bg-limestone .number,
			.slide--countdown-feature .bg-limestone .btn {
				color: $crest-black;
				background-color: $limestone;
			}


			.bg-sky-blue .main-heading,
			.bg-sky-blue .sub-heading,
			.bg-sky-blue .number,
			.slide--countdown-feature .bg-sky-blue .btn {
				color: $crest-black;
				background-color: $sky-blue;
			}

		//these default to crest-blue

			.bg-brand-twitter .main-heading,
			.bg-brand-twitter .sub-heading,
			.bg-brand-twitter .number,
			.slide--countdown-feature .bg-brand-twitter .btn {
				color: white;
				background-color: $crest-blue;
			}


			.bg-brand-facebook .main-heading,
			.bg-brand-facebook .sub-heading,
			.bg-brand-facebook .number,
			.slide--countdown-feature .bg-brand-facebook .btn {
				color: white;
				background-color: $crest-blue;
			}

		}
	}
}

.scroll-to-content {
	position: absolute;
	z-index: 100;;
	left: 50%;
	display: inline-block;
	width: 60px;
	height: 60px;
	transform: translateX(-50%) translateY(-50%);
	margin-top: 5px;
	border-radius: 50%;
	text-align: center;
	background: $crest-white;
	color: $crest-blue;
	border: 3px solid $crest-blue;
	transition: .5s ease;
	&:hover,
	&:active {
		background: $crest-blue;
		color: $crest-white;
		border-color: $crest-blue;
	}
	.fa {
		display: block;
		font-size: 36px;
		line-height: 1;
		transform: translateY(34%);
	}
}



/*COUNTDOW TIMER
**********************************************/

.countdown {
	display: block !important;
	height: auto !important;
	&.countdown-mini {
		position: absolute;
		z-index: 10;
		top: 15px;
		right: 15px;
		background: rgba($crest-white,0.9);
		padding: 10px 8px 7px;
		min-width: 240px;
		@media (min-width: $screen-md-min) {
			min-width: 300px;
		}
		.countdown__timer {
			@include clearfix;
		}
		.countdown__number {
			width: 25%;
			float: left;
			padding: 0 2px;
		}
		.number {
			display: block;
			width: 100%;
			text-align: center;
			background: $crest-black;
			color: $crest-white;
			font-weight: 700;
			font-size: 24px;
			line-height: 1;
			padding: 10px 0 9px;
			@media (min-width: $screen-md-min) {
				font-size: 32px;
				padding: 14px 0 13px;
			}
		}
		.label {
			display: block;
			width: 100%;
			text-align: left;
			text-transform: uppercase;
			font-weight: 400;
			font-size: 10px;
			color: $crest-black;
			margin: 7px 10px 0 0;
			padding: 0;
			@media (min-width: $screen-md-min) {
				font-size: 12px;
			}
		}
		.date {
			display: none;
			@media (min-width: $screen-md-min) {
				display: block;
				font-size: 14px;
				margin: 5px 0 2px;
				padding: 0;
				text-align: center;
				color: $crest-black;
			}
		}
	}
}

.module-1 {
	.slide--countdown-feature {
		figcaption {
			padding: 0 40px !important;
			left: 0;
			.outer {
				width: 100% !important;
			}.inner {
				background-color: rgba($crest-white,0.9);
				text-align: center;
				padding: 15px;
				@media (min-width: $screen-sm-min) {
					padding: 25px 30px 30px;
					max-width: 700px;
					margin: 0 auto;
				}
				@media (min-width: $screen-md-min) {
					padding: 25px 30px 30px;
					max-width: 850px;
					margin: 0 auto;
				}
				.main-heading {
					color: $crest-black !important;
					background: transparent !important;
					display: block;
					width: auto;
					max-width: 100%;
					margin: 0;
					padding: 0;
					font-size: 30px;
					@media (min-width: $screen-md-min) {
						font-size: 44px;
					}
				}
				.sub-heading {
					color: $crest-black !important;
					background: transparent !important;
					display: block;
					width: auto;
					max-width: 100%;
					margin: 5px 0 15px;
					padding: 0;
					font-size: 14px;
					@media (min-width: $screen-md-min) {
						font-size: 16px;
						margin: 10px 0 20px;
					}
				}
				.btn {
					margin: 5px 0;
					min-height: 46px;
					@media (min-width: $screen-sm-min) {
						font-size: 18px;
						padding: 14px 24px;
					}
				}
				br {
					display: none;
				}
			}
		}
		.countdown {
			&.countdown-feature {
				.countdown__timer {
					@include clearfix;
					width: 100%;
					max-width: 300px;
					margin: 10px auto;
					@media (min-width: $screen-sm-min) {
						max-width: 400px;
					}
					@media (min-width: $screen-md-min) {
						max-width: 580px;
					}
				}
				.countdown__number {
					width: 25%;
					float: left;
					padding: 0 2px;
					@media (min-width: $screen-sm-min) {
						padding: 0 5px;
					}
				}
				.number {
					display: block;
					width: 100%;
					text-align: center;
					background: $crest-black;
					color: $crest-white;
					font-weight: 700;
					font-size: 26px;
					line-height: 1;
					padding: 14px 0;
					@media (min-width: 360px) {
						font-size: 30px;
						padding: 18px 0;
					}
					@media (min-width: $screen-sm-min) {
						font-size: 38px;
						padding: 30px 0;
					}
					@media (min-width: $screen-md-min) {
						font-size: 70px;
					}
				}
				.label {
					display: block;
					width: 100%;
					text-align: left;
					text-transform: uppercase;
					font-weight: 400;
					font-size: 10px;
					color: $crest-black;
					margin: 7px 10px 0 0;
					padding: 0;
					@media (min-width: $screen-sm-min) {
						font-size: 14px;
					}
				}
				.date {
					display: block;
					font-size: 14px;
					margin: 15px 0;
					padding: 0;
					text-align: center;
					color: $crest-black;
				}
			}
		}
	}
}

//hacks to get banner to display nicely on inner page content
.content-wrap__inner {
	.module-1 {
		padding: 0 !important;
		.scroll-to-content {
			display: none;
		}
		div {
			.slideShowSingle {
				figure>div:after {
					display: none;
				}
			}
		}
	}
}

body .content-wrap__inner {

	.module-1.colour-block-text .main-heading, .content-wrap__inner .module-1.colour-block-text .main-heading {
		max-width:40%;
		font-size: 25px;
		@media (max-width: $screen-sm-min) {
			font-size: 24px;
		}
	}

	.module-1.colour-block-text .sub-heading, .content-wrap__inner .module-1.colour-block-text .sub-heading {
		max-width: 100%;
		font-size: 16px;
	}

	.module-1 .slick-dots li {
		margin: 5px !important;
	}

	.module-1 .slideShowSingle figure>div {
		@media (max-width: $screen-sm-min) {
			height: 500px;
		}
	}

}




