.module-25 {
	.alert {
		margin: 0;
		padding: 25px 25px 20px;
		border-radius: 0;
		background-color: $tint-crest-white;
		h2 {
			position: relative;
			margin: 0;
			font-family: $font-family-sans-serif;
			font-weight: 700;
			font-size: 24px;
			color: $crest-blue;
			padding-left: 40px;
			&:before {
				content:"\f05a";
				font-family: FontAwesome;
				color: $quad-green;
				font-size: 32px;
				position: absolute;
				top: -5px;
				left: 0;
				@media (min-width: $screen-sm-min) {
					top: 2px;
				}
			}
			.btn {
				position: relative;
				font-family: $font-family-sans-serif;
				font-weight: 400;
				font-size: 16px;
				background-color: transparent;
				border: 0;
				box-shadow: none;
				outline: none;
				padding: 0 22px 0 10px;
				text-decoration: underline !important;
				color: $link-color;
				@media (max-width: $screen-sm-min - 1) {
					display: block;
					padding-left: 0;
				}
				&:hover {
					text-decoration: none !important;
				}
				&:after {
					content:"\f107";
					font-family: FontAwesome;
					font-size: 20px;
					position: absolute;
					right: 0;
					top: 13px;
					transition: .3s ease;
				}
				&[aria-expanded="true"] {
					&:after {
						transform: rotate(-180deg);
					}
				}
			}
		}
		.close {
			position: relative;
			z-index: 999;
		}
		.well {
			padding: 1px 40px 0 40px;
			margin-bottom: 10px;
			max-width: 850px;
			> *:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.content-wrap__wrapper {
	div.module-25 {
		@media (min-width: $screen-md-min) {
			padding-bottom: 20px;
		}
		.alert {
			padding: 15px 20px 10px;
		}
	}
}
