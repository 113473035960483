///////////////////////////////////////////////////////////
// resets
///////////////////////////////////////////////////////////

html,body {
	@include sans-r;
	font-size: $font-size-base;
	font-weight: 400;
	color: $crest-black;
	font-size: 16px;
	overflow-x: hidden;
    width: 100%;
    transition: 0.5s;
    position: relative;
    left: 0;
    padding: 0 !important;
    min-height: 100%;
    -ms-overflow-style: scrollbar;
}

.form-control {
	appearance: none;	
	font-size: 16px;
}

input[placeholder] { 
	text-overflow: ellipsis; 
}

h1,h2,h3,h4,h5,h6 {
	@include serif-r;
	margin: 30px 0 15px;
}

ul {
	margin: 0;
	padding: 0;
	li {
		list-style: none;
	}
}

p, li {
	line-height: 1.25;
	@media (min-width: $screen-sm) {
		line-height: 1.5;
	}
	@media (min-width: $screen-md) {
		line-height: 1.75;
	}
}

a:hover, a:focus {
	text-decoration: none;
}

.well {
	box-shadow: none;
}
