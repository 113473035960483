///////////////////////////////////////////////////////////
// search modal
///////////////////////////////////////////////////////////

/*body .remodal-wrapper {
	padding: 0;
}*/
.remodal-prev-next {
	.remodal-overlay {
		&.remodal-is-opening,
		&.remodal-is-closing {
			animation: none;
		}
	}
	.remodal-wrapper {
		&.remodal-is-opening,
		&.remodal-is-closing,
		.remodal-is-opening,
		.remodal-is-closing {
			animation: none;
		}
	}
  }
.remodal-overlay.bg-crest-white {
	background-color: rgba(266,266,266,0.95);
}

/*circle close button
body .remodal-close {
	top: -25px;
	left: auto;
	right: -25px;
	width: 50px;
	height: 50px;
	margin: 0;
	padding: 0;
	-webkit-transition: color 0.2s;
	-o-transition: color 0.2s;
	transition: color 0.2s;
	text-decoration: none;
	color: $crest-white;
	background: $crest-red;
	border-radius: 50%;
	z-index: 1000;
	text-shadow: none;
	opacity: 1;
}

body .remodal-close:hover,
body .remodal-close:focus {
    color: $crest-yellow;
}

body .remodal-close:before {
	font-size: 30px;
	line-height: 50px;
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 50px;
}
*/

/*square close button*/
body .video .remodal-close {
    top: -50px;
    right: 0px;
}

body .remodal-close {
	top: 0;
	left: auto;
	right: 0;
	width: 50px;
	height: 50px;
	margin: 0;
	padding: 0;
	-webkit-transition: color 0.2s;
	-o-transition: color 0.2s;
	transition: color 0.2s;
	text-decoration: none;
	color: $crest-white;
	background: $crest-red;
	border-radius: 0;
	z-index: 1000;
	text-shadow: none;
	opacity: 1;
}

body .remodal-close:hover,
body .remodal-close:focus {
    color: $crest-yellow;
}

body .remodal-close:before {
	font-size: 30px;
	line-height: 50px;
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 50px;
}

body .remodal-arrow {
	font-family: 'FontAwesome';
	line-height: 1;
	opacity: 1;
	color: $crest-black;
	font-size: 50px;
	border: none;
	background: rgba($crest-yellow, 1);
	position: fixed;
	z-index: 1000;
	padding: 0 10px;
	top: calc(100% - 50px);
	&:hover {
		background: rgba($crest-yellow, 0.8);
	}
	&.remodal-prev {
		left: calc(50% - 44px);
		padding-right: 15px;
		&::before{
			content:"\f104";
		}
	}
	&.remodal-next {
		right: calc(50% - 44px);
		padding-left: 15px;
		&::before{
			content:"\f105";
		}
	}
}

body .video {
	.remodal-arrow {
		top: 100%;
	}
}

body .remodal.noBackground {
	  background: transparent;
}

.remodal-wrapper {
	.remodal.video {
		padding: 0 0 0 0;
		width: 96%;
		max-width: 1200px;
	}
	img {
		// max-width: 100%;
		width: 100% !important;
		height: auto !important;
	}
	.contentmodal--modal-content {
		padding: 20px 30px 20px;
		> *:first-child {
			margin-top: 0;
		}
		> *:last-child {
			margin-bottom: 0;
		}
	}
}

.stylish-input-group {
	margin-top: 5px;
}

.radio-inline input {
	height: auto !important;
}


.searchResultsList {
	margin-top: 30px;
	p,h3 {
		word-break: break-all;
	}
	li:not(.viewall) {
		width: 100%;
		padding: 15px;
		border: 1px solid $crest-yellow;
		border-left: 10px solid $crest-yellow;
		margin-bottom: 15px;
		h3 {
			font-size: 20px;
			margin: 0;
			text-align: left;
			span {
				 color: white;
				background: #0055a0;
				display: inline-block;
				padding: 1px 5px;
				font-size: 12px;
				margin-right: 5px;
				border-radius: 20px;
			}
		}
		p {
			display: none;
			word-break: break-all;
			&:last-of-type {
				margin-bottom: 0;
				> a.btn {
					display: block;
					color: white;
				}
			}
		}
	}
	li.viewall {
		clear: both;
		a {
			background-color: $crest-red;
			color: white;
			display: block;
			padding: 15px;
		}
	}
}

.resultsCount {
	color: $crest-red;
}

.remodal-wrapper {
	.contentmodal--modal-content {
		text-align: left;
		a {
			text-decoration: underline;
		}
		ul {
		padding-left: 20px;
		margin: 20px auto 20px 20px;
		li {
			position: relative;
			margin: 5px 0;
			&:before {
				content: "\f0c8";
				@include awesome;
				position: absolute;
				top: 7px;
				left: -16px;
				color: $crest-red;
				font-size: 8px;
			}
		}
	}
	}
}


/*VISITED PAGES
*****************************************************************/

.remodal.pagesListRemodal {
	width: 100%;
	min-height: 100%;
	max-width: 100%;
	margin-bottom: 0;
	text-align: left;
}

body .remodal.pagesListRemodal .remodal-close {
	top: 0;
	left: auto;
	right: 0;
	width: 50px;
	height: 50px;
	margin: 0;
	padding: 0;
	color: $crest-white;
	background: $sky-blue;
	border-radius: 0;
	opacity: 1;
}

body .remodal.pagesListRemodal .remodal-close:hover,
body .remodal.pagesListRemodal .remodal-close:focus {
    background: $crest-yellow;
}

.pagesList {
	margin: 0;
	padding: 0;
	counter-reset: li;
	li {
		font-size: 16px;
		position: relative;
		text-align: left;
		padding: 10px 0;
		font-weight: 700;
		&:before {
			font-family: $font-family-serif;
			counter-increment: li;
			//content: counter(my-awesome-counter, decimal-leading-zero) ".";
			content: counter(li, decimal-leading-zero) ".";
			color: $crest-yellow;
			z-index: 20;
			position: absolute;
			top: 20px;
			left: 15px;
			font-size: 24px;
		}
	}
	a {
		display: block;
		position: relative;
		z-index: 1;
		color: $crest-blue;
		//background: $tint-crest-white;
		padding: 40px 15px 15px;
		text-decoration: none;
		line-height: 1.25;
		background-size: cover;
		&:before {
			content: "";
			position: absolute;
			z-index: -1;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba($tint-crest-white,0.85);
		}
	}
	span {
		color: $crest-black;
		font-family: $font-family-sans-serif;
		font-size: 14px;
		font-weight: 400;
		display: block;
		margin-top: 2px;
	}
	button {
		position: absolute;
		z-index: 30;
		top: 10px;
		right: 0;
		border: 0;
		background: $crest-yellow;
		color: $crest-white;
		width: 30px;
		height: 30px;
		font-size: 16px;
		text-align: center;
		.fa, .fa:before {
			display: block;
			width: 100%;
			text-align: center;
			position: absolute;
    left: 0;
    top: 4px;
		}
	}
}

#pinnedVisitedPagesList button {
	background: $crest-red;
}


@media (min-width: $screen-sm) {

	.pagesList {
		margin: 0 -10px;
		display: flex;
		align-items: stretch;
		justify-content: left;
		flex-flow: row wrap;
		align-content: flex-end;


		li {
			font-size: 16px;
			padding: 10px;
			width: 50%;
			float: left;
			display: flex;
			align-items: stretch;
			&:before {
				top: 20px;
				left: 25px;
			}
		}
		a {
			padding: 40px 15px 15px;
			width: 100%;
		}
		button {
			right: 10px;
		}

	}


}

@media (min-width: $screen-md) {

	body .remodal.pagesListRemodal .remodal-close {
		width: 124px;
		height: 124px;
		font-size: 64px;
	}

	body .remodal.pagesListRemodal .remodal-close:before {
		font-size: 64px;
		line-height: 105px;
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 124px;
	}

	body .remodal.pagesListRemodal .remodal-close .sr-only {
		position: static;
		width: auto;
		height: auto;
		margin: auto;
		padding: 0;
		overflow: auto;
		clip: auto;
		text-transform: uppercase;
		font-size: 14px;
		padding-top: 10px;
	}

	.mega-slide h2 {
		margin: 30px auto 20px;
		font-size: 24px;
		font-weight: 700;
	}

	.mega-slide .alert {
		margin-left: auto;
		margin-right: auto;
	}

	.pagesList {
		margin: 0 -10px;
		li {
			width: 33.33333%;
			float: left;
			&:before {
				top: 20px;
				left: 30px;
			}
		}
		a {
			padding: 55px 20px 25px;
		}
		button {
			width: 30px;
			height: 30px;
		}
	}

}

/*

@media (min-width: $screen-lg) {

	.mega-slide h2 {
		max-width: 1600px;
	}

	.mega-slide .alert {
		max-width: 1600px;
	}

	.pagesList {
		max-width: 1600px;
		li {
			font-size: 20px;
			width: 25%;
		}
	}

}
*/



/*MODAL CONTENT
*********************************/

.contentmodal--modal-content table {
	width: 100% !important;
	margin: 30px auto;
}

.contentmodal--modal-content table th,
.contentmodal--modal-content table td {
	border: 1px solid #ddd;
	padding: 8px;
}

.contentmodal--modal-content table th {
	font-weight: 700;
	color: $crest-blue;
	background: $tint-crest-white;
}
