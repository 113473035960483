.module-35 {
	position: relative;
	z-index: 1001;
	margin: 0 15px;
	padding: 45px 0 30px;
}
.module-35__inner {
	border: 2px solid #717274;
	border-radius: 0 12px 12px 0;
	background-color: $crest-white;
}

.module-35__title {
	font-family: Roboto, sans-serif;
	font-size: 14px;
	color: #6F7173;
	font-weight: 700;
	text-transform: uppercase;
	margin: 0;
	padding: 0 0 15px 0;
	@media (min-width: 992px) {
		font-size: 18px;
		padding-bottom: 25px;
	}
}

.module-35__host {
	min-width: 300px;
	position: relative;
	background-color: $crest-white;
	border: 2px solid #717274;
	border-radius: 0 12px 0 0;
	padding: 30px;
	display: inline-block;
	margin-top: -45px;
	margin-left: -19px;
	img {
		height: 70px;
		width: auto;
		max-width: 240px;
	}
	&:before {
		content: "";
		width: 0;
		height: 0;
		border-top: 15px solid #808C94;
		border-right: 280px solid transparent;
		position: absolute;
		bottom: -17px;
		left: 17px;
	}
	&:after {
		content: "";
		width: 0;
		height: 0;
		border-top: 119px solid #808C94;
		border-right: 15px solid transparent;
		position: absolute;
		top: 43px;
		right: -17px;
	}
	@media (min-width: 992px) {
		position: absolute;
		z-index: 10;
		min-width: 490px;
		padding: 45px 30px 45px 120px;
		img {
			height: 110px;
			width: auto;
			max-width: 340px;
		}
		&:before {
			bottom: -27px;
			border-top: 25px solid #808C94;
			border-right: 475px solid transparent;
		}
		&:after {
			right: -27px;
			border-top: 200px solid #808C94;
			border-right: 25px solid transparent;
		}
	}
}

.module-35__partners {
	padding: 30px 30px 30px 30px;
	ul {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
	}
	li {
		padding: 0 15px 15px 0;
	}
	img {
		max-height: 50px;
		width: auto;
	}
	@media (min-width: 992px) {
		padding: 45px 30px 30px 520px;
		li {
		padding: 0 30px 30px 0;
	}
		img {
			max-height: 56px;
			width: auto;
		}
	}
}

#content + .module-35 {
	margin-top: 5vw;
	margin-bottom: 2vw;
}

