// Used by Map component

.module-40 {
    margin-bottom: 60px;

    .module-40-container {
        padding: 20px 0;
        padding: 10px;

        color: white;
        background-color: $crest-black;

        a {
            color: $crest-white;
            text-decoration: underline;
        }

        @media all and (min-width:992px) {
            padding: 20px;
        }

        @media all and (min-width:1200px) {
            padding: 30px;
        }

        h2 {
            margin-top: 0;
            margin-bottom: 30px;

            a {
                font-size: initial;
                font-weight: normal;
                line-height: 60px;

                text-decoration: underline;

                color: white;
            }
        }

        .map-home {
            position: relative;

            width: 100%;
            // border-radius: 8px;
            height: 400px;

            border: 1px solid black;

            @media all and (min-width: 992px) {
                height: 800px;
            }
        }

        .folder-content {
            @media all and (min-width: 992px) {
                display: flex;
            }
        }

        .folder-container {
            .folder {
                position: relative;

                margin-bottom: 10px;
                padding: 10px;
                padding-right: 40px;

                color: black;
                border-radius: 8px;
                background-color: white;

                @media all and (min-width: 992px) {
                    padding: 15px 30px;
                    padding-right: 60px;
                }

                h3 {
                    font-size: 18px;

                    margin: 5px 0 5px;

                    @media all and (min-width: 992px) {
                        font-size: 24px;
                    }

                    @media all and (min-width: 1440px) {
                        font-size: 26px;
                    }
                }

                p {
                    font-size: 14px;

                    @media all and (min-width: 992px) {
                        font-size: 16px;
                    }
                }

                label {
                    font-weight: 400;

                    margin-bottom: 0;

                    &:focus-within {
                        color: rebeccapurple;
                    }
                }

                input[type="radio"] {
                    font: inherit;
                    // transform: translateY(-0.075em);

                    display: grid;

                    width: 24px;
                    height: 24px;
                    margin: 0;

                    color: currentColor;
                    border: 1px solid currentColor;
                    border-radius: 50%;
                    background-color: white;

                    -webkit-appearance: none;
                    appearance: none;
                    place-content: center;
                }

                input[type="radio"]::before {
                    width: 24px;
                    height: 24px;

                    content: "";

                    border-radius: 50%;
                    // border:0;
                    // transform: scale(0);


                    // transition: 120ms transform ease-in-out;
                    // box-shadow: inset 1em 1em rebeccapurple;
                    // background-color: CanvasText;
                }

                input[type="radio"]:checked {
                    border: 0;
                    background-image: url(/en/media/2017siteassets/contentfiles/images/checkbox.svg);
                    background-repeat: no-repeat;
                    background-size: 100%;
                }

                input[type="radio"]:checked::before {
                    //   transform: scale(1);
                    width: 23px;
                    height: 23px;
                }

                input[type="radio"]:focus {
                    // outline: 2px solid currentColor;
                    // outline-offset: 2px;
                    outline: none;
                }

                .folder-icon {
                    display: inline-block;
                    // min-width: 42px;

                    margin-right: 27px;
                    padding-top: 5px;

                    vertical-align: top;

                    img {
                        width: 32px;
                        height: 45px;
                    }
                }

                .folder-info {
                    display: inline-block;

                    max-width: calc(100% - 30px);

                    p {
                        margin-bottom: 0;
                    }
                }

                .folder-radio {
                    position: absolute;
                    top: calc(50% - 6px);
                    right: 20px;

                    display: inline-block;
                }
            }
        }

        &.site-details {
            @media all and (min-width: 992px) {
                padding-left: 0;
            }

            h3 {
                margin-top: 0;
                margin-bottom: 0;

                @media all and (min-width: 768px) {
                    padding: 0 0 30px;
                }

                @media all and (min-width: 992px) {
                    padding: 0 30px 30px;
                }
            }

            dl {
                @media all and (min-width: 992px) {
                    padding-right: 30px;
                    padding-left: 30px;
                }

                dd {
                    a.linktype {
                        &:after {
                            font-family: FontAwesome;
                            padding-left: 5px;
                            content: "\f101";
                        }

                        &.linktype--internal:after {
                            content: "\f178";
                        }

                        &.linktype--external:after {
                            content: "\f08e";
                        }

                        &.linktype--modal:after {
                            content: "\f2d0";
                        }

                        &.linktype--video:after {
                            content: "\f01d";
                        }

                        &.linktype--document:after {
                            content: "\f016";
                        }
                    }
                }

            }

            dt,
            dd {
                margin: 0;
                padding-top: 12px;

                text-align: left;
            }

            dt {
                font-size: 16px;
                font-weight: 900;
                line-height: 150%;

                border-top: 1px solid rgba(#BBBCBC, 0.25);

                &:first-child {
                    @media all and (max-width: 767px) {
                        border-top: 0;
                    }
                }
            }

            dd {
                margin-bottom: 12px;
                padding-bottom: 2px;


                @media all and (min-width: 768px) {
                    margin-left: 160px;
                    padding-top: 12px;

                    border-top: 1px solid rgba(#BBBCBC, 0.25);
                }

                @media all and (max-width: 991px) {
                    margin-bottom: 10px;
                    padding-bottom: 10px;
                }
            }
        }
    }

    &__image{
        margin-top: 13px;
        max-width: 100%;
    }
}
