.module-24,
.module-15 {
    figcaption {
        font-size: 16px;
        font-weight: 400;
        font-style: italic;
        line-height: 175%;

        width: 100%;
        // color: $crest-black;
        // background-color: #ebebed;
        // padding: 15px;
        // font-style: italic;
        // display: inline-block;
        margin-bottom: 20px;
        padding: 15px;

        color: $crest-black;
        background-color: $tint-crest-white;

        @media all and (min-width:768px) {
            margin-bottom: 30px;
        }

        @media all and (min-width:1200px) {
            margin-bottom: 40px;
        }
    }
}
