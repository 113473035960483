// == VARIABLES

$header-height-xs: 130px;
$header-height-md: 184px;
$header-height-lg: 124px;

// == NO SCROLL ON MENU OPEN

html {
    height: 100%;
}

/*
ody.no-scroll {
	height: 100%;
	width: 100%;
	overflow-y: hidden !important;
}
*/
// == HEADER

body.smoothscroll .header {
    display: none;
}
.header {
    height: $header-height-xs;

    transition: transform 0.25s ease-in-out;

    background-color: $crest-white;

    will-change: transform;

    @media(min-width: $screen-md) {
        height: $header-height-lg;
    }

    &.fixed {
        position: fixed;
        z-index: 1010;
        top: 0;
        right: 0;
        left: 0;
    }

    &.unpinned {
        transform: translateY(-100%);
    }

    &.pinned {
        transform: translateY(0);

        // box-shadow: 0 5px 5px rgba(0,0,0,0.1)
    }
    .navbar__brand {
        img {
            width: auto;
            height: 40px;
            margin: 17px 0 0 15px;
            padding: 0;

            @media(min-width: $screen-md) {
                height: 70px;
                margin: 28px 0 0 30px;
            }
        }
    }
}

// == LANG
// SINGLE LANGUAGE LINK

.language {
    float: right;

    margin-right: 15px;

    > a {
        position: relative;
        top: 25px;

        padding: 10px;

        text-align: center;

        color: white;
        background-color: $quad-green;

        @media(min-width: $screen-md) {
            top: 50px;
        }
    }
}

// MULTI LANGUAGE DROPDOWN

.dropdown {
    &.dropdown--lang {
        position: relative;
        top: 16px;

        float: right;

        margin-right: 15px;

        @media(min-width: $screen-md) {
            top: 40px;
        }

        button {
            position: relative;

            padding: 9px 7px;

            text-align: center;

            color: white;
            border: 0;
            border-radius: 0;
            background-color: $quad-green;

            &:focus,
            &:active {
                outline: none;
                box-shadow: 0 0 10px rgba(black, 0.3);
            }
        }

        .dropdown-menu {
            position: absolute;
            z-index: 10;
            top: 100%;
            right: auto;
            left: 0;

            width: auto;
            width: 80px;
            min-width: 80px;
            padding: 0;

            color: white;
            border: 0;
            background-color: $quad-green;

            a {
                font-family: Roboto, sans-serif;
                font-size: 16px;
                font-weight: 400;

                padding: 10px 7px;

                color: white;
                border-top: 1px solid rgba(white, 0.2);

                &:hover {
                    background-color: $crest-blue;
                }
            }
        }
    }
}

// == TOP NAV 2020 COVID-19 SPECIAL EDITION

.top-nav {
    display: flex;
    clear: both;
    align-items: stretch;
    justify-content: center;

    width: 100%;
    height: 60px;
    margin: 0;
    padding: 0;

    border-top: 1px solid $limestone;
    border-bottom: 1px solid $limestone;
    background-color: $crest-white;

    @media(min-width: $screen-lg) {
        position: absolute;
        top: 0;
        right: 185px;

        display: block;

        width: auto;
        height: 100%;

        border: 0;
    }

    > li {
        display: flex;
        align-items: stretch;

        margin: 0;
        padding: 0;

        border-right: 1px solid $limestone;

        flex: 1;

        &:last-child {
            border: 0;
        }

        @media(min-width: $screen-lg) {
            float: left;

            height: 100%;
            margin-left: 1px;
            padding: 0 10px;

            border: 0;
        }

        > a {
            width: 100%;
            padding-top: 12px;

            text-align: center;

            @media(min-width: $screen-lg) {
                display: flex;
                align-items: center;

                padding: 0 10px 0 10px;
            }

            .fa {
                font-size: 18px;

                display: block;

                transition: 0.3s ease;

                @media(min-width: $screen-lg) {
                    font-size: 28px;

                    display: inline;

                    order: 2;
                }
            }

            span {
                font-size: 12px;

                text-transform: uppercase;

                color: $crest-black;

                @media(min-width: $screen-lg) {
                    font-size: 16px;

                    padding-right: 10px;

                    order: 1;
                }
            }
        }

        &.dropdown.open {
            > a {
                .fa {
                    transform: rotate(-90deg);
                }
            }
        }
    }
}

// top nav colours

.top-nav {
    .icon-crest-red {
        background: $crest-white;

        .fa {
            color: $crest-red;
        }

        &.open {
            background: $crest-red;

            * {
                color: $crest-white;
            }
        }
    }

    .icon-crest-white {
        background: $crest-white;

        .fa {
            color: $crest-black;
        }

        &.open {
            background: $crest-black;

            * {
                color: $crest-white;
            }
        }
    }

    .icon-tint-crest-white {
        background: $crest-white;

        .fa {
            color: $crest-black;
        }

        &.open {
            background: $crest-black;

            * {
                color: $crest-white;
            }
        }
    }

    .icon-crest-black {
        background: $crest-white;

        .fa {
            color: $crest-black;
        }

        &.open {
            background: $crest-black;

            * {
                color: $crest-white;
            }
        }
    }

    .icon-crest-blue {
        background: $crest-white;

        .fa {
            color: $crest-blue;
        }

        &.open {
            background: $crest-blue;

            * {
                color: $crest-white;
            }
        }
    }

    .icon-quad-green {
        background: $crest-white;

        .fa {
            color: $quad-green;
        }

        &.open {
            background: $quad-green;

            * {
                color: $crest-white;
            }
        }
    }

    .icon-sky-blue {
        background: $crest-white;

        .fa {
            color: $sky-blue;
        }

        &.open {
            background: $sky-blue;

            * {
                color: $crest-white;
            }
        }
    }

    .icon-crest-yellow {
        background: $crest-white;

        .fa {
            color: $crest-yellow;
        }

        &.open {
            background: $crest-yellow;

            * {
                color: $crest-black;
            }
        }
    }

    .icon-earth-red {
        background: $crest-white;

        .fa {
            color: $earth-red;
        }

        &.open {
            background: $earth-red;

            * {
                color: $crest-white;
            }
        }
    }

    .icon-ogham-stone {
        background: $crest-white;

        .fa {
            color: $ogham-stone;
        }

        &.open {
            background: $ogham-stone;

            * {
                color: $crest-white;
            }
        }
    }

    .icon-dawn-mauve {
        background: $crest-white;

        .fa {
            color: $dawn-mauve;
        }

        &.open {
            background: $dawn-mauve;

            * {
                color: $crest-white;
            }
        }
    }

    .icon-limestone {
        background: $crest-white;

        .fa {
            color: $limestone;
        }

        &.open {
            background: $limestone;

            * {
                color: $crest-black;
            }
        }
    }

    .icon-brand-twitter {
        background: $crest-white;

        .fa {
            color: $brand-twitter;
        }

        &.open {
            background: $brand-twitter;

            * {
                color: $crest-white;
            }
        }
    }

    .icon-brand-facebook {
        background: $crest-white;

        .fa {
            color: $brand-facebook;
        }

        &.open {
            background: $brand-facebook;

            * {
                color: $crest-white;
            }
        }
    }
}

.top-nav {
    .bg-crest-red {
        background: $crest-red;

        .fa,
        span {
            color: $crest-white;
        }

        &.open {
            background: $crest-red;

            * {
                color: $crest-white;
            }
        }
    }

    .bg-crest-white {
        background: $crest-white;

        .fa,
        span {
            color: $crest-black;
        }

        &.open {
            background: $crest-black;

            * {
                color: $crest-white;
            }
        }
    }

    .bg-tint-crest-white {
        background: $tint-crest-white;

        .fa,
        span {
            color: $crest-black;
        }

        &.open {
            background: $crest-black;

            * {
                color: $crest-white;
            }
        }
    }

    .bg-crest-black {
        background: $crest-black;

        .fa,
        span {
            color: $crest-white;
        }

        &.open {
            background: $crest-black;

            * {
                color: $crest-white;
            }
        }
    }

    .bg-crest-blue {
        background: $crest-blue;

        .fa,
        span {
            color: $crest-white;
        }

        &.open {
            background: $crest-blue;

            * {
                color: $crest-white;
            }
        }
    }

    .bg-quad-green {
        background: $quad-green;

        .fa,
        span {
            color: $crest-white;
        }

        &.open {
            background: $quad-green;

            * {
                color: $crest-white;
            }
        }
    }

    .bg-sky-blue {
        background: $sky-blue;

        .fa,
        span {
            color: $crest-white;
        }

        &.open {
            background: $sky-blue;

            * {
                color: $crest-white;
            }
        }
    }

    .bg-crest-yellow {
        background: $crest-yellow;

        .fa,
        span {
            color: $crest-black;
        }

        &.open {
            background: $crest-yellow;

            * {
                color: $crest-black;
            }
        }
    }

    .bg-earth-red {
        background: $earth-red;

        .fa,
        span {
            color: $crest-white;
        }

        &.open {
            background: $earth-red;

            * {
                color: $crest-white;
            }
        }
    }

    .bg-ogham-stone {
        background: $ogham-stone;

        .fa,
        span {
            color: $crest-white;
        }

        &.open {
            background: $ogham-stone;

            * {
                color: $crest-white;
            }
        }
    }

    .bg-dawn-mauve {
        background: $dawn-mauve;

        .fa,
        span {
            color: $crest-white;
        }

        &.open {
            background: $dawn-mauve;

            * {
                color: $crest-white;
            }
        }
    }

    .bg-limestone {
        background: $limestone;

        .fa,
        span {
            color: $crest-black;
        }

        &.open {
            background: $limestone;

            * {
                color: $crest-black;
            }
        }
    }

    .bg-brand-twitter {
        background: $brand-twitter;

        .fa {
            color: $crest-white;
        }

        &.open {
            background: $brand-twitter;

            * {
                color: $crest-white;
            }
        }
    }

    .bg-brand-facebook {
        background: $brand-facebook;

        .fa {
            color: $crest-white;
        }

        &.open {
            background: $brand-facebook;

            * {
                color: $crest-white;
            }
        }
    }
}

// == TOP NAV
// .top-nav {
// position: absolute;
// top: 0;
// right: 60px;
// height: 100%;
// > li {
// // height: $header-height-xs;
// padding: 0;
// &:not(.language) {
// > a {
// position: relative;
// height: $header-height-xs;
// line-height: $header-height-xs;
// font-size: 8px;
// padding-top: 14px;
// @media (min-width: 350px) {
// font-size: 10px;
// }
// }
// }
// > a {
// display: block;
// text-transform: uppercase;
// text-decoration: none;
// color: $limestone-darker;
// padding: 0 2px;
// &:hover {
// cursor: pointer;
// }
// &:after {
// @include awesome;
// position: absolute;
// left: 50%;
// transform: translateX(-50%);
// font-size: 22px;
// top: -9px;
// }
// &.courses:after {
// content: "\f02e";
// color: $crest-red;
// }
// &.links:after {
// content: "\f053";
// color: $crest-blue;
// }
// &.search:after {
// content: "\f002";
// color: $crest-yellow;
// }
// }
//
// //== SEARCH TOGGLE
//
// &.search-toggle.open {
// background: $crest-yellow;
// a {
// color: #fff;
// &:after {
// color: #fff;
// content:"\f00d";
// }
// }
// }
// }
//
// @media (min-width: $screen-sm) {
// > li {
// padding: 0 25px 0 15px;
// margin: 0 1px 0 0;
// &:not(.language) {
// > a {
// font-size: 16px;
// padding-top: 0;
// }
// }
// > a {
// &:after {
// position: absolute;
// top: auto;
// left: auto;
// transform: none;
// margin-left: 6px;
// top: -1px;
// font-size: $font-size-h5;
// }
// }
// }
// }
//
// @media (min-width: $screen-md) {
// right: 130px;
// > li {
// // height: $header-height-lg;
// padding: 0 40px 0 25px;
// > a {
// &:after {
// margin-left: 12px;
// font-size: 30px;
// }
// }
// &:last-of-type {
// top: 0;
// }
// // li has language class now
// &:not(.language) {
// > a {
// height: $header-height-lg;
// line-height: $header-height-lg;
// }
// }
// }
// }
// @media all and (min-width: $screen-lg-min) {
// > li {
// padding: 0 60px 0 30px;
// }
// }
// }
// == DROPDOWN

.dropdown {
    position: static;

    @media(min-width: $screen-sm) {
        position: relative;
    }

    &.open {
        a:after {
            display: none;
        }
    }

    .dropdown-menu {
        top: 131px;
        right: 0;
        left: 0;

        width: 320px;
        margin: 0 auto;
        padding: 20px;

        transition: 0.5s ease;

        @media(min-width: $screen-sm) {
            top: 60px;

            width: auto;
            width: 390px;
        }

        @media(min-width: $screen-md) {
            top: 124px;

            min-width: 450px;
        }

        > li {
            line-height: 30px;

            @media(min-width: $screen-md) {
                line-height: 40px;
            }

            > a {
                @include serif-b;

                padding: 5px 20px;

                text-transform: capitalize;

                color: $crest-blue;
            }

            &.icon-menu {
                font-size: $font-size-h5;

                display: inline-block;

                @media(min-width: $screen-md) {
                    font-size: $font-size-h4;
                }

                .fa {
                    padding-right: 10px;

                    color: $crest-red;

                    &.fa-history {
                        color: $sky-blue;
                    }

                    &.fa-star {
                        color: $crest-yellow;
                    }
                }

                a {
                    @include serif-b;

                    color: $crest-blue;
                }
            }

            &.split-menu {
                li {
                    float: left;

                    width: 49%;
                    margin-right: 2%;
                    // line-height: 2;
                    margin-bottom: 7.5px;

                    @media(min-width: $screen-md) {
                        font-size: 20px;
                    }

                    a {
                        color: $crest-blue;
                    }

                    &:nth-of-type(even) {
                        margin-right: 0;
                    }
                }
            }

            &.top {
                margin-top: 15px;
                margin-bottom: 15px;

                text-transform: uppercase;

                > ul {
                    display: block;
                    overflow: auto;

                    margin-bottom: 20px;
                }
            }
        }
    }

    &.open {
        > a {
            // color: $crest-white;
            // background-color: $crest-blue;

            * {
                // color: $crest-white;
            }
        }
    }
}

.navbar {
    min-height: 50px;
    margin-bottom: 0;

    border: 0;

    .nav-toggle {
        position: relative;

        float: right;

        width: 70px;
        height: 70px;

        border: 0;
        outline: none;
        background-color: $tint-crest-white-darker;

        span {
            font-size: 10px;

            position: absolute;
            right: 0;
            bottom: 15px;
            left: 0;

            display: inline-block;

            transition: 0.5s ease;
            letter-spacing: 0;

            color: $limestone-darker;
        }

        &.active span {
            bottom: 15px;

            color: #FFFFFF;
        }

        &:after {
            @include awesome;

            font-size: 28px;

            position: relative;
            top: -8px;

            display: inline-block;

            width: 28px;

            content: "\f0c9";
            transition: 0.5s ease;
            text-align: center;

            color: $crest-blue;

            @media(min-width: $screen-md) {
                font-size: 42px;

                top: -7px;

                width: 42px;
            }
        }

        &.active {
            height: 70px;

            background-color: $crest-blue;

            &:after {
                top: -8px;

                content: "\f00d";

                color: $crest-white;
            }
        }

        @media(min-width: $screen-md) {
            width: 114px;
            height: 124px;

            span {
                font-size: 14px;

                right: 0;
                bottom: 20px;
                left: 0;

                width: 100%;

                letter-spacing: 1px;
            }

            &.active {
                height: 124px;
            }

            &.active span {
                bottom: 20px;

                color: #FFFFFF;
            }
        }
    }
}

// /////////////////////////////////////////////////////////
// navigation
// /////////////////////////////////////////////////////////

@media(min-width: 320px) {
    .page-wrap {
        position: relative;

        overflow-x: hidden;

        height: 100%;
        margin-top: $header-height-xs;

        @media(min-width: $screen-md) {
            margin-top: $header-height-md;
        }

        @media(min-width: $screen-lg) {
            margin-top: $header-height-lg;
        }

        &:after {
            position: absolute;
            z-index: 100;
            top: 0;
            right: 0;

            width: 0;
            height: 0;

            content: "";
            -webkit-transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
            transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;

            opacity: 0;
            background: rgba(0, 0, 0, 0.7);
        }

        .module-wrap {
            position: relative;
            z-index: 99;
            left: 0;

            height: 100%;
            // perspective: 1000px;

            transition: transform 0.5s;

            // transform: translate3d(0, 0, 0);
        }

        &.slide {
            &:after {
                width: 100%;
                height: 100%;

                content: "";
                -webkit-transition: opacity 0.5s;
                transition: opacity 0.5s;

                opacity: 1;
            }

            .navbar__nav {
                transition: transform 0.5s;
                transform: translate3d(0, 0, 0);
            }

            .module-wrap {
                // transform: translate3d(320px, 0, 0);
            }
        }
    }

    .navbar__nav {
        position: fixed;
        z-index: 2000;
        top: 70px;
        left: 0;

        overflow: auto;

        width: 100%;
        // height: 100%;
        padding-bottom: 100px;

        transition: all 0.5s;
        transform: translate3d(-100%, 0, 0);

        background: $crest-blue;

        .navbar__inner {
            .mainNavList {
                li {
                    line-height: 50px;

                    position: relative;

                    min-height: 50px;

                    a {
                        line-height: $line-height-base;

                        display: block;
                        display: inline-block;

                        width: 100%;
                        padding: 15px;

                        vertical-align: middle;

                        color: $crest-white;
                        border-right: 56px solid rgba(266, 266, 266, 0.2);
                        border-bottom: 1px solid rgba(266, 266, 266, 0.2);
                    }

                    button {
                        position: absolute;
                        top: 0;
                        right: 0;

                        width: 56px;
                        height: 52px;

                        border: 0;
                        border-bottom: 1px solid #336387;
                        outline: 0;
                        background-color: rgba(266, 266, 266, 0.2);

                        &[aria-expanded="false"]:after {
                            @include awesome;

                            content: "\f067";

                            color: $crest-white;
                        }

                        &[aria-expanded="true"]:after {
                            @include awesome;

                            content: "\f068";

                            color: $crest-white;
                        }
                    }

                    ul[id*="mainNav"] {
                        background-color: rgba(266, 266, 266, 0.2);
                    }
                }

                > li > a {
                    @include serif-b;
                }
            }
        }
    }
}

// /////////////////////////////////////////////////////////
// megamenu
// /////////////////////////////////////////////////////////

@media(min-width: $screen-md) {
    .page-wrap {
        margin-top: $header-height-md;

        @media(min-width: $screen-lg) {
            margin-top: $header-height-lg;
        }

        &:after {
            position: fixed;
            z-index: 100;
            top: 0;
            right: 0;

            width: 0;
            height: 0;

            content: "";
            -webkit-transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
            transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;

            opacity: 0;
            background: rgba(0, 0, 0, 0.7);
        }

        &.slide:after,
        &.show-search:after,
        &.show-mega-slide:after {
            width: 100%;
            height: 100%;

            content: "";
            -webkit-transition: opacity 0.5s;
            transition: opacity 0.5s;

            opacity: 1;
        }
    }

    .navbar__nav {
        position: absolute;
        z-index: 2000;
        top: 0;
        right: 0;
        left: 0;

        overflow: hidden;

        transition: transform 0.35s ease;
        transform: translate3d(0, -100%, 0);

        background-color: $crest-white;

        .navbar__inner {
            overflow: hidden;

            padding: 30px 15px;

            @media(min-width: 1400px) {
                padding: 50px 60px;
            }

            .mainNavList {
                // display: flex;
                // justify-content: space-between;

                > li {
                    position: relative;
                    // flex:auto;

                    float: left;

                    width: 16.66667%;
                    padding: 15px;

                    > a {
                        @include serif-b;

                        font-size: 20px;
                        line-height: 1.5;

                        position: absolute;

                        color: $crest-blue;

                        @media(min-width: $screen-lg-min) {
                            font-size: 24px;
                        }
                    }
                }

                button {
                    display: none;
                }

                .collapse {
                    display: block;
                }

                ul[id*="mainNav"] {
                    margin-top: 80px;

                    li a {
                        display: block;

                        padding: 6px 0;

                        color: rgba($crest-blue, 0.7);

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    &.slide .navbar__nav {
        // top: $header-height-md;
        transition: transform 0.35s ease;
        transform: translate3d(0, 0, 0);

        border-top: 2px solid $crest-blue;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);

        // @media(min-width: $screen-lg) {
        //   top: $header-height-lg;
        // }
    }
}

/*
ody.scroll-up .slide .navbar__nav {
	top: 0;
}
*/
// /////////////////////////////////////////////////////////
// search
// /////////////////////////////////////////////////////////

.search-wrapper {
    position: absolute;
    // box-shadow: 0 5px 5px rgba(0,0,0,0.1);
    z-index: 2000;
    top: 0;
    right: 0;
    left: 0;

    overflow: hidden;

    transition: transform 0.35s ease;
    transform: translate3d(0, -100%, 0);

    background-color: $crest-white;

    .inner {
        max-width: 1000px;
        margin-right: auto;
        margin-left: auto;
        padding: 50px 20px;

        @media(min-width: $screen-md) {
            min-height: 400px;
            margin-top: 24px;
            padding: 100px 0;
        }

        .input-group {
            input {
                height: 60px;

                color: $crest-blue;
                border-radius: 0;
            }

            input[type="radio"] {
                position: static;

                padding: 0;
            }

            .input-group-addon {
                height: 60px;

                border-color: $crest-yellow;
                border-radius: 0;
                background: $crest-white !important;
            }

            .form-control {
                border-color: $crest-yellow;
                border-right: 0;
                box-shadow: 0 0 0;
            }

            button {
                border: 0;
                background: transparent;

                span {
                    font-size: $font-size-h3;

                    color: $crest-red;
                }
            }

            .reset {
                font-size: 12px;
                font-weight: 700;

                position: absolute;
                z-index: 100;
                top: 20px;
                right: 75px;

                height: auto;
                padding: 3px 6px 2px;

                text-transform: uppercase;

                color: $crest-black;
                border: 0;
                background: $tint-crest-white-darker;
            }

            @media(min-width: $screen-md) {
                input {
                    font-size: $font-size-h5;

                    padding: 0 30px;
                }

                button {
                    width: 80px;
                }

                input,
                .input-group-addon {
                    height: 100px;
                }

                .reset {
                    top: 40px;
                    right: 125px;
                }
            }
        }

        .quicksearchResults .nav-tabs {
            margin-top: 20px;
        }
    }
}

.page-wrap.show-search .search-wrapper {
    top: 0;

    transition: transform 0.35s ease;
    transform: translate3d(0, 0, 0);

    border-top: 2px solid $crest-yellow;

    // box-shadow: 0 5px 5px rgba(0,0,0,0.1)
}

// @media(min-width: $screen-md) {
//   .page-wrap.show-search .search-wrapper {
//     top: $header-height-md;
//   }
// }

// @media(min-width: $screen-lg) {
//   .page-wrap.show-search .search-wrapper {
//     top: $header-height-lg;
//   }
// }

// /////////////////////////////////////////////////////////
// Footer menu in mega menu
// /////////////////////////////////////////////////////////

.mega-footer {
    position: relative;

    overflow: hidden;

    padding: 20px 0;

    li {
    }

    a {
        font-family: "Playfair Display", serif;
        font-size: 16px;
        font-weight: 700;

        display: block;

        padding: 10px 15px;

        color: $crest-white;
    }

    .fa {
        color: $crest-yellow;
    }
}

@media(max-width: $screen-md) {
    .search-wrapper .mega-footer,
    .mega-slide .mega-footer {
        a {
            color: $crest-blue;
        }
    }
}

@media(min-width: $screen-md) {
    .mega-footer {
        padding: 20px 50px;

        li {
            float: left;

            padding: 0 30px 0 0;
        }

        a {
            font-size: 24px;

            color: $crest-blue;
        }

        .fa {
            color: $crest-yellow;
        }
    }

    .navbar__nav .mega-footer {
        border-top: 2px solid $crest-blue;
    }

    .search-wrapper .mega-footer {
        border-top: 2px solid $crest-yellow;
    }

    .mega-slide .mega-footer {
        border-top: 2px solid $sky-blue;
    }
}

// /////////////////////////////////////////////////////////
// mega slide
// /////////////////////////////////////////////////////////

.mega-slide {
    position: absolute;
    // box-shadow: 0 5px 5px rgba(0,0,0,0.1);
    z-index: 2000;
    top: 0;
    right: 0;
    // height: 600px;
    left: 0;

    overflow: hidden;

    transition: transform 0.35s ease;
    transform: translate3d(0, -100%, 0);

    background-color: $crest-white;

    .inner {
        margin-right: auto;
        // max-width: 800px;
        margin-left: auto;
        padding: 25px 20px 20px;

        // height: 100%;
        // overflow-y: auto;

        @media(min-width: $screen-md) {
            min-height: 400px;
            // margin-top: 24px;
            padding: 41px 100px;
        }
    }
}

.page-wrap.show-mega-slide .mega-slide {
    top: 0;

    transition: transform 0.35s ease;
    transform: translate3d(0, 0, 0);

    border-top: 2px solid $sky-blue;

    // box-shadow: 0 5px 5px rgba(0,0,0,0.1)
}

@media(min-width: $screen-md) {
    .page-wrap.show-mega-slide .mega-slide {
        top: $header-height-md;
    }
}

.mega-close {
    position: absolute;
    z-index: 1000;
    top: 0;
    right: 0;

    width: 50px;
    height: 50px;
    margin: 0;
    padding: 0;

    -webkit-transition: color 0.2s;
    -o-transition: color 0.2s;
    transition: color 0.2s;
    text-decoration: none;

    opacity: 1;
    color: $crest-white;
    border: 0;
    border-radius: 0;
    background: $sky-blue;
    text-shadow: none;
}

.mega-close:hover,
.mega-close:focus {
    background-color: $crest-yellow;
}

.mega-close:before {
    font-family: "FontAwesome";
    font-size: 25px;
    font-size: 30px;
    line-height: 35px;
    line-height: 50px;

    position: absolute;
    position: absolute;
    top: 0;
    top: 0;
    left: 0;
    left: 0;

    display: block;
    display: block;

    width: 35px;
    width: 50px;

    content: "\f00d";
    text-align: center;
}

@media(min-width: $screen-md) {
    /*
mega-close {
		width: 124px;
		height: 124px;
		font-size: 64px;
	}

	.mega-close:before {
		font-size: 50px;
		line-height: 105px;
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 124px;
	}

	.mega-close .sr-only {
		display: block;
		position: static;
		width: auto;
		height: auto;
		margin: auto;
		padding: 0;
		overflow: auto;
		clip: auto;
		text-transform: uppercase;
		font-size: 14px;
		padding-top: 60px;
}
*/
}

/*
****************************************************************************************

PROMPT
Used for asking users what network tp be on

*****************************************************************************************
*/

.prompt {
    position: relative;

    max-width: 260px;

    border-radius: 4px;
    background: #FFFFFF;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.1);
}

.prompt.networkPrompt {
    position: absolute;
    z-index: 30;
    top: 140px;
    right: 15px;

    display: block;

    transition: 0.5s ease 0.5s opacity;

    opacity: 1;
}

.sticky .prompt.networkPrompt {
    opacity: 0;
}

.prompt.networkPrompt.show {
    display: block;
}

.prompt:before {
    display: block;

    width: 0;
    height: 0;

    content: "";
}

.prompt.networkPrompt:before {
    position: absolute;
    top: -9px;
    right: 34px;

    display: block;

    width: 0;
    height: 0;

    content: "";

    border-right: 10px solid transparent;
    border-bottom: 10px solid #FFFFFF;
    border-left: 10px solid transparent;
}

.prompt .close {
    font-size: 22px;

    position: absolute;
    top: 3px;
    right: 8px;

    cursor: pointer;

    color: #D54D32;
}

.prompt .inner {
    padding: 10px 20px;
}

.promptTitle {
    font-size: 18px;
    line-height: 22px;

    margin: 10px 0;

    color: $crest-blue;
}

.promptTitle a {
    color: inherit;
}

.promptText {
    font-size: 13px;
    line-height: 16px;

    margin: 10px 0;

    color: #626E7A;
}

/* nimation */

.prompt {
    -webkit-animation-name: colorChange;
    -moz-animation-name: colorChange;
    -ms-animation-name: colorChange;
    animation-name: colorChange;
    -webkit-animation-duration: 2s;
    -moz-animation-duration: 2s;
    -ms-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-delay: 3s;
    -moz-animation-delay: 3s;
    -ms-animation-delay: 3s;
    animation-delay: 3s;
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    -ms-animation-iteration-count: 1;
    animation-iteration-count: 1;

    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    animation-fill-mode: both;
}

.prompt:before {
    -webkit-animation-name: arrowChange;
    -moz-animation-name: arrowChange;
    -ms-animation-name: arrowChange;
    animation-name: arrowChange;
    -webkit-animation-duration: 2s;
    -moz-animation-duration: 2s;
    -ms-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-delay: 3s;
    -moz-animation-delay: 3s;
    -ms-animation-delay: 3s;
    animation-delay: 3s;
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    -ms-animation-iteration-count: 1;
    animation-iteration-count: 1;

    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-moz-keyframes colorChange {
    1% {
        background: #FFFFFF;
    }

    50% {
        background: $crest-yellow;
    }

    100% {
        background: #FFFFFF;
    }
}
@-webkit-keyframes colorChange {
    1% {
        background: #FFFFFF;
    }

    50% {
        background: $crest-yellow;
    }

    100% {
        background: #FFFFFF;
    }
}
@-ms-keyframes colorChange {
    1% {
        background: #FFFFFF;
    }

    50% {
        background: $crest-yellow;
    }

    100% {
        background: #FFFFFF;
    }
}
@keyframes colorChange {
    1% {
        background: #FFFFFF;
    }

    50% {
        background: $crest-yellow;
    }

    100% {
        background: #FFFFFF;
    }
}
@-moz-keyframes arrowChange {
    1% {
        border-bottom: 10px solid #FFFFFF;
    }

    50% {
        border-bottom: 10px solid $crest-yellow;
    }

    100% {
        border-bottom: 10px solid #FFFFFF;
    }
}
@-webkit-keyframes arrowChange {
    1% {
        border-bottom: 10px solid #FFFFFF;
    }

    50% {
        border-bottom: 10px solid $crest-yellow;
    }

    100% {
        border-bottom: 10px solid #FFFFFF;
    }
}
@-ms-keyframes arrowChange {
    1% {
        border-bottom: 10px solid #FFFFFF;
    }

    50% {
        border-bottom: 10px solid $crest-yellow;
    }

    100% {
        border-bottom: 10px solid #FFFFFF;
    }
}
@keyframes arrowChange {
    1% {
        border-bottom: 10px solid #FFFFFF;
    }

    50% {
        border-bottom: 10px solid $crest-yellow;
    }

    100% {
        border-bottom: 10px solid #FFFFFF;
    }
}

/*
oSCRIPT
**********************************************************************
*/

.noScriptContainer {
    font-size: 15px;

    position: fixed;
    z-index: 100;
    right: 0;
    bottom: 0;
    left: 0;

    padding: 20px 10px 20px;

    text-align: center;

    color: #FFFFFF;
    background: $crest-blue;
}

.noScriptContainer:hover {
    opacity: 1;
}

.noScriptContainer a {
    display: inline-block;

    text-decoration: underline;

    color: $crest-yellow;
}

.noScriptContainer .fa {
    font-size: 18px;

    position: relative;
    top: 2px;

    padding-right: 10px;

    color: $crest-yellow;
}

.noScriptContainer .btn {
    font-size: 13px;
    line-height: 13px;

    padding: 5px 10px;

    text-decoration: none;
    text-transform: none;

    color: #FFFFFF;
    background: $crest-yellow;
}

/*
EOPLE SEARCH RESULTS
***********************************************************
*/

.search-results-list.people {
    .item {
        overflow: hidden;

        padding: 10px 0;

        border-bottom: 1px solid #DDDDDD;

        @media(min-width: $screen-sm-min) {
            display: flex;
            align-items: baseline;

            flex-wrap: nowrap;
        }

        * {
            line-height: 1.25;
        }

        &.email {
            .label {
                background-color: $crest-blue;
            }
        }

        &.phone {
            .label {
                background-color: $quad-green;
            }
        }
    }

    h3 {
        font-family: "Playfair Display", serif;
        font-size: 22px;
        font-weight: 400;

        margin: 0 0 5px;
        padding-right: 15px;

        text-decoration: none;

        color: #337AB7;

        @media(min-width: $screen-sm-min) {
            width: 35%;
        }
    }

    .label {
        font-family: Roboto, sans-serif;
        font-size: 12px;
        font-weight: 700;

        position: relative;
        top: 4px;

        float: left;

        margin-right: 10px;
        padding: 4px 7px 2px;

        letter-spacing: 1px;
        text-transform: uppercase;

        @media(min-width: $screen-sm-min) {
            top: -1px;

            float: right;
        }
    }

    p {
        float: left;

        margin: 5px 15px 5px 0;
        padding: 0;
    }

    @media(min-width: $screen-sm-min) {
        p {
            float: none;

            margin: 0;
        }

        .link {
            width: 30%;
        }

        .summary {
            width: 35%;
        }
    }
}

.search-results-list.people-grouped {
    padding: 30px 0;

    .item {
        overflow: hidden;

        padding: 18px 0 17px;

        border-bottom: 1px solid #DDDDDD;

        @media(min-width: $screen-md-min) {
            // display: flex;
        }

        * {
            line-height: 1.25;
        }
    }

    h3 {
        font-family: Roboto, sans-serif;
        font-size: 16px;
        font-weight: 400;

        margin: 0 0 5px;
        padding-right: 15px;

        text-decoration: none;

        color: $crest-black;

        em {
            font-weight: 700;
            font-style: normal;
        }

        @media(min-width: $screen-md-min) {
            float: left;

            width: 20%;
            margin-bottom: 0;
        }
    }

    .contact-info {
        margin: 0 0 0;

        @media(min-width: $screen-md-min) {
            float: left;

            width: 60%;
        }

        li {
            @include clearfix;

            &:nth-of-type(1) {
                .label {
                    margin-bottom: 10px;

                    @media(min-width: $screen-md-min) {
                        margin-bottom: 0;
                    }
                }
            }

            &:nth-of-type(2) {
                margin-bottom: 10px;

                @media(min-width: $screen-md-min) {
                    margin-bottom: 0;
                }
            }

            @media(min-width: $screen-md-min) {
                float: left;

                width: 50%;
                margin: 0 0 1px 0;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .label {
        font-family: Roboto, sans-serif;
        font-size: 12px;
        font-weight: 700;

        position: relative;
        top: -1px;

        float: left;

        min-width: 60px;
        margin-right: 7px;
        padding: 4px 2px 2px;

        letter-spacing: 1px;
        text-transform: uppercase;

        border-radius: 0;
    }

    .label-email {
        background-color: $crest-blue;
    }

    .label-phone {
        background-color: $quad-green;
    }

    p {
        margin: 0 0 0;

        @media(min-width: $screen-md-min) {
            float: left;

            width: 20%;
        }
    }

    &.people-grouped--icon {
        .label {
            position: relative;

            display: block;

            min-width: 1px;
            padding: 0;

            &:before {
                font-family: FontAwesome;
                font-size: 16px;

                display: block;

                width: 16px;
                height: 16px;

                content: "";
            }
        }

        .label-email {
            color: $crest-blue;
            background-color: transparent;

            &:before {
                content: "\f0e0";
            }
        }

        .label-phone {
            color: $quad-green;
            background-color: transparent;

            &:before {
                font-size: 18px;

                content: "\f095";
            }
        }
    }
}
