

.articleList {
	margin: 0;
	padding: 0;
	> li {
		margin: 0 0 40px;
		padding: 0;
		border: 1px solid $tint-crest-white-darker;
		overflow: hidden;
		&:before {
			content:"";
		}
	}
	a:not(.btn) {
		// display: inline-block;
		color: $crest-blue;
	}
	h2 {
		font-family: 'Roboto', sans-serif;
		font-weight: 700;
		font-size: 18px;
		//border-bottom: 1px solid $tint-crest-white-darker;
		margin: 0;
		padding: 20px 15px;

	}
	.date {
		background: $crest-yellow;
		color: $crest-black;
		font-weight: 700;
		//font-size: 14px;
		//line-height: 20px;
		text-align: center;
		//text-transform: uppercase;
		//width: 60px;
		//height: 60px;
		position: absolute;
		z-index: 20;
		top: 0;
		left: 0;
		//padding-top: 12px;
		font-size: 12px;
		line-height: 1;
		padding: 8px 12px;
		@media (min-width: $screen-sm-min) {
			font-size: 14px;
			padding: 12px 15px;
		}
		span {
			display: block;
			font-size: 24px;
			color: $crest-black;
		}
	}
	.image {
		width: 100%;
		overflow: hidden;
		position: relative;
		img {
			max-width: 100%;
		}
	}
	p {
		padding: 20px 20px 10px 20px;
	}
	.btn {
		white-space: normal;
		margin: 0 20px 20px;
		//position: absolute;
		//z-index: 10;
		//bottom: 20px;
		//right: 20px;
	}
}



@media all and (min-width: $screen-sm) {

	.articleList {
		.image {
			width: 45%;
			float: left;
			position: relative;
			.module-3c__feature {
				bottom: 0;
				top: auto;
				padding: 10px 15px !important;
			}
			img {
				width: 100%;
			}
		}
		.text {
			width: 55%;
			float: left;
			padding-top: 0;
			margin-top: -5px;
			p {
				padding-top: 0;
			}
		}
	}
	.home .articleList {
		max-width: 1400px;
		margin-left: auto;
		margin-right: auto;
		padding-top: 30px;
		@media (min-width: $screen-sm-min) {
			padding-top: 45px;
		}
		@media (min-width: $screen-md-min) {
			padding-top: 60px;
		}
		li {
			width: 65%;
			margin-left: auto;
			margin-right: auto;
		}
	}

}

@media (min-width: $screen-md) and (max-width: $screen-lg) {

	.articleList {
		/*.date {
			font-size: 12px;
			line-height: 14px;
			width: 40px;
			height: 40px;
			padding-top: 9px;
			span {
				display: block;
				font-size: 20px;
			}
		}*/
	}

}


@media (min-width: $screen-lg) and (max-width: 1400px) {

	.articleList {
		.image {
			width: 100%;
		}
		.text {
			width: 100%;
			padding-top: 20px;
			margin-top: 0;
		}
	}

}




