/*GOOGLE SEARCH
*********************************************************/

body .gsc-webResult .gsc-result,
body .gsc-webResult .gsc-result:hover {
	border-bottom: 1px solid #ddd;
}

body #website-quick .gsc-webResult .gsc-result .gsc-thumbnail-inside{
    display: block;
}

body .cse .gsc-control-cse,
body .gsc-control-cse {
	padding-left: 0;
	padding-right: 0;
}

body .gsc-control-cse .gs-spelling,
body .gsc-control-cse .gs-result .gs-title,
body .gsc-control-cse .gs-result .gs-title * {
	font-family: $font-family-serif;
	font-size: 22px;
    font-weight: 400;
    margin: 0 0 5px;
    color: #337ab7;
    text-decoration: none;
}

body .gsc-control-cse .gs-spelling:hover,
body .gsc-control-cse .gs-result .gs-title:hover {
    color: $crest-blue;
}

body .gs-result a.gs-visibleUrl,
body .gs-result .gs-visibleUrl {
	color: $quad-green;
    margin: 0 0 5px;
    font-size: 14px;
}

body .gsc-control-cse .gsc-table-result,
body .searchResultsContainer table {
	margin: 0;
}

body .gsc-above-wrapper-area {
	border: 0;
}

body .gsc-tabData.gsc-tabdActive table td {
	border: 0;
	padding: 8px;
}

body .gsc-control-cse .gs-snippet {
	color: $crest-black;
	font-size: 16px;
}

/*pagination*/

body .gsc-results .gsc-cursor {
	display: block;
}

body .gsc-results .gsc-cursor-box {
    margin: 20px 0;
    overflow: hidden;
}

body .gsc-results .gsc-cursor-box .gsc-cursor-page {
	position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.33333;
    text-decoration: none;
    color: #337ab7;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-left: -1px;
    margin-right: 0;
}

body .gsc-results .gsc-cursor-box .gsc-cursor-current-page {
	background-color: $sky-blue;
    border-color: $sky-blue;
    color: #fff;
}

body .gsc-results .gsc-cursor-box .gsc-cursor-page:first-child {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

body .gsc-results .gsc-cursor-box .gsc-cursor-page:last-child {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}

.searchResultsContainer .resultsCount {
	color: $sky-blue;
	padding: 2px 5px 1px;
	font-size: 14px;
	position: relative;
	left: 5px;
}

/*quick search*/

.search-wrapper .gsc-control-cse .gsc-table-result {
	display: none;
}

